import ReactDOMServer from "react-dom/server";
import {
  DKTooltipWrapper,
  DKButton,
  DKIcon,
  DKIcons,
  DKLabel,
  DKInput,
  INPUT_VIEW_DIRECTION,
  INPUT_TYPE
} from "deskera-ui-library";
import {
  AUDIT_ACTIONS_TITLE,
  AUDIT_BULK_OBJECT_TYPE,
  AUDIT_LOG_EMPTY_DISPLAY_VALUE,
  AUDIT_OBJECT_TYPE,
  NOTIFICATION_ACTION_TYPE,
  OBJECT_TYPE
} from "../constants/Enum";
import DOMPurify from "dompurify";
import { COLUMN_CODE, TableManger, TABLES } from "../managers/TableManger";
import Utility, {
  findAndConvertLink,
  isString,
  toCurrencyFormat
} from "../utility/Utility";
import {
  APPROVAL_STATUS_DISPLAY_VALUE,
  GOOGLE_MAP_URL,
  TICKET_DEFAULT_SYSTEM_USER
} from "../constants/Constant";
import {
  Attachment,
  AttachmentPreview,
  getAttachmentPreviewInPopup
} from "../components/common/AttachmentList";
import RouteManager, {
  PAGE_ROUTES,
  ROUTE_ID_IDENTIFIER
} from "../managers/RouteManager";
import { DateUtil } from "../utility/Date";
import TenantManager from "../managers/TenantManager";
import { store } from "../redux/store";
import { DealManager } from "../managers/DealManager";
import { getFullName } from "../model/User";
import { isEqual } from "lodash";
import { replaceCountrySpecificKeywords } from "../utility/Utility";
export class Renderers {
  static recordNameWithBookSyncStatus = ({ value, rowData, rowIndex }) => {
    const contactBooksCodeColumnId = TableManger.getColumnId(
      TABLES.CONTACT,
      COLUMN_CODE.CONTACT.BOOKS_CONTACT_ID
    );
    const accountBooksCodeColumnId = TableManger.getColumnId(
      TABLES.ACCOUNT,
      COLUMN_CODE.ACCOUNT.BOOKS_CONTACT_ID
    );

    if (
      rowData[contactBooksCodeColumnId] > 0 ||
      rowData[accountBooksCodeColumnId] > 0 ||
      rowData.books_contact_id > 0
    ) {
      return (
        <div className="text-align-left">
          {value}{" "}
          <sup>
            {" "}
            <span className="fs-xs fw-m text-blue">Synced</span>
          </sup>
        </div>
      );
    }
    return <div className="text-align-left">{value}</div>;
  };

  //** AUDIT LOG RENDERERS GOES HERE **//
  static actionTypeRenderer = ({ rowData }) => {
    const userHasCRUDPermission = [
      NOTIFICATION_ACTION_TYPE.REC_BULK_CREATE,
      NOTIFICATION_ACTION_TYPE.REC_BULK_DELETE,
      NOTIFICATION_ACTION_TYPE.REC_BULK_CREATE_REJECTED,
      NOTIFICATION_ACTION_TYPE.REC_BULK_UPDATE_REJECTED,
      NOTIFICATION_ACTION_TYPE.REC_BULK_UPDATE
    ].includes(rowData.action);
    const dynamicEstimateQuote = replaceCountrySpecificKeywords(
      AUDIT_OBJECT_TYPE[rowData.objectType]
    );

    if (userHasCRUDPermission)
      return (
        <div style={{ textAlign: "left" }}>
          {AUDIT_BULK_OBJECT_TYPE[rowData.objectType]}&nbsp;
          {AUDIT_ACTIONS_TITLE[rowData.action]}
        </div>
      );
    else if (
      [OBJECT_TYPE.TICKET].includes(rowData?.objectType) &&
      [
        NOTIFICATION_ACTION_TYPE.REC_UPDATE,
        NOTIFICATION_ACTION_TYPE.REC_CREATE,
        NOTIFICATION_ACTION_TYPE.REC_DELETE
      ].includes(rowData?.action)
    )
      return (
        <div style={{ textAlign: "left" }}>
          {AUDIT_OBJECT_TYPE[rowData.objectType]}&nbsp;
          <strong>{rowData.metaData?.recordsAfter?.[0]?.ticket_no}</strong>
          &nbsp;
          {AUDIT_ACTIONS_TITLE[rowData.action]}
        </div>
      );
    else if (
      [OBJECT_TYPE.TICKET].includes(rowData?.objectType) &&
      [
        NOTIFICATION_ACTION_TYPE.ATTACHMENT_CREATE,
        NOTIFICATION_ACTION_TYPE.ATTACHMENT_DELETE,
        NOTIFICATION_ACTION_TYPE.ATTACHMENT_UPDATE
      ].includes(rowData?.action)
    )
      return (
        <div style={{ textAlign: "left" }}>
          Attachment&nbsp;
          {AUDIT_ACTIONS_TITLE[rowData.action]} in ticket&nbsp;
          <strong>
            {
              rowData.metaData?.recordsAfter?.[0]?.component_id_detail
                ?.ticket_no
            }
          </strong>
          &nbsp;
        </div>
      );
    else if (
      [OBJECT_TYPE.TICKET].includes(rowData?.objectType) &&
      [
        NOTIFICATION_ACTION_TYPE.NOTE_CREATE,
        NOTIFICATION_ACTION_TYPE.NOTE_UPDATE,
        NOTIFICATION_ACTION_TYPE.NOTE_DELETE
      ].includes(rowData?.action)
    )
      return (
        <div style={{ textAlign: "left" }}>
          Note&nbsp;
          {AUDIT_ACTIONS_TITLE[rowData.action]} in ticket&nbsp;
          <strong>
            {
              rowData.metaData?.recordsAfter?.[0]?.component_id_detail
                ?.ticket_no
            }
          </strong>
          &nbsp;
        </div>
      );
    else if (
      [OBJECT_TYPE.QUOTE, OBJECT_TYPE.INVOICE].includes(rowData?.objectType) &&
      [
        NOTIFICATION_ACTION_TYPE.REC_UPDATE,
        NOTIFICATION_ACTION_TYPE.REC_CREATE,
        NOTIFICATION_ACTION_TYPE.REC_DELETE
      ].includes(rowData?.action)
    )
      return (
        <div style={{ textAlign: "left" }}>
          {dynamicEstimateQuote}&nbsp;
          <strong>
            {rowData.metaData?.recordsAfter?.[0]?.document_seq_code}
          </strong>
          &nbsp;
          {AUDIT_ACTIONS_TITLE[rowData.action]}
        </div>
      );
    else if (
      [
        NOTIFICATION_ACTION_TYPE.REC_LINKED,
        NOTIFICATION_ACTION_TYPE.REC_UNLINKED
      ].includes(rowData?.action)
    )
      return (
        <div style={{ textAlign: "left" }}>
          {AUDIT_OBJECT_TYPE[rowData.objectType]}{" "}
          <strong>
            {
              rowData.metaData?.rec?.cells[
                TableManger.getColumnId(
                  TABLES[rowData.metaData.objectType],
                  COLUMN_CODE.DEAL.NAME
                )
              ]
            }
          </strong>
          &nbsp;{AUDIT_ACTIONS_TITLE[rowData.action]}
        </div>
      );
    else if (
      [
        NOTIFICATION_ACTION_TYPE.COLUMN_CREATE,
        NOTIFICATION_ACTION_TYPE.COLUMN_UPDATE,
        NOTIFICATION_ACTION_TYPE.COLUMN_DELETE
      ].includes(rowData?.action)
    ) {
      return (
        <div style={{ textAlign: "left", lineBreak: "anywhere" }}>
          {rowData?.metaData?.columnsBefore?.[0]?.systemField ||
          rowData?.metaData?.columnsBefore?.[0]?.columnsAfter
            ? "Column "
            : "Custom field "}
          <strong>
            {rowData?.metaData?.recordLabel ??
              rowData?.metaData?.columnsBefore?.[0]?.name ??
              rowData?.metaData?.columnsAfter?.[0]?.name ??
              rowData?.metaData?.columnsBefore?.label ??
              rowData?.metaData?.columnsAfter?.label}
          </strong>
          &nbsp;{AUDIT_ACTIONS_TITLE[rowData.action]} in&nbsp;
          <strong>
            {AUDIT_OBJECT_TYPE[rowData.objectType] ??
              Utility.convertInTitleCase(
                rowData?.metaData?.dimensionModules?.[0]
              )}
          </strong>
          &nbsp;module
        </div>
      );
    } else if (
      [
        NOTIFICATION_ACTION_TYPE.SUPPORT_EMAIL_CREATE,
        NOTIFICATION_ACTION_TYPE.SUPPORT_EMAIL_DELETE
      ].includes(rowData?.action)
    ) {
      return (
        <div style={{ textAlign: "left" }}>
          Email&nbsp;
          {AUDIT_ACTIONS_TITLE[rowData.action]} in ticket&nbsp;
          <strong>
            {
              rowData.metaData?.recordsAfter?.[0]?.ticket_no
            }
          </strong>
        </div>
      );
    } else if (rowData.action === NOTIFICATION_ACTION_TYPE.REC_UPDATE) {
      if (
        rowData.metaData?.recordsBefore?.[0]?.length === 0 ||
        !AUDIT_OBJECT_TYPE[rowData.objectType]
      )
        return "";
      else
        return (
          <div style={{ textAlign: "left" }}>
            {AUDIT_OBJECT_TYPE[rowData.objectType]}
            <strong>
              &nbsp;
              {
                rowData.metaData?.recordsBefore?.[0]?.cells[
                  TableManger.getColumnId(
                    TABLES[rowData.objectType],
                    COLUMN_CODE.DEAL.NAME
                  )
                ]
              }
            </strong>
            &nbsp;{AUDIT_ACTIONS_TITLE[rowData.action]}
          </div>
        );
    } else if (
      rowData.action === NOTIFICATION_ACTION_TYPE.TABLE_PERMISSION_CHANGED
    )
      return (
        <div style={{ textAlign: "left" }}>
          All <strong>{AUDIT_OBJECT_TYPE[rowData.objectType]}</strong>&nbsp;
          {AUDIT_ACTIONS_TITLE[rowData.action]}
        </div>
      );
    else if (rowData.action === NOTIFICATION_ACTION_TYPE.REC_PERMISSION_CHANGED)
      return (
        <div style={{ textAlign: "left" }}>
          <strong>{AUDIT_OBJECT_TYPE[rowData.objectType]}</strong>&nbsp;
          {AUDIT_ACTIONS_TITLE[rowData.action]}
        </div>
      );
    else if (
      rowData.action ===
      NOTIFICATION_ACTION_TYPE.REQUIRED_COLUMN_SETTING_CHANGED
    )
      return (
        <div style={{ textAlign: "left" }}>
          {AUDIT_ACTIONS_TITLE[rowData.action]}
        </div>
      );
    else if (
      [
        "CRM3_RECORD_APPROVAL_REQUEST_CREATED",
        "CRM3_RECORD_APPROVAL_REQUEST_UPDATED",
        "CRM3_RECORD_APPROVAL_REQUEST_DELETED"
      ].includes(rowData.action)
    ) {
      return this.dealAutomationLogRenderer(rowData);
    } else {
      if (
        rowData.metaData?.recordsBefore?.[0]?.length === 0 ||
        !AUDIT_OBJECT_TYPE[rowData?.objectType]
      )
        return "";
      else
        return (
          <div style={{ textAlign: "left" }}>
            {AUDIT_OBJECT_TYPE[rowData?.objectType]}
            <strong>
              &nbsp;
              {rowData.metaData?.recordsAfter?.[0]?.cells &&
                rowData.metaData?.recordsAfter?.[0]?.cells[
                  TableManger.getColumnId(
                    TABLES[rowData?.objectType],
                    COLUMN_CODE.DEAL.NAME
                  )
                ]}
            </strong>
            &nbsp;{AUDIT_ACTIONS_TITLE[rowData?.action]}
          </div>
        );
    }
  };
  static userRenderer = ({ rowData }) => {
    return (
      <div
        className={`row fs-m border-radius-s data-grid-badge-color-${
          10 - (rowData?.metaData?.user?.name?.length % 10)
        }`}
        style={{
          width: "auto",
          padding: "2px 6px"
        }}
      >
        <div className="fs-r text-align-left display-block">
          {rowData?.metaData?.user?.iamUserId === -1
            ? TICKET_DEFAULT_SYSTEM_USER
            : rowData?.metaData?.user?.name}
        </div>
      </div>
    );
  };
  static actionDateRenderer = ({ rowData }) => {
    return (
      <div>
        &nbsp;
        {DateUtil.getDateStrFromDate(
          new Date(rowData.actionDate),
          DateUtil.getOrgDateFormat()
        )}
        &nbsp; {Utility.getFormattedTime(new Date(rowData.actionDate))}
      </div>
    );
  };
  static noteRenderer = (notesData: string | { message: string }[]) => {
    let noteToShow = "";

    if (isString(notesData)) {
      noteToShow = notesData as string;
    }

    if (Array.isArray(notesData)) {
      noteToShow = notesData.map((noteData) => noteData.message).join("\n");
    }

    return (
      <DKTooltipWrapper
        content={`<div>${noteToShow}</div>`}
        tooltipClassName={"row"}
      >
        <div
          className="text-align-left text-ellipsis"
          dangerouslySetInnerHTML={{
            __html: findAndConvertLink(DOMPurify.sanitize(noteToShow))
          }}
        ></div>
      </DKTooltipWrapper>
    );
  };
  static activityStatusRenderer = () => (
    <div
      style={{ paddingTop: 2, paddingBottom: 2 }}
      className="bg-chip-red border-radius-s p-h-s ml-s"
    >
      Overdue
    </div>
  );
  static attachmentRenderer = ({ value, rowIndex }) => {
    let attachments = [],
      jsonData = [];
    if (!Utility.isEmptyObject(value)) {
      jsonData = Utility.decodeJSON(value);
      jsonData.forEach((file) => {
        attachments.push(
          <div
            className="row width-auto"
            onClick={(e) => getAttachmentPreviewInPopup(file)}
          >
            <DKTooltipWrapper
              content={ReactDOMServer.renderToStaticMarkup(
                Renderers.renderFile(file)
              )}
              tooltipClassName={"row"}
            >
              <DKIcon
                src={Utility.getFileIcon(file.title)}
                className="ic-s cursor-hand"
                style={{ padding: 0, opacity: 0.8 }}
              />
            </DKTooltipWrapper>
          </div>
        );
      });

      attachments.reverse();
    }

    return attachments.length === 0 ? null : attachments;
  };
  static renderFile = (file: Attachment) => (
    <AttachmentPreview
      attachment={file}
      maxHeight={`200px`}
      maxWidth={`225px`}
      needLoader={false}
      headerIconButton={{
        icon: DKIcons.ic_view,
        onClick: (e) => {
          e?.stopPropagation?.();
          getAttachmentPreviewInPopup(file);
        }
      }}
    />
  );
  static dealOrContactRenderer = (data, onClickHandler) => {
    return (
      <div
        className="text-underline cursor-hand text-align-left"
        onClick={() => onClickHandler()}
      >
        {data?.value}
      </div>
    );
  };
  static contactByActivity = (data, onClickHandler, contacts) => {
    let contactName = "";
    contacts.forEach((val) => {
      if (val?.id === data?.value[0]) contactName = val?.name;
    });
    return (
      <div
        className="text-underline cursor-hand text-align-left"
        onClick={() => onClickHandler()}
      >
        {contactName}
      </div>
    );
  };

  static addressRenderer = (addressData: string) => {
    return (
      <div className="row">
        <div
          className="text-align-left text-ellipsis1"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(addressData)
          }}
        ></div>
        {!Utility.isEmptyObject(addressData) && addressData !== "NA" && (
          <DKIcon
            className="ic-s ml-xs edit-icon cursor-hand "
            src={DKIcons.ic_location}
            style={{ opacity: 0.7 }}
            onClick={(e) => {
              e.stopPropagation();
              Utility.openInNewTab(GOOGLE_MAP_URL + encodeURI(addressData));
              return;
            }}
          />
        )}
      </div>
    );
  };

  static getCurrencySymbolFromCode = (currencyCode: string) => {
    let currencies = store.getState().currency?.data?.content ?? [];
    return (
      currencies?.filter(
        (currency) =>
          currency.currencyCode ===
          (!Utility.isEmptyObject(currencyCode)
            ? currencyCode
            : TenantManager.getCRMCurrencyCode())
      )?.[0]?.currencySymbol ?? {}
    );
  };

  static dealAmountWithCurrencyRenderer = (value, dealData) => {
    let parseDealData = DealManager.parseDealData({
      cells: dealData,
      _id: dealData?.dealId ?? dealData?.id
    });
    return (
      <div className="row justify-content-end fs-m">
        {`${toCurrencyFormat(
          Utility.isEmptyObject(value) ? 0 : value,
          this.getCurrencySymbolFromCode(parseDealData?.currency)
        )}`}
      </div>
    );
  };

  /* ***************** AUTOMATION COMPONENT COMMON RENDERERS **************** */
  static renderPopupHeaderWithActions(title: string, onClose?: () => void) {
    return (
      <div className="row justify-content-between pt-s pb-s bg-gray3 p-h-m">
        <DKLabel text={title} className="fw-m" />
        {onClose ? (
          <DKButton icon={DKIcons.ic_close} onClick={() => onClose()} />
        ) : null}
      </div>
    );
  }
  static renderPopupBottomWithActions(
    onSave: () => void,
    onCancel: () => void
  ) {
    return (
      <div className="row-reverse p-r mt-auto" style={{ bottom: 0 }}>
        <DKButton
          title="Save"
          className="bg-button text-white ml-m"
          onClick={() => onSave()}
        />

        <DKButton
          title="Cancel"
          className="bg-gray1 border-m"
          onClick={() => onCancel()}
        />
      </div>
    );
  }

  static getFormInput(
    formInput,
    handleFormValueChange: (id: string, value: any) => void,
    saveTapped = false
  ) {
    switch (formInput.type) {
      case INPUT_TYPE.SELECT:
        const selectedValue = formInput.options.find(
          (option: any) => option.id === formInput.value?.[0]
        );
        return (
          <DKInput
            title={formInput.title}
            placeholder={formInput.placeholder}
            value={selectedValue}
            type={INPUT_TYPE.DROPDOWN}
            required={formInput.required}
            displayKey={"name"}
            dropdownConfig={{
              data: formInput.options,
              allowSearch: formInput.allowSearch ?? true,
              searchableKey: "name",
              onSelect: () => {},
              renderer: (index: number, option: any) => (
                <DKLabel text={option.name} />
              ),
              button: formInput.button
            }}
            className={`p-0`}
            direction={formInput.direction ?? INPUT_VIEW_DIRECTION.HORIZONTAL}
            onChange={(newValue: any) =>
              handleFormValueChange(
                formInput.id,
                newValue?.id ? [newValue.id] : []
              )
            }
            tooltip={formInput.tooltip}
            errorMessage={" "}
            canValidate={saveTapped}
            titleStyle={formInput.titleStyle || {}}
            valueStyle={formInput.valueStyle || {}}
          />
        );
      case INPUT_TYPE.MULTI_SELECT:
        const selectedIndices = formInput.value
          ?.map((id: number) =>
            formInput.options.findIndex((option: any) => option.id === id)
          )
          .filter((index: number) => index !== -1);
        return (
          <DKInput
            title={formInput.title}
            placeholder={formInput.placeholder}
            value={selectedIndices}
            type={INPUT_TYPE.DROPDOWN}
            required={formInput.required}
            displayKey={"name"}
            dropdownConfig={{
              data: formInput.options,
              allowSearch: true,
              searchableKey: "name",
              multiSelect: true,
              checkMarkColor: "bg-button",
              onSelect: () => {},
              renderer: (index: number, option: any) => (
                <DKLabel text={option.name} />
              )
            }}
            className={`p-0`}
            direction={formInput.direction ?? INPUT_VIEW_DIRECTION.HORIZONTAL}
            onChange={(newSelectedIndices: number[]) => {
              const newSelectedIds = [];
              newSelectedIndices.forEach((index: number) => {
                if (!formInput.options[index]) return;
                newSelectedIds.push(formInput.options[index].id);
              });
              handleFormValueChange(formInput.id, newSelectedIds);
            }}
            tooltip={formInput.tooltip}
            errorMessage={" "}
            canValidate={saveTapped}
            valueStyle={formInput.valueStyle || {}}
          />
        );
      default:
        return (
          <DKInput
            title={formInput.title}
            placeholder={formInput.placeholder}
            value={formInput.value}
            type={formInput.type}
            required={formInput.required}
            className={`p-0`}
            direction={formInput.direction ?? INPUT_VIEW_DIRECTION.HORIZONTAL}
            onChange={(value: any) =>
              handleFormValueChange(formInput.id, value)
            }
            tooltip={formInput.tooltip}
            errorMessage={" "}
            canValidate={saveTapped}
            autoFocus={formInput.autoFocus ?? false}
            valueStyle={formInput.valueStyle || {}}
          />
        );
    }
  }

  static leadScoreRenderer = (rowData) => {
    if (rowData.value === "") {
      return;
    }
    let contactEmail = TableManger.getColumnId(
      TABLES.CONTACT,
      COLUMN_CODE.CONTACT.EMAIL
    );
    let contactName = TableManger.getColumnId(
      TABLES.CONTACT,
      COLUMN_CODE.CONTACT.NAME
    );
    let valueObject = {
      name: rowData?.rowData?.[contactName],
      email: rowData?.rowData?.[contactEmail],
      score: rowData.value
    };
    return (
      <div
        style={{ paddingTop: 2, paddingBottom: 2 }}
        className="bg-chip-gray border-radius-s p-h-s ml-s cursor-hand"
        onClick={() =>
          RouteManager.navigateToPage(
            PAGE_ROUTES.CONTACT_LEAD_SCORE_UI.replace(
              ROUTE_ID_IDENTIFIER,
              Utility.encodeJSON(valueObject)
            )
          )
        }
      >
        {rowData.value}
      </div>
    );
  };

  static linkedUnLinkedRenderer = (rowData, after = false) => {
    let data = rowData.metaData?.links?.map((linkedData) => {
      return (
        <div className="row">
          <b>{AUDIT_OBJECT_TYPE[linkedData.objectType]}: &nbsp;</b>
          {after
            ? linkedData?.recs?.map(
                (record) =>
                  record?.cells[
                    TableManger.getColumnId(
                      TABLES[linkedData.objectType],
                      COLUMN_CODE.DEAL.NAME
                    )
                  ]
              )
            : AUDIT_LOG_EMPTY_DISPLAY_VALUE}
        </div>
      );
    });
    return data;
  };

  static dealAutomationRenderer = (rowData, after = false) => {
    let dealData = rowData.metaData?.recordsAfter?.[0];
    let dealBeforeData = rowData.metaData?.recordsBefore?.[0];
    const stageColumnId = TableManger.getColumnId(
      TABLES.DEAL,
      COLUMN_CODE.DEAL.STAGE_ID
    );
    const stageNameColumnId = TableManger.getColumnId(
      TABLES.STAGE,
      COLUMN_CODE.STAGES.NAME
    );
    const stageName =
      dealData?.cells?.[`${stageColumnId}_detail`]?.[0]?.cells?.[
        stageNameColumnId
      ] ?? "-";
    const stageBeforeName =
      dealBeforeData?.cells?.[`${stageColumnId}_detail`]?.[0]?.cells?.[
        stageNameColumnId
      ] ?? "-";

    return (
      <>
        {!after ? (
          <div style={{ textAlign: "left" }}>
            <b>Stage: </b>
            {stageName ?? ""} &nbsp;
            <br />
            <b>Updated At: </b>
            {DateUtil.getDateStrFromDate(
              new Date(rowData?.actionDate),
              DateUtil.getOrgDateFormat()
            )}{" "}
            {Utility.getFormattedTime(new Date(rowData?.actionDate), true)}
            &nbsp;
            <br />
          </div>
        ) : (
          <div style={{ textAlign: "left" }}>
            <b>Stage: </b>
            {stageBeforeName} &nbsp;
            <br />
            <b>Updated At: </b>
            {DateUtil.getDateStrFromDate(
              new Date(rowData?.actionDate),
              DateUtil.getOrgDateFormat()
            )}{" "}
            {Utility.getFormattedTime(new Date(rowData?.actionDate), true)}
            &nbsp;
            <br />
          </div>
        )}
      </>
    );
  };

  static getCCField = (ccData) => {
    if (Utility.isEmptyObject(ccData) || ccData?.length === 0) return null;
    return (
      <>
        <b> Cc: </b> {ccData?.toString()?.replaceAll(",", ", ")}
        <br />
      </>
    );
  };
  static dealAutomationLogRenderer = (rowData) => {
    const dealName =
      rowData.metaData?.recordsAfter?.[0]?.cells[
        TableManger.getColumnId(
          TABLES[rowData.metaData?.objectType],
          COLUMN_CODE.DEAL.NAME
        )
      ];

    switch (rowData.action) {
      case NOTIFICATION_ACTION_TYPE.CRM3_RECORD_APPROVAL_REQUEST_CREATED:
        return (
          <div style={{ textAlign: "left" }}>
            {AUDIT_OBJECT_TYPE[rowData?.metaData?.objectType]}
            <strong>
              &nbsp;
              {dealName}
            </strong>
            &nbsp;{"is sent for approval"}
          </div>
        );
      case NOTIFICATION_ACTION_TYPE.CRM3_RECORD_APPROVAL_REQUEST_UPDATED:
        return (
          <div style={{ textAlign: "left" }}>
            {AUDIT_OBJECT_TYPE[rowData?.metaData?.objectType]}
            <strong>
              &nbsp;
              {dealName}
            </strong>
            &nbsp;
            {`is ${APPROVAL_STATUS_DISPLAY_VALUE[rowData?.metaData?.approvalReq?.status]}`}
          </div>
        );
      case NOTIFICATION_ACTION_TYPE.CRM3_RECORD_APPROVAL_REQUEST_DELETED:
        return (
          <div style={{ textAlign: "left" }}>
            {"Approval request was deleted for deal"}
            <strong>
              &nbsp;
              {dealName}
            </strong>
          </div>
        );

      default:
        break;
    }
    // return <div style={{ textAlign: "left" }}> {logMessages} </div>;
  };

  static quoteCreatedByRenderer = ({ rowData }) => {
    let userList = store.getState()?.tenant?.users ?? [];
    let userDetails = userList.find((user) =>
      rowData?.isDraftDocument
        ? user.id === rowData.createdBy
        : user.userId === rowData.createdBy
    );

    return (
      <div
        className={`row fs-m border-radius-s data-grid-badge-color-${
          10 - (userDetails?.userName?.length % 10)
        }`}
        style={{
          width: "auto",
          padding: "2px 6px"
        }}
      >
        <div className="fs-r text-align-left display-block">
          {!Utility.isEmptyObject(userDetails) ? getFullName(userDetails) : "-"}
        </div>
      </div>
    );
  };

  static ticketRenderer = (rowData) => {
    if (
      [
        NOTIFICATION_ACTION_TYPE.REC_CREATE,
        NOTIFICATION_ACTION_TYPE.REC_DELETE
      ].includes(rowData.action)
    ) {
      let ticketData = rowData.metaData?.recordsAfter?.[0];
      return (
        <>
          <div style={{ textAlign: "left" }}>
            <b>Ticket No: </b>
            {ticketData?.ticket_no ?? ""} &nbsp;
            <br />
            <b> Subject: </b>
            {ticketData?.subject ?? ""}
            &nbsp;
            <br />
            <b> Requestor: </b>
            {ticketData?.requestor ?? ""}
            &nbsp;
            <br />
          </div>
        </>
      );
    }
    if (
      [
        NOTIFICATION_ACTION_TYPE.NOTE_CREATE,
        NOTIFICATION_ACTION_TYPE.NOTE_DELETE
      ].includes(rowData.action)
    ) {
      let ticketDataNote = rowData.metaData?.recordsAfter?.[0];
      let note = ticketDataNote?.note
        ? Utility.decodeBase64Uri(ticketDataNote?.note)
        : "";
      return (
        <>
          <div style={{ textAlign: "left" }}>
            <b>Updated At: </b>
            {DateUtil.getDateStrFromDate(
              new Date(ticketDataNote?.updated_at),
              DateUtil.getOrgDateFormat()
            )}{" "}
            {Utility.getFormattedTime(
              new Date(ticketDataNote?.updated_at),
              true
            )}
            &nbsp;
            <br />
            <b> Note: </b>
            {note.substr(0, 45) ?? ""}
            {note?.length > 45 ? "..." : ""}
            &nbsp;
            <br />
          </div>
        </>
      );
    }
    if (
      [
        NOTIFICATION_ACTION_TYPE.ATTACHMENT_CREATE,
        NOTIFICATION_ACTION_TYPE.ATTACHMENT_DELETE
      ].includes(rowData.action)
    ) {
      let ticketDataAttachment = rowData.metaData?.recordsAfter?.[0];
      return (
        <>
          <div style={{ textAlign: "left" }}>
            <b>Updated At: </b>
            {DateUtil.getDateStrFromDate(
              new Date(ticketDataAttachment?.updated_at),
              DateUtil.getOrgDateFormat()
            )}{" "}
            {Utility.getFormattedTime(
              new Date(ticketDataAttachment?.updated_at),
              true
            )}
            &nbsp;
            <br />
            <b> Name: </b>
            {ticketDataAttachment?.document_name ?? ""}
            &nbsp;
            <br />
          </div>
        </>
      );
    }
    if (
      [
        NOTIFICATION_ACTION_TYPE.SUPPORT_EMAIL_CREATE,
        NOTIFICATION_ACTION_TYPE.SUPPORT_EMAIL_DELETE
      ].includes(rowData.action)
    ) {
      let ticketDataAttachment = rowData.metaData?.recordsAfter?.[0];
      return (
        <>
          <div style={{ textAlign: "left" }}>
            <b>From: </b>
            {ticketDataAttachment?.sender_name ?? ""}{" "}
            {ticketDataAttachment?.sender_email
              ? "<" + ticketDataAttachment?.sender_email + ">"
              : ""}
            &nbsp;
            <br />
            <b> To: </b>
            {ticketDataAttachment?.recipient_emails
              ?.toString()
              ?.replaceAll(",", ", ") ?? ""}
            &nbsp;
            <br />
            {this.getCCField(ticketDataAttachment?.cc_emails)}
            <b> Subject: </b>
            {ticketDataAttachment?.subject ?? ""}
            &nbsp;
            <br />
          </div>
        </>
      );
    }
  };

  static customFieldColumnRenderer = (beforeData, rowData) => {
    const cfOptions = !isEqual(
      rowData?.columnsBefore?.options?.map((option) => option?.value) ?? [],
      rowData?.columnsAfter?.options?.map((option) => option?.value) ?? []
    )
      ? beforeData
        ? rowData?.columnsBefore?.options ?? []
        : rowData?.columnsAfter?.options ?? []
      : [];
    const cfLabel =
      Utility.isEmptyObject(rowData?.columnsAfter?.[0]?.datasource) ||
      Utility.isEmptyObject(rowData?.columnsBefore?.[0]?.datasource)
        ? beforeData
          ? rowData?.columnsBefore?.label || rowData?.columnsBefore?.[0]?.name
          : rowData?.columnsAfter?.label || rowData?.columnsAfter?.[0]?.name
        : "";
    const requiredLabel = beforeData
      ? rowData?.columnsBefore?.mandatory?.toString() ?? ""
      : rowData?.columnsAfter?.mandatory?.toString() ?? "";
    return (
      <div className="column" style={{ textAlign: "left" }}>
        {!beforeData &&
        cfOptions?.length === 0 &&
        (!Utility.isEmptyObject(rowData?.columnsAfter?.[0]?.datasource) ||
          !Utility.isEmptyObject(rowData?.columnsBefore?.[0]?.datasource))
          ? "Datasource is modified"
          : ""}
        {!Utility.isEmptyObject(cfLabel) && (
          <div className="row align-items-start">
            <b>Name</b>:{" "}
            <span style={{ lineBreak: "anywhere" }}>{cfLabel}</span>{" "}
          </div>
        )}
        {cfOptions?.length > 0 && (
          <div className="row align-items-start">
            <b>Options</b>:{" "}
            {cfOptions
              ?.map((option) => option?.value)
              ?.toString()
              ?.replaceAll(",", ", ")}
          </div>
        )}
        {!Utility.isEmptyObject(requiredLabel) && (
          <div className="row">
            <b>Mandatory</b>: {requiredLabel}
          </div>
        )}
      </div>
    );
  };
}
