import React, { Suspense, useEffect, useState } from "react";
import {
  DKDataGrid,
  DKSpinner,
  INPUT_TYPE,
  DKIcons,
  DKIcon,
  DKLabel
} from "deskera-ui-library";
import {
  COLUMN_CODE,
  TableManger,
  TABLES,
  TABLE_DISPLAY_NAME
} from "../managers/TableManger";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  fetchPriceBookLogs,
  recordsByTableNameSelector
} from "../redux/slices/recordSlice";
import Utility from "../utility/Utility";
import { downloadFileByRelativePath } from "../services/common/file";
import { DEFAULT_PAGE_SIZE, FILE_UID_LENGTH } from "../services/importLog";
import SideBarService from "../services/sidebar";
import {
  convertCRMDateFormatToUILibraryFormat,
  DateUtil
} from "../utility/Date";
import ic_no_data from "../../src/assets/icons/ic_no_data_3.png";
import { isMobileAppWebView } from "../utility/GetViewportSize";
import { LOG_SCROLL_CONTAINER_ID } from "../constants/Constant";

export default function PriceBookLog(props) {
  /*
   state and other declarations goes here
   */
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const importLogData = useAppSelector(
    recordsByTableNameSelector(TABLES.BOOKS_PRICE_LIST_LOG)
  );
  const [columns, setColumns] = React.useState([]);
  const [sortCol, setSortCol] = useState(null);
  const [sortDir, setSortDir] = useState(null);
  const [page, setPage] = useState(0);
  /*
   all the effect goes here
   */
  useEffect(() => {
    // RouteManager.setPresenter({ props });
    getColumnConfig();
    dispatch(
      fetchPriceBookLogs({ params: { limit: DEFAULT_PAGE_SIZE, page: 0 } })
    );
  }, []);
  useEffect(() => {
    setLoading(false);
  }, [importLogData]);
  /*
  business logic goes here
  */
  const getActionButtonsColumn = () => {
    return {
      key: "actions",
      name: "Actions",
      type: INPUT_TYPE.BUTTON,
      width: 110,
      options: []
    };
  };
  const getUpdatedRows = (rows) => {
    return JSON.parse(JSON.stringify(rows)).map((row) => {
      let rowContextMenu = [];

      if (row.module === "PRICE_LIST") {
        row.module = "PRICE_BOOK";
      }

      if (!Utility.isEmptyObject(row.updatedFilePath)) {
        rowContextMenu.push({
          title: "Original File",
          icon: DKIcons.ic_import,
          onClick: ({ rowData }) =>
            downloadFileByRelativePath(
              rowData.updatedFilePath,
              rowData.fileName.slice(FILE_UID_LENGTH, rowData.fileName.length)
            )
        });
      }
      if (!Utility.isEmptyObject(row.rejectedFilePath)) {
        rowContextMenu.push({
          title: "Rejected File",
          icon: DKIcons.ic_import,
          onClick: ({ rowData }) =>
            downloadFileByRelativePath(
              rowData.rejectedFilePath,
              rowData.fileName.slice(FILE_UID_LENGTH, rowData.fileName.length)
            )
        });
      }
      return {
        ...row,
        rowContextMenu
      };
    });
  };
  const getColumnConfig = () => {
    let columns = TableManger.getTableVisibleColumns(TABLES.IMPORT_LOG);
    columns = columns.map((column) => {
      column.key = column.columnCode;
      if (column.columnCode === COLUMN_CODE.IMPORT_LOG.RECORDS) {
        column.renderer = totalRecordsRenderer;
      }
      if (column.columnCode === COLUMN_CODE.IMPORT_LOG.FILE_NAME) {
        column.renderer = fileNameRenderer;
      }
      if (column.columnCode === COLUMN_CODE.IMPORT_LOG.IMPORTED_ON) {
        column.width = 160;
        column.renderer = (rowData) => {
          return (
            <div>
              &nbsp;
              {DateUtil.getDateStrFromDate(
                new Date(rowData.value),
                DateUtil.getOrgDateFormat()
              )}
              &nbsp; {Utility.getFormattedTime(new Date(rowData.value))}
            </div>
          );
        };
      }
      return column;
    });
    setColumns(columns);
  };
  const onPageChange = (page) => {
    setLoading(true);
    setPage(page);
    dispatch(
      fetchPriceBookLogs({ params: { limit: DEFAULT_PAGE_SIZE, page } })
    );
  };
  /*
     sub-renderer goes here
     */
  const totalRecordsRenderer = (data) => {
    const row = data.rowData;
    return `Total Records : ${row.totalRecords || 0} | Imported : ${
      (row.totalRecords || 0) - (row.rejectedRecords || 0)
    } | Rejected : ${row.rejectedRecords || 0}`;
  };
  const fileNameRenderer = (data) =>
    data.rowData.fileName.slice(FILE_UID_LENGTH, data.rowData.fileName.length);

  const onSort = ({ order, columnData }) => {
    const sortCol = columnData.id;
    const sortDir = order;
    setSortCol(sortCol);
    setSortDir(sortDir);
    dispatch(
      fetchPriceBookLogs({
        params: { limit: DEFAULT_PAGE_SIZE, page, sortCol, sortDir }
      })
    );
  };
  const getNoDataView = () => {
    return (
      <div
        className="column align-self-center align-items-center position-absolute"
        style={{ top: "30%", pointerEvents: "none" }}
      >
        <DKIcon
          src={ic_no_data}
          className="ic-l"
          style={{ opacity: 0.2, marginTop: 70 }}
        />
        <DKLabel text="No data found" className="fw-m mt-l" />
        <DKLabel
          text="Once data is available, it will appear here"
          className="text-gray mt-s "
        />
      </div>
    );
  };
  /*
   main renderer goes here
   */
  return (
    <div className="column parent-width flex-1">
      <div className="column mt-s parent-width mb-s position-relative flex-1">
        <Suspense
          fallback={
            <div className="row justify-content-center">
              <DKSpinner />
            </div>
          }
        >
          {importLogData?.content?.length === 0 && getNoDataView()}
          <DKDataGrid
            title={`${TABLE_DISPLAY_NAME[TABLES.BOOKS_PRICE_LIST_LOG]} (${
              importLogData?.numberOfElements || 0
            })`}
            allowColumnAdd={false}
            width={SideBarService.getContainerWidth()}
            gridStickyHeaderConfig={
              isMobileAppWebView()
                ? null
                : {
                    getRootScrollContainer: () =>
                      document.getElementById(LOG_SCROLL_CONTAINER_ID)
                  }
            }
            allowColumnEdit={false}
            allowColumnDelete={false}
            updating={loading}
            allowColumnShift={false}
            currentPage={importLogData?.pageable?.pageNumber + 1}
            columns={[...columns, getActionButtonsColumn()]}
            allowRowAdd={false}
            allowRowEdit={false}
            allowBulkOperation={false}
            rows={
              importLogData?.content
                ? getUpdatedRows(importLogData.content)
                : []
            }
            tableName={TABLES.IMPORT_LOG}
            needTrailingColumn={true}
            totalPageCount={importLogData?.totalPages}
            onPageChange={onPageChange}
            onSort={onSort}
            dateFormat={convertCRMDateFormatToUILibraryFormat(
              DateUtil.getOrgDateFormat()
            )}
          />
        </Suspense>
      </div>
    </div>
  );
}
