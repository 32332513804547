import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { PipelineManager } from "../../managers/PipelineManager";
import { COLUMN_CODE, TableManger, TABLES } from "../../managers/TableManger";
import ContactService from "../../services/contact";
import ImportLogService from "../../services/importLog";
import AutomationLogService from "../../services/automationLog";
import AuditLogService from "../../services/auditLog";
import SupportTicketService from "../../services/supportTicket";
import Table from "../../services/table";
import { RootState } from "../store";
import { DealService } from "../../services/deal";
import { CampaignService } from "../../services/campaign";
import Utility from "../../utility/Utility";
import { AccountService } from "../../services/accounts";
import IndiaMartService from "../../services/indiamart";
import { LOOKUP_FIELD_ENABLE_COL_TYPE } from "../../constants/Constant";
import debounce from "../../utility/Debounce";
import Report from "../../services/report";

export interface RecordState {
  data: any;
  tickets: any;
  ticketViews: any;
  ticketLogs: any;
  currentData: any;
  accounts: any;
}
const initialState: RecordState = {
  data: {},
  tickets: null,
  ticketViews: null,
  ticketLogs: null,
  currentData: {
    contact: {},
    contactDeal: {},
    contactEmails: {},
    pipelinesStages: {},
    unsubscribeCampaign: {},
    indiamartLeadSyncLogs: {}
  },
  accounts: {}
};
/* CRM Core records API goes here */
export const fetchRecordsByTable = createAsyncThunk(
  "record/fetchRecordsByTable",
  async (data: any, thunkAPI) => {
    const tableId = TableManger.getTableId(data.tableName);

    if (data.tableName === TABLES.DEAL) {
      /** @description - in case of deal need to make some additional param config like the size and contact ColumnID*/
      const contactColumnId = TableManger.getColumnId(
        TABLES.DEAL,
        COLUMN_CODE.DEAL.CONTACT_ID
      );
      let paramsToFetchCol = [contactColumnId];
      let columns = TableManger.getTableColumns(TABLES.DEAL);
      let lookupColumns = columns
        .filter(
          (column) =>
            LOOKUP_FIELD_ENABLE_COL_TYPE.includes(column.type) &&
            !Utility.isEmptyObject(column.lookup)
        )
        ?.map((col) => col.id);

      paramsToFetchCol = [...paramsToFetchCol, ...lookupColumns];
      data.params = {
        pageNo: 1,
        pageSize: 25,
        colIdsToFetchRef: paramsToFetchCol.toString(),
        ...data.params
      };
    }

    if ([TABLES.CONTACT, TABLES.SEGMENT].includes(data.tableName)) {
      const accountColumnId = TableManger.getColumnId(
        TABLES.CONTACT,
        COLUMN_CODE.CONTACT.ACCOUNT
      );
      const priceBookColumnId = TableManger.getColumnId(
        TABLES.CONTACT,
        COLUMN_CODE.CONTACT.PRICE_LIST
      );
      let paramsToFetchCol = [accountColumnId, priceBookColumnId];
      let columns = TableManger.getTableColumns(TABLES.CONTACT);
      let lookupColumns = columns
        .filter(
          (column) =>
            LOOKUP_FIELD_ENABLE_COL_TYPE.includes(column.type) &&
            !Utility.isEmptyObject(column.lookup)
        )
        ?.map((col) => col.id);

      paramsToFetchCol = [...paramsToFetchCol, ...lookupColumns];
      data.params = {
        colIdsToFetchRef: paramsToFetchCol.toString(),
        ...data.params
      };
    }

    if (data.tableName === TABLES.PIPELINE) {
      data.params = {
        ...data.params,
        pageSize: 100
      };
    }

    if (data.tableName === TABLES.STAGE) {
      data.params = {
        ...data.params,
        pageSize: 1000
      };
    }

    if (data.tableName === TABLES.ACTIVITY) {
      data.params = {
        pageNo: 1,
        pageSize: 20,
        fetchAllRef: true,
        ...data.params
      };
    }

    if (data.tableName === TABLES.DRAFT) {
      data.params = {
        pageNo: 1,
        pageSize: 25,
        ...data.params
      };
    }

    if (
      [TABLES.CAMPAIGN, TABLES.FORM, TABLES.LANDING_PAGE].includes(
        data.tableName
      )
    ) {
      data.params = {
        ...data.params,
        pageSize: 10
      };
    }

    const response = await Table.getRecordsByPage(
      data.params,
      data.payload,
      tableId
    );
    return response;
  }
);

export const fetchDraftRecords = createAsyncThunk(
  "record/fetchDraftRecords",
  async (data: any, thunkAPI) => {
    if (data.needDebounce) {
      debouncedFetchDraftRecords(data, thunkAPI);
    } else {
      const tableId = TableManger.getTableId(data.tableName);
      data.params = {
        pageNo: 1,
        pageSize: 25,
        ...data.params
      };

      const response = await Table.getRecordsByPage(
        data.params,
        data.payload,
        tableId
      );
      thunkAPI.dispatch(
        setTableRecords({ tableName: data.tableName, data: response })
      );
    }
  }
);

export const debouncedFetchDraftRecords = debounce(async (data, thunkAPI) => {
  const tableId = TableManger.getTableId(data.tableName);
  data.params = {
    pageNo: 1,
    pageSize: 25,
    ...data.params
  };

  const response = await Table.getRecordsByPage(
    data.params,
    data.payload,
    tableId
  );

  thunkAPI.dispatch(
    setTableRecords({ tableName: data.tableName, data: response })
  );
}, 300);

export const getRecordsByTable = createAsyncThunk(
  "record/getRecordsByTable",
  async (data: any, thunkAPI) => {
    const response: any = await thunkAPI.getState();
    return response.records.data[data.tableName];
  }
);
export const getRecordsById = createAsyncThunk(
  "record/getRecordsById",
  async (data: any, thunkAPI) => {
    const tableId = TableManger.getTableId(data.tableName);
    const params = data.params || {};
    if (data.tableName === TABLES.CONTACT) {
      const accountColumnId = TableManger.getColumnId(
        TABLES.CONTACT,
        COLUMN_CODE.CONTACT.ACCOUNT
      );
      let paramsToFetchCol = [accountColumnId];
      let columns = TableManger.getTableColumns(TABLES.CONTACT);
      let lookupColumns = columns
        .filter(
          (column) =>
            LOOKUP_FIELD_ENABLE_COL_TYPE.includes(column.type) &&
            !Utility.isEmptyObject(column.lookup)
        )
        ?.map((col) => col.id);

      paramsToFetchCol = [...paramsToFetchCol, ...lookupColumns];
      params["colIdsToFetchRef"] = paramsToFetchCol.toString();
    }
    const response: any = await Table.getRecordById(
      data.recordId,
      tableId,
      params
    );
    return response;
  }
);
/* Custom records other that CRM Core goes here (import_logs, etc...) */
export const fetchImportLogs = createAsyncThunk(
  "record/fetchImportLogs",
  async (data: any) => {
    let response = await ImportLogService.fetchImportLogs(data?.params);
    return response;
  }
);
export const fetchPriceBookLogs = createAsyncThunk(
  "record/fetchPriceBookLogs",
  async (data: any) => {
    let response = await ImportLogService.fetchPriceBookLogs(data?.params);
    return response;
  }
);
export const fetchAutomationLogs = createAsyncThunk(
  "record/fetchAutomationLogs",
  async (data: any, isTrigger) => {
    let response = await AutomationLogService.fetchAutomationLogs(data?.params);
    return response;
  }
);
export const fetchAuditLogs = createAsyncThunk(
  "record/fetchAuditLogs",
  async (data: any) => {
    let response = await AuditLogService.fetchAuditLogs(
      data?.params,
      data?.searchText
    );
    return response;
  }
);
export const fetchTicketAuditLogs = createAsyncThunk(
  "record/fetchTicketAuditLogs",
  async (data: any) => {
    let params = { ...data?.params, skipSubComponentLog: true };
    let response = await AuditLogService.fetchTicketAuditLogs(
      params,
      data?.searchText,
      data?.payload
    );
    return response;
  }
);

export const fetchTickets = createAsyncThunk(
  "record/fetchTickets",
  async (data: any) => {
    const response = await SupportTicketService.getTicketByViewID(
      data.params,
      data.filters
    );
    return response;
  }
);
export const fetchTicketViews = createAsyncThunk(
  "record/fetchTicketViews",
  async () => {
    const response = await SupportTicketService.getViewsList();
    return response;
  }
);

export const getPipelineStages = createAsyncThunk(
  "record/fetchPipelineStages",
  async (data: any, thunkAPI) => {
    const response: any = await PipelineManager.get(true);
    return response;
  }
);

export const fetchDealByStages = createAsyncThunk(
  "record/fetchDealByStages",
  async (data: any, thunkAPI) => {
    const dealRecords = await DealService.fetchDealByStages(
      data.params,
      data.payload
    );
    return dealRecords;
  }
);

export const getDealByContactId = createAsyncThunk(
  "record/fetchDealByContactId",
  async (data: any, thunkAPI) => {
    const response = await ContactService.getDealByContactId(data.recordId);
    response["recordId"] = data.recordId;
    return response;
  }
);

export const getEmailsByContactId = createAsyncThunk(
  "record/fetchEmailsByContactId",
  async (data: any, thunkAPI) => {
    const response: any = await ContactService.getEmailsByContactId(
      data.recordId
    );
    response["recordId"] = data.recordId;
    return response;
  }
);

export const getUnsubscribe = createAsyncThunk(
  "record/fetchUnsubscribeCampaign",
  async (data: any, thunkAPI) => {
    const response: any = await CampaignService.getUnsubscribe(data.params);
    return response;
  }
);

export const getSLATimer = createAsyncThunk(
  "record/fetchSLATimer",
  async (data: any, thunkAPI) => {
    const response: any = await Report.getSLATimerService(data);
    return response;
  }
);

export const fetchAccounts = createAsyncThunk(
  "record/fetchAccounts",
  async (data: any, thunkAPI) => {
    const response = await AccountService.fetchAccounts(data);
    return response;
  }
);

export const fetchIndiaMartLeadSyncLogs = createAsyncThunk(
  "record/fetchIndiaMartLeadSyncLogs",
  async (data: any, thunkAPI) => {
    const response = await IndiaMartService.getIndiaMartLeadSyncLogs(
      data.params,
      data.appName
    );
    return response;
  }
);

function handleAlternateSourceData(state, action, actionType) {
  switch (actionType) {
    case "UPDATE":
      if ([TABLES.CONTACT, TABLES.SEGMENT].includes(action.payload.tableName)) {
        const connectedTableName =
          action.payload.tableName === TABLES.CONTACT
            ? TABLES.SEGMENT
            : TABLES.CONTACT;
        let alternateSourceData = state.data[connectedTableName]?.data || [];
        alternateSourceData.forEach((item) => {
          if (item._id === action.payload.recId) {
            item.cells = { ...item.cells, ...action.payload.record };
          }
        });
        if (Utility.isEmptyObject(state.data[connectedTableName])) {
          state.data[connectedTableName] = {
            data: alternateSourceData,
            totalCount: alternateSourceData.length
          };
        } else {
          state.data[connectedTableName].data = alternateSourceData;
        }
      }
      break;
    case "DELETE":
      if ([TABLES.CONTACT, TABLES.SEGMENT].includes(action.payload.tableName)) {
        const connectedTableName =
          action.payload.tableName === TABLES.CONTACT
            ? TABLES.SEGMENT
            : TABLES.CONTACT;
        let alternateSourceData = state.data[connectedTableName]?.data || [];
        alternateSourceData = alternateSourceData.filter(
          (item) => !action.payload.recIds.includes(item._id)
        );

        if (Utility.isEmptyObject(state.data[connectedTableName])) {
          state.data[connectedTableName] = {
            data: alternateSourceData,
            totalCount: alternateSourceData.length
          };
        } else {
          state.data[connectedTableName].data = alternateSourceData;
        }
      }
      break;
    default:
  }
}

export const recordSlice = createSlice({
  name: "records",
  initialState,
  reducers: {
    setRecords: (state: RecordState, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    setTableRecords: (state: RecordState, action: PayloadAction<any>) => {
      state.data[action.payload.tableName] = action.payload.data;
    },
    addRecord: (state: RecordState, action: PayloadAction<any>) => {
      state.data[action.payload.tableName].data.unshift(action.payload.record);
      state.data[action.payload.tableName].totalCount++;
    },
    addBulkRecord: (state: RecordState, action: PayloadAction<any>) => {
      state.data[action.payload.tableName].data = [
        ...state.data[action.payload.tableName].data,
        ...action.payload.records
      ];
      state.data[action.payload.tableName].totalCount +=
        action.payload.records.length;
    },
    updateRecord: (state: RecordState, action: PayloadAction<any>) => {
      let data = state.data[action.payload.tableName].data;
      data.forEach((item) => {
        if (item._id === action.payload.recId) {
          item.cells = { ...item.cells, ...action.payload.record };
        }
      });
      state.data[action.payload.tableName].data = data;

      handleAlternateSourceData(state, action, "UPDATE");
    },
    deleteRecord: (state: RecordState, action: PayloadAction<any>) => {
      let data = state.data[action.payload.tableName].data;
      data = data.filter((item) => !action.payload.recIds.includes(item._id));
      state.data[action.payload.tableName].data = data;
      if (state.data[action.payload.tableName]?.totalCount > 0) {
        state.data[action.payload.tableName].totalCount =
          state.data[action.payload.tableName].totalCount - 1;
      }

      handleAlternateSourceData(state, action, "DELETE");
    },
    clearRecords: (state: RecordState, action: PayloadAction<any>) => {
      state.data[action.payload.tableName] = {
        data: [],
        totalCount: 0
      };
    },
    updateTickets: (state: RecordState, action: PayloadAction<any>) => {
      let tickets = { ...state.tickets };
      const indexOfTicketToUpdate = tickets.data.findIndex(
        (ticket) => ticket.id === action.payload.id
      );
      tickets.data[indexOfTicketToUpdate] = action.payload;
      state.tickets = tickets;
    },
    clearTickets: (state: RecordState, action: PayloadAction<any>) => {
      let tickets = { ...state.tickets };
      tickets.data = action.payload;
      state.tickets = tickets;
    },
    updateAudiLogs: (state: RecordState, action) => {
      state.data[TABLES.AUDIT_LOG] = action.payload;
    },
    setTickets: (state: RecordState, action) => {
      state.tickets = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRecordsByTable.fulfilled, (state, action) => {
      state.data[action.meta.arg.tableName] = action.payload;
    });
    builder.addCase(fetchRecordsByTable.rejected, (state, action) => {
      state.data[action.meta.arg.tableName] = {
        data: [],
        totalCount: 0
      };
    });
    builder.addCase(fetchImportLogs.fulfilled, (state, action) => {
      state.data[TABLES.IMPORT_LOG] = action.payload;
    });
    builder.addCase(fetchPriceBookLogs.fulfilled, (state, action) => {
      state.data[TABLES.BOOKS_PRICE_LIST_LOG] = action.payload;
    });
    builder.addCase(fetchAutomationLogs.fulfilled, (state, action) => {
      state.data[TABLES.AUTOMATION_LOG] = action.payload;
    });
    builder.addCase(fetchAuditLogs.fulfilled, (state, action) => {
      state.data[TABLES.AUDIT_LOG] = action.payload;
    });
    builder.addCase(fetchTicketAuditLogs.fulfilled, (state, action) => {
      state.ticketLogs = action.payload;
    });
    builder.addCase(fetchTickets.fulfilled, (state, action) => {
      state.tickets = action.payload;
    });
    builder.addCase(fetchTicketViews.fulfilled, (state, action) => {
      state.ticketViews = action.payload;
    });
    builder.addCase(getPipelineStages.fulfilled, (state, action) => {
      state.currentData.pipelinesStages = action.payload;
    });
    builder.addCase(getDealByContactId.fulfilled, (state, action) => {
      state.currentData.contactDeal = action.payload;
    });
    builder.addCase(getEmailsByContactId.fulfilled, (state, action) => {
      state.currentData.contactEmails = action.payload;
    });
    builder.addCase(fetchDealByStages.fulfilled, (state, action) => {
      state.data[TABLES.DEAL] = action.payload;
    });
    builder.addCase(getUnsubscribe.fulfilled, (state, action) => {
      state.currentData.unsubscribeCampaign = action.payload;
    });
    builder.addCase(fetchAccounts.fulfilled, (state, action) => {
      state.accounts = action.payload || {};
    });
    builder.addCase(fetchIndiaMartLeadSyncLogs.fulfilled, (state, action) => {
      state.currentData.indiamartLeadSyncLogs = action.payload || {};
    });
  }
});

export const {
  setRecords,
  setTableRecords,
  addRecord,
  addBulkRecord,
  updateRecord,
  deleteRecord,
  clearRecords,
  updateTickets,
  clearTickets,
  updateAudiLogs,
  setTickets
} = recordSlice.actions;
const selfSelector = (state: RootState) => state;
export const recordsByTableNameSelector = (tableName: string) =>
  createSelector(
    selfSelector,
    (state: RootState) => state.records.data[tableName]
  );
export const getUnsubscribeCampaign = (state) =>
  state.records.currentData.unsubscribeCampaign;
export const selectAccounts = createSelector(
  selfSelector,
  (state) => state.records.accounts
);
export const getTicketAuditLogs = (state: RootState) =>
  state.records.ticketLogs;
export default recordSlice.reducer;
