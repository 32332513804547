import { Component } from "react";
import {
  DKInput,
  INPUT_VIEW_DIRECTION,
  INPUT_TYPE,
  DKLabel,
  DKIcons,
  DKIcon,
  showAlert,
  getDateAsString,
  DKTooltipWrapper
} from "deskera-ui-library";
import {
  TICKET_PRIORITIES,
  TICKET_STATUS,
  TICKET_TYPES,
  TICKET_VALUES_NAME
} from "../../constants/Enum";
import { DealManager } from "../../managers/DealManager";
import { getFullName } from "../../model/User";
import Utility, { getRandomAlphaNumericString } from "../../utility/Utility";
import {
  convertCRMDateFormatToUILibraryFormat,
  DateUtil
} from "../../utility/Date";
import { AccountService } from "../../services/accounts";
import { COLUMN_CODE, TABLES, TableManger } from "../../managers/TableManger";
import { showFormPopup } from "../menu/AddNewRecordPopup";
import ContactManager from "../../managers/ContactManager";
import ApiConstants from "../../constants/ApiConstants";
import { fetchAccounts } from "../../redux/slices/recordSlice";
import { store } from "../../redux/store";
import {
  ACTIVITY_DATE_FORMAT,
  ERROR_MSG_FOR_EMPTY_FIELD,
  TICKET_DEFAULT_SYSTEM_USER,
  WARNING
} from "../../constants/Constant";
import { differenceInDays } from "date-fns";

export default class TicketInput extends Component<any, any> {
  allIntervals = [];
  constructor(props) {
    super(props);
    this.state = {
      selectedContactList: null,
      selectedAccountList: null,
      textFieldValues: {},
      originalTextFieldValues: {}
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps?.selectedTicket?.id !== this.props.selectedTicket?.id) {
      this.allIntervals?.forEach((id) => {
        clearInterval(id);
      });
      this.allIntervals = [];
    }
  }

  getTimerValue = (fieldName) => {
    if (this.props.selectedTicket?.timer?.length === 0) return {};
    return (
      this.props.selectedTicket?.timer?.find(
        (column) => column.code === fieldName
      ) ?? {}
    );
  };

  startCountDownDate = (dateVal) => {
    let countDownDate = new Date(dateVal).getTime();
    return countDownDate;
  };

  startTimerInterval = (dateTime, documentId) => {
    this.allIntervals.push(
      setInterval(() => {
        this.countDownTimer(this.startCountDownDate(dateTime), documentId);
      }, 1000)
    );
  };

  countDownTimer = (start, targetDOM) => {
    let now = new Date().getTime();
    let distance = start - now;

    let days: any = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours: any = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes: any = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds: any = Math.floor((distance % (1000 * 60)) / 1000);

    days = days < 10 ? "0" + days : days;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    let time = "";
    time = days !== "00" ? days + " : " : "";
    time = hours !== "00" ? time + hours + " : " : "";
    time = minutes !== "00" ? time + minutes + " : " : "";
    time = seconds !== "00" ? time + seconds : "";

    let divElement = document.querySelector("#" + targetDOM);
    if (divElement) {
      divElement.textContent = time;

      if (distance < 0) {
        // clearInterval();
        divElement.textContent = "Overdue";
      }
    }
  };

  getSystemFieldTimer = (field, timerData, uniqueId, optionalValue?) => {
    let daysDiff = differenceInDays(
      new Date(timerData?.process_at),
      new Date()
    );
    return (
      <div className={`row justify-content-between mb-m fs-s`}>
        <DKLabel text="Next action in" />
        <div className={`row justify-content-end width-auto`}>
          <div
            className={`fw-b ${daysDiff > 1 ? "data-grid-badge-color-7" : "data-grid-badge-color-10"} p-h-s p-v-xs border-radius-l`}
            id={`${field?.id}-${uniqueId}-timer`}
          ></div>
          <DKTooltipWrapper
            content={`Time remaining to change <b>${field?.name}</b> to <b>${optionalValue ?? timerData?.value}</b>`}
          >
            <DKIcon src={DKIcons.ic_info} className="ic-xs mt-xs ml-xs" />
          </DKTooltipWrapper>
        </div>
      </div>
    );
  };

  getDynamicFieldTimer = (
    fieldName,
    timerData,
    field,
    uniqueId,
    optionalValue?
  ) => {
    let daysDiffCf = differenceInDays(
      new Date(timerData?.process_at),
      new Date()
    );

    return (
      <div className={`row justify-content-between mb-m fs-s`}>
        <DKLabel text="Next action in" />
        <div className={`row justify-content-end width-auto`}>
          <div
            className={`fw-b ${daysDiffCf > 1 ? "data-grid-badge-color-7" : "data-grid-badge-color-10"} p-h-s p-v-xs border-radius-l`}
            id={`${fieldName}-${uniqueId}-timer`}
          ></div>
          <DKTooltipWrapper
            content={`Time remaining to change <b>${field?.label ?? field?.name}</b> to <b>${optionalValue ?? timerData?.value}</b>`}
          >
            <DKIcon src={DKIcons.ic_info} className="ic-xs mt-xs ml-xs" />
          </DKTooltipWrapper>
        </div>
      </div>
    );
  };

  getInputField = (field) => {
    switch (field?.fieldType?.toLowerCase()) {
      case INPUT_TYPE.TEXT:
      case INPUT_TYPE.EMAIL:
      case INPUT_TYPE.NUMBER:
      case INPUT_TYPE.DATE:
        return this.getTextField(field);
      case INPUT_TYPE.SELECT:
      case INPUT_TYPE.MULTI_SELECT:
        return this.getSelectField(field);
      case INPUT_TYPE.DROPDOWN:
        return this.getDropDownField(field);
      default:
        break;
    }
  };
  getTextField = (field) => {
    let value =
      this.state.textFieldValues[field.code] ??
      (field?.system
        ? field?.value
        : this.props?.cFields
          ? this.props?.cFields.find((cf) => cf.code === field.code)?.value
          : this.props?.selectedTicket?.custom_field?.find(
              (cf) => cf.code === field.code
            )?.value);

    if (
      field?.fieldType?.toLowerCase() === INPUT_TYPE.DATE &&
      value &&
      typeof value === "string"
    ) {
      value = new Date(value);
    }

    const isTextField = field.fieldType.toLowerCase() === INPUT_TYPE.TEXT;
    let userList = this.props.users;
    let uniqueId = getRandomAlphaNumericString(5);
    let fieldName = !field?.system ? "custom_field." + field?.code : field?.id;
    let fieldNameId = fieldName.replace(".", "-");
    let textFieldTimerData = this.getTimerValue(fieldName);
    if (!Utility.isEmptyObject(textFieldTimerData)) {
      this.startTimerInterval(
        textFieldTimerData?.process_at,
        `${fieldNameId}-${uniqueId}-timer`
      );
    }
    return (
      <div className="column parent-width cf-options">
        <div className="row justify-content-between">
          <DKLabel
            text={`${field?.label} ${field?.mandatory ? "*" : ""}`}
            className="parent-width cf-p-b"
          />
          <div className="row justify-content-end">
            {!field?.system && (
              <div className="row justify-content-end custom-field-options-edit-delete width-auto">
                {this.props.canEditCustomFields && (
                  <DKIcon
                    className="cursor-hand ic-s ml-s opacity-5 mb-xs"
                    src={DKIcons.ic_edit}
                    onClick={() => this.props?.onEditCustomField(field)}
                  />
                )}
                {this.props.canDeleteCustomFields && (
                  <DKIcon
                    className="cursor-hand ic-s ml-s opacity-5 mb-xs"
                    src={DKIcons.ic_delete}
                    onClick={() => this.props?.onDeleteCustomField(field)}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <DKInput
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          type={field.fieldType.toLowerCase()}
          value={value}
          required={field?.mandatory}
          onChange={(value) => this.handleInputChange(value, field)}
          onFocus={
            isTextField ? () => this.handleTextFieldFocus(field) : undefined
          }
          onBlur={
            isTextField ? () => this.handleTextFieldBlur(field) : undefined
          }
          className={
            Utility.isEmptyObject(textFieldTimerData) ? "mb-l" : "mb-s"
          }
          readOnly={!this.props.canEdit || field?.readOnly}
          canValidate={this.props.canValidate}
          dateFormat={convertCRMDateFormatToUILibraryFormat(
            DateUtil.getOrgDateFormat()
          )}
        />
        {!Utility.isEmptyObject(textFieldTimerData) &&
          this.getDynamicFieldTimer(
            fieldNameId,
            textFieldTimerData,
            field,
            uniqueId,
            fieldNameId === "created_by"
              ? getFullName(
                  userList?.find(
                    (user) => user.id === parseInt(textFieldTimerData.value)
                  ) ?? ""
                )
              : null
          )}
      </div>
    );
  };

  handleInputChange = (value, field) => {
    if (field.fieldType.toLowerCase() === INPUT_TYPE.TEXT) {
      this.setState((prevState) => ({
        textFieldValues: {
          ...prevState.textFieldValues,
          [field.code]: value
        }
      }));
    } else if (field.fieldType.toLowerCase() === INPUT_TYPE.DATE) {
      // Handle date input change
      this.props?.onCustomFieldChange(value, field);
    } else {
      this.props?.onCustomFieldChange(
        field?.fieldType?.toLowerCase() === INPUT_TYPE.DATE
          ? value
          : value?.trim(),
        field
      );
    }
  };

  handleTextFieldFocus = (field) => {
    const currentValue = this.state.textFieldValues[field.code] ?? field.value;
    this.setState((prevState) => ({
      originalTextFieldValues: {
        ...prevState.originalTextFieldValues,
        [field.code]: currentValue
      }
    }));
  };

  handleTextFieldBlur = (field) => {
    const newValue = this.state.textFieldValues[field.code]?.trim();
    const originalValue =
      this.state.originalTextFieldValues[field.code]?.trim();

    if (newValue !== originalValue) {
      this.props?.onCustomFieldChange(newValue, field);
    }
  };

  getSelectField = (field) => {
    let fieldName = !field?.system ? "custom_field." + field?.code : field?.id;
    let uniqueId = getRandomAlphaNumericString(5);
    let fieldNameId = fieldName.replace(".", "-");
    let textFieldTimerData = this.getTimerValue(fieldName);
    if (!Utility.isEmptyObject(textFieldTimerData)) {
      this.startTimerInterval(
        textFieldTimerData?.process_at,
        `${fieldNameId}-${uniqueId}-timer`
      );
    }
    return (
      <div className="column parent-width cf-options">
        <div className="row justify-content-between">
          <DKLabel
            text={`${field?.label} ${field?.mandatory ? "*" : ""}`}
            className="parent-width cf-p-b"
          />
          <div className="row justify-content-end">
            {!field?.system && (
              <div className="row justify-content-end custom-field-options-edit-delete width-auto">
                {this.props.canEditCustomFields && (
                  <DKIcon
                    className="cursor-hand ic-s ml-s opacity-5 mb-xs"
                    src={DKIcons.ic_edit}
                    onClick={() => this.props?.onEditCustomField(field)}
                  />
                )}
                {this.props.canDeleteCustomFields && (
                  <DKIcon
                    className="cursor-hand ic-s ml-s opacity-5 mb-xs"
                    src={DKIcons.ic_delete}
                    onClick={() => this.props?.onDeleteCustomField(field)}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <DKInput
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          type={field.type}
          value={
            this.props?.selectedTicket?.custom_field?.find(
              (cf) => cf.code === field.code
            )?.value
          }
          options={field.options.map((opt) => opt.name)}
          required={field?.mandatory}
          readOnly={!this.props.canEdit}
          onChange={(value) => this.props?.onCustomFieldChange(value, field)}
          className={
            Utility.isEmptyObject(textFieldTimerData) ? "mb-l" : "mb-s"
          }
        />
        {!Utility.isEmptyObject(textFieldTimerData) &&
          this.getDynamicFieldTimer(
            fieldNameId,
            textFieldTimerData,
            field,
            uniqueId
          )}
        {!field?.system && (
          <DKIcon
            className="cursor-hand ic-s ml-s"
            src={DKIcons.ic_delete}
            onClick={() => this.props?.onDeleteCustomField(field)}
          />
        )}
      </div>
    );
  };
  getDropDownField = (field) => {
    const value = this.props?.cFields
      ? this.props?.cFields.find((cf) => cf.code === field.code)
      : this.props?.selectedTicket?.custom_field?.find(
          (cf) => cf.code === field.code
        );
    let uniqueId = getRandomAlphaNumericString(5);
    let fieldName = !field?.system ? "custom_field." + field?.code : field?.id;
    let fieldNameId = fieldName.replace(".", "-");
    let textFieldTimerData = this.getTimerValue(fieldName);
    if (!Utility.isEmptyObject(textFieldTimerData)) {
      this.startTimerInterval(
        textFieldTimerData?.process_at,
        `${fieldNameId}-${uniqueId}-timer`
      );
    }

    return (
      <div className="column parent-width cf-options">
        <div className="row justify-content-between">
          <DKLabel
            text={`${field?.label} ${field?.mandatory ? "*" : ""}`}
            className="parent-width cf-p-b"
          />
          <div className="row justify-content-end">
            {!field?.system && (
              <div className="row justify-content-end custom-field-options-edit-delete width-auto">
                {this.props.canEditCustomFields && (
                  <DKIcon
                    className="cursor-hand ic-s ml-s opacity-5 mb-xs"
                    src={DKIcons.ic_edit}
                    onClick={() => this.props?.onEditCustomField(field)}
                  />
                )}
                {this.props.canDeleteCustomFields && (
                  <DKIcon
                    className="cursor-hand ic-s ml-s opacity-5 mb-xs"
                    src={DKIcons.ic_delete}
                    onClick={() => this.props?.onDeleteCustomField(field)}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <DKInput
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          type={INPUT_TYPE.DROPDOWN}
          value={value}
          required={field?.mandatory}
          onChange={(index) => {}}
          formatter={(obj) => {
            return obj.value;
          }}
          dropdownConfig={{
            data: field.attributes,
            renderer: (index, obj) => {
              return <DKLabel text={`${obj.value}`} />;
            },
            onSelect: (index, cfValue) => {
              if (value?.value != cfValue.value) {
                this.props?.onCustomFieldChange(cfValue?.value, field);
              }
            }
          }}
          readOnly={!this.props.canEdit}
          className={
            Utility.isEmptyObject(textFieldTimerData) ? "mb-l" : "mb-s"
          }
          canValidate={this.props.canValidate}
        />
        {!Utility.isEmptyObject(textFieldTimerData) &&
          this.getDynamicFieldTimer(
            fieldNameId,
            textFieldTimerData,
            field,
            uniqueId,
            !field.system
              ? JSON.parse(textFieldTimerData?.value)?.value
              : field.options?.find(
                  (att) => att.id === parseInt(textFieldTimerData.value)
                )?.value
          )}
      </div>
    );
  };

  showAlertForAccountChanged = (obj: any = {}) => {
    const buttons = [
      {
        title: "Cancel",
        className: "bg-gray1 border-m",
        onClick: () => {
          this.props.onValueChange(
            TICKET_VALUES_NAME.ACCOUNT,
            this.props.defaultValues.crm3_account_id
          );
        }
      },
      {
        title: "Remove Account",
        className: "bg-red text-white ml-r",
        onClick: () => {
          this.props.onAccountRemoved(
            TICKET_VALUES_NAME.ACCOUNT,
            obj?.id ?? ""
          );
          this.setState({
            selectedContactList: null,
            selectedAccountList: obj
          });
        }
      }
    ];
    showAlert(
      "Warning! ",
      "Changing this account will remove linked contact. Do you want to proceed?",
      buttons
    );
  };

  getLinkedAccountField = (contactValue, accountValue, field) => {
    let accountTimerData = this.getTimerValue(field?.id);
    let uniqueId = getRandomAlphaNumericString(5);
    if (!Utility.isEmptyObject(accountTimerData)) {
      this.startTimerInterval(
        accountTimerData?.process_at,
        `${field?.id}-${uniqueId}-timer`
      );
    }
    return (
      <div className="column parent-width position-relative">
        <DKInput
          title="Linked Account"
          type={INPUT_TYPE.DROPDOWN}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={field?.requiredByUser || false}
          canValidate={this.props.canValidate}
          renderer={(value) => (
            <div
              className="row width-auto bg-gray1 border-radius-s p-xs border-m mr-r"
              style={{ gap: 4 }}
            >
              <DKLabel className="" text={value?.name || "-"} />
              {!Utility.isEmptyObject(value) && (
                <DKIcon
                  src={DKIcons.ic_close_}
                  className={
                    "ic-s-2 ml-s unselectable " +
                    (!this.props.canEdit ? "cursor-none" : "cursor-hand")
                  }
                  style={{ height: 10, opacity: 0.5, padding: 1 }}
                  onClick={() => {
                    if (!this.props.canEdit) return;
                    if (field?.requiredByUser) {
                      showAlert(WARNING, ERROR_MSG_FOR_EMPTY_FIELD);
                      return;
                    }
                    if (!Utility.isEmptyObject(contactValue)) {
                      this.showAlertForAccountChanged();
                      return;
                    }
                    this.props.onValueChange(TICKET_VALUES_NAME.ACCOUNT, "");
                    this.props.onManualAccountSelect?.(false);
                    this.setState({ selectedAccountList: null });
                  }}
                />
              )}
            </div>
          )}
          value={accountValue ?? null}
          onChange={(value) => {}}
          dropdownConfig={{
            allowSearch: true,
            searchableKey: "name",
            data: [],
            renderer: (index, obj) => {
              return (
                <div className="column">
                  <DKLabel text={`${obj.name}`} />
                </div>
              );
            },
            searchApiConfig: {
              method: "POST",
              getUrl: (val) => {
                return (
                  ApiConstants.URL.BASE +
                  ApiConstants.URL.TABLE.GET_RECORD_BY_PAGE(
                    TableManger.getTableId(TABLES.ACCOUNT)
                  ) +
                  "?pageNo=1&pageSize=20&q=" +
                  val
                );
              },
              getPayload: (search) => {
                // let conditions = [
                //   {
                //     colId: TableManger.getColumnId(
                //       TABLES.ACCOUNT,
                //       COLUMN_CODE.ACCOUNT.NAME
                //     ),
                //     opr: "c",
                //     value: search,
                //   },
                // ];
                // return {
                //   logicalOperator: "and",
                //   conditions: conditions,
                // };
              },
              dataParser: (response) => {
                let accountList = AccountService.parseAccountPayload(
                  response.data
                );
                return accountList;
              }
            },
            onSelect: (index, obj, value) => {
              if (this.props.defaultValues.crm3_account_id === obj.id) return;
              if (!Utility.isEmptyObject(contactValue)) {
                this.showAlertForAccountChanged(obj);
                return;
              }
              this.props.onValueChange(TICKET_VALUES_NAME.ACCOUNT, obj.id);
              this.props.onManualAccountSelect?.(true);
              this.setState({ selectedAccountList: obj });
            }
          }}
          readOnly={!this.props.canEdit}
          className={Utility.isEmptyObject(accountTimerData) ? "mb-l" : "mb-s"}
        />
        {!Utility.isEmptyObject(accountTimerData) &&
          this.getSystemFieldTimer(field, accountTimerData, uniqueId)}
      </div>
    );
  };

  getTypeField = (field) => {
    let typeTimerData = this.getTimerValue("type");
    let uniqueId = getRandomAlphaNumericString(5);
    if (!Utility.isEmptyObject(typeTimerData)) {
      this.startTimerInterval(
        typeTimerData?.process_at,
        `type-${uniqueId}-timer`
      );
    }
    return (
      <div className="column parent-width position-relative">
        <DKInput
          autoFocus={true}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          title="Types"
          type={INPUT_TYPE.DROPDOWN}
          value={TICKET_TYPES.find(
            (type) => type === this.props.defaultValues.type
          )}
          required={field?.requiredByUser || false}
          canValidate={this.props.canValidate}
          onChange={(value) => {}}
          formatter={(obj) => {
            return obj;
          }}
          dropdownConfig={{
            data: TICKET_TYPES,
            renderer: (index, obj) => {
              return <DKLabel text={`${obj}`} />;
            },
            onSelect: (index, value) => {
              if (value !== this.props.defaultValues.type) {
                this.props.onValueChange("type", value);
              }
            }
          }}
          readOnly={!this.props.canEdit}
          className={Utility.isEmptyObject(typeTimerData) ? "mb-l" : "mb-s"}
        />
        {!Utility.isEmptyObject(typeTimerData) &&
          this.getSystemFieldTimer(
            { id: "type", name: "Types" },
            typeTimerData,
            uniqueId
          )}
      </div>
    );
  };

  getStatusField = (field) => {
    let statusTimerData = this.getTimerValue(field?.id);
    let uniqueId = getRandomAlphaNumericString(5);
    if (!Utility.isEmptyObject(statusTimerData)) {
      this.startTimerInterval(
        statusTimerData?.process_at,
        `${field?.id}-${uniqueId}-timer`
      );
    }
    return (
      <div className="column parent-width position-relative">
        <DKInput
          autoFocus={true}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          title="Status"
          type={INPUT_TYPE.DROPDOWN}
          value={TICKET_STATUS.find(
            (status) => status === this.props.defaultValues.status
          )}
          canValidate={this.props.canValidate}
          required={field?.requiredByUser || false}
          onChange={(index) => {}}
          formatter={(obj) => {
            return obj;
          }}
          dropdownConfig={{
            data: TICKET_STATUS,
            renderer: (index, obj) => {
              return <DKLabel text={`${obj}`} />;
            },
            onSelect: (index, value) => {
              if (TICKET_STATUS[index] !== this.props.defaultValues.status) {
                this.props.onValueChange("status", TICKET_STATUS[index]);
              }
            }
          }}
          readOnly={!this.props.canEdit}
          className={Utility.isEmptyObject(statusTimerData) ? "mb-l" : "mb-s"}
        />
        {!Utility.isEmptyObject(statusTimerData) &&
          this.getSystemFieldTimer(field, statusTimerData, uniqueId)}
      </div>
    );
  };

  getPriorityField = (selectedPriority, field) => {
    let priorityTimerData = this.getTimerValue(field?.id);
    let uniqueId = getRandomAlphaNumericString(5);
    if (!Utility.isEmptyObject(priorityTimerData)) {
      this.startTimerInterval(
        priorityTimerData?.process_at,
        `${field?.id}-${uniqueId}-timer`
      );
    }
    return (
      <div className="column parent-width position-relative">
        <DKInput
          autoFocus={true}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          title="Priority"
          type={INPUT_TYPE.DROPDOWN}
          value={selectedPriority}
          required={field?.requiredByUser || false}
          canValidate={this.props.canValidate}
          onChange={(value) => {}}
          formatter={(obj) => {
            return `<div class="row"><img class="ic-s mr-s circle" src=${obj?.icon} /> <div>${obj?.name}</div></div>`;
          }}
          dropdownConfig={{
            data: TICKET_PRIORITIES,
            renderer: (index, obj) => {
              return (
                <div className="row">
                  <DKIcon className="ic-s circle mr-s" src={obj.icon} />
                  <DKLabel text={`${obj.name}`} />
                </div>
              );
            },
            onSelect: (index, value) => {
              if (TICKET_PRIORITIES[index]?.name === selectedPriority?.name)
                return;
              this.props.onValueChange(
                "priority",
                TICKET_PRIORITIES[index]?.name
              );
            }
          }}
          readOnly={!this.props.canEdit}
          className={Utility.isEmptyObject(priorityTimerData) ? "mb-l" : "mb-s"}
        />
        {!Utility.isEmptyObject(priorityTimerData) &&
          this.getSystemFieldTimer(field, priorityTimerData, uniqueId)}
      </div>
    );
  };

  getFilterAssignee = () => {
    let assigneeRoles =
      store?.getState()?.tenant?.crmSettings?.ticketSetting?.assigneeRoles;

    let assigneeList =
      assigneeRoles?.length > 0
        ? this.props.users?.filter((user) =>
            assigneeRoles.includes(user?.crm3RoleId)
          )
        : this.props.users;
    return (
      assigneeList?.sort((a, b) => a.firstName.localeCompare(b.firstName)) ?? []
    );
  };

  getAssigneeField = (field) => {
    let userList = this.props.users;
    let selectedValueOfAssignee = userList?.find(
      (user) => user.id === this.props.defaultValues.assignee
    );

    if (
      Utility.isEmptyObject(selectedValueOfAssignee) &&
      this.props.defaultValues.assignee
    ) {
      selectedValueOfAssignee = this.props.users?.filter(
        (user) => user.id === this.props.defaultValues.assignee
      )?.[0];
    }

    let assigneeTimerData = this.getTimerValue(field?.id);
    let uniqueId = getRandomAlphaNumericString(5);
    if (!Utility.isEmptyObject(assigneeTimerData)) {
      this.startTimerInterval(
        assigneeTimerData?.process_at,
        `${field?.id}-${uniqueId}-timer`
      );
    }

    return (
      <div className="column parent-width position-relative">
        <DKInput
          autoFocus={true}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          title="Assignee"
          type={INPUT_TYPE.DROPDOWN}
          value={selectedValueOfAssignee}
          required={field?.requiredByUser || false}
          canValidate={this.props.canValidate}
          onChange={(index) => {}}
          formatter={(obj) => {
            return getFullName(obj);
          }}
          dropdownConfig={{
            data: userList,
            renderer: (index, obj) => {
              return <DKLabel text={`${getFullName(obj)}`} />;
            },
            onSelect: (index, value) => {
              if (this.props.defaultValues.assignee === userList?.[index]?.id)
                return;
              this.props.onValueChange("assignee", userList?.[index]?.id);
            }
          }}
          readOnly={!this.props.canEdit}
          className={Utility.isEmptyObject(assigneeTimerData) ? "mb-l" : "mb-s"}
        />
        {!Utility.isEmptyObject(assigneeTimerData) &&
          this.getSystemFieldTimer(
            field,
            assigneeTimerData,
            uniqueId,
            getFullName(
              userList?.find(
                (user) => user.id === parseInt(assigneeTimerData.value)
              ) ?? ""
            )
          )}
      </div>
    );
  };

  getLinkedContactField = (contactList, contactValue, field) => {
    let contactTimerData = this.getTimerValue(field?.id);
    let uniqueId = getRandomAlphaNumericString(5);
    if (!Utility.isEmptyObject(contactTimerData)) {
      this.startTimerInterval(
        contactTimerData?.process_at,
        `${field?.id}-${uniqueId}-timer`
      );
    }
    return (
      <div className="column parent-width position-relative">
        {!Utility.isEmptyObject(contactTimerData) &&
          this.getSystemFieldTimer(field, contactTimerData, uniqueId)}
        <DKInput
          title="Linked Contact"
          type={INPUT_TYPE.DROPDOWN}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={field?.requiredByUser || false}
          canValidate={this.props.canValidate}
          renderer={(value) => {
            return (
              <div
                className="row width-auto bg-gray1 border-radius-s p-xs border-m mr-r"
                style={{ gap: 4 }}
              >
                <DKLabel className="" text={value?.name || "-"} />
                {!Utility.isEmptyObject(value) && (
                  <DKIcon
                    src={DKIcons.ic_close_}
                    className={
                      "ic-s-2 ml-s unselectable " +
                      (!this.props.canEdit ? "cursor-none" : "cursor-hand")
                    }
                    style={{ height: 10, opacity: 0.5, padding: 1 }}
                    onClick={() => {
                      if (!this.props.canEdit) return;
                      if (field?.requiredByUser) {
                        showAlert(WARNING, ERROR_MSG_FOR_EMPTY_FIELD);
                        return;
                      }
                      this.props.onValueChange(TICKET_VALUES_NAME.CONTACT, "");
                      if (Utility.isEmptyObject(this.props?.selectedTicket)) {
                        setTimeout(() => {
                          this.props.onValueChange(
                            TICKET_VALUES_NAME.REQUESTOR,
                            []
                          );
                        }, 100);
                      }
                      this.setState({ selectedContactList: null });
                    }}
                  />
                )}
              </div>
            );
          }}
          value={contactValue ?? null}
          onChange={(value) => {}}
          dropdownConfig={{
            allowSearch: true,
            searchableKey: "name",
            data: [],
            searchApiConfig: {
              method: "POST",
              getUrl: (val) => {
                return (
                  ApiConstants.URL.BASE +
                  ApiConstants.URL.TABLE.GET_RECORD_BY_PAGE(
                    TableManger.getTableId(TABLES.CONTACT)
                  ) +
                  "?pageNo=1&pageSize=20&fetchAllRef=true&q=" +
                  val
                );
              },
              getPayload: (search) => {
                let conditions = [
                  {
                    colId: TableManger.getColumnId(
                      TABLES.CONTACT,
                      COLUMN_CODE.CONTACT.EMAIL
                    ),
                    opr: "neq",
                    value: ""
                  }
                ];
                if (
                  !Utility.isEmptyObject(
                    this.props.defaultValues.crm3_account_id
                  )
                ) {
                  conditions.push({
                    colId: TableManger.getColumnId(
                      TABLES.CONTACT,
                      COLUMN_CODE.CONTACT.ACCOUNT
                    ),
                    opr: "eq",
                    value: this.props.defaultValues.crm3_account_id
                  });
                }
                return {
                  logicalOperator: "and",
                  conditions: conditions
                };
              },
              dataParser: (response) => {
                let personList = DealManager.contactWithAccountData(
                  response.data
                );
                return personList;
              }
            },
            renderer: (index, obj) => {
              return (
                <div className="column parent-width">
                  <DKLabel text={`${obj.name}`} />
                  <div className="row justify-content-between align-items-start">
                    <DKLabel
                      text={`${obj?.organization ?? ""}`}
                      className="text-gray fs-s text-trailing"
                      style={{ maxWidth: "49%" }}
                    />
                    <DKLabel
                      text={`${obj?.accountName ?? ""}`}
                      className="text-gray fs-s text-trailing"
                      style={{ maxWidth: "49%" }}
                    />
                  </div>
                </div>
              );
            },
            button: {
              title: "New Contact",
              icon: DKIcons.ic_add_white,
              onClick: () =>
                showFormPopup(
                  {
                    tableName: TABLES.CONTACT,
                    defaultValues: ContactManager.getDefaultValues()
                  },
                  () => {
                    store.dispatch(fetchAccounts({}));
                  }
                ),
              className: "p-xs bg-button text-white"
            },
            onSelect: (index, obj, value) => {
              this.props.onValueChange(TICKET_VALUES_NAME.CONTACT, obj.id);
              let selectedAccountId =
                this.props.defaultValues.crm3_account_id ?? "";
              let currentObjAccountID = obj.account_id ?? "";
              if (selectedAccountId != currentObjAccountID) {
                setTimeout(() => {
                  this.props.onValueChange(
                    TICKET_VALUES_NAME.ACCOUNT,
                    obj.account_id
                  );
                }, 100);
              }

              if (Utility.isEmptyObject(this.props?.selectedTicket)) {
                setTimeout(() => {
                  this.props.onValueChange(
                    TICKET_VALUES_NAME.REQUESTOR,
                    [obj.email] ?? []
                  );
                }, 200);
              }
              this.setState({
                selectedContactList: obj,
                selectedAccountList: obj?.account_id
                  ? { id: obj.account_id, name: obj.accountName }
                  : null
              });
            }
          }}
          readOnly={!this.props.canEdit}
          className="mb-l"
        />
      </div>
    );
  };

  getReadOnlyField = (field) => {
    let value = "";
    switch (field?.id) {
      case "created_at":
      case "resolved_at":
      case "closed_at":
        const DATE_FORMAT =
          `EEE, ${convertCRMDateFormatToUILibraryFormat(DateUtil.getOrgDateFormat())}, HH:mm aa` ||
          ACTIVITY_DATE_FORMAT;
        value = Utility.isEmptyObject(this.props.selectedTicket?.[field.id])
          ? "-"
          : getDateAsString(
              new Date(this.props.selectedTicket?.[field.id]),
              DATE_FORMAT
            );
        break;
      case "created_by":
        value =
          this.props.selectedTicket?.created_by === -1
            ? TICKET_DEFAULT_SYSTEM_USER
            : this.props.selectedTicket?.[field.id + "_details"]?.first_name +
              " " +
              (this.props.selectedTicket?.[field.id + "_details"]?.last_name ??
                "");
        break;
    }

    return this.getTextField({
      ...field,
      label: field.name,
      fieldType: INPUT_TYPE.TEXT,
      system: true,
      readOnly: true,
      value: value
    });
  };

  getFields = (
    accountList,
    contactList,
    contactValue,
    selectedPriority,
    accountValue
  ) => {
    return this.props.fieldSequence?.map((field) => {
      if (Utility.isEmptyObject(field)) {
        return;
      }
      switch (field.id) {
        case "status":
          return this.getStatusField(field);

        case "types":
          return this.getTypeField(field);

        case "linkedAccount":
          return this.getLinkedAccountField(contactValue, accountValue, field);

        case "priority":
          return this.getPriorityField(selectedPriority, field);

        case "assignee":
          return this.getAssigneeField(field);

        case "linkedContact":
          return this.getLinkedContactField(contactList, contactValue, field);

        case "created_at":
        case "created_by":
        case "resolved_at":
        case "closed_at":
          return !Utility.isEmptyObject(this.props.selectedTicket)
            ? this.getReadOnlyField(field)
            : null;

        default:
          if (field.type === "cust") {
            let customField = this.props?.customFieldsData?.find(
              (cfield) => cfield.id === field.id
            );
            if (!Utility.isEmptyObject(customField)) {
              return this.getInputField(customField);
            }
          }
          break;
      }
    });
  };

  render() {
    const selectedPriority = TICKET_PRIORITIES.find(
      (priority) => priority.name === this.props.defaultValues.priority
    );
    // ZEN-11680 As requested inactive contact also showing
    const contacts = this.props?.contacts;
    let contactList = DealManager.contactWithAccountData(contacts);
    const accountList = AccountService.parseAccountPayload(
      this.props.accounts ?? []
    );
    let contactValue = contactList.find(
      (contact) => contact.id === this.props.defaultValues.crm3_contact_id
    );

    let accountValue = accountList?.find(
      (account) => account.id === this.props.defaultValues.crm3_account_id
    );

    //CRM-826 changes for contact details
    if (
      !Utility.isEmptyObject(this.props.refDetailsForContactAccount) &&
      Utility.isEmptyObject(contactValue) &&
      this.props.defaultValues.crm3_contact_id
    ) {
      contactValue = this.props.refDetailsForContactAccount;
      accountValue = {
        name: this.props.refDetailsForContactAccount.accountName,
        id: this.props.refDetailsForContactAccount?.account_id
      };
    }

    if (
      !Utility.isEmptyObject(contactValue?.accountName) &&
      Utility.isEmptyObject(accountValue) &&
      this.props.defaultValues.crm3_account_id
    ) {
      accountValue = {
        name: contactValue.accountName,
        id: contactValue?.account_id
      };
    }

    if (
      Utility.isEmptyObject(contactValue) &&
      !Utility.isEmptyObject(this.props.defaultValues.crm3_contact_id) &&
      Utility.isEmptyObject(this.props.selectedTicket) &&
      !Utility.isEmptyObject(this.props.emailDetails)
    ) {
      let contactData = this.props.emailDetails.find(
        (email) => email.id === this.props.defaultValues.crm3_contact_id
      );
      if (contactData) {
        contactValue = contactData;
        if (!Utility.isEmptyObject(contactData.accountName)) {
          accountValue = {
            name: contactData.accountName,
            id: contactData?.account_id
          };
        }
      }
    }

    if (
      Utility.isEmptyObject(contactValue) &&
      !Utility.isEmptyObject(this.props.selectedTicket) &&
      this.props.defaultValues.crm3_contact_id ===
        this.props.selectedTicket?.contact_detail?.[0]?._id
    ) {
      let contactResponse =
        this.props.selectedTicket?.contact_detail?.[0] ?? null;
      if (contactResponse) {
        contactValue = { ...contactResponse, id: contactResponse?._id };
      }
    }
    if (
      Utility.isEmptyObject(contactValue) &&
      Utility.isEmptyObject(this.props.selectedTicket) &&
      !Utility.isEmptyObject(this.props.defaultValues.crm3_contact_id) &&
      !Utility.isEmptyObject(this.state.selectedContactList)
    ) {
      contactValue = this.state.selectedContactList;
    }

    if (
      Utility.isEmptyObject(accountValue) &&
      !Utility.isEmptyObject(this.props.selectedTicket) &&
      this.props.defaultValues.crm3_account_id ===
        this.props.selectedTicket?.account_detail?.[0]?._id
    ) {
      let accountResponse =
        this.props.selectedTicket?.account_detail?.[0] ?? null;
      if (accountResponse) {
        accountValue = { ...accountResponse, id: accountResponse?._id };
      }
    }

    if (
      Utility.isEmptyObject(accountValue) &&
      Utility.isEmptyObject(this.props.selectedTicket) &&
      !Utility.isEmptyObject(this.props.defaultValues.crm3_account_id) &&
      !Utility.isEmptyObject(this.state.selectedAccountList)
    ) {
      accountValue = this.state.selectedAccountList;
    }

    if (this.props.defaultValues.crm3_account_id) {
      contactList = contactList.filter(
        (contact) =>
          contact.account_id === this.props.defaultValues.crm3_account_id
      );
    }

    return (
      <div
        className="column parent-size dk-input-holder support-ticket-input pr-s pr-m"
        style={{ overflowY: "auto" }}
      >
        {this.getFields(
          accountList,
          contactList,
          contactValue,
          selectedPriority,
          accountValue
        )}
      </div>
    );
  }
}
