import { COLUMN_CODE, TableManger, TABLES } from "./TableManger";
import { INPUT_TYPE } from "deskera-ui-library";
import Table from "../services/table";
import UserManager from "./UserManager";
import Utility, { convertDateToAgoString } from "../utility/Utility";
import { store } from "../redux/store";
export class DealManager {
  static deals: any = {};
  static dealDetails: any = {};
  static dealKanBanColumns: any = [];
  static parsePipelineData(records) {
    let pipelines = [];
    const pipelineNameColumnId = TableManger.getColumnId(
      TABLES.PIPELINE,
      COLUMN_CODE.PIPELINE.NAME
    );
    const pipelineStageColumnId = TableManger.getColumnId(
      TABLES.PIPELINE,
      COLUMN_CODE.PIPELINE.STAGE_ID_S
    );
    const defaultColumnId = TableManger.getColumnId(
      TABLES.PIPELINE,
      COLUMN_CODE.PIPELINE.IS_DEFAULT
    );
    const ownerColumnId = TableManger.getColumnId(
      TABLES.PIPELINE,
      COLUMN_CODE.PIPELINE.OWNER_ID
    );
    const subOwnerColumnId = TableManger.getColumnId(
      TABLES.PIPELINE,
      COLUMN_CODE.PIPELINE.SUB_OWNER_ID
    );
    if (records.data) {
      records.data.forEach((record) => {
        pipelines.push({
          id: record._id,
          name: record.cells[pipelineNameColumnId],
          pipelineName: record.cells[pipelineNameColumnId],
          isDefault: record.cells[defaultColumnId],
          pipelineOwner: record.cells?.[ownerColumnId],
          subOwners: record.cells?.[subOwnerColumnId],
          stages: record.cells[pipelineStageColumnId].map((stageId) => {
            return {
              id: stageId,
              name: "",
              deals: []
            };
          })
        });
      });
    }
    return pipelines;
  }
  static putStagesInPipeline(pipelines: any[], records) {
    const stageNameColumnId = TableManger.getColumnId(
      TABLES.STAGE,
      COLUMN_CODE.STAGES.NAME
    );
    const stageOrderColumnId = TableManger.getColumnId(
      TABLES.STAGE,
      COLUMN_CODE.STAGES.ORDER
    );
    const stageWonColumnId = TableManger.getColumnId(
      TABLES.STAGE,
      COLUMN_CODE.STAGES.WON_STAGE
    );
    const stageLostColumnId = TableManger.getColumnId(
      TABLES.STAGE,
      COLUMN_CODE.STAGES.LOST_STAGE
    );
    if (pipelines && records?.data) {
      pipelines.forEach((pipeline) => {
        pipeline.stages.forEach((stage, index) => {
          let matchedStage = records.data.find((rec) => rec._id === stage.id);
          if (matchedStage) {
            stage.name = matchedStage.cells[stageNameColumnId];
            stage.order = matchedStage.cells[stageOrderColumnId];
            stage.isWonStage = matchedStage.cells[stageWonColumnId]
              ? true
              : false;
            stage.isLostStage = matchedStage.cells[stageLostColumnId]
              ? true
              : false;
          }
        });
      });
    }
  }
  static getDefaultValues(values = {}) {
    return {
      [TableManger.getColumnId(TABLES.DEAL, COLUMN_CODE.DEAL.PIPELINE_ID)]: [0],
      [TableManger.getColumnId(TABLES.DEAL, COLUMN_CODE.DEAL.STAGE_ID)]: [0],
      [TableManger.getColumnId(TABLES.DEAL, COLUMN_CODE.DEAL.VISIBILITY)]: [1],
      [TableManger.getColumnId(TABLES.DEAL, COLUMN_CODE.DEAL.CLOSING_DATE)]:
        null,
      [TableManger.getColumnId(TABLES.DEAL, COLUMN_CODE.DEAL.DEAL_DATE)]:
        new Date(),
      [TableManger.getColumnId(TABLES.DEAL, COLUMN_CODE.DEAL.STATUS)]: 0,
      [TableManger.getColumnId(TABLES.DEAL, COLUMN_CODE.DEAL.OWNER_ID)]: [
        UserManager.getUserIamID()
      ],
      ...values
    };
  }
  static getQuery(type) {
    let personQuery = {
      logicalOperator: "and",
      conditions: [
        {
          colId: DealManager.getColumnId(
            COLUMN_CODE.CONTACT.TYPE,
            TABLES.CONTACT
          ),
          value: "1",
          opr: "c"
        }
      ]
    };
    let orgQuery = {
      logicalOperator: "and",
      conditions: [
        {
          colId: DealManager.getColumnId(
            COLUMN_CODE.CONTACT.TYPE,
            TABLES.CONTACT
          ),
          value: "2",
          opr: "c"
        }
      ]
    };

    switch (type) {
      case "personQuery":
        return personQuery;
      case "orgQuery":
        return orgQuery;
      default:
        return;
    }
  }
  static contactData = (contacts) => {
    let contactList = [];
    let accountDetails = store.getState().records?.accounts?.data ?? [];
    contacts?.forEach((contact) => {
      let contactName =
        contact.cells[
          DealManager.getColumnId(COLUMN_CODE.CONTACT.NAME, TABLES.CONTACT)
        ];
      let organizationName =
        contact.cells[
          DealManager.getColumnId(
            COLUMN_CODE.CONTACT.ORGANISATION,
            TABLES.CONTACT
          )
        ];
      let email =
        contact.cells[
          DealManager.getColumnId(COLUMN_CODE.CONTACT.EMAIL, TABLES.CONTACT)
        ];

      let accountName = "";
      let account =
        contact.cells[
          DealManager.getColumnId(COLUMN_CODE.CONTACT.ACCOUNT, TABLES.CONTACT)
        ];
      if (account) {
        let details = accountDetails.filter(
          (accountData) => accountData._id === account[0]
        )?.[0];
        accountName =
          details?.cells[
            DealManager.getColumnId(COLUMN_CODE.ACCOUNT.NAME, TABLES.ACCOUNT)
          ] ?? "";
      }
      let status =
        contact.cells[
          DealManager.getColumnId(COLUMN_CODE.CONTACT.STATUS, TABLES.CONTACT)
        ];

      let fields = {
        id: contact._id,
        [COLUMN_CODE.CONTACT.NAME]: contactName ? contactName : "Untitled",
        [COLUMN_CODE.CONTACT.ORGANISATION]: organizationName || "",
        [COLUMN_CODE.CONTACT.EMAIL]: email || "",
        accountName: accountName || "",
        account_id: account?.[0] ?? "",
        [COLUMN_CODE.CONTACT.STATUS]: status
      };
      contactList.push(fields);
    });
    return contactList;
  };

  static contactWithAccountData = (contacts) => {
    let contactList = [];
    contacts?.forEach((contact) => {
      let contactName =
        contact.cells[
          DealManager.getColumnId(COLUMN_CODE.CONTACT.NAME, TABLES.CONTACT)
        ];
      let organizationName =
        contact.cells[
          DealManager.getColumnId(
            COLUMN_CODE.CONTACT.ORGANISATION,
            TABLES.CONTACT
          )
        ];
      let email =
        contact.cells[
          DealManager.getColumnId(COLUMN_CODE.CONTACT.EMAIL, TABLES.CONTACT)
        ];

      let accountName = "";
      let account =
        contact.cells[
          DealManager.getColumnId(COLUMN_CODE.CONTACT.ACCOUNT, TABLES.CONTACT)
        ];

      if (account) {
        let accountDetails =
          contact.cells[
            DealManager.getColumnId(
              COLUMN_CODE.CONTACT.ACCOUNT,
              TABLES.CONTACT
            ) + "_detail"
          ];
        accountName =
          accountDetails?.[0]?.cells[
            DealManager.getColumnId(COLUMN_CODE.ACCOUNT.NAME, TABLES.ACCOUNT)
          ] ?? "";
      }
      let status =
        contact.cells[
          DealManager.getColumnId(COLUMN_CODE.CONTACT.STATUS, TABLES.CONTACT)
        ];

      let fields = {
        id: contact._id,
        [COLUMN_CODE.CONTACT.NAME]: contactName ? contactName : "Untitled",
        [COLUMN_CODE.CONTACT.ORGANISATION]: organizationName || "",
        [COLUMN_CODE.CONTACT.EMAIL]: email || "",
        accountName: accountName || "",
        account_id: account?.[0] ?? "",
        [COLUMN_CODE.CONTACT.STATUS]: status
      };
      contactList.push(fields);
    });
    return contactList;
  };

  static getColumnId(columnCode, table) {
    return TableManger.getColumnId(table ? table : TABLES.DEAL, columnCode);
  }
  static getDataToUpdate = (data) => {
    let dealColumns = {};
    TableManger.getTableColumns(TABLES.DEAL).forEach((column) => {
      if (
        column.columnCode !== COLUMN_CODE.DEAL.VISIBILITY &&
        Utility.isEmptyObject(column.datasource) &&
        (column.type === INPUT_TYPE.SELECT ||
          column.type === INPUT_TYPE.MULTI_SELECT)
      ) {
        dealColumns[column.id] = column;
      }
    });
    Object.keys(dealColumns).forEach((item) => {
      if (data[item]) {
        data[item] = data[item].map((r) =>
          dealColumns[item].options.findIndex((op) => op.id === r)
        );
      }
    });
  };
  static getDealDetail(recordId, refresh = false): Promise<any> {
    if (this.dealDetails && this.dealDetails._id === recordId && !refresh) {
      return Promise.resolve(this.dealDetails);
    } else {
      return this.fetchDealDetail(recordId);
    }
  }

  static getDealDetailById(dealId: string) {
    const dealTableId = TableManger.getTableId(TABLES.DEAL);
    const dealIds = [dealId];
    return Table.getRecordBulk(dealIds, dealTableId, {
      showAll: true
    }).then((res) => {
      const response = res;

      if (response && response.data && response.data.length > 0) {
        return Promise.resolve(response.data[0]);
      } else {
        return Promise.reject("No record found");
      }
    });
  }

  static fetchDealDetail(recordId): Promise<any> {
    const dealTableId = TableManger.getTableId(TABLES.DEAL);
    return Table.getRecordById(recordId, dealTableId, {
      fetchAllRef: true
    }).then((res) => {
      this.setDealDetail(res);
      return Promise.resolve(res);
    });
  }
  static setDealDetail(dealDetails) {
    this.dealDetails = dealDetails;
  }
  static clearDealDetails() {
    this.setDealDetail({});
  }
  static getDealDisplayValues(deal) {
    const excludeColumn = [
      COLUMN_CODE.DEAL.NAME,
      COLUMN_CODE.DEAL.STAGE_ID,
      COLUMN_CODE.DEAL.CONTACT_ID,
      COLUMN_CODE.DEAL.ACCOUNT_ID,
      COLUMN_CODE.DEAL.PIPELINE_ID,
      COLUMN_CODE.DEAL.NOTE,
      // COLUMN_CODE.DEAL.OWNER_ID,
      COLUMN_CODE.DEAL.AMOUNT
    ];
    let dealDisplayValue = [];
    let dealCol = TableManger.getTableImportColumns(TABLES.DEAL, excludeColumn);
    dealCol.push(
      TableManger.getColumn(TABLES.DEAL, COLUMN_CODE.DEAL.ATTACHMENT)
    );
    dealCol.push(
      TableManger.getColumn(TABLES.DEAL, COLUMN_CODE.DEAL.PRIMARY_QUOTE_AMOUNT)
    );

    dealCol.push(
      TableManger.getColumn(TABLES.DEAL, COLUMN_CODE.DEAL.PRIMARY_QUOTE_NUMBER)
    );

    dealCol.forEach((col) => {
      let value = deal[col.id];
      let keyOptions = [];
      if (
        col.type === INPUT_TYPE.DATE &&
        !Utility.isEmptyObject(deal[col.id])
      ) {
        value = new Date(value).toDateString();
      }
      if (col.type === INPUT_TYPE.SELECT) {
        value = col.options[deal[col.id]];
        if (
          !Utility.isEmptyObject(deal[col.id]) &&
          Utility.isEmptyObject(value)
        ) {
          value = { name: "-", id: -1 };
        }
      }

      if (!Utility.isEmptyObject(col.lookup)) {
        let lookupValue = deal[col.id + "_detail"]?.[0];
        value = lookupValue;
      }

      if (col.type === INPUT_TYPE.MULTI_SELECT) {
        let valueName = [];
        col.options?.forEach((data) => {
          valueName.push(data.name);
        });
        keyOptions = valueName;
      }
      dealDisplayValue.push({
        name: col.name,
        value: value,
        type: col.type,
        colCode: col.id,
        options: col.options,
        keyOptions: keyOptions,
        required: col.required ? col.required : col.requiredByUser ?? false,
        lookup: col.lookup,
        dropdownConfig: col.dropdownConfig
      });
    });
    return dealDisplayValue;
  }
  static parseDealData = (dealToParse: {
    _id: string;
    cells: { [key: string]: any };
  }): { [key: string]: any } => {
    const dealColumnIds = TableManger.getTableColumns(TABLES.DEAL).reduce(
      (acc, col) => {
        acc[col.columnCode] = col.id;
        return acc;
      },
      {}
    );
    return {
      id: dealToParse._id,
      name: dealToParse.cells[dealColumnIds[COLUMN_CODE.DEAL.NAME]],
      closing_date:
        dealToParse.cells[dealColumnIds[COLUMN_CODE.DEAL.CLOSING_DATE]],
      created_date:
        dealToParse.cells[dealColumnIds[COLUMN_CODE.DEAL.CREATED_AT]],
      stage_id:
        dealToParse.cells[dealColumnIds[COLUMN_CODE.DEAL.STAGE_ID]]?.[0],
      pipeline_id:
        dealToParse.cells[dealColumnIds[COLUMN_CODE.DEAL.PIPELINE_ID]]?.[0],
      amount: dealToParse.cells[dealColumnIds[COLUMN_CODE.DEAL.AMOUNT]]
        ? dealToParse.cells[dealColumnIds[COLUMN_CODE.DEAL.AMOUNT]]
        : 0,
      contact_id: dealToParse.cells[dealColumnIds[COLUMN_CODE.DEAL.CONTACT_ID]]
        ? dealToParse.cells[dealColumnIds[COLUMN_CODE.DEAL.CONTACT_ID]]?.[0]
        : "",
      contact_detail: dealToParse.cells[
        dealColumnIds[COLUMN_CODE.DEAL.CONTACT_ID] + "_detail"
      ]
        ? dealToParse.cells[
            dealColumnIds[COLUMN_CODE.DEAL.CONTACT_ID] + "_detail"
          ]?.[0]
        : "",
      deal_date: dealToParse.cells[dealColumnIds[COLUMN_CODE.DEAL.DEAL_DATE]],
      owner_id: dealToParse.cells[dealColumnIds[COLUMN_CODE.DEAL.OWNER_ID]],
      owner_detail:
        dealToParse.cells[dealColumnIds[COLUMN_CODE.DEAL.OWNER_ID] + `_detail`],
      sub_owner_id:
        dealToParse.cells[dealColumnIds[COLUMN_CODE.DEAL.SUB_OWNER_ID]],
      attachment: dealToParse.cells[dealColumnIds[COLUMN_CODE.DEAL.ATTACHMENT]],
      createdBy: dealToParse["createdBy"],
      organization_id:
        dealToParse.cells[dealColumnIds[COLUMN_CODE.DEAL.ORGANIZATION_ID]],
      reason: dealToParse.cells[dealColumnIds[COLUMN_CODE.DEAL.REASON]],
      status: dealToParse.cells[dealColumnIds[COLUMN_CODE.DEAL.STATUS]]
        ? dealToParse.cells[dealColumnIds[COLUMN_CODE.DEAL.STATUS]]
        : 0,
      note: dealToParse.cells[dealColumnIds[COLUMN_CODE.DEAL.NOTE]],
      date: convertDateToAgoString(
        dealToParse.cells[dealColumnIds[COLUMN_CODE.DEAL.CREATED_AT]]
      ),
      currency:
        dealToParse.cells[dealColumnIds[COLUMN_CODE.DEAL.CURRENCY_CODE]],
      account_id: dealToParse.cells[dealColumnIds[COLUMN_CODE.DEAL.ACCOUNT_ID]]
        ? dealToParse.cells[dealColumnIds[COLUMN_CODE.DEAL.ACCOUNT_ID]]?.[0]
        : "",
      account_detail: dealToParse.cells[
        dealColumnIds[COLUMN_CODE.DEAL.ACCOUNT_ID] + "_detail"
      ]
        ? dealToParse.cells[
            dealColumnIds[COLUMN_CODE.DEAL.ACCOUNT_ID] + "_detail"
          ]?.[0]
        : "",
      primary_quote_number:
        dealToParse.cells[
          dealColumnIds[COLUMN_CODE.DEAL.PRIMARY_QUOTE_NUMBER]
        ] ?? "NA",
      primary_quote_amount:
        dealToParse.cells[
          dealColumnIds[COLUMN_CODE.DEAL.PRIMARY_QUOTE_AMOUNT]
        ] ?? "NA"
    };
  };
}
