export const INDIAN_STATES_MOCK_DATA: any[] = [
  {
    code: "Andaman & Nicobar Islands",
    name: "Andaman & Nicobar Islands",
    stateCode: "AN",
    cities: [
      "Bakultala",
      "Bambooflat",
      "Car Nicobar",
      "Garacharma ",
      "Hut Bay",
      "Kamorta",
      "Malacca",
      "Mayabunder",
      "Port Blair",
      "Prothrapur",
      "Rangat",
      "Tarasa Island"
    ]
  },
  {
    code: "Andhra Pradesh",
    name: "Andhra Pradesh",
    stateCode: "AP",
    cities: [
      "Achanta ",
      "Adoni ",
      "Allur ",
      "Amadalavalasa ",
      "Amalapuram ",
      "Anakapalle ",
      "Anantapalli ",
      "Anantapur ",
      "Anaparthy ",
      "Annavaram ",
      "Araku ",
      "Atchutapuram APSEZ ",
      "Atmakur ",
      "Atmakur Sri Potti Sriramulu Nellore District ",
      "Attilli ",
      "Badampudi ",
      "Badvel ",
      "Banaganapalle ",
      "Bandarulanka",
      "Banumukkala ",
      "Bapatla ",
      "Bestawaripeta ",
      "Bethamcherla ",
      "Bheemunipatnam ",
      "Bhimadole ",
      "Bhimavaram ",
      "Bobbili ",
      "Bowluvada ",
      "Bugganipalle ",
      "Chagallu ",
      "Chebrole Khandrika ",
      "Chebrolu ",
      "Chennamukkapalle ",
      "Chennur ",
      "Chilakaluripet ",
      "Chimakurthy ",
      "Chintalavalasa ",
      "Chintapalle ",
      "Chippada Pharma SEZ ",
      "Chirala ",
      "Chittoor ",
      "Chodavaram ",
      "Cumbum ",
      "Denduluru ",
      "Devarapalli ",
      "Dharmavaram ",
      "Dhone ",
      "Dommara Nandyala ",
      "Dondapadu Kakathiya Cement Factory ",
      "Dondapadu KCP Cement Factory ",
      "Dowleswaram ",
      "Draksharamam ",
      "Dwaraka Tirumala ",
      "Edlapadu ",
      "Ekambarakuppam",
      "Eluru",
      "Gajapathinagaram ",
      "Gajulamandhyam",
      "Industrial Area ",
      "Gajularega ",
      "Ganapavaram ",
      "Gangapatnam ",
      "Gavaravaram ",
      "Giddaluru ",
      "Golugonda ",
      "Gooty ",
      "Gopalapuram ",
      "Gopavaram ",
      "Gudilova ",
      "Gudivada ",
      "Gudur ",
      "Gundugolanu ",
      "Guntakal ",
      "Guntur ",
      "Gurzala ",
      "Hanuman Junction ",
      "Hindupur ",
      "Hiramandalam ",
      "Horseley Hills ",
      "Hukumpeta ",
      "Ibrahimpatnam ",
      "Ichchapuram ",
      "India Cement Factory Chilamakuru ",
      "Indukurpet ",
      "Industrial Growth Centre Thimmanapalem ",
      "Jaggaiahpet ",
      "Jammalamadugu ",
      "Jangareddigudem ",
      "Jarjapupeta ",
      "Jaypee Cement Factory Budawada",
      "Kadapa ",
      "Kadiri ",
      "Kaikaram ",
      "Kakinada ",
      "Kakkalapalle ",
      "Kallur ",
      "Kallur Anantapur",
      "District ",
      "Kaluvaya ",
      "Kalyandurg ",
      "Kanapaka ",
      "Kandukur ",
      "Kanigiri ",
      "Kankipadu ",
      "Kantabamsuguda ",
      "Kathipudi ",
      "Kavali ",
      "Kavali Industrial Area ",
      "Kodumur ",
      "Kodur ",
      "Koikuntla ",
      "Komarolu ",
      "Kondapalle ",
      "Kota ",
      "Kotabammal ",
      "Kothapatnam ",
      "Kothavalasa ",
      "Kovurpalle ",
      "Kovvur ",
      "Kudithipalem ",
      "Kuppam ",
      "Kurnool ",
      "Macherla ",
      "Machilipatnam ",
      "Madanapalle ",
      "Madhurawada",
      "Mandapeta ",
      "Mangalagiri ",
      "Mangampeta ",
      "Mangasamudram ",
      "Markapur ",
      "Maruteru ",
      "Medarametla ",
      "Medikonduru ",
      "Meliaputti ",
      "Moragudi ",
      "Morampudi ",
      "Muddanur ",
      "Mulaguntapadu ",
      "Mulakuddu ",
      "Mummidivaram ",
      "Murakambattu ",
      "Muttukuru ",
      "Mydukur ",
      "Mylavaram ",
      "Nadim Tiruvuru ",
      "Nagaram ",
      "Nagari ",
      "Nagireddipalle ",
      "Nakkapalle ",
      "Nallajerla ",
      "Nandigama ",
      "Nandyal ",
      "Narasannapeta ",
      "Narasapur ",
      "Narasapuram ",
      "Narasaraopet ",
      "Narayanapuram Vizianagaram District ",
      "Narayanavanam ",
      "Narsipatnam ",
      "Nayudupeta ",
      "Nellimarla",
      "Nellore ",
      "Nidadavole ",
      "NTPC Simhadri ",
      "Nuzvid ",
      "Ongole ",
      "Paderu ",
      "Palacole ",
      "Palakonda ",
      "Palamaner ",
      "Palasa Kasibugga ",
      "Pamidi ",
      "Pamur ",
      "Paravada Industrial Area ",
      "Parvathipuram ",
      "Pathapatnam ",
      "Payakaraopeta ",
      "Peda Boddepalle ",
      "Pedana ",
      "Pedatadepalli ",
      "Peddapuram ",
      "Pentapadu ",
      "Perecherla ",
      "Phirangipuram ",
      "Piduguralla ",
      "Pileru ",
      "Pippara ",
      "Pithapuram ",
      "Podalakur ",
      "Podili ",
      "Pondura ",
      "Ponnekallu ",
      "Ponnur ",
      "Porumamilla ",
      "Prattipadu ",
      "Proddatur ",
      "Pulivendula ",
      "Punganur ",
      "Tadepalle ",
      "Puttaparthi ",
      "Tadepalligudem ",
      "Puttur ",
      "Tadpatri ",
      "Rajahmundry ",
      "Tangellamudi ",
      "Rajam ",
      "Tanuku ",
      "Rajampet ",
      "Tekkali ",
      "Ramabhadrapuram ",
      "Tenali ",
      "Ramachandrapuram ",
      "Thagarapuvalasa ",
      "Ramapuram ",
      "Thatipaka ",
      "Ramathirtham ",
      "Thullur ",
      "RAMCO Cement Factory",
      "Thumakunta Industrial Area",
      "Dharmavarappadu ",
      "Tiruchanur ",
      "Rameswaram ",
      "Tirumala ",
      "Rampachodavaram ",
      "Tirupati ",
      "Rayachoti ",
      "Tummikapalle ",
      "Rayadurg ",
      "Tuni ",
      "Razole ",
      "Unguturu ",
      "Repalle ",
      "Uppada ",
      "Rushikonda APIIC ",
      "Upper Sileru ",
      "Salur Samalkot Project Site Camp ",
      "Sanivarapupeta ",
      "Uravakonda ",
      "Santhanuthalapadu ",
      "Vaddeswaram ",
      "Satrampadu ",
      "Vavilla ",
      "Sattenapalle ",
      "Velvadam ",
      "Singarayakonda ",
      "Venkatachalam ",
      "Somandepalle Industrial Area ",
      "Sompeta ",
      "Venkatagiri ",
      "Sricity ",
      "Veparala ",
      "Srikakulam ",
      "Vetapalem ",
      "Srikalahasti ",
      "Vijayapuri South ",
      "Sriramnagar ",
      "Vijayawada ",
      "Srisailam ",
      "Vinnamala ",
      "Srungavarapukota ",
      "Vinukonda ",
      "Sulluru ",
      "Visakhapatnam ",
      "Sunnipenta ",
      "Vissannapet ",
      "Tada ",
      "Vizianagaram",
      "VSP Township ",
      "Vuyyuru ",
      "Yanam ",
      "Yelamanchili ",
      "Yemmiganur ",
      "Yenumalapalle ",
      "Yernagudem ",
      "Yerraguntla"
    ]
  },
  {
    code: "Arunachal Pradesh",
    name: "Arunachal Pradesh",
    stateCode: "AR",
    cities: [
      "Along",
      "AninI",
      "Basar",
      "Boleng",
      "Bomdila",
      "Changlang",
      "Daporijo",
      "Deomali",
      "Dirang",
      "Hawai",
      "Itanagar",
      "Jairampur",
      "Khonsa",
      "Koloriang",
      "Longding",
      "Miao",
      "Naharlagun",
      "Namsai",
      "Pasighat",
      "Roing",
      "Rupa",
      "Sagalee",
      "Seppa",
      "Tawang",
      "Tezu",
      "Yingkiong",
      "Ziro"
    ]
  },
  {
    code: "Assam",
    name: "Assam",
    stateCode: "AS",
    cities: [
      "Abhayapuri",
      "Amguri",
      "Anand Nagar",
      "Asudubi",
      "Azara",
      "Badarpur",
      "Badarpur Rly Town",
      "Bamun Sualkuchi",
      "Bandardewa IID",
      "Bangaon",
      "Barpathar",
      "Barpeta",
      "Barpeta Road",
      "Basugaon",
      "BCPL Township",
      "Behiating AIDC",
      "Belsor",
      "Bhomoraguri AIDC",
      "Bhuragaon",
      "Bihpuria",
      "Bijni",
      "Bilasipara",
      "Biswanath Chariali",
      "Bohari",
      "Bokajan",
      "Bokakhat",
      "Bongaigaon",
      "Bongaigaon RPCL Township",
      "Borgolai Gt 11",
      "Borpukhuri",
      "Chabua",
      "Chalantapara Pt IV",
      "Chandrapur Bagicha",
      "Changsari",
      "Chapakhowa Town",
      "Chapar",
      "Charingia Gaon",
      "Chekonidhara",
      "Dahali",
      "Dalgaon",
      "Damara Patpara",
      "Dergaon",
      "Dhakuakhana",
      "Dharapur",
      "Dhekiajuli",
      "Dhekorgorha",
      "Dhemaji",
      "Dhing",
      "Dhubri",
      "Dibrugarh",
      "Digaru Gaon",
      "Digboi",
      "Diphu",
      "Dispur",
      "Doboka",
      "Dokmoka",
      "Donkamokam",
      "Doom Dooma",
      "Dudhpatil Pt V",
      "Dudhpatil Pt VI",
      "Duliajan",
      "Duliajan No 1",
      "Durga Nagar Part-V",
      "Garal",
      "Gauripur",
      "Goalpara",
      "Gohpur",
      "Golaghat",
      "Golokganj",
      "Goreswar",
      "Gossaigaon",
      "Guwahati",
      "Haflong",
      "Hailakandi",
      "Hamren",
      "Hindustan Paper Corporation Ltd Township",
      "Panchgram",
      "Hojai",
      "Howli",
      "Howraghat",
      "Irongmara",
      "Jagiroad",
      "Jamunamukh",
      "Jhagra Pt III",
      "Jonai Bazar",
      "Jorhat",
      "Kakaya",
      "Kalaigaon Town Part",
      "Kamalabaria NC",
      "Kampur Town",
      "Kanisail Pt I",
      "Karimganj",
      "Khaira Bari",
      "Kharijapikon",
      "Kharupatia",
      "Kochpara",
      "Kokrajhar",
      "Kumar Kaibarta Gaon",
      "Lakhipur",
      "Lakhipur, Cachar District",
      "Lala",
      "Lanka",
      "Lido Tikok",
      "Lido Town",
      "Lumding",
      "Lumding Rly Colony",
      "Mahur",
      "Maibong",
      "Majgaon",
      "Makum",
      "Mangaldoi",
      "Mankachar",
      "Margherita",
      "Mariani",
      "Marigaon",
      "Marowa",
      "Moran Town",
      "Moranhat",
      "Mornoi Industrial Area",
      "Mushalpur",
      "Nagaon",
      "Naharkatiya",
      "Nahira",
      "Nalbari",
      "Namrup",
      "Narayanpur",
      "Naubaisa Gaon",
      "Nazira",
      "New Bongaigaon Railway Colony",
      "Niz Bahjani",
      "Niz Hajo",
      "Niz Katigorah Pt III",
      "Niz Mankata",
      "North Guwahati",
      "North Lakhimpur",
      "Numaligarh Refinery Township",
      "Palasbari",
      "Pathsala",
      "Pub Dhaniram Pather",
      "Raha",
      "Rangapara",
      "Rangia",
      "Rangia IIDC",
      "Rani",
      "Rupahi Town",
      "Rupiabathan",
      "Salakati",
      "Salpara Molandubi Pt I",
      "Sanpara",
      "Sapatgram",
      "Sarbhog",
      "Sarthebari",
      "Sarupathar",
      "Sarupathar Bengali",
      "Sepon",
      "Sibsagar",
      "Silapathar",
      "Silchar",
      "Sonapur Gaon",
      "Sonari",
      "Sualkuchi",
      "Takhlibilar Pathar",
      "Tangla",
      "Tegheria",
      "Teok",
      "Tezpur",
      "Thekashu Pt I",
      "Thekashu Pt II",
      "Tihu",
      "Tinsukia",
      "Titabor Town",
      "Udalguri",
      "Umrangso"
    ]
  },
  {
    code: "Bihar",
    name: "Bihar",
    stateCode: "BR",
    cities: [
      "Amarpur",
      "Anwari",
      "Areraj",
      "Arrah",
      "Arwal",
      "Asarganj",
      "Aurangabad",
      "Bagaha",
      "Bahadurganj",
      "Bahadurpur",
      "Bairgania",
      "Bakhri",
      "Bakhtiarpur",
      "Balia",
      "Banka",
      "Banmankhi Bazar",
      "Bara",
      "Barahiya",
      "Barauli",
      "Barauni IOC Township",
      "Barbigha",
      "Barh",
      "Baruni",
      "Baruni Thremal Power Station Township",
      "Begusarai",
      "Behea",
      "Belsand",
      "Benipur",
      "Bettiah",
      "Bhabua",
      "Bhagalpur",
      "Bhagirathpur",
      "Biharsharif",
      "Bihta",
      "Bihta Patna District",
      "Bikram",
      "Bikramganj",
      "Bodh Gaya",
      "Buxar",
      "Chakia",
      "Chanari",
      "Chand Chaur",
      "Chanpatia",
      "Chapra",
      "Dalsinghsarai",
      "Darbhanga",
      "Dariapur",
      "Daudnagar",
      "Dehri",
      "Dhaka",
      "Dighwara",
      "Dumari",
      "Dumraon",
      "Ekangar Sarai",
      "Fatwah",
      "Forbesganj",
      "Gaya",
      "Gazipur",
      "Ghoghardiha",
      "Gogri Jamalpur",
      "Gopalganj",
      "Habibpur",
      "Hajipur",
      "Hanspura",
      "Harnaut",
      "Hathua",
      "Hilsa",
      "Hisua",
      "Islampur",
      "Jagdishpur",
      "Jainagar",
      "Jamalpur",
      "Jamhaur",
      "Janakpur Road",
      "Janpur",
      "Jehanabad",
      "Jhajha",
      "Jhanjharpur",
      "Jogabani",
      "Kahalgaon",
      "Kanti",
      "Kargahia Purab",
      "Kasba",
      "Kataiya",
      "Katihar",
      "Kesaria",
      "Khagaria",
      "Kharagpur",
      "Khusrupur",
      "Kishanganj",
      "Koath",
      "Koilwar",
      "Kumarbagh",
      "Lakhisarai",
      "Lalganj",
      "Laruara",
      "Madhepura",
      "Madhuban",
      "Madhubani",
      "Maharajganj",
      "Mahnar Bazar",
      "Mairwa",
      "Makhdumpur",
      "Malhipur",
      "Maner",
      "Manihari",
      "Mansur Chak",
      "Marhaura",
      "Masaurhi",
      "Mathurapur",
      "Mehsi",
      "Mirganj",
      "Mohiuddinagar",
      "Mokameh",
      "Motihari",
      "Motipur",
      "Munger",
      "Murliganj",
      "Muzaffarpur",
      "Nabinagar",
      "Narkatiaganj",
      "Nasrigagnj",
      "Naubatpur",
      "Naugachhia",
      "Nawada",
      "Nirmali",
      "Nokha",
      "NTPC Barh",
      "Obra",
      "Padri",
      "Pakri Dayal",
      "Pandual",
      "Pareo",
      "Paria",
      "Patna",
      "Piro",
      "Puraini",
      "Purnia",
      "Rafiganj",
      "Raghunathpur",
      "Rajauli",
      "Rajgir",
      "Ramgarh",
      "Ramnagar",
      "Raxaul Bazar",
      "Revelganj",
      "Saharsa",
      "Sahebganj",
      "Sahebganj Banka District",
      "Saidpura",
      "Samastipur",
      "Saraiya",
      "Sasaram",
      "Satghara",
      "Shahpur",
      "Sheikhpura",
      "Sheohar",
      "Sherghati",
      "Silao",
      "Singhesar Asthan",
      "Sitamarhi",
      "Siwan",
      "Sonepur",
      "Sugauli",
      "Sultanganj",
      "Supaul",
      "Teghra",
      "Telkap",
      "Thakurganj",
      "Tikari",
      "Tola Baliadih",
      "Tola Chain",
      "Tola Mansaraut",
      "Tola Pairamatihana",
      "Vaishali",
      "Warisaliganj"
    ]
  },
  {
    code: "Chandigarh",
    name: "Chandigarh",
    stateCode: "CG",
    cities: ["Chandigarh", "Mohali", "Panchkula"]
  },
  {
    code: "Chhattisgarh",
    name: "Chhattisgarh",
    stateCode: "CH",
    cities: [
      "Aamdi",
      "Adawal",
      "Adbhar",
      "Ahiwara",
      "Akaltara",
      "Ambagarh Chowki",
      "Ambikapur",
      "Antagarh",
      "Arang",
      "Arasmeta",
      "Arjunda",
      "BadeBacheli",
      "Badla",
      "Bagbahara",
      "Bagicha",
      "Baikunthpur",
      "Balod",
      "Baloda",
      "BalodaBazar",
      "Balrampur",
      "Banarsi",
      "BankiMongra",
      "Baramkela",
      "Barsur",
      "Basna",
      "Bastar",
      "Berla",
      "Bhairamgarh",
      "Bhakhara",
      "Bhanupratappur",
      "Bhatapara",
      "Bhatgaon",
      "Bhilai",
      "Bhopalpattanam",
      "Bijapur",
      "Bilaigarh",
      "Bilaspur",
      "Bilha",
      "Cement Plant",
      "Champa District",
      "Dongargaon",
      "Dornapal",
      "Doundi",
      "Durg",
      "Farasgaon",
      "Fingeshwar",
      "Gandai",
      "Gariaband",
      "Gaurela",
      "Gharghoda",
      "Gidam",
      "Girwarganj IIDC",
      "GobraNawapara",
      "Govindapur",
      "Gunderdehi",
      "Hatkachora",
      "Hirmi",
      "Industrial Area Anjani",
      "Jagdalpur",
      "Jaijepur",
      "Janjgir",
      "Jarhi",
      "Jarwe",
      "Jashpurnagar",
      "Jhagrakhand",
      "Kanker",
      "Kasdol",
      "Katghora",
      "Katkona",
      "Kawardha",
      "Keskal",
      "Khairagarh",
      "Khamharia",
      "Kharod",
      "Kharora",
      "Kharsia",
      "Khongapani",
      "Kirandul",
      "Kondagaon",
      "Koni",
      "Konta",
      "Korba",
      "Kota",
      "Kotba",
      "Kumhari",
      "Kunkuri",
      "Kurasia",
      "Kurud",
      "Kusmi",
      "Rajpur",
      "Lailunga",
      "Lakhnapur",
      "Lawan",
      "Lormi",
      "Magarlod",
      "Mahasamund",
      "Mahendragarh",
      "Malhar",
      "MandharIndustrial Area",
      "MandirHasaud",
      "Maro",
      "Mehmand",
      "Mungeli",
      "Nagari",
      "NaiLedri",
      "NamnaKalan",
      "Narayanpur",
      "Narharpur",
      "Nawagarh",
      "Nawagarh Janjgir",
      "NayaBaradwar",
      "NewRaipur",
      "NTPCSipat",
      "Pakhanjur",
      "Palari",
      "Pali",
      "Pandaria",
      "Pandatarai",
      "Paratappur",
      "Parpodi",
      "Patan",
      "Pathalgaon",
      "Pathariya",
      "Phunderdihari",
      "Pipariya",
      "Pithora",
      "Pusaur",
      "Rahaud",
      "Raigarh",
      "Raipur",
      "Rajgamar",
      "Rajnandgaon",
      "Ramanujganj",
      "RaniDurgavati",
      "Ratanpur",
      "RawanAmbuja",
      "Sahaspur Lohara",
      "Abhanpur",
      "Saja",
      "Sakari",
      "Sakti",
      "Saraipali",
      "Sarangarh",
      "Sargaon",
      "Sariya",
      "Sheorinarayan",
      "SilpahariIndustrial Area",
      "Simga",
      "Sirgitti",
      "Sitapur",
      "Sonadhi",
      "Sukma",
      "Bemetra",
      "Saragaon",
      "Surajpur",
      "Takhatpur",
      "Telgaon",
      "TenduaIID",
      "Tifra",
      "Tilda",
      "Tumgaon",
      "Tundra",
      "Ultratech Cement Plant",
      "Rawan",
      "Utai",
      "Vishrampur",
      "Vishrampuri",
      "Wadrafnagar"
    ]
  },
  {
    code: "Dadra & Nagar Haveli",
    name: "Dadra & Nagar Haveli",
    stateCode: "DN",
    cities: [
      "Alok City",
      "Amli",
      "Dungra",
      "Masat Industrial Area",
      "Naroli",
      "Silvassa"
    ]
  },
  {
    code: "Daman & Diu",
    name: "Daman & Diu",
    stateCode: "DD",
    cities: ["Dadhel", "Daman", "Diu", "Ghoghla", "Kachigam"]
  },
  {
    code: "Delhi",
    name: "Delhi",
    stateCode: "DL",
    cities: [
      "Central Delhi",
      "North Delhi",
      "South Delhi",
      "East Delhi",
      "North East Delhi",
      "South West Delhi",
      "New Delhi",
      "North West Delhi",
      "West Delhi",
      "Shahdara",
      "South East Delhi"
    ]
  },
  {
    code: "Goa",
    name: "Goa",
    stateCode: "GA",
    cities: [
      "Acoi",
      "Aldona",
      "Anjuna",
      "Aquem",
      "Arambol",
      "Arpora",
      "Assagao",
      "Badem",
      "Baga",
      "Bambolim",
      "Bandora",
      "Bastora",
      "Benaulim",
      "Betim",
      "Bicholim",
      "Borim",
      "Britona",
      "Calangute",
      "Calapor",
      "Calvim",
      "Camurlim",
      "Canacona",
      "Canca",
      "Candola",
      "Candolim",
      "Carapur",
      "Carona",
      "Chapora",
      "Chicalim",
      "Chimbel",
      "Chinchinim",
      "Chopdem",
      "Chorão",
      "Colva",
      "Colvale",
      "Corjuem",
      "Cumbarjua",
      "Cunchelim",
      "Cuncolim",
      "Curchorem",
      "Curti",
      "Curtorim",
      "Divar",
      "Davorlim",
      "Guirim",
      "Jua",
      "Karaswada",
      "Mandrem",
      "Mapusa",
      "Marcaim",
      "Margao",
      "Marna",
      "Mayem",
      "Mercurim",
      "Moira",
      "Morjim",
      "Nachinola",
      "Nagoa",
      "Navelim",
      "Nerul",
      "Nuvem",
      "OldGoa",
      "Onda",
      "Orgao",
      "Oxel",
      "Pale",
      "Paliem",
      "Panaji",
      "Parcem",
      "Parra",
      "Penha De Franca",
      "Pernem",
      "Pilerne",
      "Pomburpa",
      "Ponda",
      "Porvorim",
      "Priol",
      "Quepem",
      "Queula",
      "Quitla",
      "Raia",
      "Reis Magos",
      "Saligao",
      "Salvador do Mundo",
      "Sancoale",
      "Sangolda",
      "Sanguem",
      "Sanquelim",
      "Sanvordem",
      "SaoJose de Areal",
      "Sinquerim",
      "Siolim",
      "Sirsaim",
      "Socorro",
      "Tivim",
      "Ucassaim",
      "Usgao",
      "Vagator",
      "Valpoi",
      "Varca",
      "VascoDa Gama",
      "Verla",
      "Verem",
      "Xeldem"
    ]
  },
  {
    code: "Gujarat",
    name: "Gujarat",
    stateCode: "GJ",
    cities: [
      "Abrama",
      "Adalaj",
      "Adityana",
      "Ahmedabad",
      "Ahwa",
      "Alang",
      "Alikherva",
      "Ambaji",
      "Ambaliyasan",
      "Amboli",
      "Amod",
      "Amreli",
      "Anand",
      "Anandpar",
      "Anandpar Jamnagar",
      "Andada",
      "Anjar",
      "Anklav",
      "Ankleshwar",
      "Antaliya",
      "Arambhada",
      "Arsodiya",
      "Atul",
      "Baben",
      "Babra",
      "Bagasara",
      "Balasinor",
      "Baliyasan",
      "Bansda",
      "Banaskantha",
      "Bantwa",
      "Bardoli",
      "Bareja",
      "Barwala",
      "Bavla",
      "Bayad",
      "BecharAlias Becharaji",
      "Bedi",
      "Bhabhar",
      "Bhachau",
      "Bhadkodara",
      "Bhagal",
      "BhagalValsad District",
      "Bhalpara",
      "Bhanvad",
      "Bharuch",
      "Bhat",
      "Bhavnagar",
      "Bhayavadar",
      "Bhilad",
      "Bhiloda",
      "Bhuj",
      "Bhurivel",
      "Bilimora",
      "Bodeli",
      "Boriavi",
      "Borsad",
      "Botad",
      "Chaklasi",
      "Chalala",
      "Chalthan",
      "Chanasma",
      "Changodar GIDC",
      "Chanod",
      "Chhapi",
      "Chhapra",
      "Chhatral",
      "Chhiri",
      "ChhotaUdaipur",
      "Chikhli",
      "Chorwad",
      "Chotila",
      "Dabhoi",
      "DahejSIR",
      "Dakor",
      "Damnagar",
      "Dediapada",
      "Deesa",
      "Dehari",
      "Dehgam",
      "Deodar",
      "Devgadbaria",
      "Devsar",
      "Dhandhuka",
      "Dhanera",
      "Dharampur",
      "DhasaVishi",
      "Dhola",
      "Dholka",
      "Dhoraji",
      "Dhrangadhra",
      "Dhrol",
      "Digvijaygram",
      "District",
      "District",
      "District",
      "Dohad",
      "Dungarpur",
      "Dungarpur Bhavnagar     Jetalsar",
      "Dwarka",
      "Gadhada",
      "Gamdi",
      "Gandevi",
      "Gandhidham",
      "Gandhinagar",
      "Gariadhar",
      "Ghogha",
      "GIDCAntaliya",
      "GIDCPanoli",
      "GIDCPor",
      "GIDCSavli",
      "Godhra",
      "Gondal",
      "GSFCMotikhavdi",
      "GSFCReliance Complex  Karjan",
      "GWCTownship Kovaya   Kathlal",
      "Hajira",
      "Halol",
      "Halvad",
      "Hansalpur",
      "Harij",
      "Himmatnagar",
      "Ichchhapor",
      "Ichhapor",
      "Idar",
      "Jafrabad",
      "Jalalpore",
      "Jamavala",
      "Jambusar",
      "Jamjodhpur",
      "Jamnagar",
      "Jarod",
      "Jasdan",
      "Jetpur",
      "JetpurNavagadh",
      "Jhalod",
      "Joshipura",
      "Junagadh",
      "Kadi",
      "Kadodara",
      "Kakoshi",
      "Kalavad",
      "Kalol",
      "Kalol,Panch",
      "Kandla",
      "Kanjari",
      "Kanodar",
      "Kapadvanj",
      "Karamsad",
      "Katpar",
      "Kavant",
      "Keshod",
      "Kevadiya",
      "Khambhalia",
      "Khambhat",
      "khapat",
      "Kharach",
      "Kharaghoda",
      "Kheda",
      "Khedbrahma",
      "Kheralu",
      "Kim",
      "Kodinar",
      "Kosamba",
      "Kothria",
      "Kutiyana",
      "Lathi",
      "Lavachha",
      "Lilia",
      "Limbdi",
      "Limkheda",
      "Limla",
      "Lodhikas",
      "Lunawada",
      "Madhapar",
      "Mahals District",
      "Mahesana",
      "Mahudha",
      "Mahuva",
      "Mahuvar",
      "Malanka",
      "Maliya",
      "Malpur",
      "Manavadar",
      "Mandvi",
      "MandviSurat District",
      "Mangrol",
      "Mankuva",
      "Mansa",
      "Meghraj",
      "Mehmedabad",
      "Mehsana",
      "Mithapur",
      "Modasa",
      "Modhera",
      "Morbi",
      "Mundra",
      "Nadiad",
      "Nalsarovar",
      "Nandej",
      "Nari",
      "Nasvadi",
      "NavaBhildi",
      "NavlakhiSIR",
      "Navsari",
      "Ode",
      "OkhaPort",
      "Orvad",
      "Paddhari",
      "Padra",
      "Palaj",
      "Palanpur",
      "Palej",
      "Palitana",
      "PanoliINA",
      "Parabada",
      "Pardi",
      "Parnera",
      "Patan",
      "Patdi",
      "Pethapur",
      "Petlad",
      "Porbandar",
      "Prantij",
      "Radhanpur",
      "Rajkot",
      "Rajpipla",
      "Rajula",
      "Ranavav",
      "Ranpur",
      "Rapar",
      "Raval",
      "Ravapara",
      "Sagbara",
      "Salaya",
      "Salvav",
      "Sanand",
      "SanandGIDC",
      "Sanjan",
      "Sanjeli",
      "Santrampur",
      "Saputara",
      "Sarigam",
      "Sathamba",
      "Savarkundla",
      "Savli",
      "Sayan",
      "Selamba",
      "Shapur",
      "ShapurRajkot District     Vaghodia",
      "Shehera",
      "Sidhpur",
      "Sidsar",
      "Sihor",
      "Sikka",
      "Sikka INA",
      "SimarSIR",
      "Sojitra",
      "Somnath",
      "Songadh",
      "Songadh Bhavnagar",
      "Sukhpar",
      "Surajkaradi",
      "Surat",
      "Surendranagar",
      "Sutrapada",
      "Talaja",
      "Talala",
      "Talod",
      "Tarsadi",
      "Thangadh",
      "Thara",
      "Tharad",
      "Thasra",
      "Ukai",
      "Umbergaon",
      "Umrala",
      "Umreth",
      "Una",
      "Undach",
      "Unjha",
      "Upleta",
      "Vadali",
      "Vadia",
      "Vadnagar",
      "Vadodara",
      "Valia",
      "Vallabhipur",
      "Valsad",
      "Vanthali",
      "Vapi",
      "Vartej",
      "Vasna",
      "Veraval",
      "VeravalRajkot District",
      "Vijapur",
      "Vijaynagar",
      "VilayatGIDC",
      "Viramgam",
      "Virpur",
      "VirpurRajkot District",
      "Visavadar",
      "Visnagar",
      "Vyara",
      "Wadhwan",
      "Waghai",
      "Wankaner",
      "Vijalpor"
    ]
  },
  {
    code: "Haryana",
    name: "Haryana",
    stateCode: "HR",
    cities: [
      "Adampur",
      "Ambala",
      "Area",
      "Asankhurd",
      "Assandh",
      "Ateli",
      "BadhMalak",
      "Badli",
      "Baghola",
      "Bahadurgarh",
      "Barara",
      "BarhiIndustrial Area",
      "Barwala",
      "Bawal",
      "BawaniKhera",
      "Beri",
      "Bhakali",
      "Bhiwani",
      "Bhondsi",
      "Bhuran",
      "Bilaspur",
      "BinolaIndustrial Area",
      "Boh",
      "Buria",
      "ChandiMandir",
      "Chandimandir Bir",
      "CharkhiDadri",
      "Cheeka",
      "Chhachhrauli",
      "Dharuhera",
      "Ellenabad",
      "Farakhpur",
      "Faridabad",
      "Farrukhnagar",
      "Fatehabad",
      "Ferozepur Jhirka",
      "Ganaur",
      "Gangwa",
      "GarhiHarsaru",
      "Ghaggar",
      "Gharaunda",
      "Gohana",
      "Gurgaon",
      "Haileymandi",
      "Hansi",
      "Hassanpur",
      "Hathin",
      "Hisar",
      "Hodal",
      "HSIIDCDudhola Industrial NagalChaudhry",
      "HSIIDCManakpur         Naraingarh",
      "Indri",
      "Industrial Area",
      "Industrial Area",
      "Industrial Estate Barwala  Narwana",
      "Ismailabad",
      "Jakhalmandi",
      "Jhajjar",
      "Jind",
      "Julana",
      "Kabri",
      "Kachrauli",
      "Kaithal",
      "KakarMajra",
      "Kalanaur",
      "Kalanwali",
      "Kalayat",
      "Kalka",
      "Kanina",
      "Karnal",
      "Kharkhoda",
      "KhoriKalan",
      "Kundli",
      "KundliIndustrial Area      RamGarh",
      "Kurukshetra",
      "Ladrawan",
      "Ladwa",
      "Loharu",
      "Maham",
      "Mahendragarh",
      "Majra",
      "MandiDabwali",
      "Manesar",
      "Manethi",
      "Mayyer",
      "Mustafabad",
      "Nagina",
      "Narnaul",
      "Narnaund",
      "Nilokheri",
      "Nissing",
      "Nuh",
      "Palwal",
      "Panchkula",
      "Panipat",
      "PanipatRefinery",
      "Pataudi",
      "Pehowa",
      "Piala",
      "Pinagwan",
      "Pinjore",
      "Punahana",
      "Pundri",
      "Radaur",
      "RaiIndustrial Area",
      "RaipurRani",
      "Rania",
      "Ratia",
      "Rewari",
      "Rohtak",
      "RozKaMeo",
      "Sadaura",
      "Safidon",
      "Saha",
      "Salamba",
      "Samalkha",
      "Sampla",
      "Sankhol",
      "Shahbad",
      "SikriIndustrial Area",
      "Sirsa",
      "Siwani",
      "Sohna",
      "Sonipat",
      "SunariKalan",
      "Taoru",
      "Taraori",
      "Tohana",
      "Tosham",
      "Township",
      "Uchana",
      "UgraKheri",
      "Uklanamandi",
      "UnchaSiwana",
      "Yamunanagar"
    ]
  },
  {
    code: "Himachal Pradesh",
    name: "Himachal Pradesh",
    stateCode: "HP",
    cities: [
      "Amb Industrial Area",
      "Area",
      "Area",
      "Area",
      "Arki",
      "Baddi",
      "Baddi Industrial Area",
      "Badhal Industrial Area",
      "Bagbania Industrial Area",
      "Bain Attarian Industrial",
      "Bakloh",
      "Banjar",
      "Barotiwala Industrial Area",
      "Bhambla Industrial Area",
      "Bhota",
      "Bhuntar",
      "Bilaspur",
      "Centre Industrial Area",
      "Chail",
      "Chamba",
      "Chaupal",
      "ChuariKhas",
      "Dagshai",
      "Dalhousie",
      "Daulatpur",
      "Dera Gopipur",
      "Dharampur Industrial",
      "Dharmsala",
      "Gagret",
      "Gagret Industry Area",
      "Garnota Industry Area",
      "Ghumarwin",
      "Golthai Industry Area",
      "Gondpur Industry Area",
      "Hamirpur",
      "Hatil Industry Area",
      "Indora",
      "Jais Industial Area",
      "Jawalamukhi",
      "Jhakhri",
      "Jharmajri EPIP Phase 1",
      "Jogindarnagar",
      "Jubbal",
      "Jutogh",
      "KalaAmb Industrial Area",
      "Kangra",
      "Kasauli",
      "Kasol",
      "Keylong",
      "Keylong Industial Estate",
      "Khajjiar",
      "Kinnaur",
      "Kotkhai",
      "Kullu",
      "Lodhi Majra Industrial",
      "Manali",
      "Mandi",
      "Manikaran",
      "Mehatpur",
      "Moginand Industrial Area",
      "Nadaun",
      "Nagri Industial Area",
      "NagrotaBagwan",
      "Nahan",
      "NainaDevi",
      "Nalagarh",
      "Narkanda",
      "Nurpur",
      "Palampur",
      "PaontaSahib",
      "Parel Industial Estate",
      "Parwanoo",
      "Pragpur",
      "RajaKaBagh Industrial",
      "Rajgarh",
      "Rampur",
      "Ratti Industial Area",
      "Rawalsar",
      "ReckongPeo",
      "Renuka",
      "Rohru",
      "Sabathu",
      "Sansarpur Terrace Growth",
      "Santokhgarh",
      "Sarkaghat",
      "SauliKhad Industrial Area",
      "Seoni",
      "Shamshi Industial Area",
      "Shimla",
      "Shivnagari Holi Industrial",
      "Shoghi",
      "Shoghi Industial Area",
      "Solan",
      "Solang",
      "Sundarnagar",
      "Tahliwala Industrial Area",
      "Talai",
      "Theog",
      "TiraSujanpur",
      "Una"
    ]
  },
  {
    code: "Jammu & Kashmir",
    name: "Jammu & Kashmir",
    stateCode: "JK",
    cities: [
      "Achabal",
      "Akhnoor",
      "Anantnag",
      "Arnia",
      "Arwani",
      "Ashmuji Khalsa",
      "Awantipora",
      "Bandipore",
      "Banihal",
      "Baramula ",
      "Bari Brahmana EPIP Industrial Estate",
      "Bashohli",
      "Batote",
      "Beerwah",
      "Bhaderwah",
      "Bijbehara",
      "Billawar",
      "Bishna",
      "Chenani",
      "Choglamsar",
      "Dachigam",
      "Doda",
      "Dras",
      "Duru Verinag",
      "Ganderbal",
      "Gho Manhasan",
      "Gorah Salathian",
      "Gulmarg",
      "Hajan",
      "Handwara",
      "Hanle",
      "Hiranagar",
      "Jammu",
      "Jourian",
      "Kargil",
      "Kathua",
      "Katra",
      "Khan Sahib",
      "Khilanmarg",
      "Khour",
      "Khrew",
      "Kishtwar",
      "Kud",
      "Kukernag",
      "Kulgam",
      "Kupwara",
      "Leh",
      "Magam",
      "Mattan",
      "Mohmmad Pora",
      "Nowgam",
      "Nowshehra",
      "Pahalgam",
      "Parole",
      "Patnitop",
      "Pattan",
      "Pulwama",
      "Punch",
      "Qaimoh",
      "Qazigund",
      "Rajauri",
      "Ramban",
      "Ramgarh",
      "Ramnagar",
      "Ranbirsinghpora",
      "Reasi",
      "Rehambal",
      "Samba",
      "Seer Hamdan",
      "Shangus",
      "Shopian",
      "Soolkoot",
      "Sopore",
      "Srinagar",
      "Sumbal",
      "Sunderbani",
      "Talwara",
      "Thanamandi",
      "Tral",
      "Udhampur",
      "Uri",
      "Vijay Pore"
    ]
  },
  {
    code: "Jharkhand",
    name: "Jharkhand",
    stateCode: "JH",
    cities: [
      "AEC Complex",
      "Akdoni Khurd",
      "Alagdiha",
      "Amlabad",
      "Angarpathar",
      "Ara",
      "Bachra",
      "Baharagora",
      "Baliapur",
      "Baliari",
      "Balkundra",
      "Barajamda",
      "Bardubhi",
      "Barharwa",
      "Barhi",
      "Barkakana",
      "Barki Saraiya",
      "Barora",
      "Barughutu",
      "Barwadih",
      "Basaria",
      "Basukinath",
      "Baua Kalan",
      "Bekobar",
      "Berhait Bazar",
      "Bermo",
      "Bhagatdih",
      "Bhandra",
      "Bhandra Bokaro District",
      "Bhim Kanari",
      "Bhojudih",
      "Bhowrah",
      "Bhuli",
      "Bishnugarh",
      "Bishrampur",
      "Bishrampur Latehar District",
      "Bokaro Steel City",
      "Bokaro Thermal",
      "Bongabar",
      "Borio",
      "Bundu",
      "Bursera",
      "Chaibasa",
      "Chain Pur",
      "Chakradharpur",
      "Chakulia",
      "Chandaur",
      "Chandil",
      "Chandrapura",
      "Chandwa",
      "Charhi",
      "Chatra",
      "Chauparan",
      "Chhatatanr",
      "Chhotaputki",
      "Chiria",
      "Chirkunda",
      "Churi",
      "Daltonganj",
      "Danguwapasi",
      "Dari",
      "Deoghar",
      "Deorikalan",
      "Dhanbad",
      "Dhanwar",
      "Dhaunsar",
      "Domchanch",
      "Dugda",
      "Dumarkunda",
      "Dumka",
      "Egarkunr",
      "Garhwa",
      "Ghagra",
      "Ghatshila",
      "Gidi",
      "Giridih",
      "Gobindpur",
      "Godda",
      "Godhar",
      "Gomoh",
      "Gua",
      "Gumia",
      "Gumla",
      "Haludpukhur",
      "Hariharpur",
      "Harina",
      "Hasir",
      "Hazaribagh",
      "Hesla",
      "Hussainabad",
      "Irba",
      "Isri",
      "Jadugora",
      "Jagannathpur",
      "Jai Nagar",
      "Jamadoba",
      "Jamshedpur",
      "Jamtara",
      "Jangalpur",
      "Jaridih Bazar",
      "Jasidih",
      "Jena",
      "Jharia",
      "Jharia Khas",
      "Jhinghipahari",
      "Jhinkpani",
      "Jhumri Tilaiya",
      "Jorapokhar",
      "Kailudih",
      "Kalikapur",
      "Kandra",
      "Kanke",
      "Karma",
      "Karma Chatra District",
      "Karma Tanr",
      "Karmatanr",
      "Kathhara",
      "Katras",
      "Kedla",
      "Kenduadih",
      "Kharkhari",
      "Kharsawan",
      "Khelari",
      "Khunti",
      "Kiriburu",
      "Kodarma",
      "Kuju",
      "Kumarpur",
      "Kumarpur Sahibganj District",
      "Kurpania",
      "Kustai",
      "Lakarka",
      "Lalpania",
      "Lapanga",
      "Latehar",
      "Lohardaga",
      "Loyabad",
      "Madhuban",
      "Madhupur",
      "Mahagma",
      "Mahesh Mundi",
      "Mahuda",
      "Maithon",
      "Majhion",
      "Makoli",
      "Malkera",
      "Mandu",
      "Manohar Pur",
      "Marar",
      "Marma",
      "Matigara",
      "Mera",
      "Meru",
      "Mihijam",
      "Mugma",
      "Muraidih",
      "Muri",
      "Musabani",
      "Nagri Kalan",
      "Narra",
      "Netarhat",
      "Nirsa",
      "Noamundi",
      "Okni No 2",
      "Orla",
      "Pakaur",
      "Palawa",
      "Panchet",
      "Panrra",
      "Paratdih",
      "Pathardih",
      "Patra",
      "Patratu",
      "Phusro",
      "Pondarkanali",
      "Rajbhita",
      "Rajmahal",
      "Ramgarh Cantonment",
      "Ranchi",
      "Ratu",
      "Rehla",
      "Religara",
      "4Rohraband",
      "Sahibganj",
      "Sahnidih",
      "Sanri",
      "Saraidhela",
      "Saram",
      "Sarauni",
      "Satgawan",
      "Saunda",
      "Seraikela",
      "Sewai",
      "Shahpur",
      "Sijhua",
      "Sijua",
      "Simdega",
      "Sindri",
      "Sinduria",
      "Sini",
      "Sirka",
      "Siuliban",
      "Suranga",
      "Tapin",
      "Tati",
      "Telo",
      "Telodih",
      "Tenu",
      "Tin Pahar",
      "Tisra",
      "Topa",
      "Topchanchi",
      "Torpa",
      "Toto",
      "Tundiul"
    ]
  },
  {
    code: "Karnataka",
    name: "Karnataka",
    stateCode: "KA",
    cities: [
      "Addur",
      "Adityapatna",
      "Bellary",
      "Adyar",
      "Beltangadi",
      "Afzalpur",
      "Belur",
      "Agumbe",
      "Belur Industrial Area",
      "Aland",
      "Benakanahalli",
      "Alevoor",
      "Bengaluru",
      "Allipura",
      "Bethamangala",
      "Alnavar",
      "Bhadravati",
      "Alur",
      "Bhalki",
      "Amaravathi",
      "Bhatkal",
      "Ambikanagara",
      "Bhimarayanagudi",
      "Amble Industrial Area",
      "Bidadi",
      "Aminagad",
      "Bidadi Industrial Area",
      "Ankola",
      "Bidar",
      "Annigeri",
      "Bijapur",
      "Arasinakunte",
      "Bilgi",
      "Arkalgud",
      "Birur",
      "Arkula",
      "Bobruwada",
      "Arsikere",
      "Byadgi",
      "Athni",
      "Challakere",
      "Aurad",
      "Chamarajanagar",
      "Aversa",
      "Channagiri",
      "Bada",
      "Channapatna",
      "Badagaulipady",
      "Channarayapatna",
      "Badami",
      "Chelur",
      "Bagalkot",
      "Chik Ballapur",
      "Bagepalli",
      "Chikballapur Industrial",
      "Bail Hongal Area",
      "Bandipur",
      "Chikkajajur",
      "Bangarapet",
      "Chikmagalur",
      "Bangarpet Industrial Area",
      "Chiknayakanhalli",
      "Bankapura",
      "Chikodi",
      "Bannur",
      "Chincholi",
      "Bantval",
      "Chintamani",
      "Basavakalyan",
      "Chitapur",
      "Basavana Bagevadi",
      "Chitgoppa",
      "Belgaum",
      "Chitradurga",
      "Dandeli",
      "Haralahalli",
      "Jog Falls",
      "Dargajogihalli",
      "Haralapura",
      "JSW Steel Plant",
      "Davanagere",
      "Harapanahalli",
      "Kadakola",
      "Devadurga",
      "Harihar",
      "Kadur",
      "Devanahalli",
      "Harohalli Industrial Area",
      "Kadwad",
      "Dharwad",
      "Hassan",
      "Kairangala",
      "Dobaspet Industrial Area",
      "Hatti",
      "Kakati Industrial Area",
      "Dod Ballapur Doddaballapur Industrial Area",
      "Hatti Gold Mines",
      "Kalghatgi",
      "Haveri",
      "Kamalapuram",
      "HD Kote",
      "Kamalnagar",
      "Doddaballapura Apparel Park",
      "Heroor",
      "Kamatgi",
      "Hirehally Industrial Area",
      "Kampli",
      "Donimalai Township",
      "Hirekerur",
      "Kanakapura",
      "Elwala",
      "Hiriyur",
      "Kariyangala",
      "Gadag Betigeri",
      "Holalkere",
      "Karkal",
      "Gajendragarh",
      "Hole Narsipur",
      "Karwar",
      "Gangawati",
      "Homnabad",
      "Kemmannugundi",
      "Ganjimutt",
      "Honaga Industrial Area",
      "Kerur",
      "Gargeswari",
      "Honavar",
      "Khanapur",
      "Gauribidanur",
      "Honnali",
      "Khangaon",
      "Gejjalagere Industrial Area",
      "Hoovina Hadagalli",
      "Kodiyal",
      "Gogipeth",
      "Hosanagara",
      "Kolambe",
      "Gokak",
      "Hosdurga",
      "Kolar",
      "Gokak Falls",
      "Hoskote",
      "Kollegal",
      "Gonikoppal",
      "Hoskote Industrial Area",
      "Kollur",
      "Gubbi",
      "Hospet",
      "Konnur",
      "Gudibanda",
      "Hubli",
      "Koppa",
      "Gudur",
      "Hukeri",
      "Koppal",
      "Gulbarga",
      "Huliyar",
      "Koratagere",
      "Guledgudda",
      "Humnabad Industrial Area",
      "Koteshwar",
      "Gundlupet",
      "Hungund",
      "Kotturu",
      "Gurmatkal",
      "Hunsur",
      "Krishnaraja Sagara",
      "Halga",
      "Ilkal",
      "Krishnarajanagara",
      "Haliyal",
      "Indi",
      "Krishnarajpet",
      "Hampi",
      "Jagalur",
      "Kudchi",
      "Hangal",
      "Jamkhandi",
      "Kudligi",
      "Hanur",
      "Jevargi",
      "Kudremukh",
      "Kudur",
      "Kumta",
      "Kundapura",
      "Kundgol",
      "Kunigal",
      "Kunigal Industrial Area",
      "Kurekuppa",
      "Kurgunta",
      "Kushalanagara",
      "Kushtagi",
      "Kuvettu",
      "Lakshmeshwar",
      "Lingsugur",
      "Londa",
      "Machche",
      "Maddur",
      "Madhugiri",
      "Madikeri",
      "Magadi",
      "Mahalingpur",
      "Malavalli",
      "Mallar",
      "Malur",
      "Malur Industrial Area",
      "Mandya",
      "Mandya Industrial Area",
      "Mangalore",
      "Manipura",
      "Manjanady",
      "Manjunath Township Basavkalyan",
      "Manvi",
      "Manvi Industrial Area",
      "Mellahalli",
      "Molakalmuru",
      "Moodabettu",
      "Mouje Nandgad",
      "Mudalgi",
      "Pavagada",
      "Mudbidri",
      "Periyapatna",
      "Muddebihal",
      "Pudu",
      "Mudgal",
      "Puttur",
      "Mudhol",
      "Rabkavi-Banhatti",
      "Mudigere",
      "Raichur",
      "Muduperar",
      "Raichur Growth Centre",
      "Mulbagal Industrial Area",
      "Mulgund",
      "Ramanagara",
      "Mulki",
      "Ramdurg",
      "Mulur",
      "Ranebennur",
      "Mundargi",
      "Raybag",
      "Mundargi Industrial Area",
      "Robertsonpet",
      "Mundgod",
      "Ron",
      "Munirabad Project Area",
      "Sadalgi",
      "Munnuru",
      "Sagagur",
      "Mutga",
      "Sagar",
      "Mutga Gulbarga District",
      "Sajipanadu",
      "Mysore",
      "Sakleshpur",
      "Nadsal",
      "Saligram",
      "Nagamangala",
      "Sambra",
      "Nandi",
      "Sandur",
      "Nandur Kesartigi Industrial Area",
      "Sankeshwar",
      "Sanoor",
      "Nanjangud",
      "Sathyamangala",
      "Narasapura Industrial Area",
      "Sattur",
      "Saundatti-Yellamma",
      "Narasimharajapura",
      "Savanur",
      "Naregal",
      "Sedam",
      "Nargund",
      "Shahabad",
      "Narikombu",
      "Shahabad ACC",
      "Navalgund",
      "Shahpur",
      "Navoor",
      "Shaktinagar",
      "Nelamangala",
      "Shiggaon",
      "Nipani",
      "Shikarpur",
      "Pandavapura",
      "Shimoga",
      "Pattadakal",
      "Shirawad Industrial Area",
      "Shirhatti",
      "Ullal",
      "Shorapur",
      "Uppinangady",
      "Shravanabelgola",
      "Vaddu",
      "Siddapur",
      "Varamballi",
      "Sidlaghatta",
      "Vasantha Narasapura",
      "Sindgi Industrial Area",
      "Sindhnur",
      "Venkatapura",
      "Sira",
      "Vijayapura",
      "Siralkoppa",
      "Virajpete",
      "Sirsi",
      "Vittal",
      "Siruguppa",
      "Wadi",
      "Solebhavi",
      "Yadgir",
      "Somanahalli Industrial Area",
      "Yelandur",
      "Yelbarga",
      "Somvarpet",
      "Yellapur",
      "Sorab",
      "Yellur",
      "Sringeri",
      "Srinivaspur Kerala",
      "Srirampura",
      "Srirangapatna",
      "Sulya",
      "T Narsipura",
      "Talikota",
      "Talipady",
      "Tarikere",
      "Tattilli",
      "Tekal",
      "Tekkalakote",
      "Terdal",
      "Thandya Industrial Estate",
      "Thimmenahalli Industrial Area",
      "Thumbe",
      "Tiptur",
      "Tirthahalli",
      "Tumkur",
      "Turuvekere",
      "Udupi",
      "Ullal",
      "Uppinangady",
      "Vaddu",
      "Vajamangala",
      "Varamballi",
      "Vasantha Narasapura Industrial Area",
      "Vengalapura",
      "Venkatapura",
      "Vijayapura",
      "Virajpete",
      "Vittal",
      "Wadi",
      "Yadgir",
      "Yadaladaku",
      "Yelandur",
      "Yelbarga",
      "Yellapur",
      "Yellur",
      "Hallikeda (b)",
      "Lokapura",
      "Tavaragera",
      "Kolhar",
      "Shiruru",
      "Madanayakanahalli",
      "Shrirangapattana",
      "Tikota"
    ]
  },
  {
    code: "Kerala",
    name: "Kerala",
    stateCode: "KL",
    cities: [
      "Abdu Rahiman Nagar",
      "Adat",
      "Adichanalloor",
      "Adinad",
      "Adoor",
      "Aimanam",
      "Ajanur",
      "Akathiyoor",
      "Ala",
      "Alamcode",
      "Alamcode",
      "Alappuzha",
      "Alathur",
      "Alur",
      "Aluva",
      "Amballur",
      "Ancharakandy",
      "Angadipuram",
      "Angamaly",
      "Anthicad",
      "Ariyallur",
      "Arookutty",
      "Aroor",
      "Athirampuzha",
      "Athiyannur",
      "Atholi",
      "Attingal",
      "Avanur",
      "Avinissery",
      "Avittathur",
      "Ayancheri",
      "Ayanivelikulangara",
      "Azhikode",
      "Azhiyur",
      "Azhoor",
      "Balusseri",
      "Bangramanjeshwar",
      "Beypore",
      "Bharanikkavu",
      "Brahmakulam",
      "Chala",
      "Chalakudy",
      "Changanacherry",
      "Chavakkad",
      "Chavara",
      "Chekkiad",
      "Chelakkara",
      "Chelannur",
      "Cheleri",
      "Chelora",
      "Chemancheri",
      "Chendamangalam",
      "Chendrappini",
      "Chengala",
      "Chengamanad",
      "Chengannur",
      "Chennithala",
      "Cheppad",
      "Cheranallur",
      "Cheriyamundam",
      "Cherthala",
      "Cherukavu",
      "Cheruthazham",
      "Cheruthuruthi",
      "Chevvoor",
      "Chingoli",
      "Chirakkal",
      "Chiramanangad",
      "Chiranellur",
      "Chittanda",
      "Chittur-Thathamangalam",
      "Chockli",
      "Choolissery",
      "Choondal",
      "Choornikkara",
      "Chowwara",
      "Desamangalam",
      "Dharmadom",
      "Edacheri",
      "Edakkode",
      "Edappal",
      "Edathala",
      "Edathirinji",
      "Edathiruthy",
      "Edavilangu",
      "Elampalloor",
      "Elayavoor",
      "Eloor",
      "Enkakkad",
      "Eramala",
      "Eramalloor",
      "Eranellur",
      "Kalpetta",
      "Eranholi",
      "Kandamkunnu",
      "Erattupetta",
      "Kandanassery",
      "Eravattur",
      "Kanhangad",
      "Eravu",
      "Kanhirode",
      "Ernakulam",
      "Kaniyarkode",
      "Eruvatti",
      "Kanjikkuzhi",
      "Ettumanoor",
      "Kanjikode Industrial Area",
      "Eyyal",
      "Kanjiramkulam",
      "Feroke",
      "Kannadiparamba",
      "Guruvayur",
      "Kannamangalam",
      "Haripad",
      "Kannamangalam",
      "Hosabettu Alappuzha District",
      "Idukki Township",
      "Kannapuram",
      "Irikkur",
      "Kannur",
      "Irimbiliyam",
      "Karamuck",
      "Iringal",
      "Karikkad",
      "Iringaprom",
      "Karivellur",
      "Irinjalakuda",
      "Kariyannur",
      "Iriveri",
      "Karthikappally",
      "Iroopara",
      "Karunagappally",
      "Kadachira",
      "Karuvanthuruthy",
      "Kadamakkudy",
      "Kasaragod",
      "Kadannappalli",
      "Kattakampal",
      "Kadavallur",
      "Kattanam",
      "Kadikkad",
      "Kattappana",
      "Kadirur",
      "Kattipparuthi",
      "Kadungalloor",
      "Kattur",
      "Kaipamangalam",
      "Kayamkulam",
      "Kalady",
      "Keekan",
      "Kalady Ernakulam District",
      "Keerikkad",
      "Kalamassery",
      "Keezhallur",
      "Kallelibhagom",
      "Keezhariyur",
      "Kallettumkara",
      "Kinfra Small Industries Park",
      "Kalliasseri ",
      "Kalliyoor",
      "Kizhakkummuri",
      "Kallur- Thekkummuri",
      "Kizhuparamba",
      "Kizhuppillikkara",
      "Kumarapuram",
      "Kizhuvalam -Koonthalloor",
      "Kumbalangy",
      "Kochi",
      "Kumily",
      "Kodungallur",
      "Kunjathur",
      "Kodur",
      "Kunnamkulam",
      "Koipady",
      "Kunnathunad",
      "Kolacherry",
      "Kunnummal",
      "Kolavelloor",
      "Kurattissery",
      "Kolazhy",
      "Kureekkad",
      "Kollam",
      "Kurumathur",
      "Komalapuram",
      "Kurumpilavu",
      "Kondotty",
      "Kuruvattur",
      "Koodali",
      "Kuthiathode",
      "Koothali",
      "Kuttiattoor",
      "Koothuparamba",
      "Kuttippuram",
      "Koottilangadi",
      "Madhur",
      "Koratty",
      "Malappuram",
      "Kothamangalam",
      "Manakkody",
      "Kottakal",
      "Manakunnam",
      "Kottamkara",
      "Manalur",
      "Kottappally",
      "Manantheri",
      "Kottappuram",
      "Manavalassery",
      "Kottarakkara",
      "Mangalpady",
      "Kottayam",
      "Mangattidam",
      "Kottayam-Malabar",
      "Maniyat",
      "Kottuvally",
      "Maniyoor",
      "Kovalam",
      "Maniyur",
      "Kozhenchery",
      "Manjeri",
      "Kozhikode",
      "Manjeshwar",
      "Kozhukkallur",
      "Mannanchery",
      "Kozhukkully",
      "Mannar",
      "Krishnapuram",
      "Mannarkad-I",
      "Kudlu",
      "Marampilly",
      "Kulasekharapuram",
      "Marancheri",
      "Kulathummal",
      "Marathakkara",
      "Kumarakom",
      "Mattannur",
      "Kumaranellur",
      "Mavelikkara",
      "Mavilayi",
      "Mavoor",
      "Mayyanad",
      "Mayyil",
      "Meenad",
      "Menhaniam",
      "Meppayyur",
      "Mokeri",
      "Moonniyur",
      "Muhamma",
      "Mulamthuruthy",
      "Mulavukad",
      "Mullassery",
      "Mundathikode",
      "Munderi",
      "Munnar",
      "Muthukulam",
      "Muthuthala",
      "Muvattupuzha",
      "Muzhappilangad",
      "Nadapuram",
      "Nadathara",
      "Naduvannur",
      "Naduvattom",
      "Nanmanda",
      "Nannambra",
      "Narath",
      "Nattakam",
      "Nedumangad",
      "Nedumpana",
      "Nedumpura",
      "Neduva",
      "Nelluwaya",
      "Nenmenikkara",
      "New Mahe",
      "Neyyattinkara",
      "Nilambur",
      "4Njarackal",
      "North -Thrikkaripur",
      "Oachira",
      "Olavanna",
      "Ongallur- I",
      "Ongallur- II",
      "Othukkungal",
      "Ottappalam",
      "Padiyam",
      "Padiyur",
      "Paduvilayi",
      "Palai",
      "Palakkad",
      "Palayad",
      "Palissery",
      "Pallichal",
      "Pallikkal",
      "Pallikkara",
      "Pallikkunnu",
      "Pallippuram",
      "Pallippuram Thiruvananthapuram District",
      "Pallippuram Thrissur District",
      "Paluvai",
      "Panachikkad",
      "Panangad",
      "Panangad Kozhikode District",
      "Panayam",
      "Panmana",
      "Panniyannur",
      "Panoor",
      "Pantheeramkavu",
      "Pappinisseri",
      "Pappinivattom",
      "Parakkad",
      "Parappukkara",
      "Parappur",
      "Parassala",
      "Parasuvaikkal",
      "Paravoor",
      "Paravur",
      "Pariyaram",
      "Pathanamthitta",
      "Pathirappally",
      "Pathiriyad",
      "Pathiyoor",
      "Pattiom",
      "Pavaratty",
      "Payyannur",
      "Pazhanji",
      "Peermade",
      "Perakam",
      "Peralasseri",
      "Perinad",
      "Peringathur",
      "Perinjanam",
      "Perinthalmanna",
      "Perole",
      "Perumanna",
      "Perumbaikad",
      "Perumbavoor",
      "Peruvallur",
      "Pilicode",
      "Pinarayi",
      "Ponmundam",
      "Ponnani",
      "Poomangalam",
      "Poothakkulam",
      "Porathissery",
      "Pottore",
      "Pudussery Central",
      "Pudussery West",
      "Pullur",
      "Punalur",
      "Punnayur",
      "Punnayurkulam",
      "Puranattukara",
      "Puthencruz",
      "Puthukkad",
      "Puthunagaram",
      "Puthuppally",
      "Puthur",
      "Puzhathi",
      "Quilandy",
      "Ramanattukara",
      "Shiriya",
      "Shoranur",
      "South - Thrikkaripur",
      "Talakkad",
      "Talikkulam",
      "Taliparamba",
      "Tanalur",
      "Thaikattussery",
      "Thaikkad",
      "Thalakkulathur",
      "Thalassery",
      "Thangalur",
      "Thanniyam",
      "Thazhakara",
      "Thazhecode",
      "Thazhuthala",
      "Thekkumkara",
      "Thennala",
      "Thikkody",
      "Thirumittacode-II",
      "Thirunavaya",
      "Thiruvalla",
      "Thiruvananthapuram",
      "Thiruvankulam",
      "Thodiyoor",
      "Thodupuzha",
      "Thottada",
      "Thrikkadavoor",
      "Thrikkaruva",
      "Thrikkovilvattom",
      "Thrippunithura",
      "Thrissur",
      "Thrithala",
      "Thuneri",
      "Thurayur",
      "Tirur",
      "Tirurangadi",
      "Trikkur",
      "Triprangode",
      "Udma",
      "Uliyazhathura",
      "Ulliyeri",
      "Uppala",
      "Urakam",
      "Vadakara",
      "Vadakkekad",
      "Vadakkumkara",
      "Vadakkummuri",
      "Vadakkumthala",
      "Vadama",
      "Vadanappally",
      "Vaikom",
      "Vakkom",
      "Valapattanam",
      "Valayam",
      "Vallachira",
      "Vandiperiyar",
      "Vaniyamkulam-II",
      "Varam",
      "Varappuzha",
      "Varkala",
      "Vattappara",
      "Vayalar",
      "Vazhakkala",
      "Vazhakulam",
      "Vazhayur",
      "Veiloor",
      "Vellanikkara",
      "Velur",
      "Veluthur",
      "Venganoor",
      "Vengara",
      "Vengola",
      "Venkitangu",
      "Venmanad",
      "Vilappil",
      "Villiappally",
      "Vylathur",
      "Vythiri",
      "Wadakkanchery"
    ]
  },
  {
    code: "Ladakh",
    name: "Ladakh",
    stateCode: "LA",
    cities: ["Kargil", "Leh"]
  },
  {
    code: "Lakshadweep",
    name: "Lakshadweep",
    stateCode: "LD",
    cities: ["Agatti", "Amini", "Kavaratti"]
  },
  {
    code: "Madhya Pradesh",
    name: "Madhya Pradesh",
    stateCode: "MP",
    cities: [
      "Agar",
      "Ahirkhedi",
      "Ahirkhedi, Indore District",
      "Ajaygarh",
      "Akoda",
      "Akodia",
      "Alampur",
      "Alirajpur",
      "Amanganj",
      "Amarkantak",
      "Amarpatan",
      "Amarwara",
      "Ambada",
      "Ambah",
      "Amkhera",
      "Amla",
      "Amlai",
      "Anjad",
      "Antari",
      "Anuppur",
      "Aron",
      "Ashoknagar",
      "Ashta",
      "Athner",
      "Babai",
      "Bada-Malhera",
      "Badarwas",
      "Badi",
      "Badkuhi",
      "Badnagar",
      "Badnawar",
      "Badod",
      "Badoni",
      "Badra",
      "Bagh",
      "Bagli",
      "Baihar",
      "Baikunthpur",
      "Balaghat",
      "Baldeogarh",
      "Bamhani",
      "Bamor",
      "Bamor Morena",
      "Bamora",
      "Banda",
      "Bandhawagarh",
      "Bangarda Bada",
      "Bangawan",
      "Bank",
      "Bansatar Kheda",
      "Baragaon",
      "Baragaon Shajapur",
      "Barauda",
      "Barela",
      "Bareli",
      "Barghat",
      "Bargi",
      "Barhi",
      "Barigarh",
      "Barman Kalan",
      "Baroda",
      "Barwaha",
      "Barwaha Kasba",
      "Barwani",
      "Basoda",
      "Begamganj",
      "Beohari",
      "Berasia",
      "Betma",
      "Betul",
      "Betul Bazar",
      "Bhainsa",
      "Bhainsdehi",
      "Bhander",
      "Bhanpura",
      "Bharveli",
      "Bhavra",
      "Bhedaghat",
      "Bhicholi Hapsi",
      "Bhikangaon",
      "Bhilakhedi",
      "Bhind",
      "Bhitarwar",
      "Bhonrasa",
      "Bhopal",
      "Biaora",
      "Bichhiya",
      "Bichhiya Vidisha District",
      "Bijawar",
      "Bijuri",
      "Bilaua",
      "Bina",
      "Birsinghpur",
      "Boda",
      "Borgaon",
      "Budni",
      "Burhanpur",
      "Burhar",
      "Buxwaha",
      "Chachaura-Binaganj",
      "Chakghat",
      "Chandameta Butaria",
      "Chanderi",
      "Chandia",
      "Chandla",
      "Chaurai Khas",
      "Chhapiheda",
      "Chhatarpur",
      "Chhattarpur",
      "Chhindwara",
      "Chhipabad",
      "Chhota Chhindwara",
      "Chichli",
      "Chicholi",
      "Chitrakoot",
      "Churhat",
      "Daboh",
      "Dabra",
      "Dahi",
      "Damoh",
      "Damua",
      "Datia",
      "Dehrisaray",
      "Deodara",
      "Deohara",
      "Deori",
      "Deori, Shahdol District",
      "Depalpur",
      "Devendranagar",
      "Dewas",
      "Dhamnod",
      "Dhamnod Ratlam District",
      "Dhana",
      "Dhanpuri Nargada Hari Dafai",
      "Dhar",
      "Dharampuri",
      "Dhodaramohar Alias",
      "Bhoura",
      "Dighawani",
      "Diken",
      "Dindori",
      "Dola",
      "Dongar Parasia",
      "Dumar Kachhar",
      "Dungariya Chhapara",
      "Emlikheda Industrial Area",
      "Gadarwara",
      "Gairatganj",
      "Gamiria Sagar",
      "Gandhi Sagar Haidel",
      "Garhakota",
      "Garhi -Malhara",
      "Garoth",
      "Ghansaur",
      "Jaitwara",
      "Ghoda Dongri Ryt",
      "Jamai",
      "Ghuwara",
      "Jaora",
      "Gogapur",
      "Jata Chhapar",
      "Gohad",
      "Jatara",
      "Gormi",
      "Jawad",
      "Govindgarh",
      "Jawar",
      "Guna",
      "Jaypee Nagar",
      "Gurh",
      "Jeron Khalsa",
      "Gwalior",
      "Jhabua",
      "Hanumana",
      "Jhundpura",
      "Harda",
      "Jiran",
      "Harduli",
      "Jirapur",
      "Harpalpur",
      "Jobat",
      "Harrai",
      "Joura",
      "Harsud",
      "Kailaras",
      "Hat Piplia",
      "Kakarhati",
      "Hatod",
      "Kali Chhapar",
      "Hatpiplya",
      "Kanad",
      "Hatta",
      "Kannod",
      "Hindoria",
      "Kantaphod",
      "Hirapur",
      "Kareli",
      "Hirdepur",
      "Karera",
      "Hoshangabad",
      "Kari",
      "Ichhawar",
      "Karnawad",
      "Iklehra",
      "Karrapur",
      "Indergarh",
      "Kasrawad",
      "Indore",
      "Katangi",
      "Industrial Area Jagakhedi",
      "Katangi, Jabalpur District",
      "Isagarh",
      "Kelhauri",
      "Itarsi",
      "Keolari",
      "Jabalpur",
      "Khacharod",
      "Jabera",
      "Khairi",
      "Jaisinghnagar",
      "Khajuraho",
      "Jaithari",
      "Khand",
      "Jaitpur",
      "Khandwa",
      "Jaitpur Chhatarpur District",
      "Khaniyadhana",
      "Khargapur",
      "Khargone",
      "Khategaon",
      "Khetia",
      "Khilchipur",
      "Khirkiya",
      "Khor",
      "Khujner",
      "Khurai",
      "Kirnapur",
      "Kolar",
      "Kolaras",
      "Kosmi",
      "Kotar",
      "Kothi",
      "Kothri",
      "Kotma",
      "Kukdeshwar",
      "Kukshi",
      "Kumbhraj",
      "Kundam",
      "Kurwai",
      "Kymore",
      "Ladhaura",
      "Lahar",
      "Lakhnadon",
      "Lanji",
      "Lateri",
      "Laundi",
      "Lodhikheda",
      "Loharda",
      "Machalpur",
      "Madai",
      "Madhawgdha",
      "Maharajpur",
      "Maheshwar",
      "Mahidpur",
      "Mahura",
      "Maihar",
      "Maihar Cement Factory",
      "Sarlanagar",
      "Majhgawan",
      "Majhgawan Jabalpur District",
      "Majhgawan Township",
      "Majholi",
      "Makdon",
      "Makronia Buzurg",
      "Maksi",
      "Malajkhand",
      "Malanpur Industrial Area",
      "Malhargarh",
      "Maliya Guda",
      "Manasa",
      "Manawar",
      "Mandideep",
      "Mandla",
      "Mandleshwar",
      "Mandsaur",
      "Mandu",
      "Maneri Industrial Growth Centre",
      "Mangalya",
      "Mangawan",
      "Manpur",
      "Mau",
      "Mauganj",
      "Meghnagar",
      "Mehgaon",
      "Mhow",
      "Mihona",
      "Mohgaon",
      "Morar Cantt",
      "Morena",
      "Multai",
      "Mundi",
      "Mungaoli",
      "Murwara Katni",
      "Nagda",
      "Nagod",
      "Nagri",
      "Nai-Garhi",
      "Nainpur",
      "Nalkheda",
      "Namli",
      "Nanpur",
      "Narayangarh",
      "Narsimhapur",
      "Narsingarh",
      "Narsinghgarh",
      "Narwar",
      "Nasrullaganj",
      "Naudhia",
      "Neemuch",
      "Nepanagar",
      "New Harsud",
      "Newton Chikhli Kalan",
      "Niwari",
      "Niwas",
      "Nowgong",
      "Nowrozabad",
      "Omkareshwar",
      "Orchha",
      "Ordnance Factory Itarsi",
      "Pachmarhi",
      "Pachore",
      "Pal Chaurai",
      "Palera",
      "Palsud",
      "Panagar",
      "Panara",
      "Pandhana",
      "Rampur",
      "Pandhurna",
      "Rampur Baghelan",
      "Pandhurna Industrial Area",
      "Rampura",
      "Pankhedi",
      "Ranapur",
      "Panna",
      "Ranipur",
      "Pansemal",
      "Ratangarh",
      "Pasan",
      "Ratlam",
      "Patan",
      "Rau",
      "Patharia",
      "Raymond Chhindwara",
      "Pawai",
      "Raymond Colony Vijay Gram",
      "Petlawad",
      "Phuphkalan",
      "Rehli",
      "Pichhore",
      "Rehti",
      "Pichhore, Gwalior District",
      "Rewa",
      "Pindrai",
      "Rosera",
      "Pipalrawan",
      "Sabalgarh",
      "Pipariya",
      "Sabo",
      "Pipariya Jabalpur District",
      "Sagar",
      "Pipariya, Jabalpur District",
      "Sailana",
      "Piplanarayanwar",
      "Sanawad",
      "Piploda",
      "Sanchi",
      "Piplya Mandi",
      "Sangvi",
      "Pipri",
      "Sanjay Gandhi Thermal  Power Station",
      "Pithampur",
      "Pithampur Industrial Hub",
      "Sanwer",
      "Polaykalan",
      "Sarangpur",
      "Porsa",
      "Sardarpur",
      "Prithvipur",
      "Sarni",
      "Purena",
      "Satai",
      "Raghogarh",
      "Satna",
      "Rahatgarh",
      "Satwas",
      "Raisen",
      "Sausar",
      "Rajakhedi",
      "Sehore",
      "Rajgarh",
      "Semaria",
      "Rajgarh, Dhar District",
      "Sendhwa",
      "Rajnagar",
      "Seondha",
      "Rajpur",
      "Seoni",
      "Seoni-Malwa",
      "Sethiya",
      "Shahdol",
      "Shahgarh",
      "Shahpur",
      "Shahpur, Betul District",
      "Shahpur, Sagar District",
      "Shahpura",
      "Shahpura, Jabalpur District",
      "Shajapur",
      "Shamgarh",
      "Shamshabad",
      "Sheopur",
      "Shivpuri",
      "Shujalpur",
      "Sidgunwa Industrial Area",
      "Sidhi",
      "Sihora",
      "Silwani",
      "Singoli",
      "Singrauli",
      "Sinhasa",
      "Sirgora",
      "Sirmaur",
      "Sironj",
      "Sitamau",
      "Siya Industrial Area",
      "Sohagi",
      "Sohagpur",
      "Sonkatch",
      "Soyatkalan",
      "Sultanpur",
      "Susner",
      "Suswasara",
      "Suthaliya",
      "Tal",
      "Talen",
      "Tarana",
      "Taricharkalan",
      "Tekanpur",
      "Tendukheda",
      "Tendukheda Damoh District",
      "Teonthar",
      "Thandla",
      "Tikamgarh",
      "Timarni",
      "Tirodi",
      "Tonk Khurd",
      "Ubaidullahganj",
      "Udaipura",
      "Udaypur",
      "Ujjain",
      "Ukwa",
      "Umaria",
      "Unchahara",
      "Unhel",
      "Vidisha",
      "Vijaypur Sheopur District",
      "Vijayraghavgarh",
      "Vindhya Nagar NTPC",
      "Waraseoni"
    ]
  },
  {
    code: "Maharashtra",
    name: "Maharashtra",
    stateCode: "MH",
    cities: [
      "Aamby Valley",
      "Achalpur",
      "Achalpur MIDC",
      "Additional Chandrapur MIDC",
      "Aheri",
      "Ahmadnagar",
      "Ahmadpur",
      "Ahmadpur MIDC",
      "Ajanta",
      "Badlapur",
      "Ajara",
      "Balapur",
      "Ajara MIDC",
      "Balapur Akola District",
      "Akkalkot",
      "Balapur MIDC",
      "Akkalkuwa",
      "Ballarpur",
      "Akluj",
      "Banda",
      "Akola",
      "Baramati",
      "Akot",
      "Barshi",
      "Alandi",
      "Basmat MIDC",
      "Alibag",
      "Basmath",
      "Allapalli",
      "Bela",
      "Amalner",
      "Bhabale MIDC",
      "Amalner MIDC",
      "Bhadgaon",
      "Ambad",
      "Bhadravati",
      "Ambarnath",
      "Bhadravati MIDC",
      "Ambejogai",
      "Bhalar Township",
      "Ambepur",
      "Bhandara",
      "Ambivali Tarf Wankhal",
      "Bhandara MIDC",
      "Amboli",
      "Bhatkuli",
      "Amgaon",
      "Bhatkuli MIDC",
      "Amravati",
      "Bhiwandi",
      "Anjangaon",
      "Bhiwapur",
      "Anjarle",
      "Bhiwapur MIDC",
      "Arvi",
      "Bhokar",
      "Asangaon",
      "Bhokardan",
      "Asangaon Industrial Area",
      "Bhor",
      "Ashta",
      "Bhum",
      "Ashti",
      "Bhusawal",
      "Ashti Gadchiroli District",
      "Bhusawal MIDC",
      "Ashti MIDC",
      "Bid",
      "Ashti Wardha District",
      "Biloli",
      "Atgaon Industrial Complex",
      "Birwadi",
      "Aurangabad",
      "Bisur",
      "Ausa",
      "Boisar",
      "Awalpur",
      "Borgaon",
      "Babhaleshwar",
      "Bori",
      "Babhulgaon",
      "Borivali Tarf Rahur",
      "Borkhedi",
      "Borli Panchtan",
      "Brahmanvel MIDC",
      "Brahmapuri",
      "Budhagon",
      "Budhgaon",
      "Buldana",
      "Butibori",
      "Butibori MIDC",
      "Chakan",
      "Chalisgaon",
      "Chandgad",
      "Chandrapur",
      "Chandrapur CGGC",
      "Chandrapur MIDC",
      "Chandur",
      "Chandur Amravati District",
      "Chandurbazar",
      "Chandvad",
      "Chicholi",
      "Chikhala",
      "Chikhaldara",
      "Chikhli",
      "Chimur",
      "Chinchani",
      "Chincholi MIDC",
      "Chiplun",
      "Chitegaon",
      "Chopda",
      "Chowk",
      "Dabhol",
      "Dadar",
      "Dahanu",
      "Dahivadi",
      "Dandi",
      "Dapoli",
      "Dapoli Camp",
      "Darwha",
      "Daryapur Banosa",
      "Dattapur Dhamangaon",
      "Daund",
      "Deglur",
      "Deglur MIDC",
      "Dehu",
      "Dehu Cantt",
      "Deogiri",
      "Deola",
      "Deolali Pravara",
      "Deoli",
      "Deoni",
      "Deori",
      "Desaiganj",
      "Deulgaon Raja",
      "Deulgaon Raja MIDC",
      "Devgad",
      "Devrukh",
      "Dharangaon",
      "Dharmabad",
      "Dharni",
      "Dharni MIDC",
      "Dharur",
      "Dharur MIDC",
      "Dhopatala",
      "Dhule",
      "Digras",
      "Dindori",
      "Dindori MIDC",
      "Dodamarg",
      "Dondaicha Warwade",
      "Dudhani",
      "Durgapur",
      "Eklahare",
      "Elephanta Caves",
      "Ellora",
      "",
      "Erandol",
      "Hinganghat MIDC",
      "Etapalli",
      "Hingna",
      "Faizpur",
      "Hingoli",
      "Gadchiroli",
      "Hingoli MIDC",
      "Gadhinglaj",
      "Honad Industrial Area",
      "Gadhinglaj MIDC",
      "Hudkeshwar",
      "Gane MIDC",
      "Hupari",
      "Ganeshpur",
      "Ichalkaranji",
      "Gangakhed",
      "Igatpuri",
      "Gangapur",
      "Indapur",
      "Ganpatipule",
      "Indapur MIDC",
      "Gargoti",
      "Jafrabad",
      "Genesis Industrial",
      "Jafrabad MIDC Township",
      "Jalgaon",
      "Georai",
      "Jalgaon Jamod",
      "Ghansawangi",
      "Jalkot",
      "Ghatanji",
      "Jalna",
      "Ghoti",
      "Jalochi",
      "Ghugus",
      "Jamkhed",
      "Ghulewadi",
      "Jamner",
      "Gokunda",
      "Jath",
      "Gondia MIDC",
      "Jawhar",
      "Gondiya",
      "Jawharnagar",
      "Gondpipri",
      "Jaysingpur",
      "Goregaon",
      "Jejuri",
      "Goregaon Gondiya District ",
      "Jejuri MIDC",
      "Goregaon MIDC",
      "Jintur",
      "Guhagar",
      "Jintur MIDC",
      "Hadgaon",
      "Jiwati",
      "Hajarmachi",
      "Junnar",
      "Halkarni MIDC",
      "Kabnur",
      "Harihareshwar",
      "Kadegaon",
      "Harsul",
      "Kadegaon MIDC",
      "Hatkanangle",
      "Kagal",
      "Himayatnagar",
      "Kagal MIDC",
      "Hindnagar",
      "Kaij",
      "Hinganghat",
      "Kalamb",
      "Kalameshwar",
      "Kalameshwar MIDC",
      "Kalamnuri",
      "Kallam",
      "Kalmath",
      "Kalwan",
      "Kalyan",
      "Kamalapur MIDC",
      "Kambe",
      "Kamptee",
      "Kamptee Cantt",
      "Kamshet",
      "Kandari",
      "Kandhar",
      "Kandhar MIDC",
      "Kandri",
      "Kandri Mines",
      "Kandri Nagpur",
      "Kanhan",
      "Kanhe Industrial Area",
      "Kanholibara",
      "Kankavli",
      "Kannad",
      "Karad",
      "Karalewadi",
      "Karanja",
      "Karjat",
      "Karjat Ahmadnagar District",
      "Karmala",
      "Kasara",
      "Katangi Kala",
      "Katol",
      "Katol MIDC",
      "Kavalapur",
      "Kavathe Mahankal",
      "Kelapur",
      "Khamari",
      "Khamgaon",
      "Khanapur",
      "Khandala",
      "Khandala Satara",
      "Khandbara",
      "Khapa",
      "Khapar",
      "Kharbav",
      "Khardi",
      "Khatav",
      "Khed",
      "Kherdi",
      "Khopoli",
      "Kinwat",
      "Kolhapur",
      "Kolki",
      "Kondumal",
      "Kopargaon",
      "Koradi",
      "Korchi",
      "Koregaon",
      "Koregaon Bhima",
      "Korochi",
      "Krushnoor MIDC",
      "Kudal",
      "Kudal MIDC",
      "Kudus",
      "Kuhi",
      "Kumbhari",
      "Kundalwadi",
      "Kurduwadi",
      "Kurduwadi MIDC",
      "Kurkheda",
      "Kurkumbh MIDC",
      "Kurul",
      "Kurundvad",
      "Kusgaon Budruk",
      "Lakhamapur MIDC",
      "Lakhandhur",
      "Lakshmi Cooperative",
      "Industrial Estate",
      "Lanja",
      "Lasalgaon",
      "Lat",
      "Latur",
      "Latur Industrial Area",
      "Additional",
      "Lavasa",
      "Loha",
      "Lohara",
      "Lohara Osmanabad",
      "Lonand",
      "Lonar",
      "Lonavala",
      "Loni",
      "Loni kalbhor",
      "Lote Parshuram MIDC",
      "Madangad",
      "Madha",
      "Madhavnagar",
      "Mahabaleshwar",
      "Mahad",
      "Mahad MIDC",
      "Mahad MIDC Additional",
      "Mahadula",
      "Mahagaon",
      "Mahagaon MIDC",
      "Mahapoli",
      "Mahoor",
      "Maindargi",
      "Majgaon",
      "Makranifali",
      "Malde",
      "Malegaon",
      "Morshi MIDC",
      "Malegaon Jahangir",
      "Motala",
      "Malegaon MIDC",
      "Mouje Anjanvel",
      "Malkapur",
      "Mowad",
      "Malkapur Buldana District",
      "Mudkhed",
      "Malkapur MIDC",
      "Mukhed",
      "Malshiras",
      "Muktainagar",
      "Malwan",
      "Mul",
      "Manchar",
      "Mul MIDC",
      "Mandur",
      "Mulchera",
      "Mangalvedha",
      "Mumbai",
      "Mangrulpir",
      "Murbad",
      "Mangrulpir MIDC",
      "Murbad MIDC",
      "Manjlegaon",
      "Murgud",
      "Manmad",
      "Murmadi",
      "Manor",
      "Murtajapur",
      "Manora",
      "Murud",
      "Mansar",
      "Murum",
      "Mantha",
      "Musalgaon MIDC",
      "Manwath",
      "Nagbhid MIDC",
      "Maregaon",
      "Nagbhind",
      "Matheran",
      "Nagothana",
      "Mauda",
      "Nagpur",
      "Medha",
      "Naigaon",
      "Mehkar",
      "Nakoda",
      "Mehkar MIDC",
      "Naldurg",
      "Mhasla",
      "Nampur",
      "Mhaswad",
      "Nanda",
      "MIHAN",
      "Nanded Waghala",
      "Mohadi",
      "Nandgaon",
      "Mohadi MIDC",
      "Nandgaon Khandeshwar",
      "Mohol",
      "Nandgaon Pode",
      "Mohol MIDC",
      "Nandgaon Tarf Birwadi",
      "Mohpa",
      "Nandgaonpeth MIDC",
      "Mohpada Alias Wasambe",
      "Nandura",
      "Mokhada",
      "Nandurbar",
      "Morshi",
      "Narkher",
      "Nashik",
      "Natepute",
      "Navapur",
      "Navi Mumbai",
      "Ner",
      "Neral",
      "Newasa",
      "Nijampur",
      "Nilanga",
      "Nimbhore Budruk",
      "Niphad",
      "Nira",
      "Ordnance Factory Bhadravati",
      "Osmanabad",
      "Ozar",
      "Pachora",
      "Padagha",
      "Padoli",
      "Paithan",
      "Palam",
      "Palghar",
      "Pali",
      "Palus",
      "Palus MIDC",
      "Pampalner",
      "Panchgani",
      "Pandare MIDC",
      "Pandharkaoda",
      "Pandharpur",
      "Panhala",
      "Paranda",
      "Paratwada",
      "Parbhani",
      "Parli",
      "Parner",
      "Parola",
      "Parshioni",
      "Ralegaon",
      "Partur",
      "Ramtek",
      "Partur MIDC",
      "Ranjangaon",
      "Patalganga MIDC",
      "Ranjangaon MIDC",
      "Patan",
      "Rasal Industrial Area",
      "Patan MIDC",
      "Ratnagiri",
      "Pathan MIDC",
      "Raver",
      "Pathardi",
      "Rees",
      "Pathri",
      "Renapur",
      "Patoda",
      "RIL Township",
      "Patur",
      "Risod",
      "Pauni",
      "Roha Ashtami",
      "Peint",
      "Roha MIDC",
      "Peint MIDC",
      "Sadak Arjuni",
      "Pen",
      "Raigad",
      "Sadavali MIDC",
      "Phaltan",
      "Saidapur",
      "Pimpalgaon Baswant",
      "Sakoli",
      "Pimpri Chinchwad",
      "Sakri",
      "Pipri",
      "Salekasa",
      "Pirangut",
      "Samudrapur",
      "Poladpur",
      "Samudrapur MIDC",
      "Pombhurna",
      "Sanaswadi",
      "Pulgaon",
      "Sangameshwar",
      "Pune",
      "Sangamner",
      "Purna",
      "Sangamner MIDC",
      "Purushottamnagar",
      "Sangli Miraj Kupwad",
      "Pusad",
      "Sangole",
      "Pusad MIDC",
      "Saoner MIDC",
      "Radhanagari",
      "Sasti",
      "Rahata",
      "Sasvad",
      "Rahimatpur",
      "Satana",
      "Rahuri",
      "Satara",
      "Rajapur",
      "Savda",
      "Rajgurunagar",
      "Savner",
      "Rajur",
      "Sawali",
      "Rajur Ahmadnagar District",
      "Sawangi Megh",
      "Rajura",
      "Sawantwadi",
      "Seloo",
      "Selu",
      "Sengaon",
      "Shahada",
      "Shahapur",
      "Shahapur Bhandara District",
      "Shani Shingnapur",
      "Shegaon",
      "Shendra MIDC",
      "Shendurjana",
      "Shevgaon",
      "Shikrapur",
      "Shinde MIDC",
      "Shirala",
      "Shirala MIDC",
      "Shirdi",
      "Shirgaon",
      "Shirgaon Industrial Area",
      "Shirol",
      "Shirpur",
      "Shirur",
      "Shirur Anantpal",
      "Shirur Kasar",
      "Shirwal",
      "Shivaji Nagar",
      "Shrigonda",
      "Shrirampur",
      "Shrivardhan",
      "Sillewara",
      "Sillod",
      "Sindewahi",
      "Sindewahi MIDC",
      "Sindi",
      "Sindkhed Raja",
      "Sindkheda",
      "Singnapur Kopargaon",
      "Sinnar",
      "Sinnar MIDC",
      "Sironcha",
      "Solapur",
      "Sonai",
      "Sonegaon",
      "Songirwadi",
      "Sonpeth",
      "Supa MIDC",
      "Surgana",
      "Tadali",
      "Takalghat",
      "Takwe Industrial Area",
      "Tala",
      "Talasari",
      "Talegaon Dabhade",
      "Talegaon MIDC",
      "Taloda",
      "Tarapur",
      "Tarapur Textile Park",
      "Tasawade MIDC",
      "Tasgaon",
      "Tekadi",
      "Telhara",
      "Tembhurni MIDC",
      "Thana",
      "Thane",
      "Tirora",
      "Tirora MIDC",
      "Toranmal",
      "Totaladoh",
      "Trimbak",
      "Tuljapur",
      "Tumsar",
      "Tumsar Road",
      "Udgir",
      "Ulhasnagar",
      "Umarga",
      "Umarga MIDC",
      "Umarkhed",
      "Umarsara",
      "Umbar Pada Nandade",
      "Umred",
      "Umred MIDC",
      "Umri",
      "Uran Islampur",
      "Urjagram",
      "Usar MIDC",
      "Utekhol",
      "Vada",
      "Vadgaon",
      "Vaduj",
      "Vaibhawadi",
      "Vaijapur",
      "Vaijapur MIDC",
      "Vangani",
      "Vanvadi",
      "Varangaon",
      "Vasai Virar",
      "Vasantnagar",
      "Vashind",
      "Velhe",
      "Vengurla",
      "Vijay Durg",
      "Vikramgad",
      "Vile Bhagad MIDC",
      "Vinchur",
      "Vinchur MIDC",
      "Visapur",
      "Vita",
      "Vita MIDC",
      "Wada",
      "Wadgaon",
      "Wadi Ratnagiri",
      "Wadwani",
      "Waghapur",
      "Waghoda",
      "Wai",
      "Wai MIDC",
      "Walani",
      "Waluj MIDC",
      "Walwa",
      "Wani",
      "Wardha",
      "Warora",
      "Warora MIDC",
      "Warthi",
      "Warud",
      "Warud MIDC",
      "Washi",
      "Washim",
      "WCL Umred",
      "Yavatmal",
      "Yavatmal Industrial Area",
      "Yawal",
      "Yeola",
      "Yerkheda"
    ]
  },
  {
    code: "Manipur",
    name: "Manipur",
    stateCode: "MN",
    cities: [
      "Andro",
      "Bishnupur",
      "Heirok",
      "Imphal",
      "Jiribam",
      "Kakching",
      "Kakching Khunou",
      "Kangpokpi",
      "Kumbi",
      "Kwakta",
      "Lamlai",
      "Lamsang",
      "Liong",
      "Lilong Imphal West",
      "Lilong Thoubal",
      "Mayang Imphal",
      "Moirang",
      "Moreh",
      "Nambol",
      "Ningthoukhong",
      "Oinam",
      "Samurou",
      "Samurou, Imphal West District",
      "Sekmai Bazar",
      "Senapati",
      "Sikhong Sekmai",
      "Sugnu",
      "Tamenglong",
      "Thongkhong Laxmi Bazar",
      "Thoubal",
      "Torban",
      "Ukhrul",
      "Wangjing",
      "Wangoi",
      "Yairipok",
      "Zenhang Lamka"
    ]
  },
  {
    code: "Meghalaya",
    name: "Meghalaya",
    stateCode: "ML",
    cities: [
      "Baghmara",
      "Cherrapunjee",
      "Jowai",
      "Mairang",
      "Mawlai",
      "Nongpoh",
      "Nongstoin",
      "Resubelpara",
      "Shillong",
      "Tura",
      "Umroi",
      " Williamnagar"
    ]
  },
  {
    code: "Mizoram",
    name: "Mizoram",
    stateCode: "MZ",
    cities: [
      "Aizawl",
      "Bairabi",
      "Biate",
      "Champhai",
      "Darlawn",
      "Hnahthial",
      "Khawhai",
      "Khawzawl",
      "Kolasib",
      "Lawngtlai",
      "Lengpui",
      "Lunglei",
      "Mamit",
      "N Kawnpui",
      "N Vanlaiphai",
      "Saiha",
      "Sairang",
      "Saitual",
      "Serchhip",
      "Thenzawl",
      "Tlabung",
      "Vairengte",
      "Zawlnuam "
    ]
  },
  {
    code: "Nagaland",
    name: "Nagaland",
    stateCode: "NL",
    cities: [
      "Changtongya",
      "Chumukedima",
      "Dimapur",
      "Jalukie",
      "Kiphire",
      "Kohima",
      "Longleng",
      "Medziphema",
      "Mokokchung",
      "Mon Town",
      "Naginimora",
      "Peren",
      "Pfutsero",
      "Phek",
      "Satakha",
      "Tseminyu",
      "Tuensang",
      "Tuli",
      "Tzudikong",
      "Wokha",
      "Zunheboto"
    ]
  },
  {
    code: "Odisha",
    name: "Odisha",
    stateCode: "OR",
    cities: [
      "Agastinuagan",
      "Anandapur",
      "Anandpur",
      "Anjira",
      "Anjira Jagatsinghapur District",
      "Anugul",
      "Arjyapalli",
      "Asika",
      "Athagad",
      "Athmallik",
      "Badagada",
      "Badakodanda",
      "Badamba",
      "Badmal",
      "Balagoda",
      "Balakati",
      "Balangir",
      "Baleshwar",
      "Baliguda",
      "Balimela",
      "Balipatapur",
      "Banapur",
      "Bandhbahal",
      "Bangomunda",
      "Bangura",
      "Banki",
      "Barapali",
      "Barbil",
      "Bardol",
      "Bargarh",
      "Baripada",
      "Basudebpur",
      "Baudhgarh",
      "Belagachhia",
      "Bellaguntha",
      "Belpahar",
      "Berhampur",
      "Bhadrak",
      "Bhakarsahi",
      "Bhanjanagar",
      "Bhapur",
      "Bhatli",
      "Bhawanipatna",
      "Bhuban",
      "Bhubaneswar",
      "Bhushan Steel Plant",
      "Jharsuguda",
      "Bhushan Steel Plant",
      "Meramandali",
      "Bijepur",
      "Binika",
      "Biramitrapur",
      "Birapratappur",
      "Bishama Katak",
      "Bonaigarh",
      "Borigam",
      "Boriguma",
      "Brahmabarada",
      "Brajarajnagar",
      "Budhapanka",
      "Buguda",
      "Bundia",
      "Burla",
      "Byasanagar",
      "Champua",
      "Chandapur",
      "Chandili",
      "Charibatia",
      "Chhatrapur",
      "Chikiti",
      "Chitrakonda",
      "Choudwar",
      "Cuttack",
      "Dadhapatna",
      "Daitari",
      "Damanjodi",
      "Danara",
      "Daringbadi",
      "Debagarh",
      "Dera Colliery Township",
      "Dhamanagar",
      "Dhenkanal",
      "Digapahandi",
      "Dungamal",
      "FCI Township",
      "G Udayagiri",
      "Ganjam",
      "Ghantapada",
      "Ghatagaon",
      "GMR Power Plant Kmalanga",
      "Godiputamatiapara",
      "Golabandha",
      "Gopalpur",
      "Gotamara",
      "Gourdanda",
      "Gudari",
      "Gunupur",
      "Hinjilicut",
      "Hirakud",
      "IB Themal Power Plant Banharpali",
      "IMFA Therubali",
      "Indipur",
      "Itamati",
      "Jagatsinghapur",
      "Jajanga",
      "Jajapur",
      "Jaleswar",
      "Jashipur",
      "Jatani",
      "Jeypur",
      "Jharsuguda",
      "Jhumpura",
      "Joda",
      "JSPL Town Anugul",
      "Junagarh",
      "Kabatabandha",
      "Kaipadar",
      "Kalarangiata",
      "Kaliapani",
      "Kalinga Nagar Industrial Area",
      "Kalyanasingpur",
      "Kamakshyanagar",
      "Kanheipur",
      "Kansbahal",
      "Kantabanji",
      "Kantilo",
      "Karanjia",
      "Kashinagara",
      "Kavisurjyanagar",
      "Kendrapara",
      "Kendujhar",
      "Kesinga",
      "Khaliapali",
      "Khalikote",
      "Khandapada",
      "Khariar",
      "Khariar Road",
      "Khatiguda",
      "Khordha",
      "Kochinda",
      "Kodala",
      "Koida",
      "Konark",
      "Koraput",
      "Kotpad",
      "Krushnanandapur",
      "Kuanrmunda",
      "Kukudakhandi",
      "Kullada",
      "Kunjabangarh",
      "L And T Cement Plant Jharsuguda",
      "Lanjigarh",
      "Lathikata",
      "Lochapada",
      "Loisinga",
      "Madanpur Rampur",
      "Majhihara",
      "Makundapur",
      "Malkangiri",
      "Meghahatuburu",
      "Mohana",
      "Mukhiguda",
      "Mundamarai",
      "Nabarangapur",
      "Nalco",
      "Nayagarh",
      "New Lalsingi",
      "Nilagiri",
      "Nimapada",
      "NTPC Kaniha",
      "Nuahata",
      "Nuapatna",
      "OCL Industrial Township",
      "Odagaon",
      "Padmapur",
      "Palalahada",
      "Palurgada",
      "Papadahandi",
      "Paradip",
      "Parlakhemundi",
      "Pathar",
      "Patnagarh",
      "Patrapur",
      "Pattamundai",
      "Phulabani",
      "Pipili",
      "Pitala",
      "Polasara",
      "Puri",
      "Purusottampur",
      "R Udayagiri",
      "Raighar",
      "Rairangpur",
      "Rajagangapur",
      "Rajasunakhala",
      "Rambha",
      "Ranapurgada",
      "Rayagada",
      "Redhakhol",
      "Remuna",
      "Rengali",
      "Rengali Dam Project Township",
      "Rourkela",
      "Sambalpur",
      "Saranga",
      "Sayadpur",
      "Sheragada",
      "Sohela",
      "Sonapur",
      "Soro",
      "Subalaya",
      "Sunabeda",
      "Sundargarh",
      "Surada",
      "Surala",
      "Suvani",
      "Talcher",
      "Talcher Thermal Power Station Township",
      "Tangi",
      "Tarbha",
      "Tensa",
      "Tikarpada",
      "Tikarpada Ganjam District",
      "Tipo",
      "Titlagarh",
      "Tusura",
      "Udala",
      "Umarkote",
      "Vedanta Jharsuguda",
      "Venketraipur"
    ]
  },
  {
    code: "Puducherry",
    name: "Puducherry",
    stateCode: "PY",
    cities: [
      "Karaikal",
      "Mahe",
      "Puducherry",
      "Thirumalairayan Pattinam",
      "Yanam"
    ]
  },
  {
    code: "Punjab",
    name: "Punjab",
    stateCode: "PB",
    cities: [
      "Abohar",
      "Adampur",
      "Ahmedgarh",
      "Ajnala",
      "Akalgarh",
      "Alawalpur",
      "Amargarh",
      "Amloh",
      "Amritsar",
      "Anandpur Sahib",
      "Apra",
      "Aur",
      "Baba Bakala",
      "Baddowal",
      "Badhni Kalan",
      "Balachaur",
      "Banga",
      "Banur",
      "Bareta",
      "Bariwala",
      "Barnala",
      "Baryar",
      "Bassi Pathana",
      "Batala",
      "Bathinda",
      "Beas",
      "Begowal",
      "Beharnpur",
      "Behrampur",
      "Bhabat",
      "Bhadaur",
      "Bhadson",
      "Bhagha Purana",
      "Bhagta BhaiKa",
      "Bhankarpur",
      "Bhattian ",
      "Bhawanigarh",
      "Goindwal",
      "Bhikhi",
      "Goniana",
      "Bhikhiwind",
      "Goraya",
      "Bhisiana",
      "Gurdaspur",
      "Bhogpur",
      "Guru Har Sahai",
      "Bhucho Mandi",
      "Hajipur",
      "Bhulath",
      "Halwara",
      "Budha Theh",
      "Handiaya",
      "Budhlada",
      "Hariana",
      "Bungal",
      "Hoshiarpur",
      "Chamkaur Sahib",
      "Hussainpur",
      "Cheema",
      "Jagraon",
      "Chogawan",
      "Jaitu",
      "Chohal",
      "Jalalabad",
      "Daper",
      "Jalandhar",
      "Dasuya",
      "Jandiala",
      "Dera Baba Nanak",
      "Jandiala, Jalandhar",
      "Dera Bassi District",
      "Dera Bassi Industrial Area",
      "Jodhan",
      "Dhanaula",
      "Jugial",
      "Dharamkot",
      "Kalan",
      "Dhariwal",
      "Kalanaur",
      "Dhilwan",
      "Kapurthala",
      "Dhuri",
      "Karoran",
      "Dinanagar",
      "Kartarpur",
      "Dirba",
      "Kathanian",
      "Doraha",
      "Khamanon",
      "Faridkot",
      "Khambra",
      "Fatehgarh Churian",
      "Khanauri",
      "Fazilka",
      "Khanna",
      "Firozpur",
      "Kharar",
      "Gardhiwala",
      "Khemkaran",
      "Garhshankar",
      "Khilchian",
      "Ghagga",
      "Korianwali",
      "Ghanaur",
      "Kot Fatta",
      "Ghoh",
      "Kot Ise Khan",
      "Giddarbaha",
      "Kotkapura",
      "Kotla Nihang",
      "Kurali",
      "Lalru",
      "Leather Complex Industrial Area",
      "Lehragaga",
      "Lohian Khas",
      "Longowal",
      "Ludhiana",
      "Machhiwara",
      "Mahilpur",
      "Majitha",
      "Makhu",
      "Malaut",
      "Malerkotla",
      "Mallanwala Khass",
      "Maloud",
      "Mamun",
      "Mandi Govindgarh",
      "Mansa",
      "Manwal",
      "Maur",
      "Mirpur",
      "Moga",
      "Mohali",
      "Moonak",
      "Morinda",
      "Mubarakpur",
      "Mudal",
      "Mudki",
      "Mukerian",
      "Muktsar",
      "Mullanpur Dakha",
      "Mullanpur Garib Dass",
      "Nabha",
      "Nag Kalan Industrial Area",
      "Nakodar",
      "Nangal",
      "Nangli",
      "Narot Mehra",
      "Nawanshahr",
      "Nehon",
      "Nurmahal",
      "Pathankot",
      "Patiala",
      "Patran",
      "Patti",
      "Payal",
      "Phagwara",
      "Phillaur",
      "Qadian",
      "Rahon",
      "Raikot",
      "Rail",
      "Raipur Rasulpur",
      "Raja Sansi",
      "Rajpura",
      "Rakri",
      "Raman",
      "Ramdas",
      "Rampura Phul",
      "Rayya",
      "Rupnagar",
      "Rurki Kasba",
      "Sahnewal",
      "Saloh",
      "Samana",
      "Samrala",
      "Sanaur",
      "Sangat",
      "Sangrur",
      "Sansarpur",
      "Sarai Khas",
      "Sardulgarh",
      "Satyewala",
      "Shahkot",
      "Sham ChaurasI",
      "Shikar",
      "Sirhind Fatehgarh Sahib",
      "Sri Hargobindpur",
      "Sujanpur",
      "Sultanpur",
      "Sunam",
      "Talwandi Bhai",
      "Talwandi Sabo",
      "Talwara",
      "Tapa",
      "Tarantaran",
      "Tharial",
      "Tibri",
      "Tungaheri",
      "Urmar Tanda",
      "Zira",
      "Zirakpur"
    ]
  },
  {
    code: "Rajasthan",
    name: "Rajasthan",
    stateCode: "RJ",
    cities: [
      "1SGM",
      "3STR",
      "Abu Road",
      "Ahore",
      "Ajeetgarh",
      "Ajmer",
      "Ajolion Ka Khera Industrial Area",
      "Aklera",
      "Aligarh",
      "Alwar",
      "Amet",
      "Antah",
      "Anupgarh",
      "Asind",
      "Atru",
      "Babai",
      "Baggar",
      "Bagru",
      "Bakani",
      "Bali",
      "Balotra",
      "Banasthali",
      "Bandikui",
      "Banera",
      "Bangur Nagar",
      "Banswara",
      "Bar",
      "Baral",
      "Baran",
      "Bari",
      "Bari Sadri",
      "Barmer",
      "Baskhoh",
      "Basni Belima",
      "Bassi",
      "Bay",
      "Bayana",
      "Beawar",
      "Beejoliya Kalan",
      "Begun",
      "Behror",
      "Bewanja Industrial Area",
      "Bhadra",
      "Bhalariya",
      "Bharatpur",
      "Bhavri",
      "Bhawani Mandi",
      "Bhilwara",
      "Bhim",
      "Bhinder",
      "Bhinmal",
      "Bhiwadi",
      "Bhusawar",
      "Bicciwara Industrial Area",
      "Bichhiwara",
      "Bichhri",
      "Bidasar",
      "Bigod",
      "Bikaner",
      "Bilara",
      "Bishangarh",
      "Bissau",
      "BITS Pilani Campus",
      "Boranada Special Economic Zone",
      "Borawar",
      "Budhpura",
      "Bundi",
      "Chaksu",
      "Chawand",
      "Chechat",
      "Chhabra",
      "Chhapar",
      "Chhipabarod",
      "Chhoti Sadri",
      "Chirawa",
      "Chittaurgarh",
      "Chomu",
      "Churu",
      "Danta",
      "Dariba",
      "Dausa",
      "Deeg",
      "Delwara",
      "Deogarh",
      "Deoli",
      "Deshnoke",
      "Dewrighata Industrial Area",
      "Dhariawad",
      "Industrial Area Bigod",
      "Dhaulpur",
      "Industrial Area Deoli",
      "Dhorimanna",
      "Industrial Area Gegal",
      "Didwana",
      "Industrial Area Hathipura",
      "Dudu",
      "Industrial Area Kaladera",
      "Dungargarh",
      "Industrial Area Kanya Kheri",
      "Dungarpur ",
      "Emri",
      "Industrial Area Karauli",
      "Falna",
      "Industrial Area Mathania",
      "Fatehnagar",
      "Industrial Area Napasar",
      "Fatehpur",
      "Industrial Area Narbad Khera",
      "Gajsinghpur ",
      "Galiakot",
      "Industrial Area Sangaria",
      "Gangapur",
      "Industrial Area Swaroopganj",
      "Gangapur City ",
      "Garhi",
      "Islampur",
      "Gharsana",
      "Jahazpur",
      "Gogunda",
      "Jahazpur Industrial Area",
      "Goredi Chancha",
      "Jaipur",
      "Gothan",
      "Jaisalmer",
      "Gothra",
      "Jaitaran",
      "Govindgarh, Alwar District",
      "Jalor",
      "Govindgarh, Jaipur District",
      "Jalore Industrial Area",
      "Govindpur Baori Industrial Area",
      "Jamwa Ramgarh",
      "Jhagarwas",
      "Goyli",
      "Jhalawar",
      "Guhala",
      "Jhalrapatan",
      "Gulabpura",
      "Jhunjhunun",
      "Hamirgarh",
      "Hanumangarh",
      "Jobner",
      "Hamirgarh Growth Centre RIICO",
      "Jodhpur",
      "Jodhpur Stone Park",
      "Hanumangarh Industrial Area",
      "Hindaun",
      "Kaithoon",
      "Hindaun City Industrial Area",
      "Kaladwas Industrial Area",
      "Kaman",
      "Indragarh",
      "Kanor",
      "Industrial Area Bidiyad",
      "Kanwat",
      "Kapasan",
      "Lakheri",
      "Kaprain",
      "Lalsot",
      "Karanpur",
      "Losal",
      "Karauli",
      "Lunkaransar",
      "Kasba Bonli",
      "Mahu Kalan",
      "Kawai",
      "Mahwa",
      "Kekri",
      "Makrana",
      "Kelwa",
      "Malpura",
      "Keshoraipatan",
      "Malsisar",
      "Kesrisinghpur",
      "Mandalgarh",
      "Khairabad",
      "Mandawa",
      "Khairthal",
      "Mandawar",
      "Khajuwala",
      "Mandpiya",
      "Khandela, Baran District",
      "Mangrol",
      "Khandela, Sikar District",
      "Manoharpur",
      "Khanpur",
      "Manoharthana",
      "Khara",
      "Marwar Junction",
      "Khatu",
      "Mathania",
      "Kherli",
      "Mavli",
      "Kherliganj",
      "Mehandipur",
      "Kherwara Chhaoni",
      "Merta City",
      "Khetri",
      "Merta Road",
      "Kinchan",
      "Modak",
      "Kishangarh",
      "Mokalsar",
      "Kishangarh Renwal",
      "Mount Abu",
      "Kishangarh, Alwar District",
      "Mukandgarh",
      "Kolayat",
      "Mundwa",
      "Kolvi Mandi Rajendrapur",
      "Nadbai",
      "Kota",
      "Nagar",
      "Kotputli",
      "Nagaur",
      "Kuchaman City",
      "Nagaur Industrial Area",
      "Kuchera",
      "Nainwa",
      "Kumbhkot",
      "Nandri",
      "Kumher",
      "Napasar",
      "Kushalgarh",
      "Nasirabad",
      "Lachhmangarh",
      "Nathdwara",
      "Ladnu",
      "Nawalgarh",
      "Ramgarh, Alwar District",
      "Neem Ka Thana Industrial Area",
      "Ramgarh, Sikar District ",
      "Rani",
      "Neem-Ka-Thana",
      "Rani Sagar RIICO",
      "Neemrana",
      "Ranthambore",
      "Newa Talai",
      "Ratangarh",
      "Nimbahera",
      "Ratannagar",
      "Niwai",
      "Rawatbhata",
      "Niwai Industrial Area",
      "Rawatsar",
      "Nohar",
      "Reengus",
      "Nokha",
      "Renwal",
      "Nooan",
      "Reodar",
      "Padampur",
      "RIICO Industrial Area Bassi",
      "Pali ",
      "Parbatsar",
      "RIICO Industrial Area Bidasar",
      "Partapur ",
      "Partapur Industrial Area",
      "RIICO Industrial Area Bikasar",
      "Phalodi",
      "Phalodi Industrial Area",
      "RIICO Industrial Area Chopanki",
      "Phulera",
      "Pilani Town",
      "RIICO Industrial Area Gudli",
      "Pilibanga",
      "Pindwara",
      "RIICO Industrial Area Kharani",
      "Pipar City",
      "Pirawa",
      "RIICO Industrial Area Khushkhera",
      "Pokaran",
      "Pratapgarh",
      "RIICO Industrial Area Naya Gaon",
      "Pushkar",
      "Raila",
      "RIICO Industrial Area Neemrana",
      "Raisinghnagar ",
      "Rajakhera",
      "RIICO Industrial Area Palsana",
      "Rajaldesar ",
      "Rajgarh",
      "RIICO Industrial Area Rajgarh",
      "Rajgarh, Churu District",
      "Rajsamand",
      "RIICO Industrial Area Sanchore",
      "Ramganj Mandi",
      "Ramgarh",
      "RIICO Institutional Area Ranpur",
      "Sikar",
      "RIICO Industrial Area",
      "Silora Industrial Area",
      "Rupangarh",
      "Singhana",
      "Rikhabdeo",
      "Sirohi",
      "Sadri",
      "Sirohi Industrial Area",
      "Sadulshahar",
      "Siwana",
      "Sagwara",
      "Sojat",
      "Salasar",
      "Sojat Road",
      "Salumbar",
      "Sri Ganganagar",
      "Sambhar",
      "Sri Madhopur",
      "Samdari",
      "Sri Madhopur Indusrtial",
      "Sanchore Area",
      "Sanderao",
      "Sudarshanpura RIICO",
      "Sangaria",
      "Sujangarh",
      "Sangod",
      "Suket",
      "Sapotra",
      "Suket Industrail Area",
      "Saradhana RIICO",
      "Sumerganj Mandi",
      "Sardargarh",
      "Sumerpur",
      "Sardarshahar",
      "Sumerpur Industrial Area",
      "Sarekhurd Industrial Area RIICO",
      "Sundlak RIICO Baran",
      "Surajgarh",
      "Sariska",
      "Suratgarh",
      "Sarmathura",
      "Swaroopganj",
      "Sarwar",
      "Takhatgarh",
      "Satalkheri",
      "Talera",
      "Sawa",
      "Tapookra",
      "Sawai Madhopur",
      "Taranagar",
      "Seemalwara",
      "Thana Gazi RIICO",
      "Semari",
      "Tijara",
      "Shahjahanpur",
      "Todabhim",
      "Shahjahanpur Industrial Area",
      "Todaraisingh",
      "Todra",
      "Shahpura",
      "Tonk",
      "Shahpura, Bhilwara District",
      "Tonk Industrial Area",
      "Udaipur",
      "Sheoganj",
      "Udaipurwati",
      "Shiwar",
      "Udpura",
      "Uniara",
      "Utarlai",
      "Vijainagar",
      "Vijainagar, Ganganagar District",
      "Viratnagar",
      "Weir"
    ]
  },
  {
    code: "Sikkim",
    name: "Sikkim",
    stateCode: "SK",
    cities: [
      "Gangtok",
      "Gyalshing",
      "Jorethang",
      "Mangan",
      "Meli",
      "Namchi",
      "Nayabazar",
      "Pelling",
      "Rangpo",
      "Rhenock",
      "Singtam",
      "Upper Tadong"
    ]
  },
  {
    code: "Tamil Nadu",
    name: "Tamil Nadu",
    stateCode: "TN",
    cities: [
      "VA Vellalapatti",
      "Abiramam",
      "Achampudur",
      "Acharapakkam",
      "Achipatti",
      "Adikaratti",
      "Adiramapattinam",
      "Adiyanuthu",
      "Aduthurai",
      "Agaram",
      "Agastheeswaram",
      "Alagappapuram",
      "Alanganallur",
      "Alangayam",
      "Alangudi",
      "Arakandanallur",
      "Alangulam",
      "Arakonam",
      "Alangulam, Virudhunagar District",
      "Aralvaimozhi",
      "Arani",
      "Alanthurai",
      "Arani, Thiruvallur District",
      "Alathur, Chengalpattu Taluka",
      "Aranthangi",
      "Arasiramani",
      "Aliyar",
      "Arasur",
      "Alur",
      "Aravakurichi",
      "Alwarkurichi",
      "Arcot",
      "Alwarthirunagiri",
      "Arimalam",
      "Amathur",
      "Ariyalur",
      "Ambasamudram",
      "Ariyappampalayam",
      "Ambur",
      "Ariyur",
      "Ammainaickanur",
      "Arumanai",
      "Ammanur",
      "Arumbanur",
      "Ammapattinam",
      "Arumbavur",
      "Ammapettai",
      "Arumuganeri",
      "Ammapettai, Erode District",
      "Aruppukkottai",
      "Asaripallam",
      "Ammavarikuppam",
      "Athani",
      "Ammoor",
      "Athanur",
      "AMRL Sez Vaigaikulam",
      "Athimarapatti",
      "Anaimalai",
      "Athipatti",
      "Anaiyur",
      "Athur",
      "Ananthapuram",
      "Athur, Thoothukkudi",
      "Andipalayam District",
      "Andipatti Jakkampatti",
      "Attayampatti",
      "Anjugrammam",
      "Attur",
      "Annamalai Nagar",
      "Avadattur",
      "Annanji",
      "Avalapalli",
      "Annavasal",
      "Avalpoondurai",
      "Annur",
      "Avanashi",
      "Anthiyur",
      "Avaniapuram",
      "Anuppankulam",
      "Ayakudi",
      "Appakudal",
      "Aygudi",
      "Arachalur",
      "Ayothiapattinam",
      "Ayyalur",
      "Ayyampalayam",
      "Ayyampettai",
      "Ayyampettai, Thanjavur District",
      "Azhagiapandipuram",
      "B Mallapuram",
      "B Meenakshipuram",
      "Balakrishnampatti",
      "Balakrishnapuram",
      "Balapallam",
      "Balasamudram",
      "Bargur",
      "Batlagundu",
      "Belur",
      "Bharathi Nagar",
      "Bhavani",
      "Bhavanisagar",
      "Bhuvanagiri",
      "Bikketti",
      "Bodinayakanur",
      "Boothapandi",
      "Boothipuram",
      "Chakkarapalli",
      "Chathirareddipatti",
      "Chatrapatti",
      "Chenbagaramanputhur",
      "Chengalpattu",
      "Chengam",
      "Chengappalli",
      "Chennagiri",
      "Chennai",
      "Chennasamudram",
      "Chennimalai",
      "Cheranmadevi",
      "Chetpet",
      "Chettiarpatti",
      "Chettipalayam",
      "Dharapadavedu",
      "Chettipalayam,",
      "Dharapuram",
      "Coimbatore District",
      "Dharmapuri",
      "Chettithangal",
      "Dindigul",
      "Chidambaram",
      "Doramangalam",
      "Chinnakalayamputhur",
      "Dusi",
      "Chinnakkampalayam",
      "Edaganasalai",
      "Chinnalapatti",
      "Edaikodu",
      "Chinnamanur",
      "Edakalinadu",
      "Chinnampalayam",
      "Edappadi",
      "Chinnasalem",
      "Elathur",
      "Chinnathadagam",
      "Elayirampannai",
      "Chinniampalayam, Erode District",
      "ELCOT Gangaikodan IT Park",
      "Chinthamani",
      "Ellandaikuttai",
      "Chithode",
      "Elumalai",
      "Chockampatti",
      "Eral",
      "Cholapuram",
      "Eranapuram",
      "Coimbatore",
      "Eraniel",
      "Coonoor",
      "Eriodu",
      "Courtalam",
      "Erode",
      "Cuddalore",
      "Erumaipatti",
      "Damalerimuthur",
      "Eruvadi",
      "Dasanaickenpatti",
      "Ethapur",
      "Denkanikottai",
      "Ettayapuram",
      "Desur",
      "Ettimadai",
      "Devadanapatti",
      "Ezhudesam",
      "Devakottai",
      "Frontier Mediville SEZ",
      "Devanangurichi",
      "Ganapathipuram",
      "Devarshola",
      "Gandhi Nagar",
      "Devasthanam",
      "Gangaikondan",
      "Devikapuram",
      "Gangavalli",
      "Devipattinam",
      "Ganguvarpatti",
      "Dhalavoipuram",
      "Gingee",
      "Dhali",
      "GK Industrial Park",
      "Dhaliyur",
      "Gobichettipalayam",
      "Dharamapuram",
      "Gopalasamudram",
      "Gudalur",
      "Gudalur, The Nilgiris District",
      "Gudalur, Theni District",
      "Gudiyatham",
      "Gummidipoondi",
      "Hale Dharmapuri",
      "Hanumanthampatti",
      "Harur",
      "Harveypatti",
      "Highways",
      "Hosur",
      "Hubbathala",
      "Huligal",
      "Idikarai",
      "Iduvai",
      "Ilampillai",
      "Ilanji",
      "Ilayangudi",
      "Iluppaiyurani",
      "Iluppur",
      "Irugur",
      "Jaffrabad",
      "Jalakandapuram",
      "Jambai",
      "Jayankondam",
      "Jeyamangalam",
      "Jolarpet",
      "K Madapur",
      "Kadambathur",
      "Kadambur",
      "Kadathur",
      "Kadayal",
      "Kadayam",
      "Kadayampatti",
      "Kadayanallur",
      "Kailasagiri",
      "Kakkalur",
      "Kalakad",
      "Kalakattupular",
      "Kalambur",
      "Kalappanaickenpatti",
      "Kalavai",
      "Kaliyakkavilai",
      "Kaliyapuram",
      "Kalladaikurichi",
      "Kallakkurichi",
      "Kallakudi",
      "Kallangudy",
      "Kallipalaym",
      "Kallukoottam",
      "Kalparapatti",
      "Kalugumalai",
      "Kamalakannanji SIDCO Industrial Estate",
      "Kamayagoundanpatti",
      "Kambainallur",
      "Kambam",
      "Kamuthi",
      "Kanadukathan",
      "Kanakkampalayam",
      "Kanakkanpatti",
      "Kanam",
      "Kancheepuram",
      "Kandanur",
      "Kangayampalayam",
      "Kangeyam",
      "Kaniyambadi",
      "Kaniyampoondl",
      "Kaniyur",
      "Kaniyur,Coimbatore District",
      "Kanjikoil",
      "Kannamangalam",
      "Kannampalayam",
      "Keelakarai",
      "Kannanendal",
      "Keeramangalam",
      "Kannanoor",
      "Keeranur",
      "Kannivadi",
      "Keeranur, Pudukkottai District",
      "Kannivadi, Dindigul District",
      "Keeripatti",
      "Kanniyakumari",
      "Keezhkulam",
      "Kappalur SIDCO Industrial Estate",
      "Kelamangalam",
      "Kembainaickenpalayam",
      "Kappiyarai",
      "Kethi",
      "Karadipatti",
      "Kila Ambur",
      "Karaikkudi",
      "Kilampadi",
      "Karaikudi SIDCO Industrial Estate",
      "Kilapavoor",
      "Kilkunda",
      "Karamadai",
      "Killai",
      "Karambakkudi",
      "Killiyoor",
      "Kariamangalam",
      "Kilpennathur",
      "Kariapatti",
      "Kilvaithinankuppam",
      "Karugampattur",
      "Kilvelur",
      "Karukkalvadi",
      "Kinathukadavu",
      "Karumandi Chellipalayam",
      "Kodaikanal",
      "Karumathampatti",
      "Kodavasal",
      "Karungal",
      "Kodivalasa",
      "Karunguzhi",
      "Kodumudi",
      "Karuppur",
      "Koil Palayam",
      "Karur",
      "Kolachal",
      "Kasipalayam",
      "Kolappalur",
      "Kathujuganapalli",
      "Kolathupalayam",
      "Kattathurai",
      "Kolathur",
      "Kattumannarkoil",
      "Kollancode",
      "Kattupakkam",
      "Kollankoil",
      "Kattuputhur",
      "Komaralingam",
      "Kaveripakkam",
      "Kombai",
      "Kaveripattinam",
      "Kondur",
      "Kavindapadi",
      "Konganapuram",
      "Kayalpattinam",
      "Koonavelampatti",
      "Kayatharu",
      "Kooraikundu",
      "Koothappar",
      "Koradacheri",
      "Kotagiri",
      "Kothanallur",
      "Kottaiyur",
      "Kottakuppam",
      "Kottaram",
      "Kottur",
      "Kovalam",
      "Kovilpatti",
      "Krishnagiri",
      "Krishnarayapuram",
      "Kuchanur",
      "Kuchipulayam",
      "Kuhalur",
      "Kulappuram",
      "Kulasekaram",
      "Kulasekarapuram",
      "Kulathur",
      "Kulithalai",
      "Kullursandai",
      "Kumbakonam",
      "Kumaragiri",
      "Kumarapalayam",
      "Kumarapuram",
      "Kumbakonam",
      "Kunnathur",
      "Kurinjipadi",
      "Kurudampalayam",
      "Kurukkupatti",
      "Kurumbalur",
      "Kurumbapatti",
      "Kuruppanaickenpalayam",
      "Kuthalam",
      "Kuthanallur",
      "Kuzhithurai",
      "Labbaikudikadu",
      "Lakkampatti ",
      "Lakshmi Puram",
      "Lalgudi",
      "Lalpet",
      "Lampalayam",
      "Madaharpakkam",
      "Madathukulam",
      "Madukkarai",
      "Madukkur",
      "Madurai",
      "Maduranthakam",
      "Mahindra World City",
      "Makkinampatti",
      "Mallankinaru",
      "Mallasamudram",
      "Mallur",
      "Malumichampatti",
      "Mamallapuram",
      "Mamsapuram",
      "Manachanallur",
      "Manalikarai",
      "Manalmedu",
      "Manalurpet",
      "Manamadurai",
      "Manapparai",
      "Manavalakurichi",
      "Mancad",
      "Mandaikadu",
      "Mandapam",
      "Mangalam",
      "Mangalampet",
      "Manimutharu",
      "Mannargudi",
      "Manthithoppu",
      "Maraimalainagar",
      "Marakkanam",
      "Maramangalathupatti",
      "Marandahalli",
      "Markayankottai",
      "Marthandam",
      "Marudur",
      "Marungur",
      "Maruthancode",
      "Masinaickenpatty",
      "Mathicode",
      "Mathigiri",
      "Mathur",
      "Mayiladuthurai",
      "Mecheri",
      "Melacheval",
      "Melachokkanathapuram",
      "Melagaram",
      "Melaparthibanur",
      "Melathiruppanthuruthi",
      "Melattur",
      "Melmangalam",
      "Melpattampakkam",
      "Melur",
      "Melvisharam",
      "Methukummal",
      "Mettamalai",
      "Mettunasuvanpalayam",
      "Mettupalayam",
      "Mettupalayam, Tiruchirappalli District",
      "Mettur",
      "Mevalurkuppam",
      "Milavittan",
      "Minampalli-Pachamadevi",
      "Modakurichi",
      "Mohamed Bundur",
      "Mohanur",
      "Moolakaraipatti",
      "Mopperipalayam",
      "Morattupalayam",
      "Mudalipalayam SIDCO",
      "Mudukulathur",
      "Mudumalai",
      "Mukasipidariyur",
      "Mukkudal",
      "Mulagumudu",
      "Mulanur",
      "Mullipattu",
      "Muruganpalayam",
      "Musiri",
      "Muthanampalayam",
      "Muthukadu",
      "Muthupet",
      "Muthur",
      "Muttayyapuram",
      "Muzhucode",
      "Mylaudy",
      "Nadaikavu",
      "Naduvaneri",
      "Naduvattam",
      "Nagamalaipudukottai",
      "Nagamangalam",
      "Nagapattinam",
      "Nagercoil",
      "Nagojanahalli",
      "Nallampatti",
      "Nallipalayam",
      "Nallur",
      "Namagiripettai",
      "Namakkal",
      "Nambiyur",
      "NandivaramGuduvancheri",
      "Nangavalli",
      "Nangavaram",
      "Nanguneri",
      "Nanjaiuthukuli Industrial Estate",
      "Nanjikottai",
      "Nannilam",
      "Naranammalpuram",
      "Naranapuram",
      "Narasimhanaickenpalayam",
      "Narasingapuram",
      "Narasingapuram, Salem District",
      "Nasiyanur",
      "Natchiarkoil",
      "Natham",
      "Nathampannai",
      "Natrampalli",
      "Nattalam",
      "Nattapettai",
      "Nattarasankottai",
      "Nazerath",
      "Needamangalam",
      "Neelagiri",
      "Neikkarapatti",
      "Nellikuppam",
      "Nelliyalam",
      "Nemili",
      "Neripperichal",
      "Nerkuppai",
      "Nerunjipettai",
      "Neyveli",
      "Neyyoor",
      "Nilaiyur",
      "Nilakkottai",
      "O' Valley",
      "Odaipatti",
      "Odaiyakulam",
      "Oddanchatram",
      "Odugathur",
      "Olagadam",
      "Omalur",
      "Ooty",
      "Periyamanali",
      "Puliyur",
      "Periyanaickenpalayam",
      "Pullampadi",
      "Pernampattu",
      "Punjai Thottakurichi",
      "Perumagalur",
      "Punjaipugalur",
      "Perumagoundampatti",
      "Punjaipuliampatti",
      "Perumanallur",
      "Puthalam",
      "Perumandi",
      "Putheri",
      "Perundurai",
      "Puthukkadai",
      "Perungulam",
      "Puvalur",
      "Pethampalayam",
      "R Pudupatti",
      "Pethanaickenpalayam",
      "Rajapalayam",
      "Pettai",
      "Rajapalayam, Salem District",
      "Pillanallur",
      "PJ Cholapuram",
      "Rakkiya Palayam",
      "Pollachi",
      "Ramalingapuram",
      "Polur",
      "Ramanathapuram",
      "Pongaliyur",
      "Rameswaram",
      "Ponmanai",
      "Ranipettai",
      "Ponnamaravathi",
      "Rasipuram",
      "Ponnampatti",
      "Rayagiri",
      "Ponneri",
      "RS Mangalam",
      "Poolambadi",
      "Rudravathi",
      "Poolampatti",
      "S Kannanur",
      "Poolankinar",
      "S Kodikulam",
      "Pooluvapatti",
      "S Nallur",
      "Porayar",
      "Sakkimangalam",
      "Pothanur",
      "Salangapalayam",
      "Pothatturpettai",
      "Salem",
      "Pudiyamputhur",
      "Samalapuram",
      "Pudukkottai",
      "Samanatham",
      "Pudupalayam",
      "Samathur",
      "Pudupalayam Agraharam",
      "Sambavar Vadagarai",
      "Pudupatti",
      "Sankaramanallur",
      "Pudupattinam",
      "Sankarankoil",
      "Pudur",
      "Sankaraperi",
      "Puduvayal",
      "Sankarapuram",
      "Puliankudi",
      "Sankarnagar",
      "Sirugamani",
      "Sarcarsamakulam",
      "Sirumugai",
      "Sathankulam",
      "Sithayankottai",
      "Sathiyavijayanagaram",
      "Sithurajapuram",
      "Sathyamangalam",
      "Sivaganga",
      "Sattur",
      "Sivagiri",
      "Sayalgudi",
      "Sivagiri, Erode District",
      "Sayapuram",
      "Sivagiripatti",
      "Seerapalli",
      "Sivagnanapuram",
      "Seevur",
      "Sivakasi",
      "Seithur",
      "Sivanthipatti",
      "Semmipalayam",
      "Sivanthipuram",
      "Senthamangalam",
      "Srikalikapuram",
      "Sentharapatti",
      "Srimushnam",
      "Senur",
      "Sriperumbudur",
      "Sethiathoppu",
      "Sriramapuram",
      "Sevilimedu",
      "Srivaikuntam",
      "Sevugampatti",
      "Srivilliputhur",
      "Sevur",
      "Suchindrum",
      "Shenkottai",
      "Suleeswaranpatti",
      "Sholavandan",
      "Sulur",
      "Sholingur",
      "Sundarapandiam",
      "Sholur",
      "Sundarapandianpattinam",
      "SIDCO Bargur",
      "Sundarapandiapuram",
      "Singampuneri",
      "Surandai",
      "Singaperumalkoil",
      "Suriyampalayam",
      "Singilipatti",
      "Swamimalai",
      "SIPCOT Cheyyar",
      "T Kallipatti",
      "SIPCOT Industrial Complex Pillaipakkam",
      "T Kallupatti",
      "Tajpura",
      "SIPCOT Industrial Park Sriprumbudur",
      "Tayilupatti",
      "Tenkasi",
      "SIPCOT Perundurai",
      "Terkukallikulam",
      "SIPCOT Thervoy Kandigal",
      "Thadikarankonam",
      "SIPCOT Thoothukudi",
      "Thadikombu",
      "Sircar Periapalayam",
      "Thakkolam",
      "Sirkali",
      "Thalainayar",
      "Thamaraikulam",
      "Thammampatti",
      "Thanjavur",
      "Thanthoni",
      "Thappakuttai",
      "Tharamangalam",
      "Tharangambadi",
      "Thathaiyangarpet",
      "Thathankuttai",
      "Thazhakudy",
      "Thedavur",
      "Thenambakkam",
      "Thengampudur",
      "Theni Allinagaram",
      "Thenkarai",
      "Thenkarai, Coimbatore District",
      "Thenthamaraikulam",
      "Thenthiruperai",
      "Therur",
      "Thevaram",
      "Thevarappan Patti",
      "Thevur",
      "Thiagadurgam",
      "Thikkanamcode",
      "Thimmaiyanpettai",
      "Thingalnagar",
      "Thirikoodapuram",
      "Thirukarungavur",
      "Thirukarungudi",
      "Thirukkadaiyur",
      "Thirukkattupalli",
      "Thirumalayampalayam",
      "Thirumalpur",
      "Thirumangalam",
      "Thirumuruganpoondi",
      "Thirunageswaram",
      "Thiruparankundram",
      "Tirukkoyilur",
      "Thiruparappu",
      "Tirumalaigiri",
      "Thiruporur",
      "Tirunelveli",
      "Thiruppalai",
      "Tirupathur",
      "Thiruppanandal",
      "Tirupathur, Vellore District",
      "Thirupuvanam",
      "Tiruppur",
      "Thirupuvanam, Thanjavur District",
      "Tiruttani",
      "Tiruvannamalai",
      "Thiruthangal",
      "Tiruvethipuram",
      "Thiruthuraipoondi",
      "Tittacheri",
      "Thiruvaiyaru",
      "Tittakudi",
      "Thiruvalam",
      "TNPL Pugalur",
      "Thiruvallur",
      "Tuticorin",
      "Thiruvarur",
      "TVS Plant Hosur",
      "Thiruvattar",
      "Udangudi",
      "Thiruvenkadam",
      "Udayarpalayam",
      "Thiruvennainallur",
      "Udumalaipettai",
      "Thiruverumbur",
      "Ullur",
      "Thiruvidaimarudur",
      "Ulundurpet",
      "Thiruvithancode",
      "Unjalur",
      "Thisayanvilai",
      "Unnamalaikadai",
      "Thondamuthur",
      "Uppidamangalam",
      "Thondi",
      "Uppiliapuram",
      "Thookkanaikampalayam",
      "Usilampatti",
      "Thorapadi",
      "Uthamapalayam",
      "Thorapadi, Cuddalore District",
      "Uthangarai",
      "Uthayendram",
      "Thottiyam",
      "Uthiramerur",
      "Thuraiyur",
      "Uthukkottai",
      "Thuthipattu",
      "Uthukuli",
      "Thuvakudi",
      "V Pudupatti",
      "Timiri",
      "V Pudur",
      "Tindivanam",
      "Vadakarai Keezhpadugai",
      "Tiruchendur",
      "Vadakkanandal",
      "Tiruchengode",
      "Vadakkuvalliyur",
      "Tiruchirappalli",
      "Vadalur",
      "Tirukalukundram",
      "Vadamadurai",
      "Vadapudupatti",
      "Vaddakkankulam",
      "Yercaud",
      "Zamin Uthukuli"
    ]
  },
  {
    code: "Telangana",
    name: "Telangana",
    stateCode: "TS",
    cities: [
      "Achampet",
      "Adilabad",
      "Alampur",
      "Allipur",
      "Ananthapuram",
      "Anjani Portland Cement Factory",
      "APIIC Polepally SEZ",
      "Armur",
      "Ashwaraopet",
      "Asifabad",
      "Atmakur Mahbubnagar District",
      "Ballepalle",
      "Banswada ",
      "Bellampalle",
      "Bhadrachalam",
      "Bhainsa",
      "Bheema Cements Limited",
      "Bhimaram",
      "Bhimaram Warangal District",
      "Bhongir",
      "Bhupalpalle Area",
      "Bibinagar",
      "Bodhan",
      "Boyapalle",
      "Chandur",
      "Chanukya Cement  Factory",
      "Chatakonda",
      "Chegunta",
      "Chelpur",
      "Chennur Adilabad District",
      "Chinnachitakunta",
      "Chitkul",
      "Chityala",
      "Choppadandi",
      "Choutuppal",
      "Chunchupalle",
      "Dasnapur",
      "Deccan Cement",
      "Devapur",
      "Devarakonda",
      "Devarkadra",
      "Dharmapuri",
      "Dharmaram",
      "Dharmaram Karimnagar District",
      "Dharmaram Warangal District",
      "Dornakal",
      "Eddumailaram",
      "Factory",
      "Farooqnagar",
      "Gadwal",
      "Gajwel",
      "Ghanpur",
      "Ghanpur Warangal District",
      "Gorrekunta",
      "Gurralapadu Industrial area",
      "Haliya",
      "Hanwada",
      "Husnabad",
      "Huzur Nagar",
      "Huzurabad",
      "Hyderabad",
      "Ibrahimpatnam  Rangareddy District",
      "Ichoda",
      "Ieeja",
      "Isnapur",
      "Jadcherla",
      "Jagtial",
      "Jainoor ",
      "Jallaram",
      "Jammikunta",
      "Jangaon",
      "Jannaram",
      "Jogipet",
      "Kaddam Peddur",
      "Kadipikonda",
      "Kagaznagar",
      "Kalwakurthy",
      "Kamalapuram",
      "Kamalapuram Karimnagar District",
      "Kamareddy",
      "Karimnagar",
      "Kasipet",
      "Khammam",
      "Khanapur",
      "Kisan Nagar Industrial  Area",
      "Kodad",
      "Kodangal",
      "Kondamallapalle",
      "Koratla",
      "Kosigi",
      "Kothagudem",
      "Kothakota",
      "Kothur",
      "Kyathampalle",
      "Laxmidevipalle",
      "Luxettipet",
      "Madaram",
      "Madhira",
      "Madikonda",
      "Mahabubabad ",
      "Mahbubnagar",
      "Maheswaram Industrial Area",
      "Mamda",
      "Mamnoor",
      "Mancherial",
      "Mandamarri",
      "Manugur",
      "Medak",
      "Medipalle",
      "Metapally",
      "Miryalaguda",
      "Muhammadabad",
      "Mulugu",
      "Nagarjuna Sagar",
      "Nagarkurnool",
      "Nakrekal",
      "Nalgonda",
      "Narayanapuram",
      "Narayankhed",
      "Narayanpet",
      "Narsampet",
      "Narsapur",
      "Naspur",
      "Navandgi",
      "Nirmal",
      "Nizamabad",
      "Palakurthy",
      "Palwancha",
      "Pargi",
      "Peddapalle",
      "Perur",
      "Pochampalle",
      "Pothreddipalle",
      "Raasi Cement Factory Wazirabad",
      "Raghunathpur",
      "Ramagundam",
      "Ramannapeta",
      "Ramapuram",
      "Ratnapur",
      "Ratnapur Medak District",
      "Rudraram",
      "Rudraram Industrial Area",
      "Sadasivpet",
      "Sangareddy",
      "Sarangapur",
      "Sarapaka",
      "Sathupally",
      "Secunderabad",
      "Shamshabad",
      "Shankarampet",
      "Shivunipalle",
      "Siddipet",
      "Singapuram",
      "Sircilla",
      "Soanpet",
      "Sri Vishnu Cement Limited Dondapadu",
      "Suryapet",
      "Tallada",
      "Tandur",
      "Teegalpahad",
      "Thallapalle",
      "Thimmapur",
      "Thorrur",
      "Turkapally",
      "Utnur",
      "Vatwarlapalle",
      "Vemulawada",
      "Vicarabad",
      "Wanaparthy",
      "Warangal",
      "Warangal Industrial Area",
      "Yadagirigutta",
      "Yellandu",
      "Yellareddy",
      "Yenugonda",
      "Yerrabalem",
      "Zahirabad"
    ]
  },
  {
    code: "Tripura",
    name: "Tripura",
    stateCode: "TR",
    cities: [
      "Agartala",
      "Amarpur",
      "Ambassa",
      "Anandanagar",
      "Badharghat",
      "Belonia",
      "Briddhanagar",
      "Dharmanagar",
      "Fatikroy",
      "Gakulnagar",
      "Gakulpur",
      "Gandhigram",
      "Indranagar",
      "Jogendranagar",
      "Kailasahar",
      "Kalachhari",
      "Kamalpur",
      "Kanchanpur",
      "Khowai",
      "Kumarghat",
      "Kunjaban",
      "Madhupur",
      "Manu",
      "Matarbari",
      "Narsingarh",
      "Panisagar",
      "Ranirbazar",
      "Sabroom",
      "Santirbazar",
      "Sonamura",
      "Taranagar",
      "Teliamura",
      "Udaipur"
    ]
  },
  {
    code: "Uttar Pradesh",
    name: "Uttar Pradesh",
    stateCode: "UP",
    cities: [
      "Abupur",
      "Achalganj",
      "Achhalda",
      "Achhnera",
      "Adari",
      "Afzalgarh",
      "Agarwal Mandi",
      "Agra",
      "Agro Park Karkhiyon",
      "Ahraura",
      "Ailum",
      "Air Force Area",
      "Ajhuwa",
      "Akbarpur",
      "Akbarpur Near Kanpur",
      "Aliganj",
      "Aligarh",
      "Allahabad",
      "Allahganj",
      "Allapur",
      "Amanpur",
      "Ambehta",
      "Amethi",
      "Amethi Sultanpur District",
      "Amila",
      "Amilo",
      "Aminagar Sarai",
      "Amraudha",
      "Anandnagar",
      "Anpara",
      "Antu",
      "Anupshahr",
      "Anurudhpur Purab Patti",
      "Aonla",
      "Artauni",
      "Ashrafpur Kichhauchha",
      "Atarra",
      "Atasu",
      "Atrauli",
      "Atraulia",
      "Auraiya",
      "Aurangabad",
      "Auras",
      "Awagarh",
      "Ayodhya",
      "Azamgarh",
      "Azmatgarh",
      "Babarpur Ajitmal",
      "Baberu",
      "Babina",
      "Babrala",
      "Babugarh",
      "Bachhgaon",
      "Bachhraon",
      "Bachhrawan",
      "Bad",
      "Badaun Industrial Area",
      "Baghpat",
      "Bah",
      "Bahadurganj",
      "Baheri",
      "Bahjoi",
      "Bahraich",
      "Bahsuma",
      "Bahuwa",
      "Bajna",
      "Bakewar",
      "Bakiabad",
      "Baksar",
      "Bakshi Ka Talab",
      "Baldeo",
      "Ballia",
      "Balrampur",
      "Banat",
      "Banda",
      "Bangarmau",
      "Banguwan Kalan",
      "Banjarepur",
      "Bansdih",
      "Bansgaon",
      "Bansi",
      "Bara Gaon",
      "Barabanki",
      "Barabanki Industrial Area",
      "Baragaon",
      "Barahatir Jagdishpur",
      "Baraut",
      "Bareilly",
      "Barhalganj",
      "Barhani Bazar",
      "Barkhera",
      "Baroun",
      "Barsana",
      "Barua Sagar",
      "Barwar",
      "Barwara Mazra",
      "Basantpur Saitli",
      "Basta",
      "Basti",
      "Beelna",
      "Behat",
      "Behta Hajipur",
      "Bela Pratapgarh",
      "Belthara Road",
      "Beniganj",
      "Benipur",
      "Beswan",
      "Bewar",
      "Bhabnan Bazar",
      "Bhadarsa",
      "Bhadohi",
      "Bhadohi Industrial Area",
      "Bhagwant Nagar",
      "Bharatganj",
      "Bhargain",
      "Bharthana",
      "Bharuhana",
      "Bharwari",
      "Bhatni Bazar",
      "Bhatpar Rani",
      "Bhawan Bahadur Nagar",
      "Bhinga",
      "Bhogaon",
      "Bhojpur Dharampur",
      "Bhokarhedi",
      "Bhulepur",
      "Bidhuna",
      "Bighapur",
      "Bihka",
      "Bijauli Industrial Area",
      "Bijnor",
      "Bijpur",
      "Bikapur",
      "Bilari",
      "Bilariaganj",
      "Bilaspur",
      "Bilaspur, Gautam Buddha Nagar District",
      "Bilgram",
      "Bilhaur",
      "Bilram",
      "Bilsanda",
      "Bilsi",
      "Bindki",
      "Birbhanpur",
      "Bisalpur",
      "Bisanda Buzurg",
      "Bisauli",
      "Bisharatganj",
      "Bishunipur",
      "Biswan",
      "Bithoor",
      "Budaun",
      "Budhana",
      "Bugrasi",
      "Bulandshahr",
      "Chail",
      "Chakia",
      "Chakmano",
      "Chandauli",
      "Chandausi",
      "Chandpur",
      "Charkhari",
      "Charthaval",
      "Chaumuhan",
      "Chaurhat",
      "Chhaprauli",
      "Chharra Rafatpur",
      "Chhata",
      "Chhatari",
      "Chhibramau",
      "Chhitauni",
      "Chhutmalpur",
      "Chilkana Sultanpur",
      "Chirgaon",
      "Chitbara Gaon",
      "Chitrakoot",
      "Chopan",
      "Choubepur Kalan",
      "Chunar",
      "Churk Ghurma",
      "Colonelganj",
      "Dadri",
      "Dalmau",
      "Dankaur",
      "Dariyabad",
      "Dasna",
      "Dataganj",
      "Daurala",
      "Debai",
      "Deoband",
      "Deoranian",
      "Deori Singhpura",
      "Deoria",
      "Deosaini",
      "Derapur",
      "Devinagar",
      "Dewa",
      "Dhakauli",
      "Dhampur",
      "Dhanauha",
      "Dhanaura",
      "Dharoti Khurd",
      "Dhaura Tanda",
      "Dhaurahara",
      "Dibiyapur",
      "Dildarnagar Fatehpur Bazar",
      "Dindaspur",
      "Dindaspur Chandauli District",
      "Doghat",
      "Dohrighat",
      "Domariyaganj",
      "Dostpur",
      "Dudhi",
      "Dulhipur",
      "Ekdil",
      "Erich",
      "Etah",
      "Etah Indutrial Area",
      "Etawah",
      "Etmadpur",
      "Faizabad",
      "Faizganj",
      "Farah",
      "Faridnagar",
      "Faridpur, Bareilly District",
      "Fariha",
      "Farrukhabad",
      "Fatehabad",
      "Fatehganj Pashchimi",
      "Fatehganj Purvi",
      "Fatehgarh",
      "Fatehpur",
      "Fatehpur Chaurasi",
      "Fatehpur Sikri Fatehpur, Barabanki District",
      "Fazi Nagar",
      "Firozabad",
      "Firozabad UPSIDC",
      "Gabhana",
      "Gadhi",
      "Gagalhedi Must",
      "Gajraula",
      "Gangapur",
      "Gangiri",
      "Gangoh",
      "Ganj Dundwara",
      "Ganj Muradabad",
      "Ganwaria Tulsipur",
      "Garauri",
      "Garautha",
      "Garhi Pukhta",
      "Garhmukteshwar",
      "Gaura",
      "Gaura Barhaj",
      "Gaura Kala",
      "Gauri Bazar",
      "Gausganj",
      "Gawan",
      "Ghatampur",
      "Ghaziabad",
      "Ghazipur",
      "Ghiraur",
      "Ghorawal",
      "Ghosi",
      "Ghosia Bazar",
      "Ghughuli",
      "GIDA Gorakhpur",
      "Gird Baragaon",
      "Gohand",
      "Gokul",
      "Gola Bazar",
      "Gola Gokarannath",
      "Gonda",
      "Gopamau",
      "Gopi Ganj",
      "Gorakhpur",
      "Gosainganj",
      "Gosainganj, Lucknow District",
      "Got",
      "Govardhan",
      "Greater Noida",
      "Gulariya",
      "Gunnaur",
      "Gursahaiganj",
      "Gursarai",
      "Gyanpur",
      "Hafiz Ganj",
      "Haidergarh",
      "Haldaur",
      "Hallaur",
      "Hamirpur",
      "Handia",
      "Hapur",
      "Haqiqatpur",
      "Hardoi",
      "Harduaganj",
      "Hargaon",
      "Hariharpur",
      "Hariyawan",
      "Harraiya",
      "Hasanpur",
      "Hasayan",
      "Hastinapur",
      "Hata",
      "Hathgram",
      "Hathras",
      "Hyderabad",
      "Ibrahimpur",
      "Iglas",
      "Ikauna",
      "Iltifatganj Bazar",
      "Indian Tehephone Industry Mankapur",
      "Indian Telephone Industry",
      "Islamnagar",
      "Itaunja",
      "Itwa",
      "Jafarabad",
      "Jagner",
      "Jahanabad",
      "Jahangirabad",
      "Jahangirpur",
      "Jais",
      "Jaithara",
      "Jalalabad",
      "Jalalabad, Bijnor District",
      "Jalalabad, Muzaffarnagar District",
      "Jalali",
      "Jalalpur",
      "Jalaun",
      "Jalesar",
      "Jamshila",
      "Jamuhan",
      "Jangipur",
      "Jansath",
      "Jarwal",
      "Jasra",
      "Jasrana",
      "Jaswantnagar",
      "Jatari",
      "Jaunpur",
      "Jewar",
      "Jhalu",
      "Jhansi",
      "Jhinjhak",
      "Jhinjhana",
      "Jiyanpur",
      "Joya",
      "Jugauli",
      "Jyoti Khuria",
      "Kabrai",
      "Kachhauna Patseni",
      "Kachhla",
      "Kachhwa",
      "Kachnar",
      "Kadaura",
      "Kadipur",
      "Kailashpur",
      "Kaimganj",
      "Kairana",
      "Kakari",
      "Kakod",
      "Kakori",
      "Kakrala",
      "Kalinagar",
      "Kalpi",
      "Kamalganj",
      "Kampil",
      "Kanaudia Chamical & Industries Ltd",
      "Kandharpur",
      "Kandhla",
      "Kannauj",
      "Kanpur",
      "Kanth",
      "Kanth, Shahjahanpur District",
      "Kaptanganj",
      "Karari",
      "Karhal",
      "Karnawal",
      "Karwi",
      "Kasba Khanpur",
      "Kasganj",
      "Kasiya",
      "Kataka",
      "Kataria",
      "Katghar Lalganj",
      "Kathaura",
      "Kathera",
      "Katra",
      "Katra Medniganj",
      "Katra, Gonda District",
      "Kaulakha",
      "Kauria ganj",
      "Kemri",
      "Kerakat",
      "Kewalpur",
      "Khadda",
      "Khaga",
      "Khailar",
      "Khair",
      "Khairabad",
      "Khairabad, Mau District",
      "Khalilabad",
      "Khamaria",
      "Khandauli",
      "Khanpur",
      "Kharela",
      "Khargupur",
      "Khariya",
      "Kharkhoda",
      "Khatauli",
      "Khekada",
      "Kheragarh",
      "Kheri",
      "Kheta Sarai",
      "Khudaganj",
      "Khurja",
      "Khutar",
      "Kiraoli",
      "Kiratpur",
      "Kishni",
      "Kishunpur",
      "Kithaur",
      "Koeripur",
      "Konch",
      "Kopaganj",
      "Kora Jahanabad",
      "Koraon",
      "Korwa",
      "Kosi Kalan",
      "Kota",
      "Kota, Sonbhadra District",
      "Kotra",
      "Kotwa",
      "Kotwali",
      "Kulpahar",
      "Kunda District",
      "Kundarki",
      "Kunwargaon",
      "Kuraoli",
      "Kurara",
      "Kursath",
      "Kursath, Hardoi District",
      "Kursi Road Industrial Area",
      "Kurthi Jafarpur",
      "Kushinagar",
      "Kusmara",
      "Laharpur",
      "Lakhimpur",
      "Lakhna",
      "Lal Gopalganj Nindaura",
      "Lalganj",
      "Lalitpur",
      "Lar",
      "Lawar",
      "Leather Park Banthar",
      "Ledwa Mahua",
      "Loni",
      "Lucknow",
      "Machhlishahr",
      "Madhoganj",
      "Madhogarh",
      "Madiya",
      "Maghar",
      "Mahaban",
      "Maharajganj",
      "Mahimapur",
      "Mahmudabad",
      "Mahoba",
      "Maholi",
      "Mahona",
      "Mahrajganj",
      "Mahrajganj, Azamgarh",
      "Mahroni",
      "Mahul Khas",
      "Maigal Ganj",
      "Mailani",
      "Maina Maujpur",
      "Mainpuri",
      "Majhauliraj",
      "Makhanpur",
      "Malhipur",
      "Malihabad",
      "Mallawan",
      "Mandawar",
      "Manikpur",
      "Manikpur Sarhat",
      "Maniyar",
      "Manjhanpur",
      "Mankapur",
      "Marehra",
      "Mariahu",
      "Maswasi",
      "Mataundh",
      "Mathura",
      "Mau Aima",
      "Maudaha",
      "Maunath Bhanjan",
      "Maurawan",
      "Mawana",
      "Meerut",
      "Mehdawal",
      "Mehnagar",
      "Mendu",
      "Middha",
      "Milak",
      "Miranpur",
      "Mirganj",
      "Mirzapur cum Vindhyachal",
      "Misrikh Cum Neemsar",
      "Modinagar",
      "Mogra Badshahpur",
      "Mohammadabad",
      "Mohammadabad, Farrukhabad District",
      "Mohammadi",
      "Mohan",
      "Mohanpur",
      "Mohiuddinpur",
      "Moradabad",
      "Moth",
      "Mubarakpur",
      "Mughalsarai",
      "Muhammadabad",
      "Mundera Bazar",
      "Mundia",
      "Muradnagar",
      "Mursan",
      "Musafirkhana",
      "Muzaffarnagar",
      "Nadigaon",
      "Nagina",
      "Nagram",
      "Nai Bazar",
      "Naini Industrial Area",
      "Najibabad",
      "Nakur",
      "Nanauta",
      "Nandgaon",
      "Nanpara",
      "Naraini",
      "Narauli",
      "Naraura",
      "Naugarh",
      "Naugawan Sadat",
      "Nautanwa",
      "Nawabganj",
      "Nawabganj, Bareilly District",
      "Nawabganj, Unnao District",
      "Nehtaur",
      "Nichlaul",
      "Nidhauli Kalan",
      "Nihal Garh Chak Jangla",
      "Nivi",
      "Niwari",
      "Nizamabad",
      "Noida",
      "Noorpur",
      "Nyoria Husainpur",
      "Nyotini",
      "Obra",
      "Oel Dhakwa",
      "Orai",
      "Orai Industrial Area",
      "Orai Jalaun District",
      "Oran",
      "Pachperwa",
      "Padarathpur",
      "Padrauna",
      "Pahar Ganj",
      "Pahasu",
      "Paintepur",
      "Pakbara",
      "Pali",
      "Pali, Hardoi District",
      "Palia Kalan",
      "Palpur",
      "Parasi",
      "Parichha",
      "Parikshitgarh",
      "Parsadepur",
      "Parsakhera Industrial Area",
      "Parsona",
      "Patadi",
      "Patala",
      "Patiyali",
      "Patti",
      "Pavi Sadakpur",
      "Phalauda",
      "Phaphund",
      "Phulpur",
      "Phulpur, Allahabad District",
      "Pihani",
      "Pilibhit",
      "Pilkhana",
      "Pilkhuwa",
      "Pinahat",
      "Pipalsana Chaudhari",
      "Pipara Dand ",
      "Pipiganj",
      "Pipraich",
      "Piprayli Bujurg",
      "Pipri",
      "Powayan",
      "Pratapgarh City",
      "Pukhrayan",
      "Puranpur",
      "Purdilnagar",
      "Pure Tiwari",
      "Purquazi",
      "Purwa",
      "Qasimpur Power House Colony",
      "Rabupura",
      "Radhakund",
      "Rae Bareli",
      "Raja ka Rampur",
      "Rajapur",
      "Ram Nagar Industrial Area",
      "Ramdaspur Urf Nagal",
      "Ramkola",
      "Ramnagar",
      "Rampur",
      "Rampur Bhawanipur",
      "Rampur Karkhana",
      "Rampur Maniharan",
      "Rampura",
      "Ranipur",
      "Raniyan",
      "Rasra",
      "Rasulabad",
      "Rasulabad Unnao District",
      "Ratanpura",
      "Rath",
      "Rati Ka Nagla Industrial Area",
      "Raya",
      "Renukoot",
      "Reoti",
      "Richha",
      "Risia Bazar",
      "Rithora",
      "Robertsganj",
      "Rori",
      "Rudayan",
      "Rudrapur",
      "Runkata",
      "Rura",
      "Rustamnagar Sahaspur",
      "Sadabad",
      "Sadat",
      "Sadatmasaura",
      "Sadruddin Nagar",
      "Safipur",
      "Sahanpur",
      "Saharanpur",
      "Sahaspur",
      "Sahaswan",
      "Sahatwar",
      "Sahawar",
      "Sahjanwan",
      "Sahpau",
      "Saidpur",
      "Saidpur Khajuria",
      "Saidpur, Budaun District",
      "Sainthal",
      "Saiyad Raja",
      "Sakaldiha",
      "Sakhanu",
      "Sakit",
      "Salarpur",
      "Salempur",
      "Salon",
      "Sambhal",
      "Samdhan",
      "Samthar",
      "Sandi",
      "Sandila",
      "Sandilla",
      "Sapur Banger",
      "Sarai Abdulmalik",
      "Sarai Aquil",
      "Sarai Lahur",
      "Sarai mir",
      "Sarai Mohana",
      "Saraon",
      "Sardhana",
      "Sarila ",
      "Sarnath",
      "Sarsawan",
      "Sarsual District",
      "Sasni",
      "Satiyava",
      "Satrikh",
      "Saunkh",
      "Saurikh",
      "Seohara",
      "Sewalkhas",
      "Sewarhi",
      "Shahabad",
      "Shahabad, Rampur District",
      "Shahganj",
      "Shahi",
      "Shahjahanpur",
      "Shahpur",
      "Shamli",
      "Shamsabad",
      "Shamsabad, Agra District",
      "Shankargarh",
      "Shaoron",
      "Shergarh",
      "Sherkot",
      "Shikarpur",
      "Shikohabad",
      "Shishgarh",
      "Shivli",
      "Shivrajpur",
      "Shohratgarh",
      "Siana",
      "Siddhaur",
      "Sidhauli",
      "Sidhpura",
      "Sikandarabad Industrial Area",
      "Sikanderpur",
      "Sikanderpur, Kannauj",
      "Sikandra",
      "Sikandra Rao",
      "Sikandrabad",
      "Sikindra",
      "Sikri Kalan",
      "Singahi Bhiraura",
      "Sirathu",
      "Sirauli",
      "Sirsa",
      "Sirsaganj ",
      "Sirsi",
      "Sisauli",
      "Siswa Bazar",
      "Sitapur",
      "Soron",
      "Suar",
      "Subeha",
      "Sultanpur",
      "Sumerpur",
      "Suriyawan",
      "Suthana Barsola",
      "Suzabad",
      "Talbehat",
      "Talgram",
      "Tambaur Cum  Ahmadabad",
      "Tanda",
      "Tanda, Rampur District",
      "Tatarpur Lallu",
      "Thakurdwara",
      "Thana Bhawan",
      "Thiriya Nizamat Khan",
      "Tikait Nagar",
      "Tikri",
      "Tikuniya",
      "Tilhar",
      "Tilthi",
      "Tindwari",
      "Tirwaganj",
      "Titron",
      "Tondi Fatehpur",
      "Tronica City",
      "Tulsipur",
      "Tundla",
      "Tundla Kham",
      "Tundla Rly Colony",
      "Ugu",
      "Ujhani",
      "Ujhari",
      "Umarha",
      "Umri",
      "Umri Kalan",
      "Un",
      "Unchahar",
      "Unnao",
      "Usawan",
      "Usehat",
      "Uska Bazar",
      "Utraula",
      "Varanasi",
      "Vijaigarh",
      "Vrindavan",
      "Walidpur",
      "Warhapur",
      "Wazirganj",
      "Zaidpur",
      "Zamania"
    ]
  },
  {
    code: "Uttarakhand",
    name: "Uttarakhand",
    stateCode: "UK",
    cities: [
      "Almora",
      "Auli",
      "Badrinath",
      "Bageshwar",
      "Bahadarabad",
      "Banbasa",
      "Bandia",
      "Bandiya",
      "Barkot",
      "Bazpur",
      "Begumpur",
      "Berinag",
      "Bhagwanpur",
      "Bhimtaal",
      "Bhowali",
      "Chakrata",
      "Chamba",
      "Chamoli",
      "Champawat",
      "Chaukori",
      "Corbett",
      "Dehradun",
      "Devaprayag",
      "Devaprayag, Garhwal District",
      "Dhanaulti",
      "Dharchula",
      "Dharchula Dehat",
      "Didihat",
      "Dineshpur",
      "Doiwala",
      "Dwarahat",
      "Gadarpur",
      "Gangolihat",
      "Gangotri",
      "Gochar",
      "Gopeshwar",
      "Haldwani",
      "Haridwar",
      "Herbertpur",
      "Jaspur",
      "Jhabrera",
      "Joshimath",
      "Kachnal Gosain",
      "Kaladhungi",
      "Karnaprayag",
      "Kashipur",
      "Kathgodam",
      "Kausani",
      "Kela Khera",
      "Khatima",
      "Kichha",
      "Kirtinagar",
      "Kotdwara",
      "Laksar",
      "Lalkuan",
      "Landaur",
      "Landhaura",
      "Lansdowne",
      "Lohaghat",
      "Mahua Dabra Haripura",
      "Mahua Kheraganj",
      "Manglaur",
      "Mehwar Kalan",
      "Mohanpur Mohammadpur",
      "Mukteshwar",
      "Mussoorie",
      "Nagala Imarti",
      "Nagla",
      "Nainital",
      "Nandprayag",
      "Narendranagar",
      "Natthuwa Wala",
      "New Tehri",
      "Pantnagar",
      "Pauri",
      "Piran Kaliyar",
      "Pithoragarh",
      "Pratitnagar",
      "Raipur",
      "Ramnagar",
      "Ranikhet",
      "Rishikesh",
      "Roorkee",
      "Rudraprayag",
      "Rudrapur",
      "Saidpura",
      "Sara Industrial Estate",
      "Selaqui",
      "Shaktigarh",
      "SIDCUL Haridwar",
      "SIDCUL Kotadwara",
      "Sitarganj",
      "Srinagar",
      "Sultanpur",
      "Tanakpur",
      "Tehri",
      "Uttarkashi",
      "Vikasnagar"
    ]
  },
  {
    code: "West Bengal",
    name: "West Bengal",
    stateCode: "WB",
    cities: [
      "Adra",
      "Ahmadpur",
      "Aiho",
      "Ajodhyanagar",
      "Alipukur",
      "Alipurduar",
      "Alipurduar Rly Jnc",
      "Amarshi Kasba",
      "Ambhua",
      "Amlagora",
      "Amlajora",
      "Amta",
      "Amtala",
      "Anantabati",
      "Andal",
      "Andul",
      "Ankurhati",
      "Anup Nagar",
      "Arambag",
      "Argari",
      "Arjunpur",
      "Arra",
      "Asansol",
      "Ashadtalya",
      "Aurangabad",
      "Badamtam Tea Garden",
      "Badkulla",
      "Baduria",
      "Bagnan",
      "Bagula",
      "Bahirgram",
      "Bahula",
      "Baidyabati",
      "Bakreshwar Thermal Power Township",
      "Baksa",
      "Balarampota",
      "Balarampur",
      "Balarampur, South Twenty Four Parganas District",
      "Balichak",
      "Balihati",
      "Bally",
      "Baluhati",
      "Balurghat",
      "Bamangram",
      "Bamanpukur",
      "Bamna",
      "Bamunari",
      "Ban Harishpur",
      "Banarhat Tea Garden",
      "Bandhail",
      "Bandipur",
      "Bandoan",
      "Baneshwarpur",
      "Baneshwarpur Haora District",
      "Baneswar",
      "Bangalpur",
      "Bangaon",
      "Baniban",
      "Baniban Jagadishpur",
      "Bankra",
      "Bankra North Twenty Four",
      "Bankul",
      "Bankura",
      "Bansberia",
      "Banshra",
      "Banupur",
      "Bara",
      "Bara Jumla",
      "Bara Suzapur",
      "Barabazar",
      "Barasat",
      "Barda",
      "Bargachhia",
      "Bargachhia Hugli District",
      "Barijhati",
      "Barijpur",
      "Barjora",
      "Barkalikapur",
      "Barrackpur",
      "Barrackpur Cantonment",
      "Barua Gopalpur",
      "Baruipara",
      "Baruipur",
      "Barunda",
      "Basantapur",
      "Basanti",
      "Basantia",
      "Basirhat",
      "Baska ",
      "Basudebpur",
      "Basudebpur Purba  Medinipur District",
      "Batika",
      "Batul",
      "Bayarsing",
      "Begampur",
      "Begun Kodar Parganas District",
      "Beldanga",
      "Beldubi",
      "Beliatore",
      "Belumilki",
      "Benudia",
      "Berhampore",
      "Betpuli",
      "Bhabki",
      "Bhadreswar",
      "Bhagabatipur",
      "Bhandar Gachha",
      "Bhandardaha",
      "Bhangar Raghunathpur",
      "Bhangri Pratham Khanda",
      "Bhasa",
      "Bhasaipaikar",
      "Bhatpara",
      "Bhimram",
      "Bholar Dabri",
      "Bidhan Nagar",
      "Bikihakola",
      "Bilandapur",
      "Bilpahari",
      "Bipra Noapara",
      "Bira",
      "Birlapur",
      "Birnagar",
      "Birodhi",
      "Birpara Champagachhi",
      "Birpara, Jalpaiguri District",
      "Bishnupur",
      "Bishnupur Birbhum District",
      "Bishnupur Industrial Growth Centre",
      "Bishnupur, South Twenty Four Parganas District",
      "Bolpur",
      "Bora Gagangohalia",
      "Borai",
      "Bowali",
      "Brindabanpur",
      "Budge Budge",
      "Buita",
      "Burdwan",
      "Cart Road",
      "Chachanda",
      "Chak Alampur",
      "Chak Bankola",
      "Chak Baria",
      "Chak Bhrigu",
      "Chak Enayetnagar",
      "Chak Kanthalia",
      "Chak Kashipur",
      "Chakapara",
      "Chakdaha",
      "Chakiabhita",
      "Chakmeghoan",
      "Chalsa Mahabari",
      "Champahati",
      "Champdani",
      "Chamrail",
      "Chanchal",
      "Chandannagar",
      "Chandapur",
      "Chanddandaha",
      "Chandipur",
      "Chandipur Haora District",
      "Chandpala Anantapathpur",
      "Chandpur",
      "Chandpur South Twenty Four Parganas District",
      "Chandrakona",
      "Chandrapur",
      "Chapari",
      "Chapra",
      "Chaspara",
      "Chaulia",
      "Chechakhata",
      "Chekya",
      "Chhekati",
      "Chhora",
      "Chhota Laukuthi",
      "Chhota Suzapur",
      "Chikanpara",
      "Chikrand",
      "Chinchuria",
      "Chittaranjan",
      "Chong Ghurali",
      "Chongtong Tea Garden",
      "Chopra",
      "Contai",
      "Dafahat",
      "Dakhin Rampur",
      "Dakshin Baguan",
      "Dakshin Chatra",
      "Dakshin Jhapardaha",
      "Dakshin Odlabari",
      "Dakshin Rajyadharpur",
      "Dakshin Raypur",
      "Dakshin Santoshpur",
      "Dalkhola",
      "Dalurband",
      "Danga",
      "Dankuni",
      "Darappur",
      "Darjeeling",
      "Daulatpur",
      "Deara",
      "Debipur",
      "Deora",
      "Deulgram",
      "Deuli",
      "Deulia",
      "Dhakuria",
      "Dhaliabari",
      "Dhamua",
      "Dhandadihi",
      "Dhania",
      "Dhanyakuria",
      "Dharmapur",
      "Dhatrigram",
      "Dhola",
      "Dhuilya",
      "Dhulasimla",
      "Dhulian",
      "Dhunki",
      "Dhupguri",
      "Dhusaripara",
      "Diamond Harbour",
      "Digha",
      "Dighirpar",
      "Dignala",
      "Dihimandalghat",
      "Dinga Khola",
      "Dinhata",
      "Dogachhia",
      "Dogachhia Barddhaman District",
      "Domjur",
      "Dubra",
      "Dubrajpur",
      "Dudhkalmi",
      "Durgapur",
      "Durllabhganj",
      "Egra",
      "Ekabbarpur",
      "Eksara",
      "Erashal",
      "Falakata",
      "Falta Industrial Growth Centre",
      "Farakka Barrage Township",
      "Farakka PTS Township",
      "Fatehpur",
      "Fatellapur",
      "Fatepur",
      "Gabberia",
      "Gairkata",
      "Gangadharpur",
      "Gangarampur",
      "Gangi",
      "Gangnapur",
      "Gangni",
      "Garalgachha",
      "Garbeta",
      "Garden",
      "Garh Kamalpur",
      "Garshyamnagar",
      "Garulia",
      "Gaur Daha",
      "Geni",
      "Ghatal",
      "Ghola Noapara",
      "Ghoraberia",
      "Ghutgarya",
      "Ging Tea Garden",
      "Giria",
      "Goasafat",
      "Gobardanga",
      "Gobindapur",
      "Gopalpur",
      "Guskara",
      "Habra",
      "Haldia",
      "Haldibari",
      "Halisahar",
      "Halyan",
      "Hanskunda",
      "Hanspukuria",
      "Haora",
      "Harharia Chak",
      "Harinadibhastsala",
      "Harindanga",
      "Haringhata Farm",
      "Haripur",
      "Harirampur",
      "Harishpur",
      "Hasimnagar",
      "Hatgachha",
      "Hatsimla",
      "Hijuli",
      "Hincha Gerya",
      "Hindusthan Cables Town",
      "Hingalganj",
      "Hirapur",
      "Hugli-Chinsurah",
      "Hutmura",
      "Ichhapur Defence Estate",
      "Ichhlampur",
      "Ilambazar",
      "Islampur",
      "Itahar",
      "Itinda",
      "Jadupur",
      "Jafarpur",
      "Jagadanandapur",
      "Jagadishpur",
      "Jagatballavpur",
      "Jagatnagar",
      "Jagijhora Barabak",
      "Jagtaj",
      "Jala Kendua",
      "Jallabad",
      "Jalpaiguri",
      "Jaluidanga",
      "Jamuria",
      "Janai",
      "Jangalpara",
      "Jangalpara, Hugli District",
      "Jangipur",
      "Jateshwar",
      "Jaygaon",
      "Jaykrishnapur",
      "Jaynagar Mazilpur",
      "Jaypur",
      "Jemari",
      "Jetia",
      "Jhalda",
      "Jhanti Pahari",
      "Jhargram",
      "Jhorhat",
      "Jiaganj Azimganj",
      "Jirat",
      "Joka",
      "Joypul",
      "Jujarsaha",
      "Kachu Pukur",
      "Kaijuri",
      "Kajora",
      "Kakdihi",
      "Kakramari",
      "Kalara",
      "Kalaria",
      "Kalas North",
      "Kaliaganj",
      "Kalikapota",
      "Kalikapur",
      "Kalimpong",
      "Kalipur",
      "Kalipur Barddhaman District",
      "Kalna",
      "Kalyani",
      "Kalyanpur",
      "Kamalapur",
      "Kamat Phulbari",
      "Kanaipur",
      "Kanaipur Haora District",
      "Kanchrapara",
      "Kanganbaria",
      "Kanki",
      "Kankuria",
      "Kanpur",
      "Kantaberia",
      "Kantlia",
      "Kanyanagar",
      "Kapashanria",
      "Karari Chandpur",
      "Karia",
      "Karidhya",
      "Karimpur",
      "Kashimnagar",
      "Katwa",
      "Kaugachhi",
      "Kenda",
      "Kendra Khottamdi",
      "Kendua",
      "Kesabpur",
      "Khajutti",
      "Khalia",
      "Khalor",
      "Khandra",
      "Khanpur",
      "Khantora",
      "Kharagpur",
      "Kharar",
      "Khardaha",
      "Khardaha Haora District",
      "Kharibari",
      "Kharsarai",
      "Khatra",
      "Khidirpur",
      "Khidirpur, Murshidabad District",
      "Khorddabamonia",
      "Koch Bihar",
      "Kodalia",
      "Kokapur",
      "Kolaghat",
      "Kolkata",
      "Komarhat",
      "Konardihi",
      "Konnagar",
      "Kotbar",
      "Kotulpur",
      "Koyra",
      "Kriparampur",
      "Krishna Sali",
      "Krishnanagar",
      "Krishnapur",
      "Krishnapur Maldah District",
      "Kshidirpur",
      "Kshirpai",
      "Kulia",
      "Kulihanda",
      "Kulitapara",
      "Kulti",
      "Kurseong",
      "Labhpur",
      "Lagda",
      "Lalpur",
      "Lapara",
      "Laskarpara",
      "Lataguri",
      "Madhyamgram",
      "Madna",
      "Mahadeb Nagar",
      "Mahal",
      "Mahiari",
      "Mahira",
      "Mahishrekha",
      "Mainaguri",
      "Makardaha",
      "Makhal Tala",
      "Malbazar",
      "Malda",
      "Mamrejpur",
      "Manbazar",
      "Mandarbani",
      "Mangalbari",
      "Mangarjung Tea Garden",
      "Manikpur",
      "Manirampur",
      "Mansinhapur",
      "Manushpur",
      "Masat",
      "Masat South Twenty Four",
      "Maslandapur",
      "Mathabhanga",
      "Matialihat",
      "Matiari",
      "Matla",
      "Medinipur",
      "Mejia Thermal Power Township",
      "Mekliganj",
      "Memari",
      "Milki",
      "Minakhan",
      "Mira",
      "Mirdhanga",
      "Mirik",
      "Mirzapur",
      "Mohanpur",
      "Mugkalyan",
      "Muragachha",
      "Murarai",
      "Murshidabad",
      "Murulia",
      "Nababpur",
      "Nabadwip",
      "Nabaghanapur",
      "Nabagram",
      "Nabagram Colony",
      "Nabgram",
      "Nadabhanga",
      "Nagar Changrabandha",
      "Nagdaha",
      "Nahazari",
      "Naihati",
      "Nainan",
      "Naiti",
      "Nalahati",
      "Naldanga",
      "Nalpur",
      "Nandigram",
      "Naridana",
      "Nasaratpur",
      "Nasibpur",
      "Natibpur",
      "Naul",
      "Naupala",
      "Nawapara",
      "Nayabahadurpur",
      "Nebadhai Duttapukur",
      "New Barrackpur",
      "New Town",
      "Nibra",
      "Nischintapur",
      "Nischintapur Paschim Medinipur District",
      "Nokpul",
      "North Barrackpur",
      "Nrisinghapur",
      "Odlabari",
      "Pairagachha",
      "Palashban",
      "Palashi",
      "Panchghara",
      "Panchla",
      "Panchpara",
      "Pandua",
      "Paniara",
      "Panihati",
      "Panuria",
      "Par Patiram",
      "Para",
      "Parangarpar",
      "Paranpara",
      "Parashkol",
      "Parasia",
      "Parganas District",
      "Parota",
      "Paschim Bainan",
      "Paschim Gazipur",
      "Paschim Jitpur",
      "Paschim Panchla",
      "Paschim Punropara",
      "Pashchim Khalna",
      "Patdaha",
      "Patharberia",
      "Patihal",
      "Patuli",
      "Patulia",
      "Phulia",
      "Poali",
      "Podara",
      "Prayagpur",
      "Pujali",
      "Purba Bishnupur",
      "Purbba Narayanpur",
      "Purbba Tajpur",
      "Puruliya",
      "Radhapur",
      "Raghudebbati",
      "Raghunathpur",
      "Raghunathpur, Puruliya District",
      "Raichak",
      "Raigachhi",
      "Raiganj",
      "Raipur",
      "Rajbalhat",
      "Rajnagar",
      "Rajpur Bazar",
      "Rajpur Sonarpur",
      "Ramakantapur",
      "Ramanathpur",
      "Ramchandrapur",
      "Rameswarpur",
      "Ramjibanpur",
      "Ramkrishnapur",
      "Ramnagar",
      "Rampurhat",
      "Ranaghat",
      "Rangabhita",
      "Raniganj",
      "Raninagar Industrial Growth Centre",
      "Raynagar",
      "Rishra",
      "Rongmook Ceder Tea",
      "Ruiya",
      "Sadigachhi",
      "Sahapur",
      "Sahebganj",
      "Sahebpur",
      "Sainthia",
      "Salap",
      "Salar",
      "Samali",
      "Samudragarh",
      "Samuktola",
      "Sangrampur",
      "Sankarpur",
      "Sankrail",
      "Santaldih",
      "Santipur",
      "Santoshpur",
      "Sarenga",
      "Sarpi",
      "Sehara",
      "Serampore",
      "Serpur",
      "Shankara",
      "Shashati",
      "Shilda",
      "Shimulpur",
      "Shyamdhan",
      "Shyampur",
      "Sibnagar",
      "Siduli",
      "Silampur",
      "Siliguri",
      "Simhat",
      "Simla",
      "Simlapal",
      "Singtam Tea Garden",
      "Singur",
      "Sirakol",
      "Sirsha",
      "Sisha-Jumrha",
      "Sobhaganj",
      "Solgohalia",
      "Sonada Khasmahal",
      "Sonamukhi",
      "Sonatikiri",
      "Srimantapur",
      "Sripur",
      "Subarnapur",
      "Sukdal",
      "Sukhiapokhri",
      "Suri",
      "Taki",
      "Talbandha",
      "Taldi",
      "Tamluk",
      "Tarakeswar",
      "Tehatta",
      "Tehatta Nadia District",
      "Teleni Para",
      "Telipara Tea Garden",
      "Tentulkuli",
      "Tisa",
      "Titagarh",
      "Tufanganj",
      "Udang",
      "Ukhra",
      "Uluberia",
      "Uluberia Near Kolkata",
      "Usthi",
      "Uttar Bagdogra",
      "Uttar Bishnupur",
      "Uttar Jhapardaha",
      "Uttar Kamakhyaguri",
      "Uttar Kusum",
      "Uttar Latabari",
      "Uttar Madarihat",
      "Uttar Mahammadpur",
      "Uttar Pirpur",
      "Uttar Raypur",
      "Uttar Satali",
      "Uttarpara Kotrung",
      "WBIIDC Growth Centre Uluberia"
    ]
  },
  { code: "Overseas", name: "Overseas", stateCode: "OV", cities: ["Overseas"] }
];
export const US_STATES_MOCK_DATA: any[] = [
  {
    code: "AL",
    name: "Alabama"
  },
  {
    code: "AK",
    name: "Alaska"
  },
  {
    code: "AS",
    name: "American Samoa"
  },
  {
    code: "AZ",
    name: "Arizona"
  },
  {
    code: "AR",
    name: "Arkansas"
  },
  {
    code: "CA",
    name: "California"
  },
  {
    code: "CO",
    name: "Colorado"
  },
  {
    code: "CT",
    name: "Connecticut"
  },
  {
    code: "DE",
    name: "Delaware"
  },
  {
    code: "DC",
    name: "District of Columbia"
  },
  {
    code: "FL",
    name: "Florida"
  },
  {
    code: "GA",
    name: "Georgia"
  },
  {
    code: "GU",
    name: "Guam"
  },
  {
    code: "HI",
    name: "Hawaii"
  },
  {
    code: "ID",
    name: "Idaho"
  },
  {
    code: "IL",
    name: "Illinois"
  },
  {
    code: "IN",
    name: "Indiana"
  },
  {
    code: "IA",
    name: "Iowa"
  },
  {
    code: "KS",
    name: "Kansas"
  },
  {
    code: "KY",
    name: "Kentucky"
  },
  {
    code: "LA",
    name: "Louisiana"
  },
  {
    code: "ME",
    name: "Maine"
  },
  {
    code: "MD",
    name: "Maryland"
  },
  {
    code: "MA",
    name: "Massachusetts"
  },
  {
    code: "MI",
    name: "Michigan"
  },
  {
    code: "MN",
    name: "Minnesota"
  },
  {
    code: "MS",
    name: "Mississippi"
  },
  {
    code: "MO",
    name: "Missouri"
  },
  {
    code: "MT",
    name: "Montana"
  },
  {
    code: "NE",
    name: "Nebraska"
  },
  {
    code: "NV",
    name: "Nevada"
  },
  {
    code: "NH",
    name: "New Hampshire"
  },
  {
    code: "NJ",
    name: "New Jersey"
  },
  {
    code: "NM",
    name: "New Mexico"
  },
  {
    code: "NY",
    name: "New York"
  },
  {
    code: "NC",
    name: "North Carolina"
  },
  {
    code: "ND",
    name: "North Dakota"
  },
  {
    code: "MP",
    name: "Northern Mariana Islands"
  },
  {
    code: "OH",
    name: "Ohio"
  },
  {
    code: "OK",
    name: "Oklahoma"
  },
  {
    code: "OR",
    name: "Oregon"
  },
  {
    code: "PA",
    name: "Pennsylvania"
  },
  {
    code: "PR",
    name: "Puerto Rico"
  },
  {
    code: "RI",
    name: "Rhode Island"
  },
  {
    code: "SC",
    name: "South Carolina"
  },
  {
    code: "SD",
    name: "South Dakota"
  },
  {
    code: "TN",
    name: "Tennessee"
  },
  {
    code: "TX",
    name: "Texas"
  },
  {
    code: "UM",
    name: "United States Minor Outlying Islands"
  },
  {
    code: "UT",
    name: "Utah"
  },
  {
    code: "VT",
    name: "Vermont"
  },
  {
    code: "VI",
    name: "Virgin Islands, U.S."
  },
  {
    code: "VA",
    name: "Virginia"
  },
  {
    code: "WA",
    name: "Washington"
  },
  {
    code: "WV",
    name: "West Virginia"
  },
  {
    code: "WI",
    name: "Wisconsin"
  },
  {
    code: "WY",
    name: "Wyoming"
  }
];

export enum COUNTRY_NAME {
  INDIA = "India",
  USA = "United States of America"
}
export enum COUNTRY_CODE {
  INDIA = "IN",
  USA = "US",
  OTHER = "OTHER"
}

/** @description: Use this for internationalization across Application */
export const INTL_KEYWORDS = {
  US: {
    QUOTE: "ESTIMATE",
    Quote: "Estimate",
    quote: "estimate"
  }
};
