import { COLUMN_CODE, TableManger, TABLES } from "./TableManger";
import Utility, { swapArrayElement } from "../utility/Utility";
import Table from "../services/table";
import { USER_ACTION_TYPES } from "../constants/Permission";
import { INPUT_TYPE } from "deskera-ui-library";
import PermissionService from "../services/common/permission";
import { ACTIVITY_STATUS_CODE, FILTER_OPERATORS } from "../constants/Enum";
import { CONTACT_STATUS_ACTIVE } from "../constants/Constant";
import { cloneDeep, isEqual } from "lodash";

export default class ActivityManager {
  static activityList = {};
  static timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  static status = {
    CALL: 1,
    MEETING: 2,
    TASK: 3,
    DEADLINE: 4,
    EMAIL: 5,
    LUNCH: 6
  };

  static getActivityTypeColor = (type) => {
    switch (type) {
      case ActivityManager.status.CALL:
        return "data-grid-badge-color-6";
      case ActivityManager.status.TASK:
        return "data-grid-badge-color-5";
      case ActivityManager.status.MEETING:
        return "data-grid-badge-color-7";
      case ActivityManager.status.EMAIL:
        return "data-grid-badge-color-8";
      case ActivityManager.status.LUNCH:
        return "data-grid-badge-color-2";
      case ActivityManager.status.DEADLINE:
        return "data-grid-badge-color-10";
      default:
        return "data-grid-badge-color-1";
    }
  };

  static getQuery(type, id = 0) {
    let query = {};
    if (id !== 0)
      query = {
        colId: ActivityManager.getColumnId(COLUMN_CODE.ACTIVITY.LINKED_TO),
        value: [id],
        opr: "in"
      };

    let date = new Date();
    let todayDateString = date.toISOString().substring(0, 10) + "T00:00:00Z";
    let addDate = date.setDate(date.getDate() + 1);
    let nextDateString =
      new Date(addDate).toISOString().substring(0, 10) + "T00:00:00Z";
    let todayQuery = {
      logicalOperator: "and",
      conditions: [
        query,
        {
          colId: ActivityManager.getColumnId(COLUMN_CODE.ACTIVITY.START_DATE),
          value: todayDateString,
          opr: "gte"
        },
        {
          colId: ActivityManager.getColumnId(COLUMN_CODE.ACTIVITY.START_DATE),
          value: nextDateString,
          opr: "lt"
        }
      ]
    };
    let pastQuery = {
      logicalOperator: "and",
      conditions: [
        query,
        {
          colId: ActivityManager.getColumnId(COLUMN_CODE.ACTIVITY.START_DATE),
          value: todayDateString,
          opr: "lte"
        }
      ]
    };
    let futureQuery = {
      logicalOperator: "and",
      conditions: [
        query,
        {
          colId: ActivityManager.getColumnId(COLUMN_CODE.ACTIVITY.START_DATE),
          value: nextDateString,
          opr: "gte"
        }
      ]
    };
    // let todayQuery = { "logicalOperator": "and", "conditions": [{ colId: ActivityManager.getColumnId(COLUMN_CODE.ACTIVITY.LINKED_TO), value: [id], opr: "in" }, { colId: ActivityManager.getColumnId(COLUMN_CODE.ACTIVITY.START_DATE), value: todayDateString, opr: "gte" }, { colId: ActivityManager.getColumnId(COLUMN_CODE.ACTIVITY.START_DATE), value: nextDateString, opr: "lt" }] }
    // let pastQuery = { "logicalOperator": "and", "conditions": [{ colId: ActivityManager.getColumnId(COLUMN_CODE.ACTIVITY.LINKED_TO), value: [id], opr: "in" }, { colId: ActivityManager.getColumnId(COLUMN_CODE.ACTIVITY.START_DATE), value: todayDateString, opr: "lte" }] }
    // let futureQuery = { "logicalOperator": "and", "conditions": [{ colId: ActivityManager.getColumnId(COLUMN_CODE.ACTIVITY.LINKED_TO), value: [id], opr: "in" }, { colId: ActivityManager.getColumnId(COLUMN_CODE.ACTIVITY.START_DATE), value: nextDateString, opr: "gte" }] }

    let personQuery = {
      logicalOperator: "and",
      conditions: [
        {
          colId: ActivityManager.getColumnId(
            COLUMN_CODE.CONTACT.TYPE,
            TABLES.CONTACT
          ),
          value: "1",
          opr: "c"
        }
      ]
    };

    let orgQuery = {
      logicalOperator: "and",
      conditions: [
        {
          colId: ActivityManager.getColumnId(
            COLUMN_CODE.CONTACT.TYPE,
            TABLES.CONTACT
          ),
          value: "2",
          opr: "c"
        }
      ]
    };

    switch (type) {
      case "todayQuery":
        return todayQuery;
      case "pastQuery":
        return pastQuery;
      case "futureQuery":
        return futureQuery;
      case "personQuery":
        return personQuery;
      case "orgQuery":
        return orgQuery;
      default:
        return;
    }
  }
  static getTableId() {
    return TableManger.getTableId(TABLES.ACTIVITY);
  }

  static getColumnId(columnCode, table) {
    return TableManger.getColumnId(table ? table : TABLES.ACTIVITY, columnCode);
  }

  static createRequestJson(formData) {
    let data = {};
    let startDate = new Date(
      ActivityManager.getFieldData(formData, COLUMN_CODE.ACTIVITY.START_DATE)
    );
    let endDate = new Date(
      ActivityManager.getFieldData(formData, COLUMN_CODE.ACTIVITY.END_DATE)
    );
    data[this.getColumnId(COLUMN_CODE.ACTIVITY.NAME)] =
      ActivityManager.getFieldData(formData, COLUMN_CODE.ACTIVITY.NAME);
    data[this.getColumnId(COLUMN_CODE.ACTIVITY.ACTIVTY_TYPE)] =
      ActivityManager.getFieldData(formData, COLUMN_CODE.ACTIVITY.ACTIVTY_TYPE);
    data[this.getColumnId(COLUMN_CODE.ACTIVITY.START_DATE)] =
      startDate.toISOString();
    data[this.getColumnId(COLUMN_CODE.ACTIVITY.END_DATE)] =
      endDate.toISOString();
    data[this.getColumnId(COLUMN_CODE.ACTIVITY.NOTES)] =
      ActivityManager.getFieldData(formData, COLUMN_CODE.ACTIVITY.NOTES);
    data[this.getColumnId(COLUMN_CODE.ACTIVITY.ASSIGNEE_IDS)] =
      Utility.isEmptyObject(
        ActivityManager.getFieldData(
          formData,
          COLUMN_CODE.ACTIVITY.ASSIGNEE_IDS
        )
      )
        ? []
        : ActivityManager.getFieldData(
            formData,
            COLUMN_CODE.ACTIVITY.ASSIGNEE_IDS
          );
    data[this.getColumnId(COLUMN_CODE.ACTIVITY.CONTACT_IDS)] =
      Utility.isEmptyObject(
        ActivityManager.getFieldData(formData, COLUMN_CODE.ACTIVITY.CONTACT_IDS)
      )
        ? []
        : ActivityManager.getFieldData(
            formData,
            COLUMN_CODE.ACTIVITY.CONTACT_IDS
          );
    data[this.getColumnId(COLUMN_CODE.ACTIVITY.ORGANIZATION_IDS)] =
      Utility.isEmptyObject(
        ActivityManager.getFieldData(
          formData,
          COLUMN_CODE.ACTIVITY.ORGANIZATION_IDS
        )
      )
        ? []
        : ActivityManager.getFieldData(
            formData,
            COLUMN_CODE.ACTIVITY.ORGANIZATION_IDS
          );
    data[this.getColumnId(COLUMN_CODE.ACTIVITY.LINKED_TO)] =
      Utility.isEmptyObject(
        ActivityManager.getFieldData(formData, COLUMN_CODE.ACTIVITY.LINKED_TO)
      )
        ? []
        : ActivityManager.getFieldData(
            formData,
            COLUMN_CODE.ACTIVITY.LINKED_TO
          );
    data[this.getColumnId(COLUMN_CODE.ACTIVITY.STATUS)] =
      ActivityManager.getFieldData(formData, COLUMN_CODE.ACTIVITY.STATUS);
    data[this.getColumnId(COLUMN_CODE.ACTIVITY.SEND_INVITATION)] = false;
    data[this.getColumnId(COLUMN_CODE.ACTIVITY.SEND_CONTACTS_INVITATION)] =
      false;
    data[this.getColumnId(COLUMN_CODE.ACTIVITY.OWNER_ID)] =
      Utility.isEmptyObject(
        ActivityManager.getFieldData(formData, COLUMN_CODE.ACTIVITY.OWNER_ID)
      )
        ? []
        : ActivityManager.getFieldData(formData, COLUMN_CODE.ACTIVITY.OWNER_ID);
    data[this.getColumnId(COLUMN_CODE.ACTIVITY.ACCOUNT_ID)] =
      Utility.isEmptyObject(
        ActivityManager.getFieldData(formData, COLUMN_CODE.ACTIVITY.ACCOUNT_ID)
      )
        ? []
        : ActivityManager.getFieldData(
            formData,
            COLUMN_CODE.ACTIVITY.ACCOUNT_ID
          );
    data[this.getColumnId(COLUMN_CODE.ACTIVITY.TIMEZONE)] = this.timezone;
    return data;
  }

  static getFieldData(data, key, innerFieldKey = null) {
    let value = data.find((field) => field.key === key);
    let fieldData = "";
    if (!Utility.isEmptyObject(value)) {
      fieldData = value.value;
    }

    if (innerFieldKey && !Utility.isEmptyObject(value)) {
      fieldData = value[innerFieldKey];
    }

    return fieldData;
  }

  /* Requires activities in page-record response format, for proper owner permission handling:
    i.e [ {_id, cells, permissions, ...} ]
   */
  static transformActivityData = (activities) => {
    let activityList = [];
    let pendingCount = 0;

    /* Activity module level permissions */
    const allowActivityEdit = PermissionService.getInstance().isUserPermitted(
      TABLES.ACTIVITY,
      [USER_ACTION_TYPES.REC_UPDATE]
    );
    const allowActivityDelete = PermissionService.getInstance().isUserPermitted(
      TABLES.ACTIVITY,
      [USER_ACTION_TYPES.REC_DELETE]
    );

    activities.forEach((activity) => {
      let fields = {
        allowToEdit: Boolean(allowActivityEdit),
        allowToDelete: Boolean(allowActivityDelete),
        customFieldData: this.getCustomFieldsData(activity.cells),
        id: activity._id,
        [COLUMN_CODE.ACTIVITY.NAME]:
          activity.cells[this.getColumnId(COLUMN_CODE.ACTIVITY.NAME)],
        [COLUMN_CODE.ACTIVITY.ACTIVTY_TYPE]:
          activity.cells[this.getColumnId(COLUMN_CODE.ACTIVITY.ACTIVTY_TYPE)],
        [COLUMN_CODE.ACTIVITY.START_DATE]: new Date(
          activity.cells[this.getColumnId(COLUMN_CODE.ACTIVITY.START_DATE)]
        ),
        [COLUMN_CODE.ACTIVITY.END_DATE]: new Date(
          activity.cells[this.getColumnId(COLUMN_CODE.ACTIVITY.END_DATE)]
        ),
        [COLUMN_CODE.ACTIVITY.NOTES]:
          activity.cells[this.getColumnId(COLUMN_CODE.ACTIVITY.NOTES)],
        [COLUMN_CODE.ACTIVITY.ASSIGNEE_IDS]:
          activity.cells[this.getColumnId(COLUMN_CODE.ACTIVITY.ASSIGNEE_IDS)],
        [COLUMN_CODE.ACTIVITY.CONTACT_IDS]:
          activity.cells[this.getColumnId(COLUMN_CODE.ACTIVITY.CONTACT_IDS)],
        [COLUMN_CODE.ACTIVITY.CONTACT_IDS + "_detail"]:
          activity.cells[
            this.getColumnId(COLUMN_CODE.ACTIVITY.CONTACT_IDS) + "_detail"
          ] || [],
        [COLUMN_CODE.ACTIVITY.ORGANIZATION_IDS]:
          activity.cells[
            this.getColumnId(COLUMN_CODE.ACTIVITY.ORGANIZATION_IDS)
          ] || [],
        [COLUMN_CODE.ACTIVITY.ORGANIZATION_IDS + "_detail"]:
          activity.cells[
            this.getColumnId(COLUMN_CODE.ACTIVITY.ORGANIZATION_IDS) + "_detail"
          ],
        [COLUMN_CODE.ACTIVITY.LINKED_TO]:
          activity.cells[this.getColumnId(COLUMN_CODE.ACTIVITY.LINKED_TO)],
        [COLUMN_CODE.ACTIVITY.LINKED_TO + "_detail"]:
          activity.cells[
            this.getColumnId(COLUMN_CODE.ACTIVITY.LINKED_TO) + "_detail"
          ] || [],
        [COLUMN_CODE.ACTIVITY.STATUS]:
          activity.cells[this.getColumnId(COLUMN_CODE.ACTIVITY.STATUS)],
        [COLUMN_CODE.ACTIVITY.SEND_INVITATION]:
          activity.cells[
            this.getColumnId(COLUMN_CODE.ACTIVITY.SEND_INVITATION)
          ],
        [COLUMN_CODE.ACTIVITY.SEND_CONTACTS_INVITATION]:
          activity.cells[
            this.getColumnId(COLUMN_CODE.ACTIVITY.SEND_CONTACTS_INVITATION)
          ],
        [COLUMN_CODE.ACTIVITY.OWNER_ID]:
          activity.cells[this.getColumnId(COLUMN_CODE.ACTIVITY.OWNER_ID)],
        [COLUMN_CODE.ACTIVITY.ACCOUNT_ID]:
          activity.cells[this.getColumnId(COLUMN_CODE.ACTIVITY.ACCOUNT_ID)],
        [COLUMN_CODE.ACTIVITY.ACCOUNT_ID + "_detail"]:
          activity.cells[
            this.getColumnId(COLUMN_CODE.ACTIVITY.ACCOUNT_ID) + "_detail"
          ] || []
      };
      if (
        activity.cells[this.getColumnId(COLUMN_CODE.ACTIVITY.STATUS)][0] === 2
      ) {
        pendingCount = pendingCount + 1;
      }
      activityList.push(fields);
    });

    return { activitylist: activityList, pendingCount };
  };

  static dealData = (deals) => {
    let deallist = [];
    deals.forEach((deal) => {
      let fields = {
        id: deal._id,
        [COLUMN_CODE.DEAL.NAME]:
          deal.cells[this.getColumnId(COLUMN_CODE.DEAL.NAME, TABLES.DEAL)],
        color: "data-grid-badge-color-1",
        accountId:
          deal.cells[this.getColumnId(COLUMN_CODE.DEAL.ACCOUNT_ID, TABLES.DEAL)]
      };
      deallist.push(fields);
    });
    return deallist;
  };

  static contactData = (contacts) => {
    let contactlist = [];
    const statusColumnId = TableManger.getColumnId(
      TABLES.CONTACT,
      COLUMN_CODE.CONTACT.STATUS
    );
    contacts = contacts?.filter(
      (contact) =>
        contact.cells?.[statusColumnId]?.[0] === CONTACT_STATUS_ACTIVE
    );
    contacts.forEach((contact) => {
      let fields = {
        id: contact._id,
        [COLUMN_CODE.CONTACT.NAME]:
          contact.cells[
            this.getColumnId(COLUMN_CODE.CONTACT.NAME, TABLES.CONTACT)
          ],
        color: "data-grid-badge-color-1",
        [COLUMN_CODE.CONTACT.EMAIL]:
          contact.cells[
            this.getColumnId(COLUMN_CODE.CONTACT.EMAIL, TABLES.CONTACT)
          ],
        accountId:
          contact.cells[
            this.getColumnId(COLUMN_CODE.CONTACT.ACCOUNT, TABLES.CONTACT)
          ]
      };
      contactlist.push(fields);
    });
    return contactlist;
  };

  static getActivityType = (type) => {
    switch (type) {
      case ActivityManager.status.CALL:
        return "Call";
      case ActivityManager.status.TASK:
        return "Task";
      case ActivityManager.status.MEETING:
        return "Meeting";
      case ActivityManager.status.EMAIL:
        return "Email";
      case ActivityManager.status.LUNCH:
        return "Lunch";
      case ActivityManager.status.DEADLINE:
        return "Deadline";
      default:
        return "Call";
    }
  };
  static getActivitiesByRecord(recordId = 0, reCall = false) {
    let req = { pageNo: 1, pageSize: 500 };
    if (this.activityList[recordId] && !reCall && recordId !== 0) {
      return Promise.resolve(this.activityList[recordId]);
    } else {
      return Promise.all([
        Table.getRecordsByPage(
          req,
          ActivityManager.getQuery("todayQuery", recordId),
          TableManger.getTableId(TABLES.ACTIVITY)
        ),
        Table.getRecordsByPage(
          req,
          ActivityManager.getQuery("pastQuery", recordId),
          TableManger.getTableId(TABLES.ACTIVITY)
        ),
        Table.getRecordsByPage(
          req,
          ActivityManager.getQuery("futureQuery", recordId),
          TableManger.getTableId(TABLES.ACTIVITY)
        )
      ]).then((res) => {
        this.setActivityByRecords(recordId, res);
        return Promise.resolve(res);
      });
    }
  }

  static getFilterableColumns = () => {
    return (
      TableManger.getTableVisibleColumns(TABLES.ACTIVITY)
        ?.filter((obj) => {
          return (
            obj.type &&
            obj.type !== INPUT_TYPE.BUTTON &&
            obj.type !== INPUT_TYPE.MORE &&
            (obj.allowFilter ?? true)
          );
        })
        .map((obj) => {
          if (
            obj.columnCode ===
            TableManger.getColumnId(
              TABLES.ACTIVITY,
              COLUMN_CODE.ACTIVITY.OWNER_ID
            )
          ) {
            return {
              ...obj,
              allowFilter: false
            };
          }
          return obj;
        }) || []
    );
  };

  static setActivityByRecords(recordId, data) {
    this.activityList[recordId] = data;
  }
  static isActivityOverdueFilter(filterCondition) {
    return (
      filterCondition?.colId ===
        TableManger.getColumnId(TABLES.ACTIVITY, COLUMN_CODE.ACTIVITY.STATUS) &&
      filterCondition.value.includes(ACTIVITY_STATUS_CODE.OVERDUE)
    );
  }
  static getActivityReportQuery = () => {
    return [
      {
        colId: TableManger.getColumnId(
          TABLES.ACTIVITY,
          COLUMN_CODE.ACTIVITY.STATUS
        ),
        value: [ACTIVITY_STATUS_CODE.PENDING],
        opr: FILTER_OPERATORS.CONTAINS
      },
      {
        colId: TableManger.getColumnId(
          TABLES.ACTIVITY,
          COLUMN_CODE.ACTIVITY.END_DATE
        ),
        value: new Date().toISOString(),
        opr: FILTER_OPERATORS.LESS_THAN
      }
    ];
  };

  static getCustomFieldsData(data) {
    let customFieldData = [];
    let columns = TableManger.getTableColumns(TABLES.ACTIVITY);
    let customFields = columns.filter((col) => !col.columnCode);
    customFields?.forEach((field) => {
      customFieldData.push({ [field.id]: data[field.id] });
    });
    return customFieldData;
  }

  static updateReqAsCustomFieldData = (req, customFields) => {
    let columns = TableManger.getTableColumns(TABLES.ACTIVITY);
    customFields?.forEach((field) => {
      if (
        field.type === INPUT_TYPE.SELECT ||
        field.type === INPUT_TYPE.MULTI_SELECT
      ) {
        let columnData = columns.find((col) => col.id === field.id);
        let customValueIds = [];
        field.value?.forEach((value) => {
          customValueIds.push(columnData?.options[value]?.id);
        });
        req[field.id] = customValueIds;
      } else {
        if (!Utility.isEmptyObject(field.lookup)) {
          req[field.id] = field.value?.id ? [field.value?.id] : null;
        } else {
          req[field.id] = field.value;
        }
      }
    });
    return req;
  };

  static getDataToUpdate = (data) => {
    let columns = TableManger.getTableColumns(TABLES.ACTIVITY);
    data?.forEach((field) => {
      if (
        field.type === INPUT_TYPE.SELECT ||
        field.type === INPUT_TYPE.MULTI_SELECT
      ) {
        let columnData = columns.find((col) => col.id === field.id);
        let customValueIds = [];
        customValueIds = field.value?.map((r) =>
          columnData?.options.findIndex((op) => op.id === r)
        );
        field.value = customValueIds;
      }
    });
  };

  static validateCustomFields = (customFields) => {
    let valid = true;
    customFields.forEach((field) => {
      if (field.required !== false) {
        if (
          field.value === null ||
          field.value === undefined ||
          field.value === ""
        ) {
          valid = false;
        } else if (
          typeof field.value === "string" &&
          field.value.trim() === ""
        ) {
          valid = false;
        } else if (
          field.type === INPUT_TYPE.EMAIL &&
          !Utility.isValidEmail(field.value)
        ) {
          valid = false;
        } else if (field.type === INPUT_TYPE.NUMBER && isNaN(field.value)) {
          valid = false;
        } else if (
          (field.type === INPUT_TYPE.SELECT ||
            field.type === INPUT_TYPE.MULTI_SELECT) &&
          field.value.length === 0
        ) {
          valid = false;
        } else if (field.type === INPUT_TYPE.DATE) {
          valid = false;
        }
      }
      if (
        field.type === INPUT_TYPE.EMAIL &&
        field.value !== undefined &&
        field.value !== null &&
        field.value.length > 0 &&
        !Utility.isValidEmail(field.value)
      ) {
        valid = false;
      }
    });
    return valid;
  };

  static accountData = (accountData) => {
    let accountList = [];
    accountData?.forEach((account) => {
      let fields = {
        id: account._id,
        [COLUMN_CODE.ACCOUNT.NAME]:
          account.cells[
            this.getColumnId(COLUMN_CODE.ACCOUNT.NAME, TABLES.ACCOUNT)
          ],
        color: "data-grid-badge-color-1"
      };
      accountList.push(fields);
    });
    return accountList;
  };

  static compareIndexOfPrevDay = (
    prevDayActivities,
    currentDayActivities,
    currentDay,
    isStartOfWeek
  ) => {
    let activitySortList = cloneDeep(currentDayActivities);

    if (isStartOfWeek) {
      return activitySortList.filter((act) => !Utility.isEmptyObject(act));
    }

    if (prevDayActivities?.length === 0) {
      return currentDayActivities;
    }

    //Sorted as per date in current date
    activitySortList.sort((a, b) => {
      return new Date(b.end_date).getDate() - new Date(a.end_date).getDate();
    });

    //If prev Day activities are present, match index of prev day
    if (prevDayActivities?.length > 0) {
      let commonElements = activitySortList.filter((o1) =>
        prevDayActivities.some((o2) => o1.id === o2.id)
      );

      let newActivityList = [...prevDayActivities];
      let existingActivityList = [];
      existingActivityList = activitySortList
        ?.map((list) =>
          commonElements?.findIndex((ele) => ele?.id === list?.id) === -1
            ? list
            : {}
        )
        ?.filter((item) => !Utility.isEmptyObject(item));

      newActivityList = newActivityList?.map((list) =>
        activitySortList?.findIndex((ele) => ele?.id === list?.id) !== -1
          ? list
          : {}
      );

      newActivityList = [...newActivityList, ...existingActivityList];
      activitySortList = cloneDeep(newActivityList);
    }

    if (activitySortList?.length > 0) {
      activitySortList = activitySortList?.map((activity) =>
        !Utility.isEmptyObject(activity) ? activity : {}
      );
      let isEmptyBar = activitySortList?.some((activity) =>
        Utility.isEmptyObject(activity)
      );
      let filterCurrentDateAsStartDate = activitySortList?.filter(
        (activity) => new Date(activity.start_date).getDate() === currentDay
      );
      //Prev activity index not matching with current activity swapped
      if (isEmptyBar && filterCurrentDateAsStartDate?.length > 0) {
        filterCurrentDateAsStartDate?.forEach((swapElement) => {
          let existingBlankIndex = null;
          for (let i = 0; i < activitySortList?.length; i++) {
            if (Utility.isEmptyObject(activitySortList[i])) {
              existingBlankIndex = i;
              break;
            }
          }

          let existingElementIndex = activitySortList?.findIndex(
            (activity) => activity.id === swapElement.id
          );

          activitySortList = swapArrayElement(
            activitySortList,
            existingBlankIndex,
            existingElementIndex
          );
        });
      }

      if (isEmptyBar && activitySortList?.length > 4) {
        activitySortList = this.swapAndRemoveBlankActivity(activitySortList);
      }
    }
    return activitySortList;
  };

  static swapAndRemoveBlankActivity = (activity) => {
    let swapActivityWithEmptyBar = [...activity]?.reverse();
    let actValueIndex = null;
    let blankIndex = null;

    //If empty activity swap with last index activity
    for (let index = 0; index < swapActivityWithEmptyBar.length; index++) {
      if (actValueIndex !== null && blankIndex !== null) {
        swapActivityWithEmptyBar = swapArrayElement(
          swapActivityWithEmptyBar,
          actValueIndex,
          blankIndex
        );
        break;
      } else {
        if (Utility.isEmptyObject(swapActivityWithEmptyBar[index])) {
          blankIndex = index;
        }
        if (!Utility.isEmptyObject(swapActivityWithEmptyBar[index])) {
          actValueIndex = index;
        }
      }
    }
    //Remove blank activity at end
    let removeIndex = [];
    for (let index = 0; index < swapActivityWithEmptyBar?.length; index++) {
      if (Utility.isEmptyObject(swapActivityWithEmptyBar[index])) {
        removeIndex.push(index);
      } else {
        break;
      }
    }
    if (removeIndex?.length > 0) {
      removeIndex?.reverse()?.forEach((index) => {
        swapActivityWithEmptyBar?.splice(index, 1);
      });
    }
    swapActivityWithEmptyBar.reverse();
    if (isEqual(swapActivityWithEmptyBar, activity)) {
      return swapActivityWithEmptyBar;
    } else {
      return this.swapAndRemoveBlankActivity(swapActivityWithEmptyBar);
    }
  };
}
