import { DKIcon, DKLabel, DKLine } from "deskera-ui-library";
import { Component } from "react";
import { connect } from "react-redux";
import ic_profile_pic from "../../assets/menu/ic_profile_pic.png";
import ApiConstants from "../../constants/ApiConstants";
import { NEW_SETTINGS_SECTION, SETTING_MODULE } from "../../constants/Constant";
import { USER_ACTION_TYPES } from "../../constants/Permission";
import AppManager from "../../managers/AppManager";
import UserManager from "../../managers/UserManager";
import { selectPermissionsByModule } from "../../redux/slices/rolesPermissionSlice";
import Utility from "../../utility/Utility";
import { TABLES } from "../../managers/TableManger";
import {
  setSelectedSetting,
  setSettingPopup
} from "../../redux/slices/userPrefSlice";
import { SETTING_CARD_IDS } from "../settings/Settings";

class ProfileOptionsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.onClose) {
      document.addEventListener("mouseup", this.onMouseUp);
    }
  }
  componentWillUnmount() {
    if (this.props.onClose) {
      document.removeEventListener("mouseup", this.onMouseUp);
    }
  }
  onMouseUp = (e) => {
    if (this.props.onClose) {
      const target = e.target;
      const editingContainer = target.closest(
        "#profile-options-section, #dk-alert, #dk-alert-background"
      );

      if (!editingContainer) {
        this.props.onClose();
      }
    }
  };

  render() {
    let options = [
      {
        title: "My Profile",
        url: ApiConstants.PRODUCT_URL_GO
      }
    ];

    !!this.props.settingPermissions[USER_ACTION_TYPES.ORGANIZATION] &&
      options.push({
        title: "Organization Info",
        url: ApiConstants.PRODUCT_URL_GO + "settings"
      });

    !!this.props.settingPermissions[USER_ACTION_TYPES.USER_PERMISSION] &&
      !Utility.isEmptyObject(
        this.props.moduleWisePermissions[TABLES.PERMISSION]
      ) &&
      options.push({
        title: "User Management",
        url: "settings",
        queryParams: `cardId=${SETTING_CARD_IDS.USER_PERMISSION}`
      });

    !!this.props.settingPermissions[USER_ACTION_TYPES.SUBSCRIPTION_BILLING] &&
      options.push({
        title: "Subscription & Billing",
        url: ApiConstants.PRODUCT_URL_GO + "billing"
      });

    options.push({
      title: "Settings",
      url: "settings"
    });

    return (
      <div
        id="profile-options-section"
        className="column position-absolute bg-white shadow-l border-radius-m border-m pb-m"
        style={{ top: 40, right: 0, width: 300 }}
      >
        {this.getProfileSection(true)}
        <DKLine className="mb-r" />
        {options.map((option) => (
          <div
            className="cursor-hand parent-width listPickerBG"
            onClick={
              option.url === "settings"
                ? () => {
                    this.props.onClose();
                    this.props.setSelectedSetting(
                      option.title === "Settings"
                        ? NEW_SETTINGS_SECTION.ORGANISATION_SETTINGS
                        : NEW_SETTINGS_SECTION.USER_PERMISSIONS
                    );
                    this.props.setSettingPopup(true);
                  }
                : () => {
                    this.props.onClose();
                    window.open(option.url);
                  }
            }
          >
            <DKLabel text={option.title} className="p-h-r p-v-s" />
          </div>
        ))}

        <DKLine className="mb-r mt-r" />
        <div
          className="cursor-hand parent-width listPickerBG"
          onClick={() => {
            this.props.onClose();
            AppManager.showLogoutConfirmation();
          }}
        >
          <DKLabel text="Sign Out" className="p-h-r p-v-s text-red" />
        </div>
      </div>
    );
  }

  getProfileSection(detailedView = false) {
    const loggedInUserInfo = this.props?.usersInfo?.content?.find(
      (user) => user.id === UserManager.getUserID()
    );
    return (
      <div
        className={
          "row border-radius-m p-m " + (!detailedView ? "cursor-hand" : "")
        }
        style={{ backgroundColor: "rgba(255, 255, 255, 0.08)" }}
        onClick={
          detailedView
            ? null
            : () => {
                this.setState({ needProfileOptionsSection: true });
              }
        }
      >
        <DKIcon
          src={ic_profile_pic}
          className="circle ic-m shadow-s"
          style={{ opacity: 0.7 }}
        />
        {/* <DKContactIcon
          title={UserManager.getUserName()}
          className="text-white bg-app flex-shrink-0"
        /> */}
        <div className={" ml-r " + (!detailedView ? "text-white" : "")}>
          <DKLabel
            text={`${loggedInUserInfo?.firstName || ""} ${loggedInUserInfo?.lastName || ""}`}
            style={{
              opacity: !detailedView ? 0.9 : 1,
              wordBreak: "break-all"
            }}
          />
          <DKLabel
            text={loggedInUserInfo?.email || ""}
            className="mt-xs"
            style={{ opacity: 0.5, wordBreak: "break-all" }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  usersInfo: state.tenant.usersInfo,
  settingPermissions: selectPermissionsByModule(state, SETTING_MODULE),
  moduleWisePermissions:
    state.rolesPermission?.currentUserModuleWisePermissions || {}
});
const mapDispatchToProps = {
  setSettingPopup,
  setSelectedSetting
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileOptionsSection);
