import React, { Component } from "react";
import {
  DKButton,
  DKInput,
  INPUT_TYPE,
  showLoader,
  removeLoader,
  DKLabel,
  DKIcons,
  showToast,
  TOAST_TYPE,
  DKIcon
} from "deskera-ui-library";
import EmailEditor from "../../email_editor/EmailEditor_Quill";
import ContactService from "../../../services/contact";
import Utility from "../../../utility/Utility";
import { APP_NAME } from "../../../constants/Constant";
import ic_send from "../../../assets/mail_icons/ic_send.png";
import { FILTER_OPERATORS, TICKET_STATUS } from "../../../constants/Enum";
import {
  COLUMN_CODE,
  TABLES,
  TableManger
} from "../../../managers/TableManger";
import ApiConstants from "../../../constants/ApiConstants";
import Table from "../../../services/table";
import { DealManager } from "../../../managers/DealManager";
import { store } from "../../../redux/store";
export default class EmailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canValidate: false,
      contactId: this.props.contactId,
      formData: null,
      emailConfig: this.props.emailConfig,
      threadData: this.props.thread || null,
      contactDetail: this.props.contactDetail || null,
      supportEmailConfig: this.props.config || null,
      showPopup: false,
      disable: this.props?.disableReply,
      saveButtonTapped: false,
      selectedCCEmailIndex: [],
      statusIndex: 0,
      ccFields: [
        {
          key: "field1",
          title: "CC email",
          type: INPUT_TYPE.EMAIL,
          value: ""
        }
      ],
      showCCPopup: false,
      ccEmails: [],
      toEmails: []
    };
    this.loadingForContactDetails = false;
    this.retrieveEditorBodyCallback = null;
  }

  componentDidMount() {
    if (this.state.threadData) {
      this.setDataForEmail();
    } else if (this.state.contactDetail) {
      this.saveFormData("recipient_emails", this.state.contactDetail.email);
    } else if (this.state.supportEmailConfig) {
      let dataToSend = {
        recipient_emails: this.state.supportEmailConfig?.email,
        subject: this.state.supportEmailConfig?.subject,
        saveButtonTapped: this.state.supportEmailConfig.saveButtonTapped,
        ccEmails: this.state.supportEmailConfig?.CCEmails.toString()
      };
      this.setState({
        formData: { ...this.state.formData, ...dataToSend }
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(
      {
        emailConfig: nextProps.emailConfig,
        threadData: nextProps.thread,
        contactDetail: nextProps.contactDetail,
        supportEmailConfig: nextProps.config,
        saveButtonTapped: nextProps.saveButtonTapped,
        disable: nextProps.disableReply
      },
      () => {
        if (this.state.threadData) {
          this.setDataForEmail();
        } else if (this.state.supportEmailConfig) {
          let dataToSend = {
            recipient_emails: this.state.supportEmailConfig?.email,
            subject: this.state.supportEmailConfig?.subject,
            saveButtonTapped: this.state.supportEmailConfig.saveButtonTapped,
            ccEmails: this.state.supportEmailConfig?.CCEmails.toString()
          };
          if (
            this.state.toEmails?.length !==
              this.state.supportEmailConfig?.email?.length &&
            !this.loadingForContactDetails &&
            !this.isSameArrayValue(
              nextProps.config.email,
              this.state.supportEmailConfig?.email
            )
          ) {
            this.checkEmailWithContact(dataToSend);
          } else {
            this.setState({
              formData: { ...this.state.formData, ...dataToSend }
            });
          }
        }
        if (this.state?.saveButtonTapped) {
          this.emailSave();
        }
      }
    );
  }

  isSameArrayValue = (a, b) =>
    a.length === b.length && a.every((element, index) => element === b[index]);

  removeRequestor = (requestor) => {
    let emails = [...this.state.supportEmailConfig?.email];
    let toEmails = [...this.state.toEmails];
    let index = emails.indexOf(requestor);
    if (index !== -1) {
      emails.splice(index, 1);
      toEmails.splice(index, 1);
      this.setState({ toEmails });
    }
    this.state?.supportEmailConfig?.onRequestorChange(emails, toEmails);
  };

  checkEmailWithContact = (dataToSend) => {
    if (Utility.isEmptyObject(dataToSend?.recipient_emails?.[0])) return;
    let params = { q: dataToSend?.recipient_emails?.[0], fetchAllRef: true };
    let filterPayload = {};
    this.loadingForContactDetails = true;
    showLoader();
    Table.getRecordsByPage(
      params,
      filterPayload,
      TableManger.getTableId(TABLES.CONTACT)
    )
      .then((response) => {
        let contactList = [];
        if (response.data && response.data.length > 0) {
          let contactData = DealManager.contactWithAccountData(response.data);
          contactList = contactData ?? [];
        }

        this.setState({
          formData: { ...this.state.formData, ...dataToSend },
          toEmails: contactList
        });
        this.loadingForContactDetails = false;
        removeLoader();
      })
      .catch((err) => {
        console.error("fetch error", err);
      });
  };

  render() {
    const showActionButtons = this.props.showActionButtons
      ? this.props.showActionButtons
      : Utility.isEmptyObject(this.state?.supportEmailConfig) ||
        this.state?.supportEmailConfig?.hideInput;

    const isReply = this.state.supportEmailConfig?.hideInput;
    const isSupportTicket = !Utility.isEmptyObject(
      this.state.supportEmailConfig
    );

    const hideSubject = this.props.hideSubject
      ? this.props.hideSubject
      : this.state?.supportEmailConfig?.hideInput;

    const emailRequestorFilter =
      store?.getState()?.tenant?.crmSettings?.ticketSetting
        ?.requestorEmailMatchCriteria;
    return (
      <div
        className={`log-email-container column parent-width bg-white border-box border-radius-s mb-m ${
          this.props.className
        }  ${isReply ? "p-h-m email-reply-container" : " p-m"}`}
      >
        {this.state.showCCPopup && this.getCCPopup()}
        {!this.state.supportEmailConfig && (
          <div className="row mb-s">
            <DKLabel
              style={{ whiteSpace: "nowrap", minWidth: 50 }}
              className="p-h-s"
              text="To *"
            />
            <DKInput
              autoFocus={this.state.supportEmailConfig ? false : true}
              type={INPUT_TYPE.EMAIL}
              value={this.state.formData?.recipient_emails}
              required
              readOnly={this.state.supportEmailConfig ? true : false}
              canValidate={this.state.canValidate}
              onChange={(value) => this.saveFormData("recipient_emails", value)}
              errorMessage={`Enter a valid email`}
              className=""
            />
          </div>
        )}
        {!this.state.supportEmailConfig &&
          this.renderCCInput(this.state.contactDetail)}
        {this.state.supportEmailConfig &&
          !this.state?.supportEmailConfig?.hideInput && (
            <div className="row dk-input-holder">
              <DKLabel
                style={{ whiteSpace: "nowrap", minWidth: 50 }}
                className="p-h-s"
                text="To *"
              />
              <DKInput
                autoFocus={true}
                type={INPUT_TYPE.DROPDOWN}
                value={this.state.supportEmailConfig?.email}
                renderer={(value) => {
                  return !Utility.isEmptyObject(value) ? (
                    <div className="row flex-wrap" style={{ gap: 4 }}>
                      {Array.isArray(value) &&
                        value?.map((contact) => (
                          <div className="row width-auto bg-gray1 border-radius-s p-xs border-m">
                            <DKLabel className="" text={contact || "-"} />
                            {!Utility.isEmptyObject(contact) && (
                              <DKIcon
                                src={DKIcons.ic_close_}
                                className={
                                  "ic-s-2 ml-s unselectable cursor-hand"
                                }
                                style={{ height: 10, opacity: 0.5, padding: 1 }}
                                onClick={() => {
                                  this.removeRequestor(contact);
                                }}
                              />
                            )}
                          </div>
                        ))}
                    </div>
                  ) : (
                    ""
                  );
                }}
                canValidate={this.state?.canValidate}
                onChange={(data) => {}}
                required
                dropdownConfig={{
                  allowSearch: true,
                  searchableKey: "name",
                  searchApiConfig: {
                    method: "POST",
                    getUrl: (val) => {
                      return (
                        ApiConstants.URL.BASE +
                        ApiConstants.URL.TABLE.GET_RECORD_BY_PAGE(
                          TableManger.getTableId(TABLES.CONTACT)
                        ) +
                        "?pageNo=1&pageSize=20&fetchAllRef=true&q=" +
                        val
                      );
                    },
                    getPayload: (search) => {
                      let conditions = [
                        {
                          colId: TableManger.getColumnId(
                            TABLES.CONTACT,
                            COLUMN_CODE.CONTACT.EMAIL
                          ),
                          opr: "neq",
                          value: ""
                        }
                      ];
                      if (
                        this.props.isManualAccountSelected &&
                        !Utility.isEmptyObject(
                          this.props.config?.defaultValues?.crm3_account_id
                        )
                      ) {
                        conditions.push({
                          colId: TableManger.getColumnId(
                            TABLES.CONTACT,
                            COLUMN_CODE.CONTACT.ACCOUNT
                          ),
                          opr: "eq",
                          value:
                            this.props.config?.defaultValues?.crm3_account_id
                        });
                      }
                      return {
                        logicalOperator: "and",
                        conditions: conditions
                      };
                    },
                    dataParser: (response) => {
                      let personList = DealManager.contactWithAccountData(
                        response.data
                      );

                      if (
                        emailRequestorFilter?.length > 0 &&
                        (this.props.config?.isNewTicketPopup ?? false)
                      ) {
                        personList = personList?.filter((person) => {
                          let matchFound = false;
                          let personEmail = person.email?.toLowerCase() ?? "";
                          emailRequestorFilter?.forEach((filter) => {
                            let filterEmail = filter.value?.toLowerCase();
                            if (
                              (filter.condition === FILTER_OPERATORS.EQUAL &&
                                filterEmail === personEmail) ||
                              (filter.condition === FILTER_OPERATORS.CONTAINS &&
                                personEmail.includes(filterEmail))
                            ) {
                              matchFound = true;
                            }
                          });
                          return !matchFound;
                        });
                      }
                      return personList;
                    }
                  },
                  button: {
                    title: "New Contact",
                    icon: DKIcons.ic_add_white,
                    onClick: () =>
                      this.state.supportEmailConfig?.showRequestorPopup(),
                    className: "p-xs bg-button text-white"
                  },
                  data: [],
                  renderer: (index, obj) => {
                    return (
                      <div className="column parent-width">
                        <DKLabel text={`${obj.email}`} />
                        <DKLabel
                          text={`${obj.name}`}
                          className="fs-s text-gray"
                        />
                      </div>
                    );
                  },
                  onSelect: (index, value) => {
                    let emails = [...this.state.supportEmailConfig?.email];
                    let toEmails = [...this.state.toEmails];
                    if (
                      emails.findIndex((email) => email === value.email) === -1
                    ) {
                      emails.push(value.email);
                      toEmails.push(value);
                      this.setState({ toEmails });
                    }
                    this.state?.supportEmailConfig?.onRequestorChange(
                      emails,
                      toEmails
                    );
                  }
                }}
                className="mb-s mt-m"
              />
            </div>
          )}
        {this.state.supportEmailConfig &&
          !this.state?.supportEmailConfig?.hideInput &&
          this.renderCCInput(this.state.supportEmailConfig)}
        {!hideSubject && (
          <div className="row mb-l">
            <DKLabel
              style={{ whiteSpace: "nowrap", minWidth: 50 }}
              className="p-h-s"
              text="Subject *"
            />
            <DKInput
              type={INPUT_TYPE.TEXT}
              value={this.state.formData?.subject}
              required
              canValidate={this.state.canValidate}
              onChange={(value) => {
                this.saveFormData("subject", value);
                this.state?.supportEmailConfig?.onSubjectChange(value);
              }}
              className=""
              errorMessage={`Enter a valid subject`}
            />
          </div>
        )}
        <EmailEditor
          editorID="email-editor-body"
          body={this.state.formData?.body}
          tableName={isSupportTicket ? TABLES.TICKET : TABLES.CONTACT}
          height={this.props.config?.height ? this.props.config?.height : 300}
          needEmailTemplateTool={isSupportTicket}
          needEmailPlaceholderTool={isSupportTicket}
          additionalTableNames={isSupportTicket ? [TABLES.CONTACT] : []}
          getBodyToSaveCallback={(callback) =>
            (this.retrieveEditorBodyCallback = callback)
          }
        />
        <div className="row mt-m justify-content-end">
          {showActionButtons && (
            <DKButton
              className="border-m bg-gray1 mr-s"
              title="Cancel"
              onClick={() => {
                this.props.onCancel();
              }}
            />
          )}
          {showActionButtons && (
            <DKButton
              className="bg-button text-white"
              icon={ic_send}
              title="Send"
              disabled={this.state.disable}
              onClick={() => {
                this.emailSave();
              }}
            />
          )}
        </div>
      </div>
    );
  }

  saveFormData(key, value) {
    let formData = { ...this.state.formData };
    formData[key] = value;
    this.setState({ formData: formData }, () => {
      if (key === "ccEmails" && this.state.supportEmailConfig) {
        let emails = this.convertFormCCData(value);
        this.state?.supportEmailConfig?.onCCChange(emails);
      }
    });
  }

  emailSave = () => {
    this.setState({ disable: true });
    let formData = this.getRequestObject();
    this.setState({ canValidate: true });
    if (
      Utility.isEmptyObject(formData.subject) ||
      Utility.isEmptyObject(formData.recipient_emails)
    ) {
      this.setState({ disable: false, saveButtonTapped: false }, () => {
        this.props?.onSaveFailure(this.state.saveButtonTapped);
      });
      return false;
    }

    if (
      !Utility.isEmptyObject(formData.cc_emails) &&
      this.validateEmail(formData.cc_emails)?.length > 0
    ) {
      this.setState({ disable: false, saveButtonTapped: false }, () => {
        this.props?.onSaveFailure(this.state.saveButtonTapped);
      });
      return false;
    }

    if (Utility.isEmptyObject(formData.body)) {
      showToast(`Email body cannot be empty`, TOAST_TYPE.FAILURE);
      this.setState({ disable: false, saveButtonTapped: false }, () => {
        this.props?.onSaveFailure(this.state.saveButtonTapped);
      });
      return false;
    }
    this.onSave(formData);
  };

  getEditorValue() {
    let emailBody = this.retrieveEditorBodyCallback?.(true, true);
    const placeholderData = this.props.config?.getPlaceholderData?.();

    if (!Utility.isEmptyObject(placeholderData)) {
      Object.keys(placeholderData).forEach((key) => {
        const placeholderKey = `%${key}%`;
        if (Array.isArray(placeholderData[key])) {
          emailBody = emailBody.replaceAll(
            placeholderKey,
            placeholderData[key]?.join(",")
          );
        } else {
          emailBody = emailBody.replaceAll(
            placeholderKey,
            placeholderData[key]
          );
        }
      });
    }

    if (emailBody === "<div><br></div>") {
      return "";
    }

    const html_text = Utility.encodeStringForTemplate(emailBody);
    return html_text;
  }

  getRequestObject() {
    let editorValue = this.getEditorValue();
    let formData = {};
    let emails = [];
    emails.push(this.state.formData.recipient_emails);
    formData["app_name"] = APP_NAME;
    formData["recipient_emails"] = emails;
    formData["cc_emails"] = this.convertFormCCData(
      this.state.formData?.ccEmails || ""
    );
    formData["subject"] = this.state.formData.subject;
    formData["body"] = editorValue;
    formData["crm3_contact_id"] = this.state?.contactId || "";
    formData["sender_email"] = this.state.emailConfig?.email || "";
    formData["sender_name"] = this.state.emailConfig?.username || "";
    formData["attachments"] = [];
    formData["status"] = TICKET_STATUS[this.state.statusIndex];

    if (this.state.threadData !== null && this.state.threadData !== undefined) {
      if (
        this.state.threadData.type === "forward" ||
        this.state.threadData.type === "reply" ||
        this.state.threadData.type === "reply-all"
      ) {
        formData.reply_to_email_ref = this.state.threadData.id;
      }
    }
    return formData;
  }

  onSave(formData) {
    showLoader("Sending... Please wait");
    if (this.state.supportEmailConfig) {
      this.props?.onTicketSave(formData);
      removeLoader();
    } else {
      ContactService.saveEmail(formData)
        .then(() => {
          this.props.onSuccess();
          removeLoader();
        })
        .catch(() => {
          this.props.onCancel();
          removeLoader();
        });
    }
  }

  setDataForEmail(update = true) {
    if (this.state.threadData === null && this.state.threadData === undefined) {
      return;
    }
    let formData = {
      ...this.state.formData
    };
    switch (this.state.threadData.type) {
      case "forward":
        formData.subject = "Fw: " + this.state.threadData.subject;
        formData.body = this.state.threadData.body;
        break;
      case "reply":
        formData.recipient_emails =
          this.state.threadData.sender_email !== this.state.emailConfig.email
            ? this.state.threadData.sender_email
            : this.state.threadData.recipient_emails[0];
        formData.subject = "Re: " + this.state.threadData.subject;
        formData.body =
          "</br>---------------- Reply To ---------------- </br>" +
          this.state.threadData.body;
        break;
      case "reply-all":
        formData.recipient_emails = this.state.threadData.recipient_emails[0];
        formData.subject = "Re: " + this.state.threadData.subject;
        formData.body =
          "</br>---------------- Reply To ---------------- </br>" +
          this.state.threadData.body;
        break;
      default:
        break;
    }
    if (update && this.state.contactDetail?.CCEmails?.length !== 0) {
      formData.ccEmails = this.state.contactDetail?.CCEmails?.toString();
    }
    this.setState({
      formData: formData
    });
  }

  renderCCInput = () => {
    return (
      <div className="row">
        <DKLabel
          style={{ whiteSpace: "nowrap", minWidth: 50 }}
          className="p-h-s"
          text="Cc"
        />
        <DKInput
          type={INPUT_TYPE.EMAIL}
          value={this.state?.formData?.ccEmails}
          required={false}
          canValidate={this.state.canValidate}
          onChange={(value) => this.saveFormData("ccEmails", value)}
          className="mb-l mt-m"
          validator={(value) => {
            const emailValid = this.validateEmailString(value);
            return emailValid.length > 0 ? false : true;
          }}
          errorMessage={"Please enter valid emails"}
        />
      </div>
    );
  };

  validateEmailString = (multipleEmails) => {
    let invalidEmails = [];
    multipleEmails?.split(",")?.forEach((email) => {
      const trimmedEmail = email.trim();
      if (!Utility.isValidEmail(trimmedEmail)) {
        trimmedEmail && invalidEmails.push(trimmedEmail);
        return;
      }
    });
    return invalidEmails;
  };

  validateEmail = (multipleEmails) => {
    let invalidEmails = [];
    multipleEmails?.forEach((email) => {
      const trimmedEmail = email.trim();
      if (!Utility.isValidEmail(trimmedEmail)) {
        trimmedEmail && invalidEmails.push(trimmedEmail);
        return;
      }
    });
    return invalidEmails;
  };

  convertFormCCData = (data) => {
    if (Utility.isEmptyObject(data)) {
      return [];
    }
    let emails = data.split(",");
    return emails;
  };
}
