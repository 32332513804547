import ApiConstants from "../constants/ApiConstants";
import EventManager from "../eventTracker/EventManager";
import { EVENT_TYPE } from "../eventTracker/utility/EventConstants";
import http from "../http";
import { TableManger } from "../managers/TableManger";
import { IContactAddress } from "../model/BooksContact";
import ITable, { IColumn } from "../model/Table";

class Table {
  static getAllViews(): Promise<ITable[]> {
    return http.get(ApiConstants.URL.TABLE.GET_ALL).then(
      (res) => {
        return Promise.resolve(res.data);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }
  static getTable(tableId: string) {
    return http
      .get(`${ApiConstants.URL.TABLE.GET(tableId)}`)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static addColumn(column: IColumn, tableId: string) {
    return http
      .post(`${ApiConstants.URL.TABLE.ADD_COLUMN(tableId)}`, column)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static getRecords(tableId: string) {
    return http
      .get(`${ApiConstants.URL.TABLE.GET_RECORD(tableId)}`)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static getRecordsByPage(params: any, data: any, tableId: string) {
    if (!params) params = { pageNo: 1, pageSize: 20 };
    return http
      .post(`${ApiConstants.URL.TABLE.GET_RECORD_BY_PAGE(tableId)}`, data, {
        params: { ...params, _allowRetry: true }
      })
      .then(
        (response) => {
          response.data = response.data.map((record) => {
            return {
              ...record,
              cells: {
                ...record.cells,
                createdBy: record.createdBy,
                id: record._id
              }
            };
          });
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static addRecord(record: any, tableId: string) {
    EventManager.trackEventByModule(
      TableManger.getTableNameFromId(tableId),
      EVENT_TYPE.CREATED,
      record
    );
    return http
      .post(`${ApiConstants.URL.TABLE.ADD_RECORD(tableId)}`, record)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static updateRecord(data: any, recordId: string, tableId: string) {
    return http
      .put(`${ApiConstants.URL.TABLE.UPDATE_RECORD(recordId, tableId)}`, {
        cells: data
      })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  static getRecordBulk(recordIds: string[], tableId: string, params: any = {}) {
    const urlSearchParams = new URLSearchParams(params).toString();

    return http
      .post(
        `${ApiConstants.URL.TABLE.RECORD_BULK_GET(tableId)}?${urlSearchParams}`,
        recordIds
      )
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  static getRecordById(recordId: string, tableId: string, params: any = {}) {
    EventManager.trackEventByModule(
      TableManger.getTableNameFromId(tableId),
      EVENT_TYPE.VISITED,
      { id: recordId }
    );
    return http
      .get(`${ApiConstants.URL.TABLE.GET_RECORD_BY_ID(recordId, tableId)}`, {
        params
      })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static addBulkRecord(record: any, tableId: string) {
    return http
      .post(`${ApiConstants.URL.TABLE.ADD_BULK_RECORD(tableId)}`, record)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static updateBulkRecord(record: any, tableId: string) {
    // EventManager.trackEventByModule(
    //   TableManger.getTableNameFromId(tableId),
    //   EVENT_TYPE.BULK_UPDATE,
    //   record
    // );
    return http
      .put(`${ApiConstants.URL.TABLE.UPDATE_BULK_RECORD(tableId)}`, record)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static deleteRecord(
    recordId: string,
    tableId: string,
    isForceDelete: boolean = false
  ) {
    // EventManager.trackEventByModule(
    //   TableManger.getTableNameFromId(tableId),
    //   EVENT_TYPE.DELETE,
    //   { id: recordId }
    // );
    let query = "";
    if (isForceDelete) {
      query = `?isForceDelete=${isForceDelete}`;
    }
    return http
      .delete(
        `${ApiConstants.URL.TABLE.DELETE_RECORD(recordId, tableId) + query}`
      )
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static deleteRecordInBulk(
    records: string[],
    tableId: string,
    isForceDelete: boolean = false
  ) {
    // EventManager.trackEventByModule(
    //   TableManger.getTableNameFromId(tableId),
    //   EVENT_TYPE.DELETE,
    //   records
    // );
    let query = "";
    if (isForceDelete) {
      query = `?isForceDelete=${isForceDelete}`;
    }
    return http
      .delete(`${ApiConstants.URL.TABLE.DELETE_BULK_RECORD(tableId) + query}`, {
        data: records
      })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static deleteColumn(columnId: string, tableId: string) {
    return http
      .delete(`${ApiConstants.URL.TABLE.DELETE_COLUMN(columnId, tableId)}`)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static updateColumn(data: IColumn, tableId: string, params?: any) {
    if (!params) params = "";
    return http
      .put(
        `${ApiConstants.URL.TABLE.UPDATE_COLUMN(data.key, tableId) + params}`,
        data
      )
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static shiftColumn(data: any, tableId: string) {
    return http
      .post(`${ApiConstants.URL.TABLE.SHIFT_COLUMN(tableId)}`, data)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static generateNewWebhook(
    data: { columnId: any; fieldId: any },
    tableId: string
  ) {
    const { BASE } = ApiConstants.URL;
    return http
      .post(ApiConstants.URL.TABLE.GENERATE_WEBHOOK(tableId), data)
      .then(
        (response: any) => {
          if (response.relativeUrl) {
            return Promise.resolve(
              `${BASE.slice(0, -1)}${response.relativeUrl}`
            );
          } else {
            return Promise.reject(
              `Webhook url generation failed, please try again later.`
            );
          }
        },
        (error) => Promise.reject(error)
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  static updateRequiredFields(payload: any) {
    return http
      .patch(ApiConstants.URL.TABLE.PATCH_REQUIRED_FIELD, {
        requiredColumns: payload
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error) => Promise.reject(error)
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  static async saveContactDetailedAddress(
    data: { [key: string]: IContactAddress[] | string },
    recordId: string,
    tableId: string
  ) {
    return Table.updateRecord(data, recordId, tableId);
  }

  static async deleteContactDetailedAddress(
    addressId: string,
    recordId: string,
    tableId: string
  ) {
    return http.delete(
      `${ApiConstants.URL.TABLE.DELETE_ADDRESS(addressId, recordId, tableId)}`
    );
  }
}

export default Table;
