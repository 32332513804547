import React, { Component } from "react";
import {
  DKDataGrid,
  shiftArrayElement,
  INPUT_TYPE,
  DKIcons,
  showAlert,
  showToast,
  TOAST_TYPE,
  DKLabel,
  showLoader,
  removeLoader,
  isEmpty,
  DKIcon,
  DKButton,
  DKInput,
  DKListPicker2,
  getDateAsString
} from "deskera-ui-library";
import Table from "../../services/table";
import TableDataParser from "../../Helper/TableDataParser";
import {
  COLUMN_CODE,
  TableManger,
  TABLES,
  TABLE_DISPLAY_NAME
} from "../../managers/TableManger";
import {
  AddRecordButtonConfig,
  IColumn,
  IFilterCondition
} from "../../model/Table";
import ContextMenu from "../common/ContextMenu";
import { connect, ConnectedProps } from "react-redux";
import {
  addRecord,
  fetchRecordsByTable,
  setRecords,
  updateRecord,
  deleteRecord
} from "../../redux/slices/recordSlice";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_SEARCH_QUERY,
  updateFilter,
  updatePage,
  updateSearchQuery,
  updateSortConfig
} from "../../redux/slices/tableMetaDataSlice";
import { isEqual } from "lodash";
import SideBarService from "../../services/sidebar";
import { showDataSourcePopup } from "./AddDataSourcePopup";
import {
  NO_TEAM_VIEW_ALERT,
  NO_USER_VIEW_ALERT,
  USER_ACTION_TYPES
} from "../../constants/Permission";
import {
  isMobileAppWebView,
  isViewportLarge
} from "../../utility/GetViewportSize";
import Utility, {
  isString,
  isUndefined,
  replaceCountrySpecificKeywords,
  replaceHTMLSpecialChars,
  toCurrencyFormat
} from "../../utility/Utility";
import { showPushWebhookPopup } from "./AddColumnPushWebhook";
import ic_whatsapp from "../../assets/icons/ic_whatsapp.png";
import ic_sub_owner from "../../assets/icons/ic_sub_owner.png";
import ic_team from "../../assets/icons/ic_team.png";
import ic_quote_2 from "../../assets/icons/ic_quote_2.png";
import ApiConstants from "../../../src/constants/ApiConstants";
import ic_no_data from "../../assets/icons/ic_no_data_3.png";
import { PipelineManager } from "../../managers/PipelineManager";
import debounce from "../../utility/Debounce";
import PermissionService from "../../services/common/permission";
import {
  ACTIVITY_REPORT_NAME,
  ALL_CONTACT_SEGMENT,
  CONTACT_STATUS_ACTIVE,
  ERROR_MSG_FOR_EMPTY_COLUMN,
  WARNING
} from "../../constants/Constant";
import RouteManager, {
  PAGE_ROUTES,
  ROUTE_ID_IDENTIFIER
} from "../../managers/RouteManager";
import CampaignManager from "../campaign/CampaignManager";
import Popup from "./Popup";
import { SETTING_CARD_IDS } from "../settings/Settings";
import { Renderers } from "../../Helper/Renderers";
import {
  CAMPAIGN_STATUS,
  CAMPAIGN_TYPE,
  CONTACT_DETAIL_TAB,
  CUSTOM_INPUT_TYPES,
  DEAL_STATUS_CODE,
  FILTER_LOGICAL_OPERATORS,
  PRODUCTS,
  QUERY_PARAM_KEY
} from "../../constants/Enum";
import ColumnVisibility from "./ColumnVisibility";
import {
  getHumanReadableFileSize,
  MAX_FILE_SIZE,
  uploadFileToAWS
} from "../../services/common/file";
import {
  convertCRMDateFormatToUILibraryFormat,
  DateUtil
} from "../../utility/Date";
import DetailedAddress from "./DetailedAddress";
import DOMPurify from "dompurify";
import { setUserPreferences } from "../../redux/slices/userPrefSlice";
import { RootState, store } from "../../redux/store";
import { showAddLookupFieldPopup } from "./AddLookupFIeldColumn";
import AppManager from "../../managers/AppManager";
import UserManager from "../../managers/UserManager";
import {
  DealApprovalHelper,
  buildPayloadForDealApproval,
  getDealApprovalAlert,
  showApprovalTriggerFailedAlert,
  showApprovalTriggerSuccessAlert
} from "../../Helper/DealApprovalHelper";
import { DealService } from "../../services/deal";
import { fetchApprovalTriggers } from "../../redux/slices/dealSlice";

import { selectPermissionsByModule } from "../../redux/slices/rolesPermissionSlice";
import {
  setSelectedSetting,
  setSettingPopup
} from "../../redux/slices/userPrefSlice";
import { NEW_SETTINGS_SECTION } from "../../constants/Constant";
export const DEFAULT_PAGE_SIZE = 20;
const MAX_DISPLAY_PAGE_SIZE = 20;

export const APPROVAL_PARAMS = {
  pageNo: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  populateRecordDetail: true,
  fetchAllRef: true
};
export interface IDataGridHolderProps extends PropsFromRedux {
  tableName: string;
  displayTableName?: string;
  metaDataTableName?: string;
  showRecordCount?: boolean;
  filterConditions?: IFilterCondition[];
  allowRowEdit?: boolean;
  allowColumnEdit?: boolean;
  allowRowAdd?: boolean;
  allowColumnAdd?: boolean;
  allowColumnDelete?: boolean;
  allowColumnShift?: boolean;
  allowColumnFreeze?: boolean;
  allowSearch?: boolean;
  allowFilter?: boolean;
  allowBulkOperation?: boolean;
  allowAdvancedBulkEdit?: boolean;
  allowLookupField?: boolean;
  hideActionColumn?: boolean;
  onRowUpdate?: (data) => void;
  onColumnUpdate?: (data) => void;
  onColumnShift?: (data) => void;
  onRowAdd?: (data) => void;
  onRowClick?: (rowIndex, rowData) => void;
  onColumnAdd?: (data) => void;
  onColumnDelete?: (data) => void;
  onSearch?: (searchTerm) => void;
  onFilter?: (data) => void;
  onRowDelete?: (data) => void;
  onRowEdit?: (data) => void;
  onRowOpenClick?: (data) => void;
  onRowCopy?: (data) => void;
  onRowOperation?: (data, operation: string) => void;
  onRowSelect?: (data) => void;
  onPagination?: (currentPage) => void;
  onDataRefresh?: () => void;
  onAdvancedBulkEdit?: (rowIds: string[], callback: any) => void;
  needTrailingColumn: boolean;
  addButtonConfig?: AddRecordButtonConfig;
  refresh?: boolean;
  preventRefreshOnNotification?: boolean;
  dateFormat: string;
  allowShare?: boolean;
  rows?: any;
  requestParams?: any;
}
export interface IDataGridHolderState {
  tableName: string;
  columns: IColumn[];
  rows: any[];
  totalPageCount: number;
  showContextMenu: boolean;
  isAllRowSelected: boolean;
  isRefreshing: boolean;
  width: number;
  needNoDataView: boolean;
  documentDataToShare?: any;
  attachmentData?: any;
  showColumnVisibilityPopup: boolean;
  contextMenuOwnerData?: any;
}

class DataGridHolder extends Component<
  IDataGridHolderProps,
  IDataGridHolderState
> {
  tableId: string;
  filter: any;
  tableFilters: any[] = [];
  selection: any[] = [];
  attachmentOpenFileRef;
  lookUpCallTrackerRef;
  attachmentPickerExists: Boolean = false;
  _permissionService: PermissionService;
  constructor(props: IDataGridHolderProps) {
    super(props);
    this.tableId = TableManger.getTableId(props.tableName);
    this.filter = TableManger.getFilterObject([]);
    this._permissionService = PermissionService.getInstance();
    this.tableFilters = [];
    this.state = {
      tableName: "",
      columns: [],
      rows: null,
      totalPageCount: 1,
      showContextMenu: false,
      isRefreshing: false,
      width: SideBarService.getContainerWidth(),
      needNoDataView: false,
      isAllRowSelected: false,
      documentDataToShare: null,
      attachmentData: null,
      showColumnVisibilityPopup: false,
      contextMenuOwnerData: {}
    };

    this.populateExternalFilterConditions();

    this.attachmentOpenFileRef = React.createRef();
    this.lookUpCallTrackerRef = React.createRef<boolean>();
  }
  async componentDidMount() {
    showLoader();
    this.lookUpCallTrackerRef = false;
    await this.updateLocalFilters();
    this.getRecords(this.filter);
    setTimeout(() => {
      if (this.state.rows) {
        this.refreshData();
      }
    }, 10);
    window.addEventListener("onSideBarChange", this.windowSizeUpdated);
  }
  componentWillUnmount() {
    window.removeEventListener("onSideBarChange", this.windowSizeUpdated);
  }
  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.data, this.props.data)) {
      this.onGetRecordSuccess(nextProps.data);
    }
    if (
      this.props.rows &&
      this.props.rows.data &&
      !isEqual(nextProps.rows, this.props.rows)
    ) {
      this.setState({
        rows:
          nextProps.rows &&
          TableDataParser.parseRowData(
            Utility.deepCloneObject(nextProps.rows.data),
            this.props.tableName
          ),
        totalPageCount:
          nextProps.rows &&
          Math.ceil(nextProps.rows.totalCount / nextProps.rows.pageSize)
      });
    }

    if (!isEqual(nextProps.filterConditions, this.props.filterConditions)) {
      this.onFilter(nextProps.filterConditions);
    }

    if (!isEqual(nextProps.notifications, this.props.notifications)) {
      this.onNewNotificationReceived();
    }

    if (!isEqual(nextProps.refresh, this.props.refresh) && nextProps.refresh) {
      this.refreshData();
      this.props.onDataRefresh?.();
    }

    /* For handling refresh on contact or segment route
      Userlist gets available later, hence need to re-update the columns on recieving users.

      To be removed, once table columns managed through store
    */
    if (
      nextProps.tableName === TABLES.CONTACT &&
      !isEqual(nextProps.users, this.props.users)
    ) {
      this.updateColumns();
    }

    if (
      nextProps.tableData?.columnsMetaData !==
      this.props.tableData?.columnsMetaData
    ) {
      this.updateColumns();
    }
  }
  componentDidUpdate(
    prevProps: Readonly<IDataGridHolderProps>,
    prevState: Readonly<IDataGridHolderState>,
    snapshot?: any
  ): void {
    if (
      !isEqual(
        prevProps.tableMetaData?.searchQuery,
        this.props.tableMetaData?.searchQuery
      )
    ) {
      this.refreshWithDebounce();
      return;
    }
    if (
      !isEqual(
        prevProps.tableMetaData?.filter,
        this.props.tableMetaData?.filter
      )
    ) {
      this.onFilter();
      return;
    }
    if (
      !isEqual(prevProps.tableMetaData?.page, this.props.tableMetaData?.page) ||
      !isEqual(
        prevProps.tableMetaData?.sortConfig,
        this.props.tableMetaData?.sortConfig
      )
    ) {
      this.refreshData();
      return;
    }
  }
  populateExternalFilterConditions = (
    filterConditions = this.props.filterConditions
  ) => {
    if (!Utility.isEmptyObject(filterConditions)) {
      this.filter.conditions.push(...filterConditions);
    }
    this.filter.conditions = this.filter.conditions.filter(
      (c) => !Utility.isEmptyObject(c)
    );
  };
  refreshData = () => {
    this.getRecords(this.filter, true);
  };
  refreshWithDebounce = debounce(this.refreshData, 300);

  windowSizeUpdated = () => {
    this.setState({
      width: SideBarService.getContainerWidth()
    });

    this.updateColumns();
  };

  getUpdatedColumnData = (columns) => {
    if (!isViewportLarge()) {
      return columns.map((columnData) => ({
        ...columnData,
        width: Math.floor(columnData.width / 1.5)
      }));
    }

    return columns;
  };
  getColumnVisibilityButton = () => ({
    title: "",
    icon: DKIcons.data_type.ic_multi_select,
    className: "bg-white text-white ml-r border-m column-filter-button",
    onClick: () => {
      this.setState({
        showColumnVisibilityPopup: true
      });
    }
  });
  render() {
    return (
      <div className="column flex-1 parent-width" style={{ paddingBottom: 0 }}>
        {this.getDataGrid()}
        {this.state.needNoDataView && this.getNoDataView()}
        {this.getAttachmentPicker()}
        {this.state.contextMenuOwnerData?.isTeamAssign
          ? this.getTeamSelector()
          : this.getOwnerSelector()}
      </div>
    );
  }
  getWebhookPopup = (columnData: IColumn) => {
    showPushWebhookPopup({
      tableName: this.props.tableName,
      targetColumnId: columnData?.id,
      targetColumnName: columnData?.name
    });
  };
  getDisplayColumns = () => {
    const moduleName = this.props.tableName.toUpperCase();
    const moduleFreezedColumns = this.props.freezedColumns[moduleName] || [];
    let columns: IColumn[] = this.state.columns
      .filter((column) => !this.props.hiddenColumns.includes(column.id))
      .map((column) => ({
        ...column,
        freezed: moduleFreezedColumns.includes(column.id)
      }));

    if (!this.props.hideActionColumn) {
      columns.push(this.getActionButtonsColumn());
    }

    if (
      [TABLES.CONTACT, TABLES.SEGMENT, TABLES.DEAL, TABLES.ACTIVITY].includes(
        this.props.tableName
      )
    ) {
      const OWNER_ID = TableManger.getColumnId(
        this.props?.tableName,
        COLUMN_CODE.CONTACT.OWNER_ID
      );
      const SUB_OWNER_ID = TableManger.getColumnId(
        this.props?.tableName,
        COLUMN_CODE.CONTACT.SUB_OWNER_ID
      );
      const DEAL_CONTACT_ID = TableManger.getColumnId(
        this.props?.tableName,
        COLUMN_CODE.DEAL.CONTACT_ID
      );
      const isContactLinkEnabled =
        store.getState().tenant?.crmSettings?.contactLinkingDisabledFor ?? [];

      columns.forEach((col) => {
        if (col.id === OWNER_ID || col.id === SUB_OWNER_ID) {
          col.editable =
            !!this.props.permissions[
              USER_ACTION_TYPES.ASSIGN_OWNER_SUBOWNER_TEAM
            ];
        }
        //Disble editMode for Deal Contact column when setting is enabled for account
        if (
          TABLES.DEAL === this.props.tableName &&
          DEAL_CONTACT_ID === col.id &&
          isContactLinkEnabled?.includes("DEAL")
        ) {
          col.editable = false;
        }
      });

      //Sorting disabled for column type
      columns.forEach((col) => {
        if (
          [
            INPUT_TYPE.MULTI_SELECT,
            "user_array",
            "ref_array",
            "html",
            "json",
            "json_array",
            "file",
            "address",
            "formula",
            "lookup"
          ].includes(col.type)
        ) {
          col.allowColumnSort = false;
        }
      });
    }
    return columns;
  };
  getDataGrid() {
    const columnVisibilityButtonRect = document
      .getElementsByClassName("column-filter-button")?.[0]
      ?.getBoundingClientRect();
    const columnsToDisplay = this.getDisplayColumns();
    return (
      <div className="column parent-height parent-width">
        {this.state.showContextMenu && (
          <ContextMenu
            tableName={this.props.tableName}
            maxWidth={this.state.width}
            displayTableName={this.state.tableName}
            allowBulkDelete={
              !!this.props.permissions[USER_ACTION_TYPES.REC_DELETE]
            }
            allowAdvancedBulkEdit={!!this.props.allowAdvancedBulkEdit}
            onAdvanceBulkEdit={() =>
              this.props.onAdvancedBulkEdit?.(
                this.selection?.map((item) => item.id) || [],
                (data) => this.onContextMenuOp(data, true)
              )
            }
            onDelete={() => this.getDeleteConfirmation(this.selection)}
            onApply={(data) => this.onContextMenuOp(data)}
            onCancel={() => this.onContextMenuCancel()}
          />
        )}
        <DKDataGrid
          title={this.state.tableName}
          width={this.state.width}
          columns={columnsToDisplay}
          rows={this.state.rows ? [...this.state.rows] : []}
          updating={this.state.isRefreshing}
          currentPage={this.props?.tableMetaData?.page || DEFAULT_PAGE_NUMBER}
          totalPageCount={this.state.totalPageCount}
          gridStickyHeaderConfig={
            isMobileAppWebView()
              ? null
              : {
                  getRootScrollContainer: () =>
                    AppManager.getScrollContainerDiv()
                }
          }
          onRowUpdate={this.onRowUpdate}
          onColumnUpdate={this.onColumnUpdate}
          onColumnShift={(data) => this.onColumnShift(data, columnsToDisplay)}
          onRowAdd={this.onRowAdd}
          onRowClick={this.onRowClick}
          onColumnAdd={this.onColumnAdd}
          onColumnDelete={this.onColumnDelete}
          onColumnFreezeToggle={this.onColumnFreezeToggle}
          onSearch={(data) => this.onSearch(data)}
          onFilter={(data: any) => {
            this.props?.onFilter?.({ ...data, filter: this.filter });
            this.onTableFilterUpdate(data);
          }}
          allowRowAdd={this.props.allowRowAdd}
          allowRowEdit={this.props.allowRowEdit}
          allowColumnAdd={this.props.allowColumnAdd}
          allowColumnEdit={this.props.allowColumnEdit}
          isAllRowSelected={this.state.isAllRowSelected}
          allowColumnDelete={this.props.allowColumnDelete}
          allowColumnShift={this.props.allowColumnShift}
          allowColumnFreeze={this.props.allowColumnFreeze ?? true}
          allowBulkOperation={this.props.allowBulkOperation}
          allowSearch={this.props.allowSearch}
          allowFilter={this.props.allowFilter}
          // allowFormulaColumn={[
          //   TABLES.CONTACT,
          //   TABLES.SEGMENT,
          //   TABLES.DEAL,
          //   TABLES.ACTIVITY
          // ].includes(this.props.tableName)}
          allowFormulaColumn={false}
          onPageChange={this.onPagination}
          filterData={this.tableFilters}
          filterOperator={this.filter.logicalOperator}
          onRowSelect={(data) => this.onRowSelect(data)}
          onAllRowSelect={(data) => this.onAllRowSelect(data)}
          showHeader={!this.state.showContextMenu}
          buttons={
            this.props.addButtonConfig
              ? Array.isArray(this.props.addButtonConfig)
                ? this.props.addButtonConfig
                : [this.props.addButtonConfig, this.getColumnVisibilityButton()]
              : null
          }
          onRowOpenClick={
            this.props.onRowOpenClick || this.props.onRowEdit || null
          }
          dateFormat={
            this.props.dateFormat
              ? this.props.dateFormat
              : convertCRMDateFormatToUILibraryFormat(
                  DateUtil.getOrgDateFormat()
                )
          }
          onDataSourceClick={this.onColumnDataSourceClick}
          allowColumnWebhook={this.props.allowColumnEdit}
          onLookupSourceClick={this.onLookupSourceClick}
          allowLookupField={this.props?.allowLookupField}
          onRequestColumnWebhook={this.getWebhookPopup}
          needTrailingColumn={this.props?.needTrailingColumn}
          onSort={this.onSort}
          searchBarConfig={{
            searchText:
              this.props.tableMetaData?.searchQuery || DEFAULT_SEARCH_QUERY
          }}
        />
        {this.state.showColumnVisibilityPopup && (
          <ColumnVisibility
            columns={TableManger.getTableVisibleColumns(this.props.tableName)}
            style={{
              top: columnVisibilityButtonRect?.height,
              right:
                (columnVisibilityButtonRect?.y || 0) -
                ((columnVisibilityButtonRect?.width || 0) + 45)
            }}
            hiddenColumns={this.props.hiddenColumns}
            tableName={this.props.tableName}
            onCancel={() => this.setState({ showColumnVisibilityPopup: false })}
            onApply={() => {
              this.setState({ showColumnVisibilityPopup: false });
              this.getColumns();
            }}
          />
        )}
      </div>
    );
  }
  getOwnerSelector() {
    if (Utility.isEmptyObject(this.state.contextMenuOwnerData?.columnData))
      return;

    const { rowId, columnData, value, isSubOwner } =
      this.state.contextMenuOwnerData;

    const field = isSubOwner
      ? {
          placeholder: "Select",
          type: INPUT_TYPE.DROPDOWN,
          dropdownConfig: {
            data: columnData.options,
            displayKey: "name",
            allowSearch: true,
            searchableKey: "name",
            multiSelect: true,
            checkMarkColor: "bg-button",
            selectedIndexes: value,
            renderer: (index, option) => option.name
          }
        }
      : {
          placeholder: "Select",
          type: columnData.type,
          options: columnData.options.map((option) => option.name)
        };

    return (
      <Popup
        popupWindowStyles={{
          maxWidth: 400,
          padding: 0,
          overflowY: "visible"
        }}
      >
        <div className="row justify-content-between pt-l p-h-l">
          <DKLabel
            text={isSubOwner ? "Assign sub owner" : "Assign owner"}
            className="fw-m fs-l"
          />
          <div className="row width-auto">
            <DKButton
              title="Cancel"
              className="bg-gray1 border-m"
              onClick={() => {
                this.setState({
                  contextMenuOwnerData: null
                });
              }}
            />
            <DKButton
              title="Save"
              className="dk-bg-button text-white ml-r"
              onClick={() => {
                let dataToUpdate = {
                  id: rowId,
                  [columnData.id]:
                    value?.map(
                      (optionIndex) => columnData.options[optionIndex].id
                    ) || []
                };
                this.onRowUpdate({
                  rowData: dataToUpdate,
                  columnKey: columnData.id,
                  columnData
                });
                this.setState({
                  contextMenuOwnerData: null
                });
              }}
            />
          </div>
        </div>
        {isSubOwner ? (
          <DKListPicker2
            className="bg-white parent-width"
            allowSearch={field.dropdownConfig.allowSearch}
            displayKey={field.dropdownConfig.displayKey}
            searchableKey={field.dropdownConfig.searchableKey}
            data={field.dropdownConfig.data}
            renderer={field.dropdownConfig.renderer}
            multiSelect={field.dropdownConfig.multiSelect}
            selectedIndexes={field.dropdownConfig.selectedIndexes}
            checkMarkColor={field.dropdownConfig.checkMarkColor}
            onClear={() => {
              this.setState((prevState) => ({
                contextMenuOwnerData: {
                  ...prevState.contextMenuOwnerData,
                  value: []
                }
              }));
            }}
            onSelect={(index, value) => {
              let newSelectedIndexes = [
                ...field.dropdownConfig.selectedIndexes
              ];
              if (newSelectedIndexes.includes(index)) {
                let indexToDelete = newSelectedIndexes.indexOf(index);
                newSelectedIndexes.splice(indexToDelete, 1);
              } else {
                newSelectedIndexes = [...newSelectedIndexes, ...[index]];
              }
              this.setState((prevState) => ({
                contextMenuOwnerData: {
                  ...prevState.contextMenuOwnerData,
                  value: newSelectedIndexes
                }
              }));
            }}
          />
        ) : (
          <DKInput
            className="p-l"
            placeholder={field.placeholder}
            value={value}
            options={field.options}
            type={field.type}
            dropdownConfig={field.dropdownConfig}
            displayKey={field.dropdownConfig?.displayKey}
            onChange={(newValue) => {
              this.setState((prevState) => ({
                contextMenuOwnerData: {
                  ...prevState.contextMenuOwnerData,
                  value: newValue
                }
              }));
            }}
          />
        )}
      </Popup>
    );
  }
  getTeamSelector() {
    if (Utility.isEmptyObject(this.state.contextMenuOwnerData)) return;

    const { rowData, value } = this.state.contextMenuOwnerData;

    return (
      <Popup
        popupWindowStyles={{
          maxWidth: 400,
          padding: 0,
          overflowY: "visible"
        }}
      >
        <div className="row justify-content-between pt-l p-h-l">
          <DKLabel text={"Assign team"} className="fw-m fs-l" />
          <div className="row width-auto">
            <DKButton
              title="Cancel"
              className="bg-gray1 border-m"
              onClick={() => {
                this.setState({
                  contextMenuOwnerData: null
                });
              }}
            />
            <DKButton
              title="Save"
              className="dk-bg-button text-white ml-r"
              onClick={() => {
                const subOwnerColumn = TableManger.getColumn(
                  this.props.tableName,
                  COLUMN_CODE.CONTACT.SUB_OWNER_ID
                );

                const subOwnerValue = rowData?.[subOwnerColumn.id] || [];
                const selectedTeamUsers = this.props.teams[value]?.users
                  ?.map((user) => user.iamUserId)
                  .filter((userId) => !subOwnerValue.includes(userId));

                if (Utility.isEmptyObject(selectedTeamUsers)) return;

                let dataToUpdate = {
                  id: rowData.id,
                  [subOwnerColumn.id]: [...subOwnerValue, ...selectedTeamUsers]
                };

                this.onRowUpdate({
                  rowData: dataToUpdate,
                  columnKey: subOwnerColumn.id,
                  columnData: subOwnerColumn
                });
                this.setState({
                  contextMenuOwnerData: null
                });
              }}
            />
          </div>
        </div>
        <DKInput
          className="p-l"
          placeholder={"Select"}
          value={value}
          options={this.props.teams.map((team) => team.name) || []}
          type={INPUT_TYPE.SELECT}
          onChange={(newValue) => {
            this.setState((prevState) => ({
              contextMenuOwnerData: {
                ...prevState.contextMenuOwnerData,
                value: newValue
              }
            }));
          }}
        />
      </Popup>
    );
  }
  checkPermissionForOwnerAssignment = (
    allowRowEdit: boolean,
    needSubOwner?: boolean
  ) => {
    if (needSubOwner && this.props.tableName === TABLES.ACTIVITY) return false;

    let isPermitted = false;
    const TABLES_TO_CHECK = [
      TABLES.CONTACT,
      TABLES.DEAL,
      TABLES.SEGMENT,
      TABLES.ACCOUNT,
      TABLES.ACTIVITY
    ];

    isPermitted = TABLES_TO_CHECK.includes(this.props.tableName);
    if (this.props.tableName !== TABLES.SEGMENT) {
      isPermitted =
        this.props.permissions[USER_ACTION_TYPES.ASSIGN_OWNER_SUBOWNER_TEAM];
    } else {
      isPermitted = isPermitted && allowRowEdit;
    }
    return isPermitted;
  };
  getGridContextMenu(isRowButtons = false, rowData = null) {
    let contextMenuButtons = [];
    const allowRowEdit =
      !!this.props.permissions[USER_ACTION_TYPES.REC_UPDATE] ||
      rowData.allowRowEdit;
    if (allowRowEdit) {
      contextMenuButtons.push({
        title: !isRowButtons ? "Edit" : undefined,
        icon: DKIcons.ic_edit,
        onClick: (data) => {
          if (this.props.onRowEdit) this.props.onRowEdit(data);
        },
        className: "p-0"
      });
    }
    if (
      this.props.allowRowAdd &&
      this.props.permissions[USER_ACTION_TYPES.REC_CREATE]
    ) {
      contextMenuButtons.push({
        title: !isRowButtons ? "Duplicate" : undefined,
        icon: DKIcons.ic_copy,
        onClick: (data) => {
          if (this.props.onRowCopy) this.props.onRowCopy(data);
        },
        className: "p-0"
      });
    }

    if (
      [TABLES.CONTACT, TABLES.SEGMENT, TABLES.DEAL].includes(
        this.props.tableName
      ) &&
      allowRowEdit
    ) {
      contextMenuButtons.push({
        title: !isRowButtons ? "Attach file" : undefined,
        icon: DKIcons.ic_attachment,
        onClick: (data) => {
          this.setState(
            {
              attachmentData: data
            },
            () => this.openAttachmentPicker()
          );
        },
        className: "p-0"
      });
    }

    if ([TABLES.CONTACT, TABLES.SEGMENT].includes(this.props.tableName)) {
      if (
        this.props.permissions[USER_ACTION_TYPES.REC_EMAIL] ||
        rowData.allowRowEdit
      ) {
        contextMenuButtons.push({
          title: !isRowButtons ? "Email" : undefined,
          icon: DKIcons.ic_email,
          onClick: (data) => {
            if (Utility.isEmptyObject(data?.rowData?.id)) return;

            const url = PAGE_ROUTES.CONTACTS_UI.replace(
              ROUTE_ID_IDENTIFIER,
              data.rowData.id
            );
            RouteManager.navigateToPage(
              url,
              `${QUERY_PARAM_KEY.CONTACT_DETAIL_TAB}=${CONTACT_DETAIL_TAB.EMAIL}`
            );
          },
          className: "p-0"
        });
      }
      if (
        this.props.permissions[USER_ACTION_TYPES.REC_WHATSAPP] ||
        rowData.allowRowEdit
      ) {
        const phoneColID = TableManger.getColumnId(
          this.props.tableName,
          COLUMN_CODE.CONTACT.PHONE
        );
        const sanitizedNumber = Utility.sanitizePhoneForWhatsapp(
          rowData[phoneColID]
        );
        if (!Utility.isEmptyObject(sanitizedNumber)) {
          contextMenuButtons.push({
            title: !isRowButtons ? "Whatsapp" : undefined,
            icon: ic_whatsapp,
            onClick: (data) => {
              window.open(
                ApiConstants.URL.WHATSAPP.WHATSAPP_URL(sanitizedNumber),
                "_blank"
              );
            },
            className: "p-0"
          });
        }
      }
    }

    if (this.checkPermissionForOwnerAssignment(allowRowEdit)) {
      contextMenuButtons.push({
        title: !isRowButtons ? "Assign owner" : undefined,
        icon: DKIcons.ic_user,
        onClick: (data) => {
          const ownerColumnData = this.state.columns.find(
            (column) => column.columnCode === COLUMN_CODE.CONTACT.OWNER_ID
          );

          if (Utility.isEmptyObject(ownerColumnData?.options)) {
            showAlert("No users found", NO_USER_VIEW_ALERT);
            return;
          }

          const selectedOwnerIndex = (rowData[ownerColumnData.id] || []).map(
            (ownerId) =>
              ownerColumnData.options.findIndex(
                (option) => option.id === ownerId
              )
          );
          this.setState({
            contextMenuOwnerData: {
              rowId: rowData.id,
              columnData: ownerColumnData,
              value: selectedOwnerIndex,
              isSubOwner: false
            }
          });
        },
        className: "p-0"
      });
    }

    if (this.checkPermissionForOwnerAssignment(allowRowEdit, true)) {
      contextMenuButtons.push({
        title: !isRowButtons ? "Assign sub owner" : undefined,
        icon: ic_sub_owner,
        onClick: (data) => {
          const subOwnerColumnData = this.state.columns.find(
            (column) => column.columnCode === COLUMN_CODE.CONTACT.SUB_OWNER_ID
          );

          if (Utility.isEmptyObject(subOwnerColumnData?.options)) {
            showAlert("No users found", NO_USER_VIEW_ALERT);
            return;
          }

          const selectedOwnerIndices = (
            rowData[subOwnerColumnData.id] || []
          ).map((ownerId) =>
            subOwnerColumnData.options.findIndex(
              (option) => option.id === ownerId
            )
          );

          this.setState({
            contextMenuOwnerData: {
              rowId: rowData.id,
              columnData: subOwnerColumnData,
              value: selectedOwnerIndices,
              isSubOwner: true
            }
          });
        },
        className: "p-0"
      });

      contextMenuButtons.push({
        title: !isRowButtons ? "Assign team" : undefined,
        icon: ic_team,
        onClick: (data) => {
          if (!this.props.teams?.length) {
            const actions = [
              {
                title: "Cancel",
                className: "bg-gray1 border-m",
                onClick: () => {}
              },
              {
                title: "Create team",
                className: "bg-button text-white ml-r",
                onClick: () => {
                  this.props.setSelectedSetting(
                    NEW_SETTINGS_SECTION.TEAM_PERMISSIONS
                  );
                  this.props.setSettingPopup(true);
                }
              }
            ];
            showAlert("No team found", NO_TEAM_VIEW_ALERT, actions);
            return;
          }

          this.setState({
            contextMenuOwnerData: {
              rowData,
              value: [0],
              isTeamAssign: true
            }
          });
        },
        className: "p-0"
      });
    }
    const hasBookPermission = UserManager.isBookPermissionEnabled();
    const canCreateQuote = this._permissionService.isUserPermitted(
      TABLES.BOOKS_QUOTE,
      [USER_ACTION_TYPES.REC_CREATE]
    );
    const isContactLinkEnabled =
      store.getState().tenant?.crmSettings?.contactLinkingDisabledFor ?? [];

    if (
      [TABLES.CONTACT, TABLES.SEGMENT].includes(this.props.tableName) &&
      canCreateQuote &&
      hasBookPermission
    ) {
      const statusColumnId = TableManger.getColumnId(
        TABLES.CONTACT,
        COLUMN_CODE.CONTACT.STATUS
      );
      const isContactActive =
        rowData[statusColumnId]?.[0] === CONTACT_STATUS_ACTIVE;
      !isContactLinkEnabled?.includes("QUOTE") &&
        isContactActive &&
        contextMenuButtons.push({
          title: replaceCountrySpecificKeywords("Create Quote"),
          icon: ic_quote_2,
          onClick: (data) => {
            this.props?.onRowOperation?.(data, "createQuote");
          },
          className: "p-0"
        });
    }

    if (this.props.tableName === TABLES.AUTOMATION) {
      const automationNameColumnId = TableManger.getColumnId(
        TABLES.AUTOMATION,
        COLUMN_CODE.AUTOMATION.NAME
      );
      contextMenuButtons.push({
        title: "View logs",
        icon: DKIcons.ic_document,
        onClick: (data) =>
          RouteManager.navigateToPage(
            PAGE_ROUTES.AUTOMATION_LOGS,
            `automationId=${rowData.id}&automationName=${rowData[automationNameColumnId]}`
          ),
        className: "p-0"
      });
    }

    if (
      !!this.props.permissions[USER_ACTION_TYPES.REC_DELETE] ||
      rowData.allowRowEdit
    ) {
      contextMenuButtons.push({
        title: !isRowButtons ? "Delete" : undefined,
        icon: DKIcons.ic_delete,
        onClick: (data) => this.getDeleteConfirmation([data.rowData]),
        className: "p-0"
      });
    }
    const statusColId = TableManger.getColumnId(
      TABLES.CAMPAIGN,
      COLUMN_CODE.CAMPAIGN.STATUS
    );
    const campaignTypeColId = TableManger.getColumnId(
      TABLES.CAMPAIGN,
      COLUMN_CODE.CAMPAIGN.CAMPAIGN_TYPE
    );
    if (
      this.props.tableName === TABLES.CAMPAIGN &&
      [CAMPAIGN_STATUS.SENT, CAMPAIGN_STATUS.PARTIALLY_SENT].includes(
        rowData[statusColId]?.[0]
      ) &&
      rowData[campaignTypeColId]?.[0] !== CAMPAIGN_TYPE.WHATSAPP
    ) {
      contextMenuButtons.push({
        title: "Tracking Details",
        icon: DKIcons.ic_reports,
        onClick: (data) => {
          CampaignManager.setCampaignDataFromContextMenu(data?.rowData);
          RouteManager.navigateToPage(
            PAGE_ROUTES.CAMPAIGN_DETAILS + "/" + data?.rowData?.id
          );
        },
        className: "p-0"
      });
    }
    return contextMenuButtons.length ? contextMenuButtons : null;
  }
  getGridRowButtons(rowData, allowRowEdit: boolean) {
    const rowButtons = [];

    if (allowRowEdit) {
      rowButtons.push({
        title: "Edit",
        onClick: (data) => {
          if (this.props.onRowEdit) this.props.onRowEdit(data);
        },
        className: "p-0 text-blue fw-m text-underline"
      });
    }

    if (this.props.tableName === TABLES.AUTOMATION) {
      const automationNameColumnId = TableManger.getColumnId(
        TABLES.AUTOMATION,
        COLUMN_CODE.AUTOMATION.NAME
      );
      rowButtons.push({
        title: "View logs",
        onClick: (data) =>
          RouteManager.navigateToPage(
            PAGE_ROUTES.AUTOMATION_LOGS,
            `automationId=${rowData.id}&automationName=${rowData[automationNameColumnId]}`
          ),
        className: "p-v-0 p-h-strict-0 mr-s text-gray fw-m text-underline"
      });
    }

    return rowButtons;
  }
  getNoDataView() {
    return (
      <div
        className="column align-self-center align-items-center position-absolute"
        style={{ top: "30%", pointerEvents: "none" }}
      >
        <DKIcon
          src={ic_no_data}
          className="ic-l"
          style={{ opacity: 0.2, marginTop: 70 }}
        />
        <DKLabel text="No data found" className="fw-m mt-l" />
        <DKLabel
          text="Once data is available, it will appear here"
          className="text-gray mt-s "
        />
      </div>
    );
  }
  getActionButtonsColumn = () => {
    return {
      key: "actions",
      name: "Actions",
      type: INPUT_TYPE.BUTTON,
      width: this.props.tableName === TABLES.AUTOMATION ? 200 : 110,
      options: []
    } as IColumn;
  };
  getRecords = (payload = this.filter, refresh = false): void => {
    this.setState({ isRefreshing: true });
    this.updateSelection(true);
    let req = {
      pageNo: this.props?.tableMetaData?.page,
      pageSize: DEFAULT_PAGE_SIZE,
      sortCol: this.props.tableMetaData?.sortConfig?.sortCol || undefined,
      sortDir: this.props.tableMetaData?.sortConfig?.sortDir || undefined,
      q: this.props.tableMetaData?.searchQuery || DEFAULT_SEARCH_QUERY,
      ...(this.props.requestParams || {})
    };
    let data = this.props.data;

    if (payload?.conditions) {
      payload = TableDataParser.getModifiedFilterPayload(
        payload,
        this.state.columns
      );
    }

    if (refresh || isEmpty(data)) {
      this.props
        .fetchRecordsByTable({
          tableName: this.props.tableName,
          params: req,
          payload
        })
        .then((res) => this.onGetRecordSuccess(res.payload));
    } else {
      this.onGetRecordSuccess(data);
    }
  };
  getMaxRecordsToDisplay = (allRecordData) => {
    /* For handling kanban deals (max 350) present in recordSlice,
      to avoid load time increase while switching from kanban to grid
    */
    if (
      ![TABLES.DEAL, TABLES.ACTIVITY].includes(this.props.tableName) ||
      !allRecordData?.data
    )
      return allRecordData;

    const currentPage = this.props?.tableMetaData?.page;
    const maxRecords = { ...allRecordData };

    const sliceStart = MAX_DISPLAY_PAGE_SIZE * (currentPage - 1);
    const sliceEnd = MAX_DISPLAY_PAGE_SIZE * currentPage;
    if (maxRecords?.data && maxRecords.data.length > MAX_DISPLAY_PAGE_SIZE) {
      maxRecords.pageSize = MAX_DISPLAY_PAGE_SIZE;
      maxRecords.pageNo = currentPage;
      maxRecords.data = maxRecords.data.slice(sliceStart, sliceEnd);
    }

    return maxRecords;
  };
  onGetRecordSuccess = (res) => {
    const allRecordData = this.getMaxRecordsToDisplay(res);

    if (allRecordData) {
      removeLoader();
      this.setState({ isRefreshing: false });
      this.updateColumns(allRecordData);
      this.updateRows(allRecordData);
    }
  };
  getColumns = () => {
    Table.getTable(TableManger.getTableId(this.props.tableName)).then(
      (data) => {
        TableManger.setTableColumns(data, this.props.tableName);
        this.lookUpCallTrackerRef = false;
        this.updateColumns();
      },
      (err) => {}
    );
  };
  updateRows = (resp) => {
    let rows = this.props.rows?.data || resp.data;
    rows = TableDataParser.parseRowData(
      JSON.parse(JSON.stringify(rows)),
      this.props.tableName
    );
    rows = rows.map((row) => {
      try {
        /* Allowing to edit record, if user is owner/creator, irrespective of their current permissions */
        const allowRowEdit =
          row.allowRowEdit ||
          !!this.props.permissions[USER_ACTION_TYPES.REC_UPDATE];
        return {
          ...row,
          allowRowEdit,
          rowContextMenu: this.getGridContextMenu(false, row),
          rowButtons: this.getGridRowButtons(row, allowRowEdit)
        };
      } catch (error) {
        return row;
      }
    });
    this.setState(
      {
        rows,
        totalPageCount: this.props.rows
          ? Math.ceil(this.props.rows.totalCount / this.props.rows.pageSize)
          : Math.ceil(resp.totalCount / resp.pageSize)
      },
      () => {
        this.setState({
          needNoDataView: this.state.rows && this.state.rows.length === 0
        });
      }
    );
  };
  setDataSourceOptions = (column) => {
    if (
      Utility.isEmptyObject(column.datasource) ||
      ![INPUT_TYPE.SELECT, INPUT_TYPE.MULTI_SELECT].includes(column.type)
    )
      return;

    column.options =
      TableManger.getOptionsFromDataSource(column.datasource) || [];
  };
  // fetchAndUpdatedLookupOptions = () => {
  //   if (!this.lookUpCallTrackerRef) {
  //     this.lookUpCallTrackerRef = true;
  //     const columns: IColumn[] = [...this.state.columns];
  //     const filteredColumns = columns.filter(column => LOOKUP_FIELD_ENABLE_COL_TYPE.includes(column.type) && !Utility.isEmptyObject(column.lookup));

  //     const promiseArray = [];
  //     filteredColumns.forEach(column => {
  //       promiseArray.push(Table.getRecordsByPage(
  //         {},
  //         column.lookup.filter,
  //         column?.lookup?.sourceTableId
  //       ))
  //       column.allowAddOption = false;
  //     });
  //     Promise.all(promiseArray).then((response: any[]) => {
  //       if (!Utility.isEmptyObject(response)) {
  //         filteredColumns.forEach((column: IColumn, index: number) => {
  //           const indexInAllColumns = columns.findIndex(col => col.key === column.key);
  //           if (indexInAllColumns !== -1) {
  //             columns[indexInAllColumns].allowAddOption = false;
  //             let options = (response?.[index]?.data?.map((record) => ({ id: record._id, name: record.cells[column.lookup?.sourceColumn] })) || [])?.filter(option => option?.name && !Utility.isEmptyObject(option?.name));
  //             columns[indexInAllColumns].options = options;
  //             TableManger.updateColumn(column.columnCode, this.props.tableName, {...column, options: options})
  //             const sourceTableColumns = TableManger.getTableColumns((column.lookup.objectType).toLowerCase());
  //             sourceTableColumns.forEach((sTColumn: any) => {
  //               if ((sTColumn.id || sTColumn.key) === column?.lookup?.sourceColumn) {
  //                 if (sTColumn.type === INPUT_TYPE.DATE) {
  //                   let dateOptions = columns[indexInAllColumns]?.options?.map(option => ({ ...option, name: DateUtil.getDateStrFromDate(new Date(option.name), DateUtil.getOrgDateFormat()) }));
  //                   columns[indexInAllColumns].options = dateOptions;
  //                   TableManger.updateColumn(column.columnCode, this.props.tableName, {...column, options: dateOptions})
  //                 }
  //                 if (sTColumn.currency && sTColumn.type === INPUT_TYPE.NUMBER) {
  //                   let currencyOptions = columns[indexInAllColumns]?.options?.map(option => ({ ...option, name: toCurrencyFormat(option.name) }));
  //                   columns[indexInAllColumns].options = currencyOptions;
  //                   TableManger.updateColumn(column.columnCode, this.props.tableName, {...column, options: currencyOptions});
  //                 }
  //                 if (!sTColumn.currency && sTColumn.type === INPUT_TYPE.NUMBER) {
  //                   let numberOptions = columns[indexInAllColumns]?.options?.map(option => ({ ...option, name: (option.name).toString() }));
  //                   columns[indexInAllColumns].options = numberOptions;
  //                   TableManger.updateColumn(column.columnCode, this.props.tableName, {...column, options: numberOptions});
  //                 }
  //               }
  //             });

  //           }
  //         })
  //         this.setState({ columns });
  //       }
  //     }).catch((err) => {
  //       this.lookUpCallTrackerRef = false;
  //       console.error('error while fetching Lookup fields', err);
  //     })
  //   }
  // }

  updateColumns = (allRecordData = this.props.data) => {
    let columns = TableManger.getTableVisibleColumns(this.props.tableName);

    const ORG_DATE_FORMAT = convertCRMDateFormatToUILibraryFormat(
      DateUtil.getOrgDateFormat()
    );
    const DATE_FORMAT = `EEE, ${ORG_DATE_FORMAT}, HH:mm aa`;

    columns = columns.map((col) => {
      this.setDataSourceOptions(col);

      if (
        col.type === CUSTOM_INPUT_TYPES.JSON_ARRAY ||
        col.type === INPUT_TYPE.FILE
      ) {
        col.allowColumnSort = false;
      }

      switch (col.columnCode) {
        case COLUMN_CODE.CONTACT.ATTACHMENT:
        case COLUMN_CODE.DEAL.ATTACHMENT:
          col.renderer = this.attachmentRenderer;
          col.allowFilter = false;
          break;
        case COLUMN_CODE.DEAL.STAGE_ID:
          col = {
            ...col,
            type: INPUT_TYPE.DROPDOWN,
            renderer: this.stageRender,
            dropdownConfig: {
              data: PipelineManager.getStagesForCurrentPipeline(),
              displayKey: "name",
              allowSearch: true,
              searchableKey: "name",
              onSelect: (data) => {},
              renderer: (index, option) => option.name
            }
          };
          break;
        case COLUMN_CODE.CONTACT.NAME:
          col.renderer = Renderers.recordNameWithBookSyncStatus;
          break;
        case COLUMN_CODE.CONTACT.LEAD_SCORE:
          col.renderer = Renderers.leadScoreRenderer;
          break;
        case COLUMN_CODE.ACTIVITY.STATUS:
          if (this.props.displayTableName === ACTIVITY_REPORT_NAME) {
            col = {
              ...col,
              type: INPUT_TYPE.TEXT,
              renderer: Renderers.activityStatusRenderer
            };
          }
          break;
        case COLUMN_CODE.CONTACT.NOTE:
        case COLUMN_CODE.DEAL.NOTE:
          col = {
            ...col,
            type: INPUT_TYPE.TEXT,
            disableWebhook: true,
            renderer: ({ value }) => Renderers.noteRenderer(value)
          };
          break;
        case COLUMN_CODE.CONTACT.ADDRESS:
          col = {
            ...col,
            renderer: ({ value }) => Renderers.addressRenderer(value)
          };
          break;
        case COLUMN_CODE.DEAL.AMOUNT:
          col = {
            ...col,
            renderer: ({ value, rowData }) =>
              this.props.tableName === TABLES.ORDER
                ? value
                : Renderers.dealAmountWithCurrencyRenderer(value, rowData)
          };
          break;
        case COLUMN_CODE.CAMPAIGN.SEGMENT_ID:
          if (this.props.tableName !== TABLES.CAMPAIGN) break;

          col = {
            ...col,
            options: (col.options || []).concat(ALL_CONTACT_SEGMENT)
          };
          break;
        case COLUMN_CODE.CONTACT.CREATED_AT:
        case COLUMN_CODE.CONTACT.UPDATED_AT:
          col = {
            ...col,
            renderer: ({ value }) => (
              <DKLabel
                text={getDateAsString(value, DATE_FORMAT)}
                className="fs-m parent-width "
                style={{
                  textAlign: col.textAlign ? col.textAlign : "left"
                }}
              />
            )
          };
          break;
        case COLUMN_CODE.DEAL.PRIMARY_QUOTE_AMOUNT:
          col = {
            ...col,
            renderer: ({ value }) =>
              value && (
                <DKLabel
                  text={toCurrencyFormat(value)}
                  className="fs-m parent-width text-align-right"
                />
              )
          };
          col.name = replaceCountrySpecificKeywords(col.name);
          break;
        case COLUMN_CODE.CONTACT.PRICE_LIST:
          col = {
            ...col,
            editable:
              !!this.props.priceBookPermissions[
                USER_ACTION_TYPES.ASSIGN_TO_CONTACT
              ],
            renderer: ({ value, rowData }) => {
              return (
                !Utility.isEmptyObject(
                  rowData?.[`${col.id}_detail`]?.[0]?.value
                ) && (
                  <DKLabel
                    text={rowData?.[`${col.id}_detail`]?.[0]?.value}
                    className="border-radius-s data-grid-badge-color-1 fs-r width-auto"
                    style={{ padding: "2px 6px" }}
                  />
                )
              );
            }
          };
          break;
        default:
          if (
            col.type === INPUT_TYPE.ADDRESS ||
            col.columnCode === COLUMN_CODE.CONTACT.DETAILED_ADDRESS
          ) {
            const addressColumnId = TableManger.getColumnId(
              this.props.tableName,
              COLUMN_CODE.CONTACT.ADDRESS
            );
            col = {
              ...col,
              allowFilter: false,
              renderer: ({ value, rowData }) => (
                <DetailedAddress
                  styles={{ position: "absolute" }}
                  recordId={rowData.id}
                  readOnly={
                    !col.editable ||
                    !(
                      !!this.props.permissions[USER_ACTION_TYPES.REC_UPDATE] &&
                      rowData.allowRowEdit
                    )
                  }
                  required={
                    col.required ? col.required : col?.requiredByUser ?? false
                  }
                  addressList={value || []}
                  tableName={this.props.tableName}
                  needPickerFixedPosition={true}
                  defaultFullAddress={
                    Utility.isEmptyObject(value)
                      ? rowData[addressColumnId] || ""
                      : ""
                  }
                  onEdit={() => this.refreshData()}
                  onDelete={() => this.refreshData()}
                />
              )
            };
          } else if (col.type === CUSTOM_INPUT_TYPES.JSON_ARRAY) {
            col = {
              ...col,
              renderer: ({ value }) => Renderers.noteRenderer(value)
            };
          } else if (col.type === INPUT_TYPE.FILE) {
            col.renderer = (data) =>
              this.attachmentRenderer(data, !col.editable);
            col.allowFilter = false;
          }
      }

      col.disableWebhook =
        col.disableWebhook ||
        !Utility.isEmptyObject(col.datasource) ||
        ![
          INPUT_TYPE.TEXT,
          INPUT_TYPE.EMAIL,
          INPUT_TYPE.NUMBER,
          INPUT_TYPE.PHONE
        ].includes(col.type);

      col.allowDataSourceEdit = !Utility.isEmptyObject(col.datasource);
      col.allowLookupFieldEdit =
        !Utility.isEmptyObject(col.lookup) &&
        !!this.props.permissions?.[USER_ACTION_TYPES.LOOKUP_FIELD_UPDATE];

      if (TableManger.isSelectField(col)) {
        const allowFieldUpdate =
          !!this.props.permissions[USER_ACTION_TYPES.FIELD_UPDATE];
        col.allowAddOption = isUndefined(col.allowAddOption)
          ? allowFieldUpdate
          : allowFieldUpdate && col.allowAddOption;
      }

      return col;
    });

    let displayTableName =
      this.props.displayTableName ||
      `My ${TABLE_DISPLAY_NAME[this.props.tableName]}`;

    if (this.props.showRecordCount) {
      const recordCount = allRecordData?.totalCount || 0;
      displayTableName = `${displayTableName} (${recordCount})`;
    }

    this.setState(
      {
        tableName: displayTableName,
        columns: this.getUpdatedColumnData(columns)
      },
      () => {
        // this.fetchAndUpdatedLookupOptions();
      }
    );
  };

  stageRender = (data) => {
    if (data?.length === 0) return "";
    let stageIndex = PipelineManager.getStagesForCurrentPipeline().findIndex(
      (stage) => stage.id === data?.value?.[0]
    );
    return (
      <div
        className={`row border-radius-s fs-w width-auto data-grid-badge-color-${
          (stageIndex % 10) + 1
        }`}
        style={{
          padding: "2px 6px"
        }}
      >
        {PipelineManager.getStagesForCurrentPipeline()?.[stageIndex]?.name}
      </div>
    );
  };

  getRowUpdateData = (data) => {
    const { rowData, columnKey, columnData } = data;
    const dataToUpdate = {};
    switch (columnData?.type) {
      case INPUT_TYPE.DROPDOWN:
        dataToUpdate[columnKey] = rowData[columnKey]?.id
          ? [rowData[columnKey].id]
          : [];
        break;
      case INPUT_TYPE.DATE:
        dataToUpdate[columnKey] = DateUtil.getDateWithCurrentTime(
          rowData[columnKey]
        );
        break;
      default:
        dataToUpdate[columnKey] = rowData[columnKey];
    }
    /**
     * If selected column is stage from deal table, and that stage is either WON or LOST, then update deal status and columnId as well
     */
    if (this.props.tableName === TABLES.DEAL) {
      const dealStageColumn = TableManger.getColumn(
        TABLES.DEAL,
        COLUMN_CODE.DEAL.STAGE_ID
      );
      if (columnKey === dealStageColumn.id) {
        const selectedStage =
          PipelineManager.getStagesForCurrentPipeline().find(
            (stage) => stage.id === rowData[columnKey]?.[0]
          );
        const dealStatusColumn = TableManger.getColumn(
          TABLES.DEAL,
          COLUMN_CODE.DEAL.STATUS
        );
        if (
          selectedStage &&
          (selectedStage.isWonStage || selectedStage.isLostStage)
        ) {
          const dealClosingDateColumn = TableManger.getColumn(
            TABLES.DEAL,
            COLUMN_CODE.DEAL.CLOSING_DATE
          );
          dataToUpdate[dealStatusColumn.id] = selectedStage.isWonStage
            ? DEAL_STATUS_CODE.WON
            : DEAL_STATUS_CODE.LOST;
          dataToUpdate[dealClosingDateColumn.id] = new Date().toISOString();
        } else {
          dataToUpdate[dealStatusColumn.id] = DEAL_STATUS_CODE.DEFAULT;
        }
      }
    }
    return dataToUpdate;
  };
  onRowUpdate = (data) => {
    let { rowData } = data;
    if (
      (data.columnData.required || data.columnData.requiredByUser) &&
      Utility.isEmptyObject(data.rowData[data.columnKey])
    ) {
      showAlert("Error", `${data.columnData?.name} cannot be empty.`);
      this.refreshData();
      return;
    }
    let dataToUpdate = this.getRowUpdateData(data);
    const stageColumnId = TableManger.getColumnId(
      TABLES.DEAL,
      COLUMN_CODE.DEAL.STAGE_ID
    );
    const pipelineColumnId = TableManger.getColumnId(
      TABLES.DEAL,
      COLUMN_CODE.DEAL.PIPELINE_ID
    );
    const isStageColumn = data?.columnKey === stageColumnId;
    const stageToUpdateIndex = data.columnData?.dropdownConfig?.data?.findIndex(
      (option) => option.id === dataToUpdate?.[stageColumnId]?.[0]
    );
    const prevStageIndex = data.columnData?.dropdownConfig?.data?.findIndex(
      (option) =>
        option.id ===
        this.props.data?.data?.[data.rowIndex]?.cells?.[stageColumnId][0]
    );

    if (stageToUpdateIndex < prevStageIndex) {
      const isApprovalFlowPresent =
        DealApprovalHelper.isDealStageApprovalFlowsPresent();
      const isApprovalRequired = DealApprovalHelper.isDealApprovalRequired({
        pipeline: [rowData[pipelineColumnId][0]],
        stage: [
          this.props.data?.data?.[data?.rowIndex]?.cells?.[stageColumnId]?.[0]
        ]
      });
      if (isApprovalFlowPresent && isApprovalRequired) {
        getDealApprovalAlert(() => {
          const dataToSendForApproval = buildPayloadForDealApproval(
            { id: rowData.id },
            dataToUpdate,
            prevStageIndex
          );
          if (!Utility.isEmptyObject(dataToSendForApproval?.recordId)) {
            DealService.TriggerDealStageChangeApproval(
              dataToSendForApproval
            ).then((response) => {
              showApprovalTriggerSuccessAlert();
              this.refreshData();
              this.props?.fetchApprovalTriggers({ APPROVAL_PARAMS });
            });
          } else {
            showApprovalTriggerFailedAlert();
          }
        });
        this.refreshData();
      } else {
        this.props.updateRecord({
          recId: rowData.id,
          record: dataToUpdate,
          tableName: this.props.tableName
        });
        Table.updateRecord(dataToUpdate, rowData.id, this.tableId)
          .catch((err) => {})
          .finally(() => {
            this.refreshData();
          });
        if (this.props.onRowUpdate) this.props.onRowUpdate(data);
      }
    } else {
      this.props.updateRecord({
        recId: rowData.id,
        record: dataToUpdate,
        tableName: this.props.tableName
      });
      Table.updateRecord(dataToUpdate, rowData.id, this.tableId)
        .catch((err) => {})
        .finally(() => {
          this.refreshData();
        });
      if (this.props.onRowUpdate) this.props.onRowUpdate(data);
    }
  };
  onColumnUpdate = (data) => {
    const { columnData, property } = data;
    if (Utility.isEmptyObject(columnData?.name)) {
      showAlert(WARNING, ERROR_MSG_FOR_EMPTY_COLUMN);
      this.getColumns();
      return;
    }
    let dataToUpdate = {
      name: columnData.name,
      key: columnData.id,
      [property]: columnData[property]
    };
    let jsonString = JSON.stringify(dataToUpdate);
    let replacedString = replaceHTMLSpecialChars(
      DOMPurify.sanitize(jsonString)
    );
    dataToUpdate = JSON.parse(replacedString);
    let queryParams = "";
    if (property === "options" && columnData[property]?.length === 0) {
      queryParams = `?action=DELETE_OPTION`;
    }
    Table.updateColumn(dataToUpdate, this.tableId, queryParams).then((data) => {
      this.getColumns();
    });
    if (this.props.onColumnUpdate) this.props.onColumnUpdate(data);
  };
  onColumnFreezeToggle = (data: { column: IColumn; freezed: boolean }) => {
    if (!data?.column?.id) return;

    const module = this.props.tableName.toUpperCase();
    let freezedColumnsByModule = this.props.freezedColumns[module] || [];

    freezedColumnsByModule = freezedColumnsByModule.filter(
      (columnId) => columnId !== data.column.id
    );

    if (data.freezed) {
      freezedColumnsByModule = freezedColumnsByModule.concat(data.column.id);
    }

    this.props.setUserPreferences({
      freezedColumns: {
        ...this.props.freezedColumns,
        [module]: freezedColumnsByModule
      }
    });
  };
  onColumnShift = (data, displayColumns) => {
    let { newIndex, oldIndex } = data;
    const allColumns = [...this.state.columns];

    const oldColumnInGridColumns = displayColumns.find(
      (col: any, index: number) => oldIndex === index
    );
    const oldIndexInAllColumns = allColumns.findIndex(
      (col: any, index: number) => col.id === oldColumnInGridColumns?.id
    );

    const newColumnInGridColumns = displayColumns.find(
      (col: any, index: number) => newIndex === index
    );
    const newIndexInAllColumns = allColumns.findIndex(
      (col: any, index: number) => col.id === newColumnInGridColumns?.id
    );

    if (oldIndexInAllColumns === -1 || newIndexInAllColumns === -1) return;

    let newColumnArray = shiftArrayElement(
      allColumns,
      oldIndexInAllColumns,
      newIndexInAllColumns
    );
    const columnIdArray = newColumnArray.map(
      (column) => column.key
    ) as string[];

    Table.shiftColumn(columnIdArray, this.tableId).then((data) => {
      this.getColumns();
    });
    if (this.props.onColumnShift) this.props.onColumnShift(data);
  };
  onRowAdd = (data) => {
    let { rowData } = data;
    Table.addRecord({ cells: { ...rowData } }, this.tableId)
      .then((data) => {
        this.refreshData();
      })
      .catch((err) => {});
    if (this.props.onRowAdd) this.props.onRowAdd(data);
  };
  onColumnAdd = (data, dataSourceProps = null) => {
    let { columnData } = data;
    columnData = TableDataParser.getSanitizedJsonData(columnData);
    Table.addColumn(
      TableDataParser.getColumnToAdd(columnData, dataSourceProps),
      this.tableId
    ).then((data) => {
      this.getColumns();
    });
    if (this.props.onColumnAdd) this.props.onColumnAdd(data);
  };
  onColumnDelete = (data) => {
    let { columnData } = data;
    Table.deleteColumn(columnData.key, this.tableId).then((data) => {
      this.getColumns();
    });
    if (this.props.onColumnDelete) this.props.onColumnDelete(data);
  };
  onRowClick = (rowIndex, rowData) => {
    if (this.props.onRowClick) this.props.onRowClick(rowIndex, rowData);
  };
  onPagination = (currentPage) => {
    this.onPageUpdate(currentPage);
    if (this.props.onPagination) this.props.onPagination(currentPage);
  };
  onSort = ({ order, columnData }) => {
    const sortCol = columnData.id;
    const sortDir = order === "ASC" ? 1 : -1;

    this.props.updateSortConfig({
      sortConfig: { sortCol, sortDir },
      tableName: this.props.metaDataTableName || this.props.tableName
    });
  };
  onSearch = (data) => {
    this.onPageUpdate(1);
    this.props.updateSearchQuery({
      searchQuery: data,
      tableName: this.props.metaDataTableName || this.props.tableName
    });

    if (this.props.onSearch) this.props.onSearch(data);
  };
  onFilter = async (extFilterConditions?: IFilterCondition[]) => {
    this.onPageUpdate(1);
    await this.updateLocalFilters(extFilterConditions);
    this.refreshData();
  };
  updateLocalFilters = (extFilterConditions?: IFilterCondition[]) => {
    this.tableFilters = [];
    this.filter.conditions = [];
    this.filter.logicalOperator =
      this.props.tableMetaData?.filter?.logicalOperator ??
      FILTER_LOGICAL_OPERATORS.AND;
    this.populateExternalFilterConditions(extFilterConditions);

    this.props.tableMetaData?.filter?.conditions?.forEach((condition) => {
      /* Push all conditions for filter payload */
      this.filter.conditions.push(TableManger.getFilterSubCondition(condition));

      if (condition.isExternal) return;

      /* Pushing conditions for filterPopup only
        - Not using deepClone utility here, to avoid issues with dates
      */
      this.tableFilters.push({ ...condition });
    });

    return Promise.resolve();
  };
  deleteRecord = (data, isForceDelete = false) => {
    const displayedRecordSize = this.state.rows.length;
    const recIds = data.map((item) => item.id);
    if (isForceDelete)
      this.props.deleteRecord({ recIds, tableName: this.props.tableName });
    Table.deleteRecordInBulk(
      recIds,
      TableManger.getTableId(this.props.tableName),
      isForceDelete
    )
      .then((res: any) => {
        if (this.needsLinkedRecordCheck())
          this.checkForSkippedRecords(data, res);
        let pageNo = this.props?.tableMetaData?.page;
        if (data.length === displayedRecordSize) {
          if (pageNo !== 1) pageNo = pageNo - 1;
        }
        this.updateSelection(true);
        if (this.props.tableMetaData?.page !== pageNo) {
          this.onPageUpdate(pageNo);
        } else {
          this.refreshData();
        }
        if (this.props.onRowDelete) this.props.onRowDelete(res);
      })
      .catch((err) => {
        if (this.needsLinkedRecordCheck()) {
          if (err.status === 409 && !Utility.isEmptyObject(err.data)) {
            this.isRecordLinked(data);
          }
        }
      });
  };
  checkForSkippedRecords = (data, res) => {
    if (res.success && res.skippedRecordCount > 0) {
      let message = `${res.ids?.length} ${res.ids?.length === 1 ? "record" : "records"} deleted successfully, while ${res.skippedRecordCount} ${res.skippedRecordCount === 1 ? "record" : "records"} currently in used, do you still want to delete?`;
      let updatedData = data.filter((record) => !res.ids?.includes(record.id));
      const buttons = [
        {
          title: "Cancel",
          className: "bg-gray1 border-m"
        },
        {
          title: "Delete",
          className: "bg-red text-white ml-r",
          onClick: () => this.deleteRecord(updatedData, true)
        }
      ];
      showAlert(
        `${res.skippedRecordCount === 1 ? "Record" : "Records"} is in use`,
        message,
        buttons
      );
    }
  };
  needsLinkedRecordCheck = () => {
    return [
      TABLES.CONTACT,
      TABLES.DEAL,
      TABLES.ACTIVITY,
      TABLES.ACCOUNT,
      TABLES.SEGMENT
    ].includes(this.props.tableName);
  };
  onRowSelect = (data = null) => {
    if (data) {
      const index = this.selection.findIndex(
        (item) => item.id === data.rowData.id
      );
      if (index === -1) {
        this.selection.push(data.rowData);
      } else {
        this.selection.splice(index, 1);
      }
    }
    this.updateSelection(false);
  };
  onAllRowSelect = (data) => {
    if (!data.selected) {
      this.selection = [];
    } else {
      this.selection = [...this.state.rows.filter((row) => row.allowRowEdit)];
    }

    this.updateSelection(false);
  };
  updateSelection = (reset = false) => {
    if (reset) {
      this.selection = [];
    }

    const selectionCount = this.selection?.length;

    this.setState({
      showContextMenu:
        selectionCount !== 0 && this.selection.every((sel) => sel.allowRowEdit),
      isAllRowSelected:
        selectionCount !== 0 &&
        selectionCount ===
          this.state.rows.filter((row) => row.allowRowEdit)?.length
    });

    this.props.onRowSelect && this.props.onRowSelect(this.selection);
  };

  onStageChangeBulk = (
    data: any,
    selection: any[],
    onlyStageChanged = false,
    isAdvanceEditOperation
  ) => {
    const approvalAPIPayload = [];
    const bulkUpdateAPIPayload = [];
    const selectedRows = Utility.deepCloneObject(selection);
    const stageColumnId = TableManger.getColumnId(
      TABLES.DEAL,
      COLUMN_CODE.DEAL.STAGE_ID
    );
    const pipelineColumnId = TableManger.getColumnId(
      TABLES.DEAL,
      COLUMN_CODE.DEAL.PIPELINE_ID
    );
    const stageColumn = this.state.columns.find(
      (column) => column.id === stageColumnId
    );
    const stages = stageColumn?.dropdownConfig?.data?.stage ?? [];

    selectedRows?.forEach((row) => {
      const sourceStageIndex = stages?.findIndex(
        (stage) => stage.id === row?.[stageColumnId]?.[0]
      );
      const targetStageIndex = stages?.findIndex(
        (stage) => stage.id === data?.stage?.[0]?.id
      );

      const isApprovalFlowPresent =
        DealApprovalHelper.isDealStageApprovalFlowsPresent();
      const isApprovalRequired = DealApprovalHelper.isDealApprovalRequired({
        pipeline: row?.[pipelineColumnId],
        stage: row?.[stageColumnId]
      });
      if (
        isApprovalFlowPresent &&
        isApprovalRequired &&
        targetStageIndex < sourceStageIndex
      ) {
        const dataToSendForApproval = buildPayloadForDealApproval(
          { id: row?.id },
          {
            [stageColumnId]: [...data?.stage?.map((item) => item.id)]
          },
          stages?.[sourceStageIndex]?.id
        );
        if (!Utility.isEmptyObject(dataToSendForApproval?.recordId)) {
          approvalAPIPayload.push(dataToSendForApproval);
        }
      } else {
        bulkUpdateAPIPayload.push({
          _id: row.id,
          cells: {
            [stageColumnId]: [...data?.stage?.map((item) => item.id)],
            [pipelineColumnId]: data?.pipeline
          }
        });
      }
    });

    if (!Utility.isEmptyObject(approvalAPIPayload)) {
      const onSendForApproval = async () => {
        const promises = approvalAPIPayload?.map(async (payload) => {
          return await DealService.TriggerDealStageChangeApproval(payload);
        });
        const response = await Promise.all(promises);
        showApprovalTriggerSuccessAlert();
        if (onlyStageChanged) {
          this.refreshData();
          this.props?.fetchApprovalTriggers({ APPROVAL_PARAMS });
        }
      };
      getDealApprovalAlert(() => onSendForApproval());
    }

    if (!Utility.isEmptyObject(bulkUpdateAPIPayload)) {
      const callAPI = () => {
        Table.updateBulkRecord(
          bulkUpdateAPIPayload,
          TableManger.getTableId(this.props.tableName)
        ).then(() => {
          if (onlyStageChanged || isAdvanceEditOperation) {
            this.refreshData();
            if (onlyStageChanged) {
              showToast("Records Updated Successfully.", TOAST_TYPE.SUCCESS);
            }
          }
        });
      };
      if (isAdvanceEditOperation) {
        setTimeout(() => callAPI(), 1000);
      } else {
        callAPI();
      }
    }
  };

  onContextMenuOp = (data, isAdvanceEditOperation = false) => {
    const copyOfData = Utility.deepCloneObject(data);
    const stageColumnId = TableManger.getColumnId(
      TABLES.DEAL,
      COLUMN_CODE.DEAL.STAGE_ID
    );
    const pipelineColumnId = TableManger.getColumnId(
      TABLES.DEAL,
      COLUMN_CODE.DEAL.PIPELINE_ID
    );
    const hasStageChanged = Object.keys(copyOfData).some(
      (columnId) => columnId === stageColumnId
    );
    if (hasStageChanged) {
      const dataToSend = data;
      if (isAdvanceEditOperation) {
        dataToSend[stageColumnId] = dataToSend[stageColumnId]?.map((id) => ({
          id
        }));
        this.onStageChangeBulk(
          {
            stage: dataToSend[stageColumnId],
            pipeline: dataToSend[pipelineColumnId]
          },
          this.selection,
          Object.keys(data)?.length === 1,
          isAdvanceEditOperation
        );
      } else {
        this.onStageChangeBulk(
          {
            stage: dataToSend[stageColumnId],
            pipeline: dataToSend[pipelineColumnId]
          },
          this.selection,
          Object.keys(data)?.length === 1,
          isAdvanceEditOperation
        );
      }
      delete copyOfData[pipelineColumnId];
      delete copyOfData[stageColumnId];
    }
    if (!Utility.isEmptyObject(copyOfData)) {
      let dataToUpdate = [];
      this.selection.forEach((row) => {
        let cells = {};
        Object.keys(copyOfData).forEach((columnId) => {
          if (isAdvanceEditOperation) {
            if (
              !Utility.isEmptyObject(copyOfData[columnId]) ||
              Utility.isValidDate(copyOfData[columnId])
            ) {
              cells[columnId] = copyOfData[columnId];
            }
          } else {
            cells[columnId] = [...copyOfData[columnId].map((item) => item.id)];
          }
        });
        dataToUpdate.push({
          _id: row.id,
          cells
        });
      });
      Table.updateBulkRecord(
        dataToUpdate,
        TableManger.getTableId(this.props.tableName)
      ).then((data) => {
        showToast("Records Updated Successfully.", TOAST_TYPE.SUCCESS);
        this.refreshData();
      });
    }
  };
  getDeleteConfirmation = (data) => {
    const buttons = [
      {
        title: "Cancel",
        className: "bg-gray1 border-m"
      },
      {
        title: "Delete",
        className: "bg-red text-white ml-r",
        onClick: () => this.deleteRecord(data)
      }
    ];
    showAlert(
      "Delete Record?",
      "Deleting this record will delete it permanently you will not be able to restore it.",
      buttons
    );
  };
  isRecordLinked = (data) => {
    let title = `Record is in use`;
    let message = `This record is already in use, do you still want to delete it?`;
    if (data?.length > 1) {
      title = `Records are in use`;
      message = `These records are already in use, do you still want to delete them?`;
    }
    const buttons = [
      {
        title: "Cancel",
        className: "bg-gray1 border-m"
      },
      {
        title: "Delete",
        className: "bg-red text-white ml-r",
        onClick: () => this.deleteRecord(data, true)
      }
    ];
    showAlert(title, message, buttons);
  };
  onContextMenuCancel = () => {
    this.getRecords();
  };
  onLookupSourceClick = (columnName: string, columnData?: IColumn) => {
    showAddLookupFieldPopup(
      {
        tableName: TableManger.getTableNameFromId(
          columnData?.lookup?.sourceTableId
        ),
        columnName: columnName,
        lookup: columnData?.lookup || null
      },
      (res) => this.onSaveLookupField(res, columnData),
      () => {}
    );
  };
  onColumnDataSourceClick = (columnName: string, columnData?: IColumn) => {
    showDataSourcePopup(
      {
        tableName: this.props.tableName,
        columnName: columnName,
        dataSource: columnData?.datasource || null
      },
      (res) => this.onSaveDataSource(res, columnData),
      () => {}
    );
  };
  onSaveDataSource = (response: any, columnToUpdate?: IColumn) => {
    if (Utility.isEmptyObject(columnToUpdate?.id)) {
      this.onColumnAdd(
        {
          columnData: { name: response.columnName }
        },
        {
          datasource: response.datasource,
          type: response.dataSourceFieldType
        }
      );
    } else {
      this.onColumnUpdate({
        columnData: {
          ...columnToUpdate,
          name: response.columnName,
          datasource: response.datasource,
          type: response.dataSourceFieldType
        }
      });
    }
  };

  onSaveLookupField = (response: any, columnToUpdate?: IColumn) => {
    if (Utility.isEmptyObject(columnToUpdate?.id)) {
      this.onColumnAdd(
        {
          columnData: { name: response.name }
        },
        {
          lookup: response.lookup,
          type: response.type
        }
      );
    } else {
      this.onColumnUpdate({
        columnData: {
          ...columnToUpdate,
          name: response.name,
          lookup: response.lookup,
          type: response.type
        },
        property: "lookup"
      });
    }
  };
  openAttachmentPicker = () => {
    this.attachmentPickerExists = true;
    this.attachmentOpenFileRef.current.click();
  };
  getAttachmentPicker = () => {
    return (
      <input
        id="dataGridAttachment"
        type="file"
        accept="image/*, application/pdf, .txt, .doc, .xls , .ppt, .docx, .xlsx, .pptx"
        multiple={true}
        ref={this.attachmentOpenFileRef}
        style={{ display: "none" }}
        onClick={(e) => {
          this.initializeImageSelect();
          e.stopPropagation();
        }}
        onChange={(e) => {
          if (!e.target.files) return;

          const files = Array.from(e.target.files);

          const fileExceedingLimit = files.filter(
            (file) => file.size > MAX_FILE_SIZE
          );
          if (fileExceedingLimit.length) {
            showAlert(
              "File size exceeds! ",
              `File size should not be more than ${getHumanReadableFileSize(
                MAX_FILE_SIZE
              )}. Please optimize <em>${fileExceedingLimit
                .map((file) => file.name)
                .join(", ")}</em> or select different file.`
            );
            return;
          }

          showToast("Uploading attachments...");

          Promise.all(files.map((file) => uploadFileToAWS(file)))
            .then((responseList) => {
              const attachmentColID = TableManger.getColumnId(
                this.props.tableName,
                COLUMN_CODE.CONTACT.ATTACHMENT
              );
              const attachmentCol = TableManger.getColumnById(
                this.props.tableName,
                attachmentColID
              );
              const rowData = { ...this.state.attachmentData.rowData };
              const attachments = rowData[attachmentColID]
                ? Utility.decodeJSON(rowData[attachmentColID])
                : [];
              let hasValidAttachment = false;
              responseList.forEach((attachmentPath, index) => {
                const file = files[index];
                if (
                  !Utility.isEmptyObject(attachmentPath) &&
                  file &&
                  isString(attachmentPath)
                ) {
                  attachments.push({
                    path: attachmentPath,
                    title: file.name,
                    type: file.type
                  });

                  hasValidAttachment = true;
                }
              });

              if (!hasValidAttachment) return;

              rowData[attachmentColID] = Utility.encodeJSON(attachments);
              this.onRowUpdate({
                rowIndex: this.state.attachmentData.rowIndex,
                rowData: rowData,
                columnKey: attachmentColID,
                columnData: attachmentCol
              });
            })
            .catch((err) => {
              console.log("Error while attaching files ", err);
            })
            .finally(() => {
              this.attachmentPickerExists = false;
            });
        }}
      />
    );
  };
  initializeImageSelect = () => {
    document.body.onfocus = this.checkIt;
  };
  checkIt = () => {
    if (document.querySelector("#dataGridAttachment")) {
      const fileElement = document.querySelector(
        "#dataGridAttachment"
      ) as HTMLInputElement;
      if (fileElement.files && fileElement.files.length === 0) {
        this.attachmentPickerExists = false;
        return;
      }
    }
  };

  attachmentRenderer = ({ value, rowIndex }: any, columnReadOnly = false) => {
    const rowData = this.state.rows?.[rowIndex];
    const canEdit =
      (!!this.props.permissions[USER_ACTION_TYPES.REC_UPDATE] ||
        rowData?.allowRowEdit) &&
      !columnReadOnly;

    const attachmentsToDisplay =
      Renderers.attachmentRenderer({ value, rowIndex }) || [];
    if (canEdit) {
      attachmentsToDisplay.unshift(
        <DKIcon
          src={DKIcons.ic_add}
          className="ic-xs-2 cursor-hand opacity-5 pb-xs"
          onClick={(e) => {
            e?.stopPropagation?.();
            this.addAttachment(rowIndex);
          }}
        />
      );
    }
    return (
      <div
        className="row align-items-start parent-height hide-scroll-bar"
        style={{ overflowX: "auto", gap: 4 }}
      >
        {attachmentsToDisplay}
      </div>
    );
  };

  addAttachment = (index) => {
    let data = {
      rowData: this.state.rows[index],
      rowIndex: index
    };
    this.setState(
      {
        attachmentData: data
      },
      () => this.openAttachmentPicker()
    );
  };

  onNewNotificationReceived() {
    const recentNotification = this.props.notifications?.data?.[0];
    if (
      recentNotification &&
      !this.props.preventRefreshOnNotification &&
      recentNotification.objectType.toLowerCase() === this.props.tableName
    ) {
      this.refreshData();
    }
  }
  onTableFilterUpdate = ({ query: filterConditions, logicalOperator }) => {
    const updatedConditions =
      this.props.tableMetaData?.filter?.conditions?.filter(
        (condition) => condition.isExternal
      ) || [];
    updatedConditions.push(...filterConditions);
    this.props.updateFilter({
      tableName: this.props.metaDataTableName || this.props.tableName,
      filter: {
        conditions: updatedConditions,
        logicalOperator: logicalOperator ?? this.filter.logicalOperator
      }
    });
  };
  onPageUpdate = (page: number) => {
    this.props.updatePage({
      page,
      tableName: this.props.metaDataTableName || this.props.tableName
    });
  };
}

const mapStateToProps = (state: RootState, ownProps) => ({
  data: state.records.data[ownProps.tableName],
  tableData: state.table.data[ownProps.tableName],
  permissions:
    state.rolesPermission?.currentUserModuleWisePermissions?.[
      ownProps.tableName
    ] || {},
  users: state.tenant.users,
  teams: state.tenant.teams,
  hiddenColumns:
    state.userPref.hiddenColumns?.[ownProps.tableName.toUpperCase()] || [],
  freezedColumns: state.userPref.freezedColumns || {},
  notifications: state.userPref.notifications,
  tableMetaData:
    state.tableMetaData[ownProps.metaDataTableName || ownProps.tableName],
  priceBookPermissions:
    selectPermissionsByModule(state, TABLES.BOOKS_PRICE_LIST) ?? {}
});
const mapDispatchToProps = {
  addRecord,
  setRecords,
  updateFilter,
  updatePage,
  updateSearchQuery,
  updateSortConfig,
  updateRecord,
  deleteRecord,
  fetchRecordsByTable,
  fetchApprovalTriggers,
  setUserPreferences,
  setSettingPopup,
  setSelectedSetting
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(DataGridHolder);
