import {
  DKLabel,
  INPUT_TYPE,
  DKFilterPopup,
  showAlert,
  DKButton,
  DKIcons
} from "deskera-ui-library";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchCRMSettings,
  selectCRMSettings
} from "../../redux/slices/tenantSlice";
import Utility from "../../utility/Utility";
import { cloneDeep } from "lodash";
import { updateTenantSettings } from "../../services/tenant";
import { FILTER_OPERATORS } from "../../constants/Enum";

export default function TicketRequestorEmailCriteria(props: any) {
  const dispatch = useAppDispatch();
  const crmSettings = useAppSelector(selectCRMSettings());
  const [emailFilterList, setEmailFilterList] = useState([]);
  const [showEmailCriteriaPopup, setShowEmailCriteriaPopup] = useState(false);

  useEffect(() => {
    updateInitialState();
  }, [crmSettings?.ticketSetting]);

  const updateInitialState = () => {
    setEmailFilterList(
      cloneDeep(crmSettings?.ticketSetting?.requestorEmailMatchCriteria ?? [])
    );
  };

  const onSave = async () => {
    try {
      const payload = {
        ticketSetting: { requestorEmailMatchCriteria: emailFilterList }
      };

      const response = await updateTenantSettings(payload);
      if (!Utility.isEmptyObject(response)) {
        dispatch(fetchCRMSettings({}));
      }
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };

  const onFilterChanged = (filteredData) => {
    let anyValueIsEmpty = filteredData?.some((list) =>
      Utility.isEmptyObject(list?.value)
    );

    if (anyValueIsEmpty) {
      showAlert("Empty value", "Some of fields have empty value");
      return;
    }

    let invalidEmail = filteredData?.some(
      (list) =>
        !Utility.isValidEmail(list?.value) &&
        list.condition === FILTER_OPERATORS.EQUAL
    );

    if (invalidEmail) {
      showAlert("Invalid email", "Please provide valid email");
      return;
    }
    setShowEmailCriteriaPopup(false);
    onSave();
  };

  const emailCriteriaPopup = () => {
    return (
      <div className="transparent-background position-absolute parent-width z-index-3 email-requestor-criteria">
        <DKFilterPopup
          filterData={emailFilterList}
          headers={[
            {
              id: "email",
              name: "Email",
              type: INPUT_TYPE.EMAIL,
              required: false,
              width: 250,
              editable: true,
              hidden: false,
              systemField: true,
              uiVisible: true,
              columnCode: "email",
              requiredByUser: true
            }
          ]}
          onSubmit={(data, operator) => {
            onFilterChanged(data);
          }}
          onCancel={() => {
            updateInitialState();
            setShowEmailCriteriaPopup(false);
          }}
        />
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="column parent-width">
        <DKLabel
          text={`Support Ticket Requestor Email Criteria`}
          className="mr-m mb-xs fw-m"
        />
        <DKLabel
          className="text-gray text-ellipsis"
          text="Matched emails will be excluded from requestor in support ticket."
        />
      </div>
    );
  };

  return (
    <div className="column parent-width border-m border-radius-m p-r mt-l">
      <div className="column parent-width">
        <div className="row parent-width justify-content-between row-responsive">
          {renderHeader()}
          <div className="row width-auto justify-content-end align-items-center">
            <DKButton
              title={`View Criteria ${crmSettings?.ticketSetting?.requestorEmailMatchCriteria?.length > 0 ? "(" + crmSettings?.ticketSetting?.requestorEmailMatchCriteria?.length + ")" : ""}`}
              icon={DKIcons.ic_arrow_right2}
              className="align-self-end bg-gray1 border-m"
              onClick={() => setShowEmailCriteriaPopup(true)}
              isReverse={true}
            />
          </div>
        </div>
      </div>
      {showEmailCriteriaPopup && emailCriteriaPopup()}
    </div>
  );
}
