import {
  DKLabel,
  INPUT_TYPE,
  Toggle,
  DKInput,
  INPUT_VIEW_DIRECTION,
  DKButton
} from "deskera-ui-library";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchCRMSettings,
  selectCRMSettings
} from "../../redux/slices/tenantSlice";
import { isEqual } from "lodash";
import Utility from "../../utility/Utility";
import { IRole } from "../../model/Roles";
import {
  fetchRoles,
  selectRoles
} from "../../redux/slices/rolesPermissionSlice";
import { updateTenantSettings } from "../../services/tenant";

export default function TicketAssigneeBasedOnRole(props: any) {
  const dispatch = useAppDispatch();
  const roles: IRole[] = useAppSelector(selectRoles);
  const crmSettings = useAppSelector(selectCRMSettings());
  const [assigneeInputToggle, setAssigneeInputToggle] = useState(false);
  const [userRoleList, setUserRoleList] = useState([]);

  useEffect(() => {
    if (Utility.isEmptyObject(roles)) {
      dispatch(fetchRoles({}))
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAssigneeInputToggle(
      crmSettings?.ticketSetting?.assigneeRoles?.length > 0
    );
    if (crmSettings?.ticketSetting?.assigneeRoles?.length > 0) {
      let roleIndexes =
        crmSettings?.ticketSetting?.assigneeRoles?.map((roleId) =>
          roles.findIndex((role) => role?._id === roleId)
        ) ?? [];

      setUserRoleList(roleIndexes);
    }
  }, [crmSettings?.ticketSetting, roles]);

  const onSave = async (onDisable = false) => {
    try {
      const rolesIndexes =
        userRoleList?.length > 0 && !onDisable ? userRoleList : [];
      let selectedRolesIds = [];
      if (rolesIndexes?.length > 0) {
        selectedRolesIds = rolesIndexes?.map((index) => roles[index]?._id);
      }

      const payload = {
        ticketSetting: { assigneeRoles: selectedRolesIds }
      };

      const response = await updateTenantSettings(payload);
      if (!Utility.isEmptyObject(response)) {
        dispatch(fetchCRMSettings({}));
      }
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };

  const isValueChange = () => {
    let prevRoles =
      crmSettings?.ticketSetting?.assigneeRoles?.length > 0
        ? crmSettings?.ticketSetting?.assigneeRoles?.map((roleId) =>
            roles.findIndex((role) => role?._id === roleId)
          )
        : [];

    return !isEqual(prevRoles, userRoleList);
  };

  const renderRolesDropdown = () => {
    return (
      <div
        className="column width-auto"
        style={{ minWidth: 200, maxWidth: 250 }}
      >
        <DKInput
          title=""
          type={INPUT_TYPE.DROPDOWN}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
          className=""
          value={userRoleList}
          canValidate={false}
          onChange={(value) => {}}
          renderer={(values) => {
            return (
              <div className="row width-auto flex-wrap" style={{ gap: 5 }}>
                {values.map((value) => {
                  return roles[value] ? (
                    <DKLabel
                      className="bg-white border-radius-s p-h-xs border-m"
                      text={roles[value]?.name || "-"}
                    />
                  ) : null;
                })}
              </div>
            );
          }}
          dropdownConfig={{
            title: "Select roles",
            multiSelect: true,
            checkMarkColor: "bg-button",
            allowSearch: false,
            searchableKey: "name",
            style: { minWidth: 230 },
            className: "shadow-m",
            data: roles ?? [],
            renderer: (index, obj) => {
              return <DKLabel text={`${obj.name}`} />;
            },
            onSelect: (index, obj, rowIndex) => {
              let roles = [...userRoleList];
              let roleExists = roles.findIndex(
                (roleIndex) => roleIndex === index
              );

              if (roleExists === -1) {
                roles.push(index);
              } else if (roleExists !== -1) {
                roles.splice(roleExists, 1);
              }
              setUserRoleList(roles);
            },
            onClear: () => {
              setUserRoleList([]);
            }
          }}
        />
      </div>
    );
  };

  const renderToggleButton = () => {
    return (
      <div className="ml-m">
        <Toggle
          isOn={assigneeInputToggle}
          onChange={() => {
            if (assigneeInputToggle) {
              setUserRoleList([]);
              onSave(true);
            }
            setAssigneeInputToggle(!assigneeInputToggle);
          }}
          className=""
          color="bg-green"
          style={{ width: 100 }}
        />
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="column" style={{ width: "70%" }}>
        <DKLabel
          text={`Support Ticket Role Based Assignees`}
          className="mr-m mb-xs fw-m"
        />
        <DKLabel
          className="text-gray text-ellipsis"
          text="Select your assignee roles. Assignees will be displayed in the assignee selection for the support ticket."
        />
      </div>
    );
  };

  return (
    <div className="column parent-width border-m border-radius-m position-relative p-r mt-l">
      <div className="column parent-width">
        <div className="row parent-width justify-content-between row-responsive">
          {renderHeader()}
          <div className="row justify-content-end align-items-center width-300">
            {assigneeInputToggle && (
              <div className="row justify-content-end ">
                {renderRolesDropdown()}
                {isValueChange() && (
                  <DKButton
                    title="Save"
                    className="bg-button text-white m-h-s"
                    onClick={() => onSave()}
                  />
                )}
              </div>
            )}
            {renderToggleButton()}
          </div>
        </div>
      </div>
    </div>
  );
}
