import { COLUMN_CODE, TableManger, TABLES } from "./TableManger";

import Table from "../services/table";
import Utility from "../utility/Utility";
import { LOCAL_STORAGE_KEYS } from "../constants/Enum";
import { DealService } from "../services/deal";

export class PipelineManager {
  static pipelines: any = {};
  static stageMetaData: any = [];

  static get(refresh = false): Promise<any> {
    let options = {
      colIdsToFetchRef: TableManger.getColumnId(
        TABLES.PIPELINE,
        COLUMN_CODE.PIPELINE.STAGE_ID_S
      ),
      pageSize: 100
    };
    const pipelineTableId = TableManger.getTableId(TABLES.PIPELINE);
    if (refresh || this.pipelines?.data?.length === 0) {
      PipelineManager.getStageMetaData();
      return Table.getRecordsByPage(options, null, pipelineTableId).then(
        (res) => {
          this.set(res);
          return Promise.resolve(res);
        }
      );
    } else {
      return Promise.resolve(this.pipelines);
    }
  }
  static set(pipeline) {
    this.pipelines = pipeline;
  }

  static updatePipelineData(updatedPipelineRecord) {
    if (!updatedPipelineRecord?._id) return;

    this.pipelines.data.forEach((pipeline, pipelineIndex) => {
      if (pipeline._id !== updatedPipelineRecord._id) return;

      this.pipelines.data[pipelineIndex] = updatedPipelineRecord;
    });
  }

  static clear() {
    this.set({});
  }
  static processPipelineData() {
    let dataToReturn = [];
    const pipelineNameColId = TableManger.getColumnId(
      TABLES.PIPELINE,
      COLUMN_CODE.PIPELINE.NAME
    );
    const stageColumnId = TableManger.getColumnId(
      TABLES.PIPELINE,
      COLUMN_CODE.PIPELINE.STAGE_ID_S
    );
    const stageNameColumnId = TableManger.getColumnId(
      TABLES.STAGE,
      COLUMN_CODE.STAGES.NAME
    );
    const stageWonStageColumnId = TableManger.getColumnId(
      TABLES.STAGE,
      COLUMN_CODE.STAGES.WON_STAGE
    );
    const stageLostStageColumnId = TableManger.getColumnId(
      TABLES.STAGE,
      COLUMN_CODE.STAGES.LOST_STAGE
    );
    const stageDetailsKey = `${stageColumnId}_detail`;
    if (this.pipelines && this.pipelines.data) {
      this.pipelines.data.forEach((pipeline) => {
        let pipelineData = {};
        const pipelineMetaData = PipelineManager.stageMetaData?.data?.find(
          (data) => data.pipelineId === pipeline._id
        );
        pipelineData["id"] = pipeline._id;
        pipelineData["name"] = pipeline.cells[pipelineNameColId];
        pipelineData["stages"] =
          pipeline.cells[stageDetailsKey]?.map((stage) => {
            const stageData = pipelineMetaData?.data.find(
              (data) => data.stageId === stage._id
            );
            return {
              id: stage._id,
              name: stage.cells[stageNameColumnId],
              isWonStage: stage.cells[stageWonStageColumnId],
              isLostStage: stage.cells[stageLostStageColumnId],
              ...stageData
            };
          }) ?? [];
        dataToReturn.push(pipelineData);
      });
    }
    return dataToReturn;
  }
  static getStagesForCurrentPipeline() {
    const pipelines = this.processPipelineData();
    if (!pipelines?.length) return [];

    let currentSelectedPipelineIndex = Utility.isEmptyObject(
      Utility.getPersistentValue(LOCAL_STORAGE_KEYS.SELECTED_PIPELINE)
    )
      ? 0
      : parseInt(
          Utility.getPersistentValue(LOCAL_STORAGE_KEYS.SELECTED_PIPELINE)
        );

    return pipelines[currentSelectedPipelineIndex]?.stages || [];
  }
  static getStageMetaData = async (payload?: any, params = {}) => {
    try {
      PipelineManager.stageMetaData = await DealService.fetchStageMetaData(
        payload,
        params
      );
    } catch (err) {
      PipelineManager.stageMetaData = [];
    }
  };
}
